import { CdkMenuTrigger } from '@angular/cdk/menu';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, fromEvent, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'shabic-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent implements OnInit, OnDestroy {
  private _destroy = new Subject<void>();
  private _opened = new BehaviorSubject<boolean>(false);

  opened$ = this._opened.asObservable();
  readonly positions = [
    new ConnectionPositionPair(
      { originX: 'end', originY: 'bottom' },
      { overlayX: 'end', overlayY: 'top' }
    ),
  ];

  @Input() wrapped = false;

  @ViewChild(CdkMenuTrigger) trigger?: CdkMenuTrigger;

  ngOnInit(): void {
    fromEvent(window, 'scroll')
      .pipe(takeUntil(this._destroy))
      .subscribe(() => {
        if (this._opened.value) {
          this.trigger?.close();
        }
      });
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  onOpen() {
    this._opened.next(true);
  }

  onClose() {
    this._opened.next(false);
  }

  close() {
    this.trigger?.close();
  }
}
