import { Injectable, Injector, OnDestroy } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { Subject, takeUntil } from 'rxjs';
import { ComponentPortal } from '@angular/cdk/portal';
import { SearchPopoverComponent } from '../components';

@Injectable()
export class SearchService implements OnDestroy {
  private _showPopover = new Subject<HTMLElement>();
  private _search = new Subject<void>();
  private _destroy = new Subject<void>();
  private overlayRef?: OverlayRef;

  search$ = this._search.asObservable();

  constructor(private overlay: Overlay) {
    this._showPopover
      .asObservable()
      .pipe(takeUntil(this._destroy))
      .subscribe(element => {
        this.createPopover(element);
      });
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
    this.closePopover();
  }

  showPopover(origin: HTMLElement) {
    this._showPopover.next(origin);
  }

  onSearch() {
    this._search.next();
    this.closePopover();
  }

  closePopover() {
    this.overlayRef?.dispose();
  }

  private createPopover(origin: HTMLElement) {
    const config = new OverlayConfig({
      disposeOnNavigation: true,
      width: getComputedStyle(origin).width,
      maxHeight: '10rem',
      backdropClass: 'transparent',
    });

    config.positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(origin)
      .withGrowAfterOpen(true)
      .withPositions([
        {
          originX: 'end',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'top',
        },
      ]);

    config.scrollStrategy = this.overlay.scrollStrategies.reposition();

    this.closePopover();

    const portalInjector = Injector.create({
      providers: [{ provide: SearchService, useValue: this }],
    });

    this.overlayRef = this.overlay.create(config);
    this.overlayRef.attach(
      new ComponentPortal(SearchPopoverComponent, null, portalInjector)
    );
  }
}
