import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormComponent } from '@shabic/form-control';
import {
  AuthResponse,
  Company,
  Contact,
  ENV,
  IEnvironment,
  isErrorResponse,
  ROLE_SUPPLIER,
} from '@shabic/models';
import { AccountService, AuthService, SnackBarEvent } from '@shabic/core';
import { CLIENT_APP, SUCCESS } from '@shabic/constants';
import { AppEvent, EAppEvent, EventBrokerService } from '@shabic/event-broker';

@Component({
  selector: 'shabic-profile-info',
  templateUrl: './profile-info.component.html',
  styleUrls: ['./profile-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileInfoComponent
  extends FormComponent<Contact | Company | AuthResponse | unknown>
  implements OnInit
{
  private _contact?: Contact;

  formGroup: FormGroup = new FormGroup({
    mediaId: new FormControl(''),
    avatar: new FormControl(''),
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    phone: new FormControl('', [Validators.required]),
    langKey: new FormControl(),
    supplier: new FormControl(),
  });

  get isClientApp(): boolean {
    return this.env.app === CLIENT_APP;
  }

  constructor(
    private accountService: AccountService,
    private eventBrokerService: EventBrokerService,
    private authService: AuthService,
    @Inject(ENV) private env: IEnvironment,
    protected override cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.getAccount();
  }

  onSubmit(): void {
    if (this.env.app === CLIENT_APP) {
      this.submitForm(
        this.formGroup,
        this.accountService.toggleSupplier(this.formGroup.value.supplier)
      ).subscribe(status => {
        if (status === SUCCESS) {
          this.authService.upgradeToken().subscribe();
        }
      });
    }

    this.submitForms(
      [this.formGroup],
      [
        this.accountService.update({
          mediaId: this.formGroup.value.mediaId,
          langKey: this.formGroup.value.langKey,
        }),
      ]
    ).subscribe(([status]) => {
      if (status === SUCCESS) {
        this.eventBrokerService.emit(
          new AppEvent(EAppEvent.ChangeLanguage, this.formGroup.value.langKey)
        );
      }
    });
  }

  onLogout(): void {
    this.eventBrokerService.emit(new AppEvent(EAppEvent.LogOut, null));
  }

  onDelete() {
    if (!this._contact) {
      return;
    }

    this.accountService.blockUser(this._contact).subscribe(response => {
      if (!isErrorResponse(response)) {
        this.eventBrokerService.emit(new AppEvent(EAppEvent.LogOut, null));
      } else {
        const event = new SnackBarEvent(
          response.payload.message || '',
          'error'
        );

        this.eventBrokerService.emit(new AppEvent(event.type, event.payload));
      }
    });
  }

  private getAccount(): void {
    this.accountService.get().subscribe(response => {
      if (response.payload instanceof Contact) {
        this._contact = response.payload;
        const isSupplier =
          this.authService.currentUser.roles.includes(ROLE_SUPPLIER);
        this.formGroup.patchValue({
          ...response.payload,
          avatar: response.payload.avatar,
          supplier: isSupplier,
        });

        this.formGroup.get('firstName')?.disable();
        this.formGroup.get('lastName')?.disable();
        this.formGroup.get('email')?.disable();
        this.formGroup.get('phone')?.disable();
      }
    });
  }
}
