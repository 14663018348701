<shabic-animate>
  <div class="flex items-center justify-between mb-4">
    <shabic-headline variant="h1">
      {{ 'page.bank-information' | translate }}
    </shabic-headline>

    <shabic-filter-dropdown
      label="common.bank-status"
      [options]="statuses"
      [value]="(params$ | async)?.['statuses']"
      (sort)="onSort($event)"
    ></shabic-filter-dropdown>
  </div>

  <ng-container *ngIf="list$ | async as list">
    <ng-container *ngIf="loading$ | async; else accountsTemplate">
      <div class="text-grey flex justify-center py-5">
        <shabic-spinner size="xl"></shabic-spinner>
      </div>
    </ng-container>

    <ng-template #accountsTemplate>
      <div class="grid gap-5.5">
        <div *ngFor="let account of list.content">
          <div
            class="grid gap-3 bg-grey-350 rounded-3xl p-3 desktop:max-w-[560px]"
          >
            <div class="flex items-center gap-3">
              <img
                [src]="account.bank.logo"
                shabicImage
                class="rounded-2xl w-7 h-7"
                width="64"
                height="64"
              />

              <div class="inline-flex item-center gap-1">
                <shabic-headline variant="h5">
                  {{ account.name }}
                </shabic-headline>

                <span *ngIf="account.isActive" class="text-green">
                  <ng-template shabicIcon="check"></ng-template>
                </span>

                <span *ngIf="account.isDiasable" class="text-red">
                  <ng-template shabicIcon="close"></ng-template>
                </span>
              </div>
            </div>

            <div class="desktop:grid grid-cols-2 gap-3">
              <div class="bg-white p-4 rounded-3xl">
                <!-- <shabic-property-with-label label="common.account-number">
                  <span class="truncate">
                    {{ account.accountNumber }}
                  </span>
                </shabic-property-with-label> -->

                <shabic-property-with-label label="common.iban-number">
                  <span class="truncate">
                    {{ account.iban }}
                  </span>
                </shabic-property-with-label>
              </div>

              <div
                class="grid gap-3 content-center bg-white p-4 rounded-3xl mt-1 desktop:mt-0 min-h-[144px]"
              >
                <span class="text-primary">
                  <ng-template shabicIcon="icon-bulk"></ng-template>
                </span>

                <shabic-property-with-label label="common.current-balance">
                  <span class="text-secondary text-lg font-semibold">
                    {{ account.availableBalance | currency }}
                  </span>
                </shabic-property-with-label>
              </div>
            </div>

            <div class="flex gap-3 flex-wrap">
              <!-- <button
          type="button"
          shabicButton="primary"
          (click)="onEdit(account)"
        >
          {{ 'button.edit' | translate }}
        </button> -->

              <button
                type="button"
                shabicButton="default"
                (click)="onToggleStatus(account)"
              >
                {{
                  (account.isActive ? 'button.disconnect' : 'button.connect')
                    | translate
                }}
              </button>
            </div>
          </div>

          <div class="flex gap-2 desktop:gap-3 mt-3 desktop:mt-4 flex-wrap">
            <button
              type="button"
              shabicButton="primary"
              class="w-full desktop:w-auto"
              (click)="onShowBankBalance(account)"
              [disabled]="!account.isActive"
            >
              {{ 'button.inquire-bank-balance' | translate }}
            </button>

            <button
              type="button"
              shabicButton="default"
              class="w-full desktop:w-auto"
              (click)="onRequestAccountStatement(account)"
              [disabled]="!account.isActive"
            >
              {{ 'button.account-statement' | translate }}
            </button>
          </div>
        </div>
      </div>
    </ng-template>

    <div class="flex gap-3 mt-5.5">
      <button type="button" shabicButton="primary" (click)="onAddBankAccount()">
        {{ 'button.add-bank-account' | translate }}
      </button>
    </div>
  </ng-container>
</shabic-animate>
