import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'shabic-product-sorting',
  templateUrl: './product-sorting.component.html',
  styleUrls: ['./product-sorting.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductSortingComponent {
  readonly options: Record<'key' | 'label', string>[] = [
    { key: 'lastModifiedDate,asc', label: 'common.newest' },
    { key: 'lastModifiedDate,desc', label: 'common.oldest' },
    { key: 'priceFirst,desc', label: 'common.high_price' },
    { key: 'priceFirst,asc', label: 'common.low_price' },
  ];

  @Input() value?: string;

  @Output() sort: EventEmitter<string> = new EventEmitter();

  onSelect(option: Record<'key' | 'label', string>): void {
    this.sort.emit(option.key);
  }
}
