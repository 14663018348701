<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M3.66667 22C3.2037 22 2.81019 21.838 2.48611 21.5139C2.16204 21.1898 2 20.7963 2 20.3333V3.66667C2 3.2037 2.16204 2.81019 2.48611 2.48611C2.81019 2.16204 3.2037 2 3.66667 2H20.3333C20.7963 2 21.1898 2.16204 21.5139 2.48611C21.838 2.81019 22 3.2037 22 3.66667V20.3333C22 20.7963 21.838 21.1898 21.5139 21.5139C21.1898 21.838 20.7963 22 20.3333 22H3.66667ZM3.66667 20.3333H20.3333V5.88889H3.66667V20.3333ZM12 17.4167C10.5185 17.4167 9.19444 17.0185 8.02778 16.2222C6.86111 15.4259 6.00926 14.3889 5.47222 13.1111C6.00926 11.8333 6.86111 10.7963 8.02778 10C9.19444 9.2037 10.5185 8.80556 12 8.80556C13.4815 8.80556 14.8056 9.2037 15.9722 10C17.1389 10.7963 17.9907 11.8333 18.5278 13.1111C17.9907 14.3889 17.1389 15.4259 15.9722 16.2222C14.8056 17.0185 13.4815 17.4167 12 17.4167ZM12 16.0278C13.0556 16.0278 14.0278 15.7685 14.9167 15.25C15.8056 14.7315 16.5 14.0185 17 13.1111C16.5 12.2037 15.8056 11.4907 14.9167 10.9722C14.0278 10.4537 13.0556 10.1944 12 10.1944C10.9444 10.1944 9.97222 10.4537 9.08333 10.9722C8.19444 11.4907 7.5 12.2037 7 13.1111C7.5 14.0185 8.19444 14.7315 9.08333 15.25C9.97222 15.7685 10.9444 16.0278 12 16.0278ZM12 14.5C12.3889 14.5 12.7176 14.3657 12.9861 14.0972C13.2546 13.8287 13.3889 13.5 13.3889 13.1111C13.3889 12.7222 13.2546 12.3935 12.9861 12.125C12.7176 11.8565 12.3889 11.7222 12 11.7222C11.6111 11.7222 11.2824 11.8565 11.0139 12.125C10.7454 12.3935 10.6111 12.7222 10.6111 13.1111C10.6111 13.5 10.7454 13.8287 11.0139 14.0972C11.2824 14.3657 11.6111 14.5 12 14.5Z"
    fill="currentColor"
  />
</svg>
