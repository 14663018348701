import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'shabic-stepper-line',
  templateUrl: './stepper-line.component.html',
  styleUrls: ['./stepper-line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepperLineComponent {
  @Input() isActive = false;
}
