import { DIALOG_DATA } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { BankAccountService } from '@shabic/core';
import {
  AccountStatementDialogData,
  ListComponent,
  IBankAccountStatementResponse,
  IListParams,
  List,
  BankAccountStatement,
} from '@shabic/models';
import { switchMap } from 'rxjs';

@Component({
  selector: 'shabic-account-statement-dialog',
  templateUrl: './account-statement-dialog.component.html',
  styleUrls: ['./account-statement-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountStatementDialogComponent
  extends ListComponent<BankAccountStatement, IBankAccountStatementResponse[]>
  implements OnInit
{
  constructor(
    @Inject(DIALOG_DATA) private data: AccountStatementDialogData,
    private bankAccountService: BankAccountService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initList(
      switchMap(params =>
        this.bankAccountService.getAccountStatement(params as IListParams)
      )
    );

    this.setParams({
      ...this.data.params,
      ...List.initParams,
    });
  }

  onChangeParam(params: IListParams): void {
    this.setParams(params);
  }
}
