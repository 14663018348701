<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M21.9964 8.37513H17.7618C15.7911 8.37859 14.1947 9.93514 14.1911 11.8566C14.1884 13.7823 15.7867 15.3458 17.7618 15.3484H22V15.6543C22 19.0136 19.9636 21 16.5173 21H7.48356C4.03644 21 2 19.0136 2 15.6543V8.33786C2 4.97862 4.03644 3 7.48356 3H16.5138C19.96 3 21.9964 4.97862 21.9964 8.33786V8.37513ZM6.7403 8.36742H12.3803H12.3839H12.391C12.8132 8.36569 13.1545 8.03029 13.1527 7.61775C13.151 7.20608 12.8061 6.87327 12.3839 6.87501H6.7403C6.32074 6.87674 5.9803 7.20868 5.97852 7.61862C5.97674 8.03029 6.31808 8.36569 6.7403 8.36742Z"
    fill="currentColor"
  />
  <path
    opacity="0.4"
    d="M16.0374 12.2967C16.2465 13.2479 17.0805 13.9171 18.0326 13.8997H21.2825C21.6787 13.8997 22 13.5716 22 13.1661V10.6345C21.9991 10.2298 21.6787 9.90089 21.2825 9.90002H17.9561C16.8731 9.90351 15.9983 10.8025 16 11.9103C16 12.04 16.0128 12.1696 16.0374 12.2967Z"
    fill="currentColor"
  />
  <circle cx="18" cy="11.9" r="1" fill="currentColor" />
</svg>
