import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Language, Translated } from '@shabic/models';
import { map, merge, of } from 'rxjs';

@Pipe({
  name: 'currentLang',
})
export class CurrentLangPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value?: Translated<string>) {
    if (!value) {
      return of(null);
    }

    return merge(
      this.translateService.onLangChange,
      of({ lang: this.translateService.currentLang })
    ).pipe(
      map(event => {
        const result = value.get(event.lang as Language);

        return result || null;
      })
    );
  }
}
