import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Optional,
} from '@angular/core';
import { FormControl, FormGroupDirective } from '@angular/forms';
import { FilterOption } from '@shabic/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { SearchService } from '../../services';

@Component({
  selector: 'shabic-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFilterComponent implements OnInit {
  private _list = new BehaviorSubject<FilterOption[]>([]);
  private _collapse = new BehaviorSubject<boolean>(true);

  @Input() name = '';
  @Input() param!: string;
  @Input() options!: FilterOption[];
  @Input() property: keyof FilterOption = 'label';

  control = new FormControl<string[]>([]);
  options$: Observable<FilterOption[]> = this._list.asObservable();

  constructor(
    @Optional() private form: FormGroupDirective,
    @Optional() private searchService: SearchService
  ) {}

  ngOnInit(): void {
    if (this.form) {
      this.form.control.addControl(this.param, this.control);
    }

    this.form.valueChanges?.subscribe(v => {
      if (!v[this.param]) {
        this.options.forEach(el => el.control.patchValue(false));
      }
    });

    this._collapse.subscribe(v => {
      this._list.next(v ? this.options.slice(0, 5) : this.options);
    });

    this.control.patchValue(
      this.options
        .filter(el => el.control.value)
        .map(el => {
          const key = el[this.property];

          return key as string;
        })
    );
  }

  onToggle() {
    this._collapse.next(!this._collapse.value);
  }

  onChange(option: FilterOption, value: unknown, item: HTMLElement) {
    let values: string[] = this.control.value || [];
    const key = option[this.property];

    if (value) {
      values.push(key as string);
    } else {
      values = values?.filter(el => el !== key);
    }

    this.control.patchValue(values);
    this.searchService.showPopover(item);
  }
}
