<shabic-dialog #dialog>
  <div class="w-full max-w-[400px] mx-auto">
    <header class="text-center">
      <div class="flex items-center justify-center mb-4">
        <img src="/assets/return-box.svg" />
      </div>

      <shabic-headline variant="h3">
        {{ 'common.return-order' | translate }}
      </shabic-headline>
      <shabic-sub-headline>
        {{ 'common.return-order-description' | translate }}
      </shabic-sub-headline>
    </header>

    <form
      class="mt-4 grid gap-1"
      [formGroup]="formGroup"
      (ngSubmit)="confirm(dialog)"
    >
      <div class="grid gap-1">
        <ng-container *ngIf="isCancelReasons">
          <shabic-form-field
            class="blok border-2 border-grey-350 rounded-2xl p-[20px]"
            [class.border-primary]="
              reasonControl.value === ECancelOrderReason.WrongProduct
            "
          >
            <shabic-radio-control>
              <input
                type="radio"
                id="wrong-product"
                shabicInput
                formControlName="reason"
                class="peer"
                [value]="ECancelOrderReason.WrongProduct"
                name="reason"
              />

              <shabic-form-field-label forAttribute="wrong-product">
                <span class="normal-case font-medium text-sm">
                  {{
                    getReasonKey(ECancelOrderReason.WrongProduct) | translate
                  }}
                </span>
              </shabic-form-field-label>
            </shabic-radio-control>
          </shabic-form-field>

          <shabic-form-field
            class="blok border-2 border-grey-350 rounded-2xl p-[20px]"
            [class.border-primary]="
              reasonControl.value === ECancelOrderReason.NotEnoughFunds
            "
          >
            <shabic-radio-control>
              <input
                type="radio"
                id="not-enough-funds"
                shabicInput
                formControlName="reason"
                class="peer"
                [value]="ECancelOrderReason.NotEnoughFunds"
                name="reason"
              />

              <shabic-form-field-label forAttribute="not-enough-funds">
                <span class="normal-case font-medium text-sm">
                  {{
                    getReasonKey(ECancelOrderReason.NotEnoughFunds) | translate
                  }}
                </span>
              </shabic-form-field-label>
            </shabic-radio-control>
          </shabic-form-field>
        </ng-container>

        <ng-container *ngIf="isReturnReasons">
          <shabic-form-field
            class="blok border-2 border-grey-350 rounded-2xl p-[20px]"
            [class.border-primary]="
              reasonControl.value === EReturnOrderReason.DamagedGoods
            "
          >
            <shabic-radio-control>
              <input
                type="radio"
                id="damaged-goods"
                shabicInput
                formControlName="reason"
                class="peer"
                [value]="EReturnOrderReason.DamagedGoods"
                name="reason"
              />

              <shabic-form-field-label forAttribute="damaged-goods">
                <span class="normal-case font-medium text-sm">
                  {{
                    getReasonKey(EReturnOrderReason.DamagedGoods) | translate
                  }}
                </span>
              </shabic-form-field-label>
            </shabic-radio-control>
          </shabic-form-field>

          <shabic-form-field
            class="blok border-2 border-grey-350 rounded-2xl p-[20px]"
            [class.border-primary]="
              reasonControl.value ===
              EReturnOrderReason.NotMeetingRequiredSpecifications
            "
          >
            <shabic-radio-control>
              <input
                type="radio"
                id="not-meeting-required-specifications"
                shabicInput
                formControlName="reason"
                class="peer"
                [value]="EReturnOrderReason.NotMeetingRequiredSpecifications"
                name="reason"
              />

              <shabic-form-field-label
                forAttribute="not-meeting-required-specifications"
              >
                <span class="normal-case font-medium text-sm">
                  {{
                    getReasonKey(
                      EReturnOrderReason.NotMeetingRequiredSpecifications
                    ) | translate
                  }}
                </span>
              </shabic-form-field-label>
            </shabic-radio-control>
          </shabic-form-field>
        </ng-container>

        <shabic-form-field
          class="blok border-2 border-grey-350 rounded-2xl p-[20px]"
          [class.border-primary]="!reasonControl.value"
        >
          <shabic-radio-control>
            <input
              type="radio"
              id="other"
              shabicInput
              formControlName="reason"
              class="peer"
              [value]="null"
              name="reason"
            />

            <shabic-form-field-label forAttribute="other">
              <span class="normal-case font-medium text-sm">
                {{ 'common.other' | translate }}
              </span>
            </shabic-form-field-label>
          </shabic-radio-control>
        </shabic-form-field>

        <shabic-form-field *ngIf="!reasonControl.value" class="mt-4">
          <shabic-form-field-label forAttribute="reason">
            {{ 'common.description' | translate }}
          </shabic-form-field-label>

          <textarea
            shabicInput
            rows="6"
            id="reason"
            class="resize-none"
            formControlName="description"
          ></textarea>
        </shabic-form-field>
      </div>

      <div class="text-center">
        <button
          type="submit"
          shabicButton="primary"
          class="w-full desktop:w-auto mt-5.5 mb-6"
        >
          {{ 'button.confirm' | translate }}
        </button>
      </div>
    </form>
  </div>
</shabic-dialog>
