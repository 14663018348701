<svg
  width="24"
  height="25"
  viewBox="0 0 24 25"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_522_73)">
    <path
      d="M21.4688 7.29688H2.53125C1.36814 7.29688 0.421875 8.24314 0.421875 9.40625V17.8438C0.421875 19.0069 1.36814 19.9531 2.53125 19.9531H21.4688C22.6319 19.9531 23.5781 19.0069 23.5781 17.8438V9.40625C23.5781 8.24314 22.6319 7.29688 21.4688 7.29688ZM5.98786 14.719C5.80809 14.719 5.50097 14.7202 5.21348 14.7217V16.3434C5.21348 16.7317 4.89867 17.0465 4.51036 17.0465C4.12205 17.0465 3.80723 16.7317 3.80723 16.3434L3.79688 11.0023C3.79627 10.8154 3.87009 10.636 4.002 10.5036C4.13391 10.3712 4.31316 10.2969 4.5 10.2969H5.98786C7.2233 10.2969 8.22844 11.2888 8.22844 12.5079C8.22844 13.7271 7.2233 14.719 5.98786 14.719ZM12.0239 16.877C11.6051 16.8843 10.5576 16.8883 10.5132 16.8885C10.5123 16.8885 10.5114 16.8885 10.5105 16.8885C10.325 16.8885 10.1469 16.8152 10.0152 16.6844C9.8828 16.553 9.80808 16.3744 9.80737 16.1879C9.80733 16.1691 9.79688 11.0014 9.79688 11.0014C9.79655 10.8147 9.87042 10.6355 10.0023 10.5033C10.1342 10.3712 10.3133 10.2969 10.5 10.2969H11.9686C13.6417 10.2969 14.7657 11.6214 14.7657 13.5927C14.7657 15.468 13.6126 16.8493 12.0239 16.877ZM19.2291 12.8445C19.6174 12.8445 19.9322 13.1593 19.9322 13.5477C19.9322 13.936 19.6174 14.2508 19.2291 14.2508H17.9531V16.25C17.9531 16.6383 17.6383 16.9531 17.25 16.9531C16.8617 16.9531 16.5469 16.6383 16.5469 16.25V10.9457C16.5469 10.5574 16.8617 10.2426 17.25 10.2426H19.3998C19.7881 10.2426 20.103 10.5574 20.103 10.9457C20.103 11.334 19.7881 11.6488 19.3998 11.6488H17.9531V12.8445H19.2291Z"
      fill="currentColor"
    />
    <path
      d="M11.9693 11.7031H11.2051C11.2062 12.3378 11.21 14.8678 11.212 15.4786C11.5046 15.4767 11.8179 15.4741 12 15.4709C12.9396 15.4545 13.3602 14.5158 13.3602 13.5927C13.3602 13.1494 13.2599 11.7031 11.9693 11.7031Z"
      fill="currentColor"
    />
    <path
      d="M5.9877 11.7031H5.20508C5.20592 12.016 5.20672 12.3489 5.20672 12.5079C5.20672 12.6938 5.20803 13.0161 5.20953 13.3155C5.49809 13.314 5.80634 13.3127 5.98775 13.3127C6.44 13.3127 6.82208 12.9442 6.82208 12.5079C6.82208 12.0717 6.43995 11.7031 5.9877 11.7031Z"
      fill="currentColor"
    />
    <path
      d="M20.7401 5.89062C20.5771 5.44391 20.324 5.03103 19.9888 4.6775L17.0683 1.59683C16.4074 0.899797 15.4775 0.5 14.517 0.5H5.15625C3.99314 0.5 3.04688 1.44627 3.04688 2.60938V5.89062H20.7401Z"
      fill="currentColor"
    />
    <path
      d="M3.04688 21.3594V22.3906C3.04688 23.5537 3.99314 24.5 5.15625 24.5H18.8438C20.0069 24.5 20.9531 23.5537 20.9531 22.3906V21.3594H3.04688Z"
      fill="currentColor"
    />
  </g>
  <defs>
    <clipPath id="clip0_522_73">
      <rect
        width="24"
        height="24"
        fill="currentColor"
        transform="translate(0 0.5)"
      />
    </clipPath>
  </defs>
</svg>
