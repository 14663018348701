import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SUCCESS, equalValidator, patternValidator } from '@shabic/constants';
import { OtpService } from '@shabic/core';
import { FormComponent } from '@shabic/form-control';
import { AuthResponse, AUTH_ROLE_NOT_VERIFIED } from '@shabic/models';
import { BehaviorSubject } from 'rxjs';
import { SignUp, StepState } from '../../models';
import { SignUpService } from '../../services';

@Component({
  selector: 'shabic-sign-up-create-account-step',
  templateUrl: './sign-up-create-account-step.component.html',
  styleUrls: ['./sign-up-create-account-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignUpCreateAccountStepComponent
  extends FormComponent<AuthResponse>
  implements OnInit
{
  private _state = new BehaviorSubject<StepState>('form');

  @Input() signUp!: SignUp;
  @Output() completed = new EventEmitter<void>();

  state$ = this._state.asObservable();

  formGroup: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
      patternValidator(/\d/, { hasNumber: true }),
      patternValidator(/[A-Z]/, { hasCapitalCase: true }),
      patternValidator(/[a-z]/, { hasSmallCase: true }),
      patternValidator(/[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/, {
        hasSpecialCharacters: true,
      }),
    ]),
    confirmPassword: new FormControl('', [Validators.required]),
    passcode: new FormControl(''),
    phone: new FormControl(''),
  });

  constructor(
    private signUpService: SignUpService,
    private otpService: OtpService,
    protected override cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.signUp.hasRole(AUTH_ROLE_NOT_VERIFIED)) {
      this._state.next('passcode');
      this.formGroup
        .get('passcode')
        ?.setValidators([Validators.required, Validators.minLength(6)]);
      this.formGroup.removeControl('email');
      this.formGroup.removeControl('password');
      this.formGroup.removeControl('confirmPassword');
    } else {
      this.formGroup.setValidators([
        equalValidator(
          ['password', 'confirmPassword'],
          'error.not-equal-password'
        ),
      ]);
    }
  }

  onSubmit(): void {
    const state: StepState = this._state.value;
    const formValue = this.formGroup.value;

    if (state === 'form') {
      this.createAccount({
        email: formValue.email,
        password: formValue.password,
        confirmPassword: formValue.confirmPassword,
      });
    } else {
      this.verifyEmail(formValue.passcode);
    }
  }

  private createAccount(payload: {
    email: string;
    password: string;
    confirmPassword: string;
  }): void {
    this.submitForm(
      this.formGroup,
      this.signUpService.createAccount(payload)
    ).subscribe(status => {
      if (status === SUCCESS) {
        this.formGroup
          .get('passcode')
          ?.setValidators([Validators.required, Validators.minLength(6)]);
        this.formGroup.updateValueAndValidity();
        this._state.next('passcode');
      }
    });
  }

  private verifyEmail(passcode: string) {
    this.submitForm(
      this.formGroup,
      this.otpService.verify(passcode, 'EMAIL')
    ).subscribe(status => {
      if (status === SUCCESS) {
        this.completed.emit();
      }
    });
  }
}
