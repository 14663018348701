<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M20.4692 7.36999C20.4692 7.36999 20.4692 7.36999 20.4692 7.28999L20.4092 7.13999C20.3883 7.10816 20.3649 7.07806 20.3392 7.04999C20.3124 7.00765 20.2823 6.96753 20.2492 6.92999L20.1592 6.85999L19.9992 6.77999L12.4992 2.14999C12.3402 2.05066 12.1566 1.99799 11.9692 1.99799C11.7817 1.99799 11.5981 2.05066 11.4392 2.14999L3.99917 6.77999L3.90917 6.85999L3.81917 6.92999C3.78602 6.96753 3.75592 7.00765 3.72917 7.04999C3.70346 7.07806 3.68005 7.10816 3.65917 7.13999L3.59917 7.28999C3.59917 7.28999 3.59917 7.28999 3.59917 7.36999C3.58934 7.45638 3.58934 7.5436 3.59917 7.62999V16.37C3.59883 16.5399 3.6418 16.7072 3.72404 16.8559C3.80627 17.0046 3.92506 17.1299 4.06917 17.22L11.5692 21.85C11.6153 21.8785 11.6661 21.8988 11.7192 21.91C11.7192 21.91 11.7692 21.91 11.7992 21.91C11.9683 21.9636 12.15 21.9636 12.3192 21.91C12.3192 21.91 12.3692 21.91 12.3992 21.91C12.4523 21.8988 12.503 21.8785 12.5492 21.85L19.9992 17.22C20.1433 17.1299 20.2621 17.0046 20.3443 16.8559C20.4265 16.7072 20.4695 16.5399 20.4692 16.37V7.62999C20.479 7.5436 20.479 7.45638 20.4692 7.36999ZM10.9992 19.21L5.49917 15.81V9.42999L10.9992 12.82V19.21ZM11.9992 11.09L6.39917 7.62999L11.9992 4.17999L17.5992 7.62999L11.9992 11.09ZM18.4992 15.81L12.9992 19.21V12.82L18.4992 9.42999V15.81Z"
    fill="currentColor"
  />
</svg>
