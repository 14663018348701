import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Route, RouterModule } from '@angular/router';
import {
  LayoutModule,
  MapModule,
  PasscodeModule,
  StepperModule,
} from '@shabic/shared';
import { SignUpComponent } from './sign-up.component';
import { FormControlModule } from '@shabic/form-control';
import { UiModule } from '@shabic/ui';
import { ReactiveFormsModule } from '@angular/forms';
import { IconModule } from '@shabic/icon';
import { SignUpFormDirective } from './directives';
import {
  SignUpCompanyVerificationComponent,
  SignUpCreateAccountStepComponent,
  SignUpGovtIdStepComponent,
  SignUpPhoneNumberStepComponent,
  SignUpSuccessStepComponent,
  SignUpVerifyGovtIdStepComponent,
} from './components';
import { TranslateModule } from '@ngx-translate/core';
import { SignUpService } from './services';
import { AuthGuard } from '@shabic/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { NgxMaskModule } from 'ngx-mask';

const routes: Route[] = [
  {
    path: 'sign-up',
    component: SignUpComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [
    CommonModule,

    RouterModule.forChild(routes),
    StepperModule,
    FormControlModule,
    UiModule,
    PasscodeModule,
    ReactiveFormsModule,
    IconModule,
    MapModule,
    LayoutModule,
    TranslateModule,
    MatDatepickerModule,
    MatMomentDateModule,
    NgxMaskModule,
  ],
  declarations: [
    SignUpComponent,
    SignUpCreateAccountStepComponent,
    SignUpPhoneNumberStepComponent,
    SignUpGovtIdStepComponent,
    SignUpVerifyGovtIdStepComponent,
    SignUpSuccessStepComponent,
    SignUpCompanyVerificationComponent,
    SignUpFormDirective,
  ],
  providers: [SignUpService],
})
export class SignUpModule {}
