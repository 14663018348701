<shabic-form-field>
  <shabic-dropdown [wrapped]="true">
    <div
      class="inline-flex items-center gap-[6px] text-sm text-primary font-semibold"
      target
    >
      {{
        (selectedValue$ | async)?.label || 'common.filters'
          | translate
            : getTranslateParams({
                currency: (currentCurrency$ | async)?.value
              })
      }}

      <ng-template
        *ngIf="(selectedValue$ | async)?.icon"
        [shabicIcon]="(selectedValue$ | async)?.icon"
      ></ng-template>
    </div>

    <div content>
      <ul class="max-h-[240px] overflow-y-auto" *ngIf="options">
        <li
          class="text-primary flex items-center gap-[6px] text-sm px-3 py-2 rtl:text-right hover:cursor-pointer hover:bg-primary hover:text-white"
          [class.font-semibold]="isSelected(option)"
          *ngFor="let option of options"
          (click)="onSelect(option)"
        >
          {{
            option.label
              | translate
                : getTranslateParams({
                    currency: (currentCurrency$ | async)?.value
                  })
          }}

          <ng-template
            *ngIf="option.icon"
            [shabicIcon]="option.icon"
          ></ng-template>
        </li>
      </ul>
    </div>
  </shabic-dropdown>
</shabic-form-field>
