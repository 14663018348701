<ul class="flex gap-5 justify-end" *ngIf="navigation">
  <li
    class="inline-flex items-center"
    *ngFor="let item of navigation | auth | async"
  >
    <a
      class="uppercase font-medium text-sm hover:underline"
      [routerLink]="item['route']"
    >
      {{ item['label'] | translate }}
    </a>
  </li>
</ul>
