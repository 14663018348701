<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    opacity="0.4"
    d="M16.191 2H7.81C4.77 2 3 3.78 3 6.83V17.16C3 20.26 4.77 22 7.81 22H16.191C19.28 22 21 20.26 21 17.16V6.83C21 3.78 19.28 2 16.191 2Z"
    fill="currentColor"
    fill-opacity="0.75"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M8.08094 6.64844V6.65844C7.64994 6.65844 7.30094 7.00844 7.30094 7.43844C7.30094 7.86844 7.64994 8.21844 8.08094 8.21844H11.0699C11.5009 8.21844 11.8509 7.86844 11.8509 7.42744C11.8509 6.99844 11.5009 6.64844 11.0699 6.64844H8.08094ZM15.9208 12.7368H8.08078C7.64978 12.7368 7.30078 12.3868 7.30078 11.9568C7.30078 11.5268 7.64978 11.1758 8.08078 11.1758H15.9208C16.3508 11.1758 16.7008 11.5268 16.7008 11.9568C16.7008 12.3868 16.3508 12.7368 15.9208 12.7368ZM15.9209 17.3084H8.08094C7.78094 17.3484 7.49094 17.1984 7.33094 16.9484C7.17094 16.6884 7.17094 16.3584 7.33094 16.1084C7.49094 15.8484 7.78094 15.7084 8.08094 15.7384H15.9209C16.3199 15.7784 16.6209 16.1184 16.6209 16.5284C16.6209 16.9274 16.3199 17.2684 15.9209 17.3084Z"
    fill="currentColor"
    fill-opacity="0.75"
  />
</svg>
