import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CurrencyService } from '@shabic/core';
import { AppEvent, EAppEvent, EventBrokerService } from '@shabic/event-broker';
import {
  currencies,
  Currency,
  CurrencyType,
  getCountryFlag,
} from '@shabic/models';

@Component({
  selector: 'shabic-currency-dropdown',
  templateUrl: './currency-dropdown.component.html',
  styleUrls: ['./currency-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrencyDropdownComponent {
  currentCurrency$ = this.currencyService.currentCurrency$;
  readonly currencies = currencies.map(el => new Currency(el));

  constructor(
    private currencyService: CurrencyService,
    private eventBrokerService: EventBrokerService
  ) {}

  onChangeCurrency(currency: CurrencyType) {
    this.eventBrokerService.emit(
      new AppEvent<CurrencyType>(EAppEvent.ChangeCurrency, currency)
    );
  }

  getFlag(currency?: Currency) {
    if (!currency) {
      return '';
    }

    return getCountryFlag(currency.country);
  }
}
