<form
  class="px-[20px] rounded w-full max-w-[432px] mx-auto"
  [formGroup]="model.formGroup"
  (ngSubmit)="onSubmit()"
>
  <ng-container *ngIf="!completed">
    <shabic-headline variant="h2" align="center">
      {{ 'create-password.title' | translate }}
    </shabic-headline>

    <shabic-form-field class="mt-5.5">
      <shabic-form-field-label>
        {{ 'common.new-password' | translate }}
      </shabic-form-field-label>

      <input
        type="password"
        class="text-primary"
        shabicInput
        formControlName="password"
      />
    </shabic-form-field>

    <shabic-form-field class="mt-4">
      <shabic-form-field-label>
        {{ 'common.confirm-new-password' | translate }}
      </shabic-form-field-label>

      <input
        type="password"
        class="text-primary"
        shabicInput
        formControlName="confirmPassword"
      />
    </shabic-form-field>

    <shabic-form-field-errors
      [errors]="this.model.formGroup.errors"
    ></shabic-form-field-errors>

    <p class="mt-4">
      <button
        type="submit"
        shabicButton="secondary"
        class="w-full gap-1"
        [disabled]="(loading$ | async) || this.model.formGroup.invalid"
      >
        {{ 'button.create-password' | translate }}

        <shabic-spinner *ngIf="loading$ | async"></shabic-spinner>
      </button>
    </p>
  </ng-container>

  <ng-container *ngIf="completed">
    <ng-content select="[message]"></ng-content>
  </ng-container>
</form>
