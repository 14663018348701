<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M9.34933 14.8594C5.38553 14.8594 2 15.4717 2 17.919C2 20.3682 5.364 21.0016 9.34933 21.0016C13.3131 21.0016 16.6987 20.3893 16.6987 17.942C16.6987 15.4928 13.3347 14.8594 9.34933 14.8594Z"
    fill="currentColor"
    fill-opacity="0.75"
  />
  <path
    opacity="0.4"
    d="M9.34935 12.5248C12.049 12.5248 14.2124 10.4062 14.2124 7.76241C14.2124 5.11865 12.049 3 9.34935 3C6.65072 3 4.48633 5.11865 4.48633 7.76241C4.48633 10.4062 6.65072 12.5248 9.34935 12.5248Z"
    fill="currentColor"
    fill-opacity="0.75"
  />
  <path
    opacity="0.4"
    d="M16.1728 7.8502C16.1728 9.19651 15.7599 10.4528 15.0358 11.4963C14.9605 11.6036 15.027 11.7483 15.1581 11.7713C15.3401 11.801 15.527 11.8192 15.7178 11.823C17.616 11.8719 19.3196 10.6751 19.7902 8.87263C20.4879 6.1982 18.4409 3.79688 15.8333 3.79688C15.5505 3.79688 15.2794 3.82562 15.0153 3.87832C14.9791 3.88599 14.9399 3.90324 14.9203 3.9339C14.8949 3.97319 14.9135 4.02398 14.9389 4.05751C15.7227 5.13361 16.1728 6.44351 16.1728 7.8502Z"
    fill="currentColor"
    fill-opacity="0.75"
  />
  <path
    d="M21.7785 15.1678C21.4311 14.4424 20.5926 13.9451 19.3166 13.7007C18.7149 13.557 17.0847 13.3529 15.5691 13.3816C15.5465 13.3845 15.5338 13.3998 15.5319 13.4094C15.5289 13.4248 15.5358 13.4478 15.5651 13.464C16.2657 13.8033 18.9732 15.2789 18.6327 18.3913C18.618 18.5273 18.7286 18.6423 18.8665 18.6232C19.5329 18.5302 21.2472 18.169 21.7785 17.0459C22.073 16.4518 22.073 15.7619 21.7785 15.1678Z"
    fill="currentColor"
    fill-opacity="0.75"
  />
</svg>
