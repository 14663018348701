import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { LANGUAGE_STORAGE_KEY } from '@shabic/constants';
import {
  ADD_PRODUCT_NAV,
  CART_NAV,
  INavItem,
  NOT_AUTH_NAV,
  PROFILE_NAV,
} from '@shabic/data';
import { Currency } from '@shabic/models';
import { prop, sortWith, ascend, descend } from 'ramda';

const coefficient = 0.2659626812;

const fromUSDtoSAR = (price: number) => {
  return price / coefficient;
};

const fromSARtoUSD = (price: number) => {
  return price * coefficient;
};

export const convertCurrency = (config: {
  locale: string;
  currency: Currency;
  price: number;
  priceCurrency?: Currency;
  hasSymbol?: boolean;
}) => {
  const { locale, currency, price, priceCurrency, hasSymbol = true } = config;

  let value: string | undefined;

  if (currency.value !== priceCurrency?.value) {
    switch (currency.value) {
      case 'USD':
        value = formatCurrency(fromSARtoUSD(price), 'en', '');
        break;
      case 'SAR':
        value = formatCurrency(fromUSDtoSAR(price), 'en', '');
        break;
    }
  }

  if (!value) {
    value = formatCurrency(price, 'en', '');
  }

  return hasSymbol
    ? `${value} ${getCurrencySymbol(currency.value, 'wide', locale)}`
    : value;
};

export function sortBy<T>(
  value: T[],
  options: { property: keyof T; direction: 'asc' | 'desc' }
): T[] {
  const { property, direction } = options;

  const sortFn =
    direction === 'asc'
      ? sortWith<any>([ascend<any>(prop(property))])
      : sortWith<any>([descend<any>(prop(property))]);

  return sortFn(value);
}

export function getRoute(url: string): INavItem | null {
  const nav = [
    ...NOT_AUTH_NAV,
    ...CART_NAV,
    ...ADD_PRODUCT_NAV,
    ...PROFILE_NAV,
    {
      label: 'common.gov-api',
      route: '/gov-api',
    },
  ];
  const innerRoutes = ['/catalog'];

  if (
    url.split('/').length > 2 &&
    innerRoutes.some(route => url.startsWith(route))
  ) {
    return {
      label: 'button.back',
      icon: 'arrow-left',
      route: '/',
      onClick: event => {
        event.preventDefault();

        history.back();
      },
      childRoute: true,
    };
  }

  return (
    nav.find(el => {
      if (typeof el.route === 'string') {
        return el.route.includes(url) || url.includes(el.route);
      } else {
        return el.route[0].includes(url) || url.includes(el.route[0]);
      }
    }) || null
  );
}

export const getCurrentLocale = () => {
  const locale = localStorage.getItem(LANGUAGE_STORAGE_KEY);

  return locale ? JSON.parse(locale) : 'en-GB';
};
