<shabic-drawer-dialog (back)="onBack()" [config]="drawerDialogConfig$ | async">
  <form [formGroup]="formGroup"></form>
  <ng-container *ngIf="activeFilter$ | async as filter; else filterList">
    <ul class="border-t border-grey-350 border-b">
      <ng-container *ngFor="let item of filter.options | async">
        <ng-container *ngIf="isFilterOption(item)">
          <ng-container
            *ngTemplateOutlet="
              filterItem;
              context: { option: item, colorBox: filter.colorBox }
            "
          ></ng-container>
        </ng-container>

        <ng-container *ngIf="isCategory(item)">
          <ng-container
            *ngTemplateOutlet="categoryItem; context: { category: item }"
          ></ng-container>
        </ng-container>
      </ng-container>
    </ul>
  </ng-container>
</shabic-drawer-dialog>

<ng-template #filterList>
  <ul class="border-t border-grey-350 border-b">
    <li
      class="flex justify-between p-[20px] border-b border-grey-350 last:border-b-0"
      *ngFor="let filter of data.filters"
      (click)="onOpenFilter(filter)"
    >
      <span class="text-sm font-medium text-primary">
        {{ filter.label | translate }}
      </span>

      <span class="text-grey ltr:-rotate-90 rtl:rotate-90">
        <ng-template shabicIcon="arrow"></ng-template>
      </span>
    </li>
  </ul>
</ng-template>

<ng-template #filterItem let-option="option" let-colorBox="colorBox">
  <li class="border-b border-grey-350 last:border-b-0">
    <label class="flex justify-between gap-3 p-[20px]">
      <span
        class="text-sm font-medium text-primary inline-flex items-center gap-3"
      >
        <ng-container *ngIf="colorBox">
          <span
            class="inline-block w-4 h-4 rounded-lg bg-white border border-grey-400"
            [ngStyle]="getColor(option)"
          ></span>
        </ng-container>

        {{ option.label | translate }}
      </span>

      <input
        type="checkbox"
        class="peer"
        shabicInput
        [formControl]="option.control"
      />

      <span class="text-pimary invisible peer-checked:visible">
        <ng-template shabicIcon="check"></ng-template>
      </span>
    </label>
  </li>
</ng-template>

<ng-template #categoryItem let-category="category">
  <li
    class="border-b border-grey-350 last:border-b-0"
    (click)="onChangeCategory(category)"
  >
    <label class="flex justify-between gap-3 p-[20px]">
      <span class="inline-flex gap-1">
        <span class="text-sm font-medium text-primary">
          {{ category.name | currentLang | async }}
        </span>

        <span class="text-grey text-sm font-medium">
          ({{ category.count }})
        </span>
      </span>

      <span
        [class.visible]="category.active"
        [class.invisible]="!category.active"
        class="text-pimary"
      >
        <ng-template shabicIcon="check"></ng-template>
      </span>
    </label>
  </li>
</ng-template>
