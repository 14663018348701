import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'shabic-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent {
  @Input() size: 'md' | 'xl' = 'md';
}
