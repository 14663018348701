<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M19.6095 16C19.5552 15.8785 19.4808 15.7669 19.3895 15.67L14.7595 11C14.5682 10.8362 14.3222 10.7506 14.0705 10.7603C13.8188 10.77 13.5801 10.8743 13.402 11.0524C13.2239 11.2305 13.1195 11.4693 13.1098 11.7209C13.1001 11.9726 13.1857 12.2187 13.3495 12.41L16.2695 15.33H9.26953C8.47388 15.33 7.71082 15.0139 7.14821 14.4513C6.5856 13.8887 6.26953 13.1256 6.26953 12.33V3C6.26953 2.73478 6.16417 2.48043 5.97664 2.29289C5.7891 2.10536 5.53475 2 5.26953 2C5.00431 2 4.74996 2.10536 4.56242 2.29289C4.37489 2.48043 4.26953 2.73478 4.26953 3V12.37C4.26953 13.6961 4.79632 14.9679 5.734 15.9055C6.67168 16.8432 7.94345 17.37 9.26953 17.37H16.2695L13.3495 20.29C13.2558 20.383 13.1814 20.4936 13.1306 20.6154C13.0799 20.7373 13.0537 20.868 13.0537 21C13.0537 21.132 13.0799 21.2627 13.1306 21.3846C13.1814 21.5064 13.2558 21.617 13.3495 21.71C13.443 21.8027 13.5538 21.876 13.6756 21.9258C13.7975 21.9755 13.9279 22.0008 14.0595 22C14.3219 21.9989 14.5733 21.8947 14.7595 21.71L19.3895 17.08C19.4842 16.9858 19.559 16.8736 19.6095 16.75C19.7068 16.5095 19.7068 16.2405 19.6095 16Z"
    fill="currentColor"
  />
</svg>
