import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, interval, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'shabic-property-with-label',
  templateUrl: './property-with-label.component.html',
  styleUrls: ['./property-with-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PropertyWithLabelComponent implements AfterViewInit, OnDestroy {
  private _destroy = new Subject<void>();
  private _hasContent = new BehaviorSubject<boolean>(false);

  @Input() label!: string;
  @Input() translateParams: Record<string, unknown> = {};

  @ViewChild('ref') content?: ElementRef<unknown>;

  get hasContent$() {
    return this._hasContent.asObservable();
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  ngAfterViewInit(): void {
    interval(1000)
      .pipe(takeUntil(this._destroy))
      .subscribe(() => {
        const element = this.content?.nativeElement;

        if (element instanceof HTMLElement) {
          const hasContent = !!element.outerText.trim();
          this._hasContent.next(hasContent);
        }
      });
  }
}
