<div
  class="w-full h-12 desktop:h-[395px] rounded-2xl desktop:rounded-3xl overflow-hidden empty:hidden"
>
  <ng-container *ngFor="let banner of banners$ | async; let index = index">
    <shabic-animate *ngIf="(activeBanner$ | async) === index">
      <a routerLink="/catalog" [queryParams]="{ query: banner.productsQuery }">
        <img
          shabicImage
          [src]="banner.media.url"
          class="w-full h-full object-cover bg-grey-350"
        />
      </a>
    </shabic-animate>
  </ng-container>
</div>
