<shabic-layout [hasSignUpWave]="true">
  <ng-container header>
    <div class="flex items-center justify-center desktop:py-5.5 mt-4 mb-[20px]">
      <a routerLink="/"><img [src]="logoSource" /></a>
    </div>

    <shabic-stepper
      *ngIf="!companyForm"
      [steps]="signUp.steps"
      [currentStep]="signUp.currentStep$ | async"
    ></shabic-stepper>
  </ng-container>

  <ng-container *ngIf="!companyForm">
    <div
      shabicLayoutContent
      class="pb-10"
      [ngSwitch]="signUp.currentStepIndex$ | async"
    >
      <shabic-sign-up-create-account-step
        *ngSwitchCase="0"
        [signUp]="signUp"
        (completed)="onNextStep()"
      ></shabic-sign-up-create-account-step>

      <shabic-sign-up-phone-number-step
        *ngSwitchCase="1"
        (completed)="onNextStep()"
      ></shabic-sign-up-phone-number-step>

      <shabic-sign-up-govt-id-step
        *ngSwitchCase="2"
        (completed)="onNextStep()"
      ></shabic-sign-up-govt-id-step>

      <shabic-sign-up-verify-govt-id-step
        *ngSwitchCase="3"
        (completed)="onNextStep()"
      ></shabic-sign-up-verify-govt-id-step>

      <shabic-sign-up-company-verification
        *ngSwitchCase="4"
        (completed)="onCompanyCreated()"
      ></shabic-sign-up-company-verification>

      <!-- <shabic-sign-up-success-step
        *ngSwitchCase="4"
        (completed)="showCompanyForm()"
      ></shabic-sign-up-success-step> -->
    </div>
  </ng-container>

  <!-- <div shabicLayoutContent class="py-10" *ngIf="companyForm">
    <shabic-sign-up-company-verification
      (completed)="onCompanyCreated()"
    ></shabic-sign-up-company-verification>
  </div> -->
</shabic-layout>
