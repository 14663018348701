<div class="flex items-center gap-3">
  <ng-content select="input"></ng-content>

  <span
    class="w-full whitespace-nowrap ext-ellipsis overflow-hidden text-primary font-medium text-sm"
  >
    <ng-container *ngIf="file$ | async as file; else defaultName">
      {{ file.name }}
    </ng-container>
  </span>

  <button
    type="button"
    shabicButton="primary"
    [disabled]="disabled"
    (click)="onUpload()"
  >
    <ng-container *ngIf="file$ | async; else upload">
      {{ 'button.uploaded' | translate }}
    </ng-container>

    <ng-template #upload>
      {{ 'button.upload' | translate }}
    </ng-template>
  </button>
</div>

<ng-template #defaultName>{{
  'common.name-of-document' | translate
}}</ng-template>
