import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ElementRef,
} from '@angular/core';

@Component({
  selector: 'shabic-stepper-point',
  templateUrl: './stepper-point.component.html',
  styleUrls: ['./stepper-point.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepperPointComponent {
  @Input() isActive = false;
  @Input() completed = false;
  @Input() index = 0;

  constructor(private el: ElementRef<any>) {}

  get pointOffset() {
    return this.getOffsetLeft(this.el.nativeElement);
  }

  private getOffsetLeft(el: HTMLElement, offset = 0): number {
    const elementOffset = el.offsetLeft || 0;
    const offsetParent = el.offsetParent as HTMLElement;

    if (offsetParent) {
      return this.getOffsetLeft(offsetParent, offset + elementOffset);
    }

    const calculatedOffset = elementOffset + offset;

    if (document.documentElement.getAttribute('dir') === 'rtl') {
      return window.innerWidth - calculatedOffset;
    } else {
      return calculatedOffset;
    }
  }
}
