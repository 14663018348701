<shabic-dialog>
  <section class="grid grid-cols-2 gap-y-3 gap-x-3">
    <shabic-property-with-label label="common.company-name-en">
      {{ company.name.get('en') }}
    </shabic-property-with-label>

    <shabic-property-with-label label="common.company-name-ar">
      {{ company.name.get('ar') }}
    </shabic-property-with-label>

    <shabic-property-with-label label="common.cr-number">
      {{ company.crNumber }}
    </shabic-property-with-label>

    <shabic-property-with-label label="common.formation-date">
      {{ company.formationDate | time: 'D MMM YYYY' }}
    </shabic-property-with-label>

    <shabic-property-with-label label="common.company-type">
      {{ company.type | translate }}
    </shabic-property-with-label>

    <shabic-property-with-label label="common.business-activity">
      {{ company.activity | translate }}
    </shabic-property-with-label>

    <shabic-property-with-label label="common.classification">
      {{ company.classification | translate }}
    </shabic-property-with-label>

    <shabic-property-with-label label="common.company-email">
      <a [href]="company.email | link: 'email'">
        {{ company.email }}
      </a>
    </shabic-property-with-label>

    <shabic-property-with-label label="common.company-phone">
      <a [href]="company.phone | link: 'phone'">
        {{ company.phone | mask: '+000-00-000-0000' }}
      </a>
    </shabic-property-with-label>

    <shabic-property-with-label label="common.street">
      {{ company.address.street }}
    </shabic-property-with-label>

    <shabic-property-with-label label="common.city">
      {{ company.address.city }}
    </shabic-property-with-label>

    <shabic-property-with-label label="common.po-box">
      {{ company.address.poBox }}
    </shabic-property-with-label>

    <shabic-property-with-label label="common.zipcode">
      {{ company.address.zipCode }}
    </shabic-property-with-label>

    <shabic-property-with-label label="common.company-website">
      {{ company.webSite }}
    </shabic-property-with-label>
  </section>
</shabic-dialog>
