<header class="bg-primary">
  <div class="grid grid-cols-header items-center py-3 ltr" shabicLayoutContent>
    <a routerLink="/">
      <img class="max-w-[152px]" [src]="logoSource" alt="Shabic logo" />
    </a>

    <div class="flex flex-col gap-3" *ngIf="hasNavigation">
      <div
        class="flex text-white grid-flow-col justify-end items-center gap-5.5 min-h-[40px]"
        shabicLayoutContent
      >
        <shabic-navigation [navigation]="navigation"></shabic-navigation>

        <shabic-language-dropdown *ngIf="isAdminApp"></shabic-language-dropdown>

        <a
          href="/"
          *ngIf="(account$ | async) === null"
          class="font-medium text-sm text-yellow hover:underline uppercase"
          (click)="onLogin($event)"
        >
          {{ 'button.sign-in' | translate }}
        </a>

        <div class="shrink-0">
          <shabic-dropdown *ngIf="account$ | async as user">
            <div target class="flex items-center gap-1">
              <shabic-avatar
                class="shrink-0"
                [avatarSource]="user.avatar"
              ></shabic-avatar>
              <span class="text-white font-medium text-sm uppercase">
                {{ user.fullName }}
              </span>
            </div>

            <div content>
              <ul class="grid gap-4 p-4">
                <li *ngFor="let item of profileNavigation | auth | async">
                  <a
                    class="text-sm font-medium w-full rtl:text-right block hover:underline"
                    [routerLink]="item['route']"
                  >
                    {{ item['label'] | translate }}
                  </a>
                </li>
              </ul>

              <a
                class="border-t border-grey-350 text-sm rtl:text-right font-medium w-full block hover:underline px-4 py-3"
                routerLink="/"
                (click)="onLogout()"
              >
                {{ 'navigation.logout' | translate }}
              </a>
            </div>
          </shabic-dropdown>
        </div>
      </div>
    </div>
  </div>
</header>

<div
  class="bg-gradient-to-r from-primary via-blue to-yellow min-h-[30px]"
></div>

<ng-template #emptyBlock>
  <div class="min-h-[52px]">
    <shabic-language-dropdown></shabic-language-dropdown>
  </div>
</ng-template>
