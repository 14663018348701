import {
  ChangeDetectionStrategy,
  Component,
  
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CurrencyService } from '@shabic/core';
import { SizeOption } from '@shabic/models';
import { BehaviorSubject } from 'rxjs';
@Component({
  selector: 'shabic-size-dropdown',
  templateUrl: './size-dropdown.component.html',
  styleUrls: ['./size-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SizeDropdownComponent implements OnChanges {
  private _selectedValue = new BehaviorSubject<SizeOption<any> | null>(null);

  @Input() label!: string;
  @Input() options!: SizeOption<any>[];
  @Input() value?: unknown;
  @Input() translateParams = {};

  @Output() size = new EventEmitter<SizeOption<any>>();

  selectedValue$ = this._selectedValue.asObservable();
  currentCurrency$ = this.currencyService.currentCurrency$;

  constructor(private currencyService: CurrencyService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']) {
      const option =
        this.options.find(el => {
          if (Array.isArray(el.key)) {
            if (el.key.length) {
              return el.key.includes(changes['value'].currentValue);
            } else {
              return !changes['value'].currentValue && !el.key.length;
            }
          }
          return el.key === changes['value'].currentValue;
        }) || null;

      this._selectedValue.next(option);
    }
  }

  isSelected(option: SizeOption<any>) {
    if (Array.isArray(option.key)) {
      return (
        (typeof this.value === 'string' && option.key.includes(this.value)) ||
        (!option.key.length && !this.value)
      );
    }

    return option.key === this.value;
  }

  onSelect(option: SizeOption<any>): void {
    this.size.emit(option);
  }

  getTranslateParams(params: Record<string, unknown>) {
    return {
      ...params,
      ...this.translateParams,
    };
  }
}