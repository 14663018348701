import { DIALOG_DATA } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { ConfirmDialogData } from '@shabic/models';
import { BehaviorSubject, finalize } from 'rxjs';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'shabic-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogComponent {
  private _loading = new BehaviorSubject<boolean>(false);

  loading$ = this._loading.asObservable();

  constructor(@Inject(DIALOG_DATA) public data: ConfirmDialogData) {}

  confirm(dialog: DialogComponent) {
    this._loading.next(true);

    this.data
      .onConfirm()
      .pipe(finalize(() => this._loading.next(false)))
      .subscribe(() => {
        dialog.close();
      });
  }
}
