<div class="group flex items-center gap-3">
  <ng-content select="input"></ng-content>

  <span
    class="w-4 h-4 shrink-0 rounded-lg flex items-center justify-center border bg-white border-grey-400 peer-checked:border-none peer-checked:bg-primary text-transparent peer-checked:text-white transition-colors peer-disabled:hover:border-grey-400 peer-disabled:cursor-not-allowed"
    (click)="onToggle()"
  >
    <ng-template shabicIcon="check"></ng-template>
  </span>

  <span
    class="-mb-[10px] empty:hidden text-grey peer-checked:text-primary peer-disabled:hover:text-grey-350 group-hover:text-primary transition-colors peer-disabled:text-grey-350"
  >
    <ng-content select="shabic-form-field-label"></ng-content>
  </span>
</div>
