<svg
  width="80"
  height="80"
  viewBox="0 0 80 80"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_477_1116)">
    <path
      d="M69.0032 40.4052L67.2132 35.3189L47.9045 54.6281C46.018 56.5183 43.5047 57.5576 40.8346 57.5576C38.1651 57.5576 35.6518 56.5183 33.7653 54.6281L19.5992 40.462C17.709 38.5755 16.669 36.0622 16.669 33.3927C16.669 30.7225 17.709 28.2093 19.5992 26.3228C21.4857 24.4332 23.999 23.3932 26.6685 23.3932C29.3386 23.3932 31.8519 24.4332 33.7384 26.3228L40.8346 33.4159L59.8472 14.4039L58.5668 10.7706L57.9772 7.24422C57.6074 4.98421 56.117 3.11114 54.0004 2.23412C51.874 1.35099 49.5011 1.62441 47.6378 2.96101L38.375 9.66718L29.0085 2.94392C27.1287 1.6177 24.749 1.35404 22.6355 2.24083C20.536 3.12091 19.059 4.99092 18.6892 7.23751L16.8759 18.5266L5.51974 20.4131C3.2634 20.7835 1.39033 22.2733 0.510249 24.3899C-0.36983 26.5132 -0.096407 28.8928 1.24019 30.7561L7.94332 40.0189L1.22005 49.3849C-0.10312 51.2646 -0.36983 53.6479 0.516963 55.7578C1.40009 57.8579 3.26645 59.3343 5.51303 59.7047L16.8027 61.521L18.6892 72.8736C19.056 75.1269 20.536 76.9969 22.6355 77.877C24.7521 78.7631 27.1354 78.5001 29.0323 77.1568L38.2951 70.4506L47.6378 77.1568C48.7809 77.9734 50.1108 78.3933 51.4608 78.3933C52.3171 78.3933 53.1771 78.2267 54.0004 77.8837C56.1206 77.0036 57.6074 75.1305 57.9772 72.8803L59.7935 61.5912L71.146 59.7047C73.3993 59.3343 75.2663 57.8579 76.1494 55.7578C77.0362 53.6479 76.7726 51.2646 75.4293 49.3617L69.0032 40.4052Z"
      fill="#F0FF00"
    />
    <path
      d="M40.8338 50.8917C39.9806 50.8917 39.1273 50.5652 38.4774 49.9152L24.3113 35.7491C23.0082 34.4461 23.0082 32.3393 24.3113 31.0363C25.6149 29.7326 27.7211 29.7326 29.0247 31.0363L40.8338 42.8453L74.3085 9.37057C75.6122 8.06754 77.7184 8.06754 79.022 9.37057C80.325 10.6736 80.325 12.7804 79.022 14.0834L43.1902 49.9152C42.5402 50.5652 41.687 50.8917 40.8338 50.8917Z"
      fill="#00A4B9"
    />
  </g>
  <defs>
    <clipPath id="clip0_477_1116">
      <rect width="80" height="80" fill="white" />
    </clipPath>
  </defs>
</svg>
