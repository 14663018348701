import { InjectionToken } from '@angular/core';
import { AppType } from './app.model';

export interface IEnvironment {
  production: boolean;
  app: AppType;
  clientApiUrl: string;
  marketplaceApiUrl: string;
}

export const ENV = new InjectionToken<IEnvironment>('env');
