import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  BIRTHDAY_STORAGE_KEY,
  GOV_ID_STORAGE_KEY,
  SUCCESS,
} from '@shabic/constants';
import { AccountService, StorageService } from '@shabic/core';
import { FormComponent } from '@shabic/form-control';

@Component({
  selector: 'shabic-sign-up-verify-govt-id-step',
  templateUrl: './sign-up-verify-govt-id-step.component.html',
  styleUrls: ['./sign-up-verify-govt-id-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignUpVerifyGovtIdStepComponent
  extends FormComponent<unknown>
  implements OnInit
{
  @Output() completed: EventEmitter<void> = new EventEmitter();

  formGroup: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required]),
    phone: new FormControl('', [Validators.required]),
    fullName: new FormControl('', [Validators.required]),
    birthday: new FormControl(''),
    gender: new FormControl('male'),
    nationality: new FormControl('Saudi'),
    govId: new FormControl(''),
  });

  constructor(
    private accountService: AccountService,
    private storageService: StorageService,
    protected override cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.accountService.get().subscribe(response => {
      if (response.status === SUCCESS) {
        const birthday = this.storageService.retrieve(
          BIRTHDAY_STORAGE_KEY,
          true
        );
        const govId = this.storageService.retrieve(GOV_ID_STORAGE_KEY, true);

        this.formGroup.patchValue({ ...response.payload, birthday, govId });
        this.formGroup.disable();
      }
    });
  }

  onSubmit(): void {
    const { birthday } = this.formGroup.value;

    const value = {
      ...this.formGroup.value,
      birthday: birthday ? new Date(birthday).toISOString() : null,
    };

    this.completed.emit(value);
  }
}
