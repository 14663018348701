import {
  ChangeDetectionStrategy,
  Component,
  Input,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { StepperPoint } from './models';
import { StepperPointComponent } from './stepper-point/stepper-point.component';

@Component({
  selector: 'shabic-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepperComponent {
  @Input() steps: StepperPoint[] = [];
  @Input() currentStep?: StepperPoint | null;
  @ViewChildren(StepperPointComponent)
  points?: QueryList<StepperPointComponent>;

  get progresStyles() {
    return {
      'width.px': this.getActiveStepOffset(),
    };
  }

  private getActiveStepOffset() {
    const activePoint = this.points?.find(
      item => item.index === this.currentStep?.id
    );

    return activePoint?.pointOffset;
  }
}
