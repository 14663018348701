import { DIALOG_DATA } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ibanValidator, SUCCESS } from '@shabic/constants';
import { BankService } from '@shabic/core';
import { FormComponent } from '@shabic/form-control';
import {
  BankAccount,
  CreateBankAccountDialogData,
  EditBankAccountDialogData,
  IBankAccountPayload,
  List,
  Option,
} from '@shabic/models';
import { map, Observable, tap } from 'rxjs';
import { DialogComponent } from '../dialog/dialog.component';
import { EventBrokerService } from '@shabic/event-broker';

@Component({
  selector: 'shabic-bank-account-dialog',
  templateUrl: './bank-account-dialog.component.html',
  styleUrls: ['./bank-account-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BankAccountDialogComponent
  extends FormComponent<BankAccount>
  implements OnInit
{
  formGroup = new FormGroup({
    bank: new FormControl('', [Validators.required]),
    // accountNumber: new FormControl('', [
    //   Validators.required,
    //   numberValidator(),
    // ]),
    iban: new FormControl('', [Validators.required, ibanValidator()]),
    active: new FormControl('yes'),
  });
  private _banks: Option[] = [];
  banks$: Observable<Option[]> = this.bankService
    .getBankList({
      ...List.initParams,
      statuses: ['ACTIVE'],
      size: 100,
    })
    .pipe(
      map(response => {
        if (response instanceof List) {
          return response.content.map(
            bank =>
              new Option(
                this.translateService,
                bank.name.get('en') as string,
                bank.id
              )
          );
        } else {
          return [];
        }
      }),
      tap(options => (this._banks = options))
    );

  constructor(
    @Inject(DIALOG_DATA)
    public data: CreateBankAccountDialogData | EditBankAccountDialogData,
    private translateService: TranslateService,
    protected override cd: ChangeDetectorRef,
    private bankService: BankService,
    private eventBrokerService: EventBrokerService
  ) {
    super();
  }

  get isEditMode() {
    return this.data instanceof EditBankAccountDialogData;
  }

  ngOnInit(): void {
    if (this.data instanceof EditBankAccountDialogData) {
      const { account } = this.data;

      this.formGroup.patchValue({
        bank: account.bank.name.get('en'),
        iban: account.iban,
        // accountNumber: account.accountNumber,
        active: account.status === 'ACTIVE' ? 'yes' : 'no',
      });

      this.formGroup.get('bank')?.disable();
      this.formGroup.get('iban')?.disable();
      this.formGroup.get('accountNumber')?.disable();
    }
  }

  onSubmit(dialog: DialogComponent) {
    if (this.formGroup.invalid) {
      return;
    }

    const { iban, bank, active } = this.formGroup.value;
    const bankId =
      this._banks.find(option => option.label === bank)?.id || null;

    const body: IBankAccountPayload = {
      bankId: bankId as string,
      iban: iban as string,
      name: bank as string,
      status: active === 'yes' ? 'ACTIVE' : 'DISABLED',
    };

    if (this.data instanceof CreateBankAccountDialogData) {
      this.submitForm(this.formGroup, this.data.onConnect(body)).subscribe(
        status => {
          if (status === SUCCESS) {
            this.data.onSuccess();
            dialog.close();
          }
        }
      );
    }

    if (this.data instanceof EditBankAccountDialogData) {
      this.submitForm(
        this.formGroup,
        this.data.onEdit({ status: body.status })
      ).subscribe(status => {
        if (status === SUCCESS) {
          this.data.onSuccess();
          dialog.close();
        }
      });
    }
  }
}
