<form shabicLoginForm [formGroup]="model.formGroup" (ngSubmit)="model.verify()">
  <shabic-headline
    variant="h2"
    [invert]="true"
    class="block pb-0.5"
    align="center"
  >
    {{ 'passcode.enter-passcode' | translate }}
  </shabic-headline>
  <shabic-sub-headline [invert]="true">
    {{ 'passcode.email-passcode' | translate }}
  </shabic-sub-headline>

  <shabic-passcode class="mt-7 text-white"></shabic-passcode>

  <button class="mt-5.5 w-full" type="submit" shabicButton="secondary">
    {{ 'button.confirm' | translate }}
  </button>
</form>
