import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import {
  BehaviorSubject,
  filter,
  fromEvent,
  Subject,
  take,
  takeUntil,
  merge,
  delay,
} from 'rxjs';

@Directive({
  selector: '[shabicInViewport]',
})
export class InViewportDirective implements OnInit, OnDestroy, AfterViewInit {
  private readonly _inView = new BehaviorSubject<boolean>(false);
  private readonly _destroy = new Subject<void>();

  @Input() inViewportClass?: string;

  constructor(
    private el: ElementRef<HTMLElement>,
    private renderer: Renderer2
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.inViewportClass) {
        this._inView.next(this.isInViewport());
      }
    }, 10);
  }

  ngOnInit(): void {
    this.subscribe();

    if (this.inViewportClass) {
      this.renderer.addClass(this.el.nativeElement, 'invisible');
    }
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  private subscribe() {
    if (!this.inViewportClass) {
      return;
    }

    fromEvent(window, 'scroll')
      .pipe(
        takeUntil(
          merge(
            this._destroy,
            this._inView.pipe(
              filter(v => v),
              take(1)
            )
          )
        )
      )
      .subscribe(() => {
        this._inView.next(this.isInViewport());
      });

    this._inView
      .pipe(
        filter(v => v),
        take(1),
        delay(100)
      )
      .subscribe(() => {
        if (this.inViewportClass) {
          this.renderer.addClass(this.el.nativeElement, this.inViewportClass);
          this.renderer.removeClass(this.el.nativeElement, 'invisible');
        }
      });
  }

  private isInViewport() {
    if (!this.el) {
      return false;
    }

    const bounding = this.el.nativeElement.getBoundingClientRect();
    const myElementHeight = this.el.nativeElement.offsetHeight;
    const myElementWidth = this.el.nativeElement.offsetWidth;

    return (
      bounding.top >= -myElementHeight &&
      bounding.left >= -myElementWidth &&
      bounding.right <=
        (window.innerWidth || document.documentElement.clientWidth) +
          myElementWidth &&
      bounding.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) +
          myElementHeight
    );
  }
}
