import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ProductStatus } from '@shabic/models';
import { IProductStatus } from '../../models';

@Component({
  selector: 'shabic-product-statuses',
  templateUrl: './product-statuses.component.html',
  styleUrls: ['./product-statuses.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductStatusesComponent {
  @Input() statuses!: IProductStatus[];

  @Output() changed = new EventEmitter<ProductStatus>();

  onChange(event: MouseEvent, item: IProductStatus) {
    event.stopPropagation();
    event.preventDefault();

    this.changed.emit(item.status);
  }
}
