import { Pipe, PipeTransform } from '@angular/core';
import { Option } from '@shabic/models';

@Pipe({
  name: 'selectControlFilter',
})
export class SelectControlFilterPipe implements PipeTransform {
  transform(value: Option[] | null, ...args: string[]): Option[] | null {
    const query = args[0];

    if (!value || query === '' || !query) {
      return value;
    }

    const target = query.toLocaleLowerCase();

    return value.filter(option =>
      option.label.toLocaleLowerCase().includes(target)
    );
  }
}
