import { Pipe, PipeTransform } from '@angular/core';
import { getPeriodRange, Period, Time } from '@shabic/models';

@Pipe({
  name: 'rangeFormat',
})
export class RangeFormatPipe implements PipeTransform {
  transform(period: Period, ...args: Time[]): string | undefined {
    const [date] = args;
    const { from, to } = getPeriodRange(period, date);
    const fromFormat = from?.format('D MMM YYYY');
    const toFormat = to?.format('D MMM YYYY');

    if (from?.format('YYYY/MM/DD') === to?.format('YYYY/MM/DD')) {
      return fromFormat;
    }

    return `${fromFormat} - ${toFormat}`;
  }
}
