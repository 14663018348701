<div class="desktop:pb-10 flex items-start">
  <div *ngIf="currentStep" class="absolute mt-4" [ngStyle]="progresStyles">
    <shabic-stepper-line class="w-full" [isActive]="true"></shabic-stepper-line>
  </div>

  <shabic-stepper-line
    class="w-full mt-4"
    [isActive]="false"
  ></shabic-stepper-line>

  <ng-container *ngFor="let step of steps; let first = first">
    <shabic-stepper-line
      *ngIf="!first"
      class="w-full mt-4"
      [isActive]="false"
    ></shabic-stepper-line>
    <shabic-stepper-point
      class="invisible desktop:visible"
      [isActive]="step.isActive"
      [completed]="step.completed"
      [index]="step.id"
    >
      <small
        class="block text-center whitespace-nowrap font-medium mt-0.5 text-sm"
        [class.text-primary]="step.isActive"
        [class.text-grey]="!step.isActive"
        [class.text-secondary]="step.completed"
      >
        {{ step.subtitle | translate }}
      </small>
    </shabic-stepper-point>
  </ng-container>

  <shabic-stepper-line
    class="w-full mt-4"
    [isActive]="false"
  ></shabic-stepper-line>
</div>

<div
  class="mb-6 mt-3 text-primary text-center text-sm font-semibold desktop:hidden"
>
  {{ currentStep?.title || '' | translate }}:
  {{ currentStep?.subtitle || '' | translate }}
</div>
