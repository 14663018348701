<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M17.7102 12.71C17.6172 12.8037 17.5066 12.8781 17.3848 12.9289C17.2629 12.9797 17.1322 13.0058 17.0002 13.0058C16.8682 13.0058 16.7375 12.9797 16.6156 12.9289C16.4937 12.8781 16.3831 12.8037 16.2902 12.71L13.0002 9.41V17C13.0002 17.2652 12.8948 17.5196 12.7073 17.7071C12.5198 17.8946 12.2654 18 12.0002 18C11.735 18 11.4806 17.8946 11.2931 17.7071C11.1055 17.5196 11.0002 17.2652 11.0002 17V9.41L7.71019 12.71C7.52188 12.8983 7.26649 13.0041 7.00019 13.0041C6.73388 13.0041 6.47849 12.8983 6.29019 12.71C6.10188 12.5217 5.99609 12.2663 5.99609 12C5.99609 11.7337 6.10188 11.4783 6.29019 11.29L11.2902 6.29C11.3853 6.19896 11.4974 6.12759 11.6202 6.08C11.7399 6.02709 11.8693 5.99977 12.0002 5.99977C12.1311 5.99977 12.2605 6.02709 12.3802 6.08C12.5029 6.12759 12.6151 6.19896 12.7102 6.29L17.7102 11.29C17.8039 11.383 17.8783 11.4936 17.9291 11.6154C17.9798 11.7373 18.006 11.868 18.006 12C18.006 12.132 17.9798 12.2627 17.9291 12.3846C17.8783 12.5064 17.8039 12.617 17.7102 12.71Z"
    fill="currentColor"
  />
</svg>
