import { DIALOG_DATA } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { BankAccount, BankBalanceDialogData, Time } from '@shabic/models';

@Component({
  selector: 'shabic-bank-balance-dialog',
  templateUrl: './bank-balance-dialog.component.html',
  styleUrls: ['./bank-balance-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BankBalanceDialogComponent {
  readonly account: BankAccount;

  readonly currentTime: Time = Time.now();

  constructor(@Inject(DIALOG_DATA) data: BankBalanceDialogData) {
    this.account = data.account;
  }
}
