import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'link',
})
export class LinkPipe implements PipeTransform {
  transform(
    value: string | undefined,
    type: 'email' | 'phone'
  ): string | undefined {
    if (value) {
      switch (type) {
        case 'email':
          return `mailto:${value}`;
        case 'phone':
          return `tel:${value}`;
      }
    }

    return value || '';
  }
}
