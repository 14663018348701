import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormFieldComponent } from './form-field/form-field.component';
import { FormFieldLabelComponent } from './form-field-label/form-field-label.component';
import { InputDirective } from './directives/input.directive';
import { FormFieldErrorsComponent } from './form-field-errors/form-field-errors.component';
import { RadioControlComponent } from './radio-control/radio-control.component';
import { FormFieldIconComponent } from './form-field-icon/form-field-icon.component';
import { CheckboxControlComponent } from './checkbox-control/checkbox-control.component';
import { IconModule } from '@shabic/icon';
import { TranslateModule } from '@ngx-translate/core';
import { ImageControlComponent } from './image-control/image-control.component';
import { UiModule } from '@shabic/ui';
import { FileControlComponent } from './file-control/file-control.component';
import { SelectControlComponent } from './select-control/select-control.component';
import { CdkMenuModule } from '@angular/cdk/menu';
import { SelectControlFilterPipe } from './pipes';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    TranslateModule,
    UiModule,
    CdkMenuModule,
    FormsModule,
  ],
  declarations: [
    FormFieldComponent,
    FormFieldLabelComponent,
    InputDirective,
    FormFieldErrorsComponent,
    RadioControlComponent,
    FormFieldIconComponent,
    CheckboxControlComponent,
    ImageControlComponent,
    FileControlComponent,
    SelectControlComponent,
    SelectControlFilterPipe,
  ],
  exports: [
    FormFieldComponent,
    FormFieldLabelComponent,
    InputDirective,
    RadioControlComponent,
    FormFieldIconComponent,
    CheckboxControlComponent,
    FormFieldErrorsComponent,
    ImageControlComponent,
    FileControlComponent,
    SelectControlComponent,
  ],
})
export class FormControlModule {}
