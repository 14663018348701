<section class="grid min-h-screen grid-rows-layout">
  <div>
    <ng-content select="[header]"></ng-content>
  </div>

  <main class="grow" [class.gradient]="hasGradient">
    <ng-content></ng-content>

    <div *ngIf="hasSignUpWave" class="sign-up-wave"></div>
  </main>

  <!-- <div>
    <shabic-footer [hasNav]="hasFooterNav"></shabic-footer>
  </div> -->
</section>
