import { Directive, HostBinding, Input } from '@angular/core';

type Variant =
  | 'primary'
  | 'secondary'
  | 'warning'
  | 'danger'
  | 'danger-filled'
  | 'default'
  | 'green'
  | 'navigation'
  | 'gradient'
  | 'grey'
  | 'link'
  | '';
@Directive({
  selector: '[shabicButton]',
})
export class ButtonDirective {
  @Input() shabicButton!: Variant;

  @HostBinding('class')
  get elementClass(): string {
    const initClasses =
      'group text-sm font-semibold rounded-2xl inline-flex justify-center rtl:flex-row-reverse items-center disabled:cursor-not-allowed [&:not(:disabled):hover]:cursor-pointer box-border z-1';

    switch (this.shabicButton) {
      case 'primary':
        return `${initClasses} relative bg-primary text-white disabled:bg-grey-350 py-3 px-4 before:content-[''] before:rounded-2xl before:absolute before:-z-1 before:w-full before:h-full before:bg-main-pattern [&:not(:disabled):hover]:bg-transparent transition-colors before:opacity-0 [&:not(:disabled):hover]:before:opacity-100 before:transition-opacity`;
      case 'warning':
        return `${initClasses} bg-yellow text-primary disabled:bg-grey-350 py-3 px-4`;
      case 'secondary':
        return `${initClasses} bg-secondary text-white [&:not(:disabled):hover]:shadow-secondary transition-shadow  disabled:bg-grey-350 py-3 px-4`;
      case 'default':
        return `${initClasses} [&:not(:disabled)]:shadow-inner-border [&:not(:disabled):hover]:bg-primary text-primary [&:not(:disabled):hover]:text-white disabled:text-grey disabled:bg-grey-350 disabled:text-white py-3 px-4 transition-all`;
      case 'danger':
        return `${initClasses} text-red disabled:text-white [&:not(:disabled)]:shadow-inner-border disabled:bg-grey-350 py-3 px-4`;
      case 'danger-filled':
        return `${initClasses} border-2 transition-colors [&:not(:disabled):hover]:bg-red [&:not(:disabled):hover]:text-white border-red text-red disabled:text-grey-350 disabled:border-grey-350 py-3 px-4`;
      case 'green':
        return `${initClasses} bg-green text-white disabled:bg-grey-350 py-3 px-4`;
      case 'navigation':
        return `${initClasses} text-grey [&:not(:disabled):hover]:bg-grey-400 transition-colors w-5.5 h-5.5 rounded-xl`;
      case 'gradient':
        return `${initClasses} text-white [&:not(:disabled)]:bg-main-pattern disabled:border-grey disabled:bg-grey-350 py-3 px-4`;
      case 'grey':
        return `${initClasses} text-primary [&:not(:disabled)]:bg-grey-350 [&:not(:disabled):hover]:bg-grey-400 disabled:text-grey-400 disabled:bg-grey-300 py-3 px-4 transition-colors`;
      case 'link':
        return `${initClasses} text-grey [&:not(:disabled):hover]:text-secondary transition-colors disabled:text-grey-400 py-3 px-4`;
      default:
        return initClasses;
    }
  }
}
