import { DIALOG_DATA } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { CLIENT_APP, LOGO_SOURCE_PRIMARY, SUCCESS } from '@shabic/constants';
import { AuthService } from '@shabic/core';
import { AppEvent, EAppEvent, EventBrokerService } from '@shabic/event-broker';
import { FormComponent } from '@shabic/form-control';
import {
  AuthResponse,
  ENV,
  ForgotPasswordDialogData,
  IEnvironment,
  Login,
  LoginDialogData,
} from '@shabic/models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'shabic-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginDialogComponent
  extends FormComponent<AuthResponse>
  implements OnInit, OnDestroy
{
  model = new Login();
  logoSource = LOGO_SOURCE_PRIMARY;

  @ViewChild(DialogComponent) dialog!: DialogComponent;

  get isClientApp(): boolean {
    return this.env.app === CLIENT_APP;
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    private eventBrokerService: EventBrokerService,
    @Inject(DIALOG_DATA) public data: LoginDialogData,
    @Inject(ENV) private env: IEnvironment
  ) {
    super();
  }

  ngOnInit(): void {
    this.model.completed$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.dialog.close();
      this.data.onSuccess();
    });

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.dialog.close();
    });
  }

  ngOnDestroy(): void {
    this.onDestoy();
    this.dialog.close();
  }

  onSubmit(): void {
    const value = this.model.formGroup.value;

    this.submitForm(
      this.model.formGroup,
      this.authService.login({
        username: value.username,
        password: value.password,
        rememberMe: value.rememberMe,
      })
    ).subscribe(response => {
      if (response === SUCCESS) {
        this.model.submit();
      }
    });
  }

  onForgotPassword(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();

    this.dialog.close();
    this.eventBrokerService.emit(
      new AppEvent<ForgotPasswordDialogData>(
        EAppEvent.OpenForgotPasswordDialog,
        {}
      )
    );
  }
}
