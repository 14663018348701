<ng-container *ngIf="isAdminApp; else clientContent">
  <shabic-layout>
    <shabic-header header></shabic-header>

    <div class="py-10" shabicLayoutContent>
      <ng-template [ngTemplateOutlet]="content"></ng-template>
    </div>
  </shabic-layout>
</ng-container>

<ng-template #clientContent>
  <ng-template [ngTemplateOutlet]="content"></ng-template>
</ng-template>

<ng-template #content>
  <div
    class="desktop:grid gap-5.5 desktop:gap-8 desktop:grid-cols-profile desktop:mt-3"
  >
    <div>
      <div
        class="grid desktop:gap-1 bg-grey-350 rounded-3xl desktop:p-1 relative"
        [class.collapsed]="collapsed$ | async"
      >
        <a
          *ngFor="let item of navigation | auth | async"
          class="text-grey font-medium text-sm p-4 desktop:p-3 flex items-center gap-3 rounded-2xl order-1 transition-colors hover:bg-primary/[0.1]"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          [routerLink]="item['route']"
        >
          <span *ngIf="item['icon']" class="icon text-primary">
            <ng-template [shabicIcon]="item['icon']"></ng-template>
          </span>

          {{ item['label'] | translate }}
        </a>

        <button
          type="button"
          shabicButton="navigation"
          [class.rotate]="(collapsed$ | async) === false"
          class="absolute desktop:hidden ltr:ml-auto rtl:mr-auto ltr:right-3 rtl:left-3 top-3"
          (click)="onToggle()"
        >
          <ng-template shabicIcon="arrow"></ng-template>
        </button>
      </div>
    </div>

    <div class="mt-5.5 desktop:mt-0 min-w-0">
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-template>
