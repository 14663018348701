import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[shabicLoginForm]',
})
export class LoginFormDirective {
  @HostBinding('class')
  get elementClasses(): string {
    return 'px-[20px] rounded w-full max-w-[432px] mx-auto';
  }
}
