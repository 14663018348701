import { Inject, Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { ADMIN_APP } from '@shabic/constants';
import { ENV, IEnvironment } from '@shabic/models';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  constructor(@Inject(ENV) private env: IEnvironment, private router: Router) {}

  canActivate(): boolean | UrlTree {
    if (this.env.app === ADMIN_APP) {
      return true;
    }

    return this.router.parseUrl('/');
  }
}
