import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Align } from '../../models';

@Component({
  selector: 'shabic-sub-headline',
  templateUrl: './sub-headline.component.html',
  styleUrls: ['./sub-headline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubHeadlineComponent {
  @Input() align?: Align = 'center';
  @Input() invert?: boolean = false;
}
