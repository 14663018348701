import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ApiErrorResponse,
  ApiMarketplace,
  ApiResponse,
  Contact,
  ENV,
  IEnvironment,
  IListParams,
  IListResponse,
  IPromotionPayload,
  IPromotionResponse,
  List,
  Promotion,
} from '@shabic/models';
import { CompanyService } from './company.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { MediaService } from './media.service';
import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root',
})
export class PromotionService extends ApiMarketplace {
  override url = '/promotion';

  constructor(
    private http: HttpClient,
    private accountService: AccountService,
    private mediaService: MediaService,
    @Inject(ENV) env: IEnvironment
  ) {
    super(env);
  }

  getCompanyPromotions() {
    return this.accountService.get().pipe(
      switchMap(response => {
        if (response.payload instanceof Contact) {
          const companyId = response.payload.company?.id;

          return this.getPromotions({
            companyIds: [companyId],
            page: 0,
          }).pipe(
            map(response => {
              if (response instanceof List) {
                return {
                  companyId,
                  promotions: response.content,
                };
              } else {
                return response;
              }
            })
          );
        } else {
          return of(response);
        }
      })
    );
  }

  getPromotions(params: IListParams) {
    const query = new HttpParams({
      fromObject: params || {},
    });

    return this.http
      .get<IListResponse<IPromotionResponse[]>>(this.apiModelUrl, {
        params: query,
      })
      .pipe(
        map(
          response =>
            new List(response, content => content.map(el => new Promotion(el)))
        ),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }

  updatePromotion(payload: IPromotionPayload) {
    return this.uploadPicture(payload).pipe(
      switchMap(response => {
        const mediaId = response.payload[0]?.id;

        const { id, ...restPayload } = payload;
        const body = { ...restPayload, mediaId };
        const request = payload.id
          ? this.http.put<IPromotionResponse>(
              this.getEndpoint(`/${payload.id}`),
              body
            )
          : this.http.post<IPromotionResponse>(this.apiModelUrl, {
              ...body,
              active: false,
            });

        return request.pipe(
          map(response => new ApiResponse(new Promotion(response))),
          catchError(response =>
            of(new ApiResponse(new ApiErrorResponse(response)))
          )
        );
      })
    );
  }

  toggleActive(promotion: Promotion, active: boolean) {
    return this.http
      .put<IPromotionResponse>(this.getEndpoint(`/${promotion.id}`), {
        mediaId: promotion.media.id,
        active,
        description: promotion.description,
      })
      .pipe(
        map(response => new ApiResponse(new Promotion(response))),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }

  private uploadPicture(payload: IPromotionPayload) {
    if (typeof payload.mediaId === 'number') {
      return of(new ApiResponse([{ id: payload.mediaId }]));
    } else if (payload.mediaId?.length) {
      return this.mediaService.upload(
        this.getApiEndpoint(''),
        payload.mediaId,
        'PROMOTION'
      );
    } else {
      return of(new ApiResponse([]));
    }
  }
}
