import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  LANGUAGE_STORAGE_KEY,
  REFRESH_TOKEN_KEY,
  TOKEN_KEY,
} from '@shabic/constants';
import { AppEvent, EAppEvent, EventBrokerService } from '@shabic/event-broker';
import {
  ApiClient,
  ApiErrorResponse,
  ApiResponse,
  AuthResponse,
  Contact,
  ENV,
  IAuthResponse,
  IContactParams,
  IContactResponse,
  IEnvironment,
  Language,
  IListResponse,
  List,
  PasswordPayload,
} from '@shabic/models';
import { Observable, of } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';
import { MediaService } from './media.service';
import { StorageService } from './storage.service';

type AccountUpdatePayload = {
  mediaId?: File[];
  langKey?: string;
};

@Injectable({
  providedIn: 'root',
})
export class AccountService extends ApiClient {
  override readonly url: string = '/account';

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private eventBrokerService: EventBrokerService,
    @Inject(ENV) env: IEnvironment,
    private mediaService: MediaService
  ) {
    super(env);
  }

  get(): Observable<ApiResponse<Contact | ApiErrorResponse>> {
    return this.http.get<IContactResponse>(this.apiModelUrl).pipe(
      map(response => new ApiResponse(new Contact(response))),
      tap(response => {
        if (
          !this.storageService.retrieve(LANGUAGE_STORAGE_KEY, true) &&
          response.payload.langKey
        ) {
          this.eventBrokerService.emit(
            new AppEvent<Language>(
              EAppEvent.ChangeLanguage,
              response.payload.langKey as Language
            )
          );
        }
      }),
      catchError(response =>
        of(new ApiResponse(new ApiErrorResponse(response)))
      )
    );
  }

  update(
    payload: AccountUpdatePayload
  ): Observable<ApiResponse<Contact | ApiErrorResponse>> {
    return this.uploadPicture(payload).pipe(
      switchMap(response => {
        const mediaId = response.payload[0]?.id;

        return this.http
          .put<IContactResponse>(this.apiModelUrl, { ...payload, mediaId })
          .pipe(
            map(response => new ApiResponse(new Contact(response))),
            catchError(response =>
              of(new ApiResponse(new ApiErrorResponse(response)))
            )
          );
      })
    );
  }

  sendEmail(email: string) {
    const params = new HttpParams({
      fromObject: { email },
    });

    return this.http
      .get<unknown>(this.getEndpoint('/reset-password-request'), {
        params,
      })
      .pipe(
        map(response => new ApiResponse(response)),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }

  resetPassword(password: string, token: string) {
    const body = {
      password,
      confirmPassword: password,
      token,
    };

    return this.http
      .post<unknown>(this.getEndpoint('/reset-password'), body)
      .pipe(
        map(response => new ApiResponse(response)),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }

  changePassword(payload: PasswordPayload) {
    return this.http
      .post<unknown>(this.getEndpoint('/change-password'), payload)
      .pipe(
        map(response => new ApiResponse(response)),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }

  toggleSupplier(enabled: boolean) {
    const params = new HttpParams({
      fromObject: { enabled },
    });

    return this.http
      .put<IAuthResponse>(this.getEndpoint('/supplier'), {}, { params })
      .pipe(
        map(response => {
          const apiResponse = new ApiResponse(new AuthResponse(response));
          this.storageService.store(TOKEN_KEY, apiResponse.payload.token, true);
          this.storageService.store(
            REFRESH_TOKEN_KEY,
            apiResponse.payload.refreshToken,
            true
          );

          return apiResponse;
        }),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }

  toggleAccountAccess(payload: {
    contact: Contact;
    disable: boolean;
    disableReason: string;
  }) {
    const body = {
      userId: payload.contact.id,
      disable: payload.disable,
      disableReason: payload.disableReason,
    };

    return this.http.put<unknown>(this.getEndpoint('/disable'), body).pipe(
      map(response => new ApiResponse(response)),
      catchError(response =>
        of(new ApiResponse(new ApiErrorResponse(response)))
      )
    );
  }

  blockUser(contact: Contact) {
    const body = {
      userId: contact.id,
      disable: true,
      disableReason: 'Reason',
    };

    return this.http.put<unknown>(this.getEndpoint('/disable'), body).pipe(
      map(response => new ApiResponse(response)),
      catchError(response =>
        of(new ApiResponse(new ApiErrorResponse(response)))
      )
    );
  }

  unBlockUser(contact: Contact) {
    const body = {
      userId: contact.id,
      disable: false,
      disableReason: 'Reason',
    };

    return this.http.put<unknown>(this.getEndpoint('/disable'), body).pipe(
      map(response => new ApiResponse(response)),
      catchError(response =>
        of(new ApiResponse(new ApiErrorResponse(response)))
      )
    );
  }

  getAccountList(query: IContactParams) {
    const params = new HttpParams({
      fromObject: { ...query },
    });

    return this.http
      .get<IListResponse<IContactResponse[]>>(this.getEndpoint('/search'), {
        params,
      })
      .pipe(
        map(
          response =>
            new List(response, content =>
              content.map(contact => new Contact(contact))
            )
        ),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }

  private uploadPicture(payload: AccountUpdatePayload) {
    if (payload.mediaId?.length) {
      return this.mediaService.upload(
        this.getApiEndpoint(''),
        payload.mediaId,
        'USER'
      );
    } else {
      return of(new ApiResponse([]));
    }
  }
}
