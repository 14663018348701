import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ApiClient,
  ApiErrorResponse,
  ApiResponse,
  Bank,
  BankStatus,
  ENV,
  IBankPayload,
  IBankResponse,
  IEnvironment,
  IListParams,
  IListResponse,
  List,
} from '@shabic/models';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MediaService } from '.';

@Injectable({
  providedIn: 'root',
})
export class BankService extends ApiClient {
  override readonly url = '/banks';

  constructor(
    private http: HttpClient,
    @Inject(ENV) env: IEnvironment,
    private mediaService: MediaService
  ) {
    super(env);
  }

  getBankList(payload: IListParams) {
    const params = new HttpParams({
      fromObject: payload,
    });

    return this.http
      .get<IListResponse<IBankResponse[]>>(this.apiModelUrl, { params })
      .pipe(
        map(
          response =>
            new List(response, content => content.map(el => new Bank(el)))
        ),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }

  addBank(payload: IBankPayload) {
    return this.uploadPicture(payload).pipe(
      switchMap(response => {
        const media = response.payload[0];

        return this.http
          .put<IBankResponse>(this.apiModelUrl, {
            ...payload,
            mediaId: media?.id,
          })
          .pipe(
            map(response => new ApiResponse(new Bank(response))),
            catchError(response =>
              of(new ApiResponse(new ApiErrorResponse(response)))
            )
          );
      }),
      catchError(response =>
        of(new ApiResponse(new ApiErrorResponse(response)))
      )
    );
  }

  updateBank(bank: Bank, status: BankStatus) {
    return this.http
      .put<IBankResponse>(this.getEndpoint(`/${bank.id}`), { status })
      .pipe(
        map(response => new ApiResponse(new Bank(response))),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }

  deleteBank(bank: Bank) {
    return this.http.delete<unknown>(this.getEndpoint(`/${bank.id}`)).pipe(
      map(response => new ApiResponse(response)),
      catchError(response =>
        of(new ApiResponse(new ApiErrorResponse(response)))
      )
    );
  }

  private uploadPicture(payload: IBankPayload) {
    if (payload.mediaId?.length) {
      return this.mediaService.upload(
        this.getApiEndpoint(''),
        payload.mediaId,
        'BANK'
      );
    } else {
      return of(new ApiResponse([]));
    }
  }
}
