import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  BIRTHDAY_STORAGE_KEY,
  CART_STORAGE_KEY,
  GOV_ID_STORAGE_KEY,
  LANGUAGE_STORAGE_KEY,
  MY_QUOTAS_STORAGE_KEY,
  REFRESH_TOKEN_KEY,
  TOKEN_KEY,
} from '@shabic/constants';
import { AppEvent, EAppEvent, EventBrokerService } from '@shabic/event-broker';
import {
  ApiClient,
  ApiErrorResponse,
  ApiResponse,
  ApiToken,
  AuthResponse,
  ENV,
  IAuthResponse,
  IEnvironment,
} from '@shabic/models';
import { Observable, of } from 'rxjs';
import { map, catchError, filter } from 'rxjs/operators';
import { SnackBarEvent } from './snack-bar.service';
import { StorageService } from './storage.service';

type LoginPayload = {
  username: string;
  password: string;
  rememberMe: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class AuthService extends ApiClient {
  override readonly url = '/authenticate';

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private eventBrokerService: EventBrokerService,
    private router: Router,
    @Inject(ENV) env: IEnvironment
  ) {
    super(env);
    this.subscribeOnLogOut();
  }

  get hasRefreshTokenAndNotExpired() {
    const token = this.storageService.retrieve<string>(REFRESH_TOKEN_KEY, true);

    return token ? !new ApiToken(token).isExpired : false;
  }

  get hasAccessTokenAndNotExpired() {
    const token = this.storageService.retrieve<string>(TOKEN_KEY, true);

    return token ? !new ApiToken(token).isExpired : false;
  }

  get token() {
    return this.storageService.retrieve<string>(TOKEN_KEY, true) || '';
  }

  get currentUser() {
    return new ApiToken(this.storageService.retrieve<string>(TOKEN_KEY, true));
  }

  login(
    payload: LoginPayload
  ): Observable<ApiResponse<AuthResponse | ApiErrorResponse>> {
    return this.http.post<IAuthResponse>(this.apiModelUrl, payload).pipe(
      map(response => {
        const apiResponse = new ApiResponse(new AuthResponse(response));
        this.storageService.store(TOKEN_KEY, apiResponse.payload.token, true);
        this.storageService.store(
          REFRESH_TOKEN_KEY,
          apiResponse.payload.refreshToken,
          true
        );

        return apiResponse;
      }),
      catchError(response =>
        of(new ApiResponse(new ApiErrorResponse(response)))
      )
    );
  }

  logout(): Observable<boolean> {
    this.storageService.delete(TOKEN_KEY, true);
    this.storageService.delete(REFRESH_TOKEN_KEY, true);
    this.storageService.delete(LANGUAGE_STORAGE_KEY, true);
    this.storageService.delete(BIRTHDAY_STORAGE_KEY, true);
    this.storageService.delete(CART_STORAGE_KEY, true);
    this.storageService.delete(GOV_ID_STORAGE_KEY, true);
    // this.storageService.delete(MY_QUOTAS_STORAGE_KEY, true);

    return of(true);
  }

  refreshToken(): Observable<ApiResponse<AuthResponse | ApiErrorResponse>> {
    const refresh_token = this.storageService.retrieve(REFRESH_TOKEN_KEY, true);

    return this.http
      .post<IAuthResponse>(this.getEndpoint('/refresh'), {
        refresh_token,
      })
      .pipe(
        map(response => {
          const apiResponse = new ApiResponse(new AuthResponse(response));
          this.storageService.store(TOKEN_KEY, apiResponse.payload.token, true);
          this.storageService.store(
            REFRESH_TOKEN_KEY,
            apiResponse.payload.refreshToken,
            true
          );

          return apiResponse;
        }),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }

  upgradeToken() {
    return this.http.get<IAuthResponse>(this.getEndpoint('/upgrade')).pipe(
      map(response => {
        const apiResponse = new ApiResponse(new AuthResponse(response));
        this.storageService.store(TOKEN_KEY, apiResponse.payload.token, true);
        this.storageService.store(
          REFRESH_TOKEN_KEY,
          apiResponse.payload.refreshToken,
          true
        );

        return apiResponse;
      }),
      catchError(response =>
        of(new ApiResponse(new ApiErrorResponse(response)))
      )
    );
  }

  private subscribeOnLogOut(): void {
    this.eventBrokerService.events$
      .pipe(filter(event => event.type === EAppEvent.LogOut))
      .subscribe(event => {
        this.logout().subscribe(() => {
          if (event.payload) {
            const { type, payload } = new SnackBarEvent(
              event.payload as string,
              'info'
            );

            this.eventBrokerService.emit(new AppEvent(type, payload));
          }

          this.router.navigateByUrl('/sign-in');
        });
      });
  }
}
