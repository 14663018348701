import { DIALOG_DATA } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SUCCESS } from '@shabic/constants';
import { ProductService } from '@shabic/core';
import { FormComponent } from '@shabic/form-control';
import { DeactivateProductDialogData } from '@shabic/models';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'shabic-deactivate-product-dialog',
  templateUrl: './deactivate-product-dialog.component.html',
  styleUrls: ['./deactivate-product-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeactivateProductDialogComponent extends FormComponent<unknown> {
  @ViewChild(DialogComponent) dialog?: DialogComponent;

  formGroup = new FormGroup({
    reason: new FormControl(''),
  });

  constructor(
    @Inject(DIALOG_DATA) public data: DeactivateProductDialogData,
    private productService: ProductService
  ) {
    super();
  }

  onSubmit() {
    this.submitForm(
      this.formGroup,
      this.productService.changeStatus(
        this.data.product,
        this.data.status,
        this.formGroup.value.reason || ''
      )
    ).subscribe(status => {
      if (status === SUCCESS) {
        this.dialog?.close();
        this.data.onSuccess();
      }
    });
  }
}
