<shabic-dialog name="dialog.bank-balance">
  <hr class="text-grey-350 mb-3" />

  <div class="flex items-center gap-3">
    <img
      [src]="account.bank.logo"
      shabicImage
      class="rounded-2xl bg-white w-7 h-7 border border-grey-350"
      width="64"
      height="64"
    />

    <div class="inline-flex item-center gap-1">
      <shabic-headline variant="h4">
        {{ account.bank.name | currentLang | async }}
      </shabic-headline>

      <span class="text-green">
        <ng-template shabicIcon="check"></ng-template>
      </span>
    </div>
  </div>

  <hr class="text-grey-350 my-3" />

  <div class="desktop:grid grid-cols-2 gap-3">
    <!-- <shabic-property-with-label label="common.account-number">
      {{ account.accountNumber }}
    </shabic-property-with-label> -->

    <!-- <hr class="text-grey-350 my-3 desktop:hidden" /> -->

    <shabic-property-with-label label="common.iban-number">
      {{ account.iban }}
    </shabic-property-with-label>
  </div>

  <hr class="text-grey-350 my-3" />

  <div class="desktop:grid grid-cols-2 gap-3">
    <shabic-property-with-label label="common.available-balance">
      {{ account.availableBalance | currency }}
    </shabic-property-with-label>

    <hr class="text-grey-350 my-3 desktop:hidden" />

    <shabic-property-with-label label="common.ledger-balance">
      {{ account.ledgerBalance | currency }}
    </shabic-property-with-label>
  </div>

  <hr class="text-grey-350 my-3" />

  <div class="desktop:grid grid-cols-2 gap-3">
    <shabic-property-with-label label="common.bank-time-stamp">
      {{ account.lastBalanceUpdateDate | time: 'D MMM YYYY hh:mm A' }}
    </shabic-property-with-label>

    <hr class="text-grey-350 my-3 desktop:hidden" />

    <shabic-property-with-label label="common.current-time">
      {{ currentTime | time: 'D MMM YYYY hh:mm A' }}
    </shabic-property-with-label>
  </div>
</shabic-dialog>
