import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';

export class Login {
  private _completed = new Subject<void>();

  formGroup: FormGroup = new FormGroup({
    username: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
    rememberMe: new FormControl(true),
    passcode: new FormControl(''),
  });

  completed$ = this._completed.asObservable();

  submit(): void {
    this._completed.next();
  }
}
