import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { AuthService } from '@shabic/core';
import { AppEvent, EAppEvent, EventBrokerService } from '@shabic/event-broker';
import { AUTH_ROLE_NOT_VERIFIED, LoginDialogData } from '@shabic/models';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'shabic-login-require-dialog',
  templateUrl: './login-require-dialog.component.html',
  styleUrls: ['./login-require-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginRequireDialogComponent {
  @ViewChild(DialogComponent) dialog!: DialogComponent;

  get isLoggedIn() {
    return this.authService.currentUser.roles.includes(AUTH_ROLE_NOT_VERIFIED);
  }

  constructor(
    private eventBrokerService: EventBrokerService,
    private authService: AuthService
  ) {}

  onLogin(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();

    this.dialog.close();
    this.eventBrokerService.emit(
      new AppEvent<LoginDialogData>(EAppEvent.OpenLoginDialog, {
        onSuccess: () =>
          this.eventBrokerService.emit(
            new AppEvent<null>(EAppEvent.Authorized, null)
          ),
      })
    );
  }
}
