import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  Optional,
  SkipSelf,
} from '@angular/core';
import { FormFieldComponent } from '../form-field/form-field.component';
import { FORM_CONFIG, IFormConfig } from '../models';

@Component({
  selector: 'shabic-form-field-label',
  templateUrl: './form-field-label.component.html',
  styleUrls: ['./form-field-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldLabelComponent {
  @Input() forAttribute?: string;
  @Input() colorInherit = false;

  get isRequired(): boolean {
    if (!this.formConfig || !this.formConfig.markRequiredFields) {
      return false;
    }

    return this.formField?.isRequired || false;
  }

  constructor(
    @Optional() @SkipSelf() private formField: FormFieldComponent,
    @Optional() @Inject(FORM_CONFIG) private formConfig: IFormConfig
  ) {}
}
