import { EN, getImageExample } from '@shabic/constants';
import { Company, CompanyStatus, ICompanyResponse } from './company.model';
import { IListParams } from './list.model';

export type ContactStatus = 'ACTIVE' | 'BLOCKED' | 'DELETED';

export interface IContactResponse {
  id: number;
  login: string;
  phone: string;
  email: string;
  fullName: string;
  imageUrl: string;
  langKey: string;
  status: ContactStatus;
  supplier: boolean;
  mediaUrl: string;
  company: ICompanyResponse | null;
}

export interface IContactParams extends IListParams {
  companyStatuses?: CompanyStatus[] | null;
  userStatuses?: ContactStatus[];
  search?: string;
  hasCompany?: boolean;
}

export class Contact {
  id?: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  avatar: string;
  fullName: string;
  langKey: string;
  active: boolean;
  supplier: boolean;
  company: Company | null;
  status: ContactStatus;

  constructor(payload: IContactResponse) {
    this.id = payload.id;
    this.firstName = payload.fullName?.split(' ')[0] || 'John';
    this.lastName = payload.fullName?.split(' ')[1] || 'Doe';
    this.email = payload.email;
    this.phone = payload.phone;
    this.avatar = payload.mediaUrl || getImageExample();
    this.fullName = `${this.firstName} ${this.lastName}`;
    this.langKey = payload.langKey || EN;
    this.active = payload.status === 'ACTIVE';
    this.supplier = payload.supplier;
    this.company = payload.company ? new Company(payload.company) : null;
    this.status = payload.status;
  }

  get deleted() {
    return this.status === 'DELETED';
  }

  get blocked() {
    return this.status === 'BLOCKED';
  }
}

export type PasswordPayload = {
  oldPassword: Nullable<string>;
  password: Nullable<string>;
  confirmPassword: Nullable<string>;
};
