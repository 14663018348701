import { DIALOG_DATA } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SUCCESS } from '@shabic/constants';
import { CompanyService } from '@shabic/core';
import { FormComponent } from '@shabic/form-control';
import { RejectCompanyDialogData } from '@shabic/models';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'shabic-reject-company-dialog',
  templateUrl: './reject-company-dialog.component.html',
  styleUrls: ['./reject-company-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RejectCompanyDialogComponent extends FormComponent<unknown> {
  @ViewChild(DialogComponent) dialog?: DialogComponent;

  formGroup = new FormGroup({
    rejectReason: new FormControl(''),
  });

  constructor(
    @Inject(DIALOG_DATA) public data: RejectCompanyDialogData,
    private companyService: CompanyService
  ) {
    super();
  }

  onSubmit() {
    const rejectReason = this.formGroup.value.rejectReason || '';

    this.submitForm(
      this.formGroup,
      this.companyService.rejectCompany(this.data.company, rejectReason)
    ).subscribe(status => {
      if (status === SUCCESS) {
        this.dialog?.close();
        this.data.onRejected();
      }
    });
  }
}
