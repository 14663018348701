import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Align } from '../../models';

type Variant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

@Component({
  selector: 'shabic-headline',
  templateUrl: './headline.component.html',
  styleUrls: ['./headline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeadlineComponent {
  @Input() variant: Variant = 'h1';
  @Input() align?: Align;
  @Input() invert?: boolean = false;
  @Input() classes = '';
}
