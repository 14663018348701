import { DIALOG_DATA } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CategoriesService } from '@shabic/core';
import { FormComponent } from '@shabic/form-control';
import {
  ApiResponse,
  CategoryDialogData,
  ICategoryPayload,
  Media,
} from '@shabic/models';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'shabic-category-dialog',
  templateUrl: './category-dialog.component.html',
  styleUrls: ['./category-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryDialogComponent
  extends FormComponent<Media[]>
  implements OnInit
{
  formGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    secondName: new FormControl('', Validators.required),
    mediaId: new FormControl([], Validators.required),
    media: new FormControl('', Validators.required),
  });

  constructor(
    private categoryService: CategoriesService,
    @Inject(DIALOG_DATA) private data: CategoryDialogData
  ) {
    super();
  }

  ngOnInit(): void {
    const category = this.data.category;

    if (category) {
      const name = category.name.get('en');
      const secondName = category.name.get('ar');
      const media = category.image;

      this.formGroup.patchValue({ name, secondName, media });
    }
  }

  onSubmit(dialog: DialogComponent) {
    const image = this.formGroup.value.mediaId;

    if (image?.length) {
      this.submitForm(
        this.formGroup,
        this.categoryService.uploadImage(image as unknown as File[]),
        {
          returnResponse: true,
          stopLoading: true,
        }
      ).subscribe(response => {
        if (
          response instanceof ApiResponse &&
          Array.isArray(response.payload)
        ) {
          dialog.close();
          this.data.onSuccess({
            ...this.formGroup.value,
            mediaId: response.payload[0].id,
          } as ICategoryPayload);
        }
      });
    } else {
      dialog.close();
      this.data.onSuccess(this.formGroup.value as ICategoryPayload);
    }
  }
}
