import { DIALOG_DATA } from '@angular/cdk/dialog';
import { BreakpointObserver } from '@angular/cdk/layout';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DESKTOP_LAYOUT } from '@shabic/constants';
import {
  Category,
  DrawerDialogConfig,
  FilterDialogData,
  FilterOption,
  FilterPayload,
} from '@shabic/models';
import { BehaviorSubject, Subject, takeUntil, tap } from 'rxjs';
import { DrawerDialogComponent } from '../drawer-dialog/drawer-dialog.component';

@Component({
  selector: 'shabic-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterDialogComponent implements AfterViewInit, OnDestroy {
  private _destroy = new Subject<void>();
  private _activeFilter = new BehaviorSubject<FilterPayload | null>(null);
  private _drawerDialogConfig = new BehaviorSubject<DrawerDialogConfig>({
    isFullSize: false,
    isPrimaryBg: false,
    hasCloseBtn: false,
    hasBackBtn: false,
    title: 'common.filter',
  });

  readonly drawerDialogConfig$ = this._drawerDialogConfig.asObservable();
  readonly activeFilter$ = this._activeFilter.asObservable();
  readonly formGroup = new FormGroup<any>({
    categoryIds: new FormControl([]),
  });

  @ViewChild(DrawerDialogComponent) dialog?: DrawerDialogComponent;

  constructor(
    @Inject(DIALOG_DATA) public data: FilterDialogData,
    private breakpointObserver: BreakpointObserver
  ) {
    this.data.filters.forEach(el => {
      el.options = el.options.pipe(
        tap(options =>
          options?.forEach(
            option =>
              this.isFilterOption(option) &&
              this.registerControl(el.key as string, option as FilterOption)
          )
        )
      );
    });
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  ngAfterViewInit(): void {
    this.dialog?.onClose$.subscribe(() => {
      this.data.onClose(this.convertFormValue(this.formGroup.value));
    });

    this.breakpointObserver
      .observe([DESKTOP_LAYOUT])
      .pipe(takeUntil(this._destroy))
      .subscribe(event => {
        if (event.matches) {
          this.dialog?.close();
        }
      });
  }

  getColor(option: FilterOption) {
    const color = option.label.toLowerCase();

    return {
      'background-color': color,
      'border-color': color === 'white' ? '' : color,
    };
  }

  isFilterOption(value: unknown): value is FilterOption {
    return value instanceof FilterOption;
  }

  isCategory(value: unknown): value is Category {
    return value instanceof Category;
  }

  onBack() {
    this._activeFilter.next(null);
    this._drawerDialogConfig.next({
      ...this._drawerDialogConfig,
      hasBackBtn: false,
      title: 'common.filter',
    });
  }

  onOpenFilter(filter: FilterPayload) {
    this._activeFilter.next(filter);
    this._drawerDialogConfig.next({
      ...this._drawerDialogConfig,
      hasBackBtn: true,
      title: filter.label,
    });
  }

  onChangeCategory(category: Category) {
    this.formGroup.get('categoryIds')?.patchValue([category.id]);
    this.dialog?.close();
  }

  private convertFormValue(value: any) {
    const result: Record<string, string[]> = {};

    Object.keys(value).forEach(key => {
      const values = value[key];
      if (Array.isArray(values)) {
        result[key] = values;
      } else {
        const list = Object.keys(values)
          .filter(key => values[key])
          .map(key => key);

        result[key] = list;
      }
    });

    return result;
  }

  private registerControl(name: string, option: FilterOption) {
    const group = (this.formGroup.get(name) || new FormGroup({})) as FormGroup;

    group.addControl(option.value, option.control);

    this.formGroup.addControl(name, group);
  }
}
