import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NgxDropzoneComponent } from 'ngx-dropzone';
import { BehaviorSubject, Observable, Subject, takeUntil, timer } from 'rxjs';

@Component({
  selector: 'shabic-dropzone',
  templateUrl: './dropzone.component.html',
  styleUrls: ['./dropzone.component.scss'],
})
export class DropzoneComponent {
  private _destroy: Subject<void> = new Subject();
  private _files: BehaviorSubject<File[]> = new BehaviorSubject<File[]>([]);

  files$: Observable<File[]> = this._files.asObservable();

  get hasFiles(): boolean {
    return this._files.value.length > 0;
  }

  get isImage(): boolean {
    return this.accept.includes('image');
  }

  @Input() accept = '*';
  @Input() disabled: boolean | null = false;
  @Output() changed: EventEmitter<File[]> = new EventEmitter();
  @ViewChild('drop') dropzone!: NgxDropzoneComponent;

  constructor(private cd: ChangeDetectorRef) {}

  onSelect(event: any): void {
    this.changed.emit(event.addedFiles);
    this._files.next(event.addedFiles);
    timer(1000)
      .pipe(takeUntil(this._destroy))
      .subscribe(() => this.cd.detectChanges());
  }

  onRemove(file: File): void {
    const files = this._files.value;
    this._files.next(files.filter(el => el !== file));
  }

  showFileSelector(): void {
    this.dropzone.showFileSelector();
  }
}
