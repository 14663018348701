<ul class="flex flex-wrap">
  <li
    *ngFor="let point of steps; let last = last; let first = first"
    class="flex-1 relative flex flex-col items-center"
  >
    <div
      class="w-6 h-6 bg-white rounded-full text-secondary inline-flex items-center justify-center relative z-1"
    >
      <ng-template
        *ngIf="point.isActive"
        [shabicIcon]="point.icon"
      ></ng-template>

      <span
        *ngIf="!point.isActive"
        [ngClass]="getPointStyles(point)"
        class="w-2 h-2 rounded-full"
      ></span>
    </div>

    <div
      [ngClass]="getPointLineStyles(point)"
      [class.first]="first"
      [class.last]="last"
      class="h-0.5 absolute top-4 -translate-y-1/2 w-full line"
    ></div>

    <div class="px-4 pb-3 grid gap-1 items-center">
      <shabic-headline variant="h5" align="center">
        <span [class.font-semibold]="point.isActive">
          {{ point.name | translate }}
        </span>
      </shabic-headline>

      <time *ngIf="point.date" class="text-xs text-grey text-center">
        {{ point.date | time: 'D MMM YYYY hh:mm A' }}
      </time>
    </div>
  </li>
</ul>
