<form shabicLoginForm [formGroup]="model.formGroup" (ngSubmit)="onSubmit()">
  <shabic-headline variant="h2" align="center">
    {{ 'login.title' | translate }}
  </shabic-headline>

  <shabic-sub-headline>
    {{ 'login.sub-title' | translate }}
  </shabic-sub-headline>

  <shabic-form-field class="mt-5.5">
    <shabic-form-field-label forAttribute="email">
      {{ 'common.email' | translate }}
    </shabic-form-field-label>

    <input type="email" id="email" shabicInput formControlName="username" />
  </shabic-form-field>

  <shabic-form-field class="mt-4">
    <shabic-form-field-label forAttribute="password">
      {{ 'common.password' | translate }}
    </shabic-form-field-label>
    <input
      type="password"
      id="password"
      shabicInput
      formControlName="password"
    />
  </shabic-form-field>

  <p class="rtl:text-right mt-3">
    <a
      class="inline-block font-semibold text-xs text-secondary hover:underline"
      routerLink="/forgot-password"
    >
      {{ 'login.forgot-password' | translate }}
    </a>
  </p>

  <shabic-form-field-errors
    [errors]="model.formGroup.errors"
  ></shabic-form-field-errors>

  <button
    class="w-full gap-1 mt-3"
    shabicButton="gradient"
    type="submit"
    [disabled]="model.formGroup.invalid || (loading$ | async)"
  >
    {{ 'button.login' | translate }}

    <shabic-spinner *ngIf="loading$ | async"></shabic-spinner>
  </button>

  <p
    *ngIf="isClientApp"
    class="mt-5.5 text-grey text-center text-sm font-medium"
  >
    {{ 'login.register-lead' | translate }}
    <a
      class="text-secondary font-semibold hover:underline focus:underline"
      routerLink="/sign-up"
    >
      {{ 'button.create-account' | translate }}
    </a>
  </p>
</form>
