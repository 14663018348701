import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { fromEvent, Subject, takeUntil } from 'rxjs';

@Directive({
  selector: '[shabicImagePreview]',
})
export class ImagePreviewDirective implements OnInit, OnDestroy {
  private _destroy = new Subject<void>();

  @Input() shabicImagePreview!: File;

  constructor(
    private el: ElementRef<HTMLImageElement>,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    const file = this.shabicImagePreview;
    const reader = new FileReader();

    fromEvent(reader, 'load')
      .pipe(takeUntil(this._destroy))
      .subscribe(() => {
        this.renderer.setAttribute(
          this.el.nativeElement,
          'src',
          reader.result as string
        );
      });

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }
}
