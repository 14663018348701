import { DIALOG_DATA } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  CancelOrderDialogData,
  EReturnOrderReason,
  ECancelOrderReason,
  ReasonKey,
  ReturnOrderDialogData,
  returnReasonMap,
} from '@shabic/models';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'shabic-return-order-dialog',
  templateUrl: './return-order-dialog.component.html',
  styleUrls: ['./return-order-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReturnOrderDialogComponent {
  readonly EReturnOrderReason = EReturnOrderReason;
  readonly ECancelOrderReason = ECancelOrderReason;
  readonly formGroup = new FormGroup({
    reason: new FormControl(null),
    description: new FormControl(''),
  });

  get reasonControl() {
    return this.formGroup.get('reason') as FormControl;
  }

  get isReturnReasons() {
    return this.data instanceof ReturnOrderDialogData;
  }

  get isCancelReasons() {
    return this.data instanceof CancelOrderDialogData;
  }

  constructor(
    @Inject(DIALOG_DATA)
    public data: ReturnOrderDialogData | CancelOrderDialogData,
    private translateService: TranslateService
  ) {}

  confirm(dialog: DialogComponent) {
    const reason = this.formGroup.value.reason as unknown as ReasonKey;
    const description =
      returnReasonMap.get(reason) || this.formGroup.value.description || '';

    this.data
      .onConfirm({
        description: this.translateService.instant(description),
      })
      .subscribe(() => {
        dialog.close();
      });
  }

  getReasonKey(reason: ReasonKey) {
    return returnReasonMap.get(reason) || '';
  }
}
