<p class="flex flex-col gap-[2px]">
  <label class="text-xs font-semibold text-grey rtl:text-right">
    {{ label | translate: translateParams }}
  </label>

  <span class="text-primary text-sm font-medium rtl:text-right inline-flex">
    <span
      class="text-ellipsis overflow-hidden max-w-full inline-block"
      #ref
      [title]="ref.innerText"
    >
      <ng-content></ng-content>
    </span>

    <ng-container *ngIf="(hasContent$ | async) === false">-</ng-container>
  </span>
</p>
