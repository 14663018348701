import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ProductListComponent,
  ProductStatusesComponent,
  ProductSortingComponent,
  ProductAdminActionsComponent,
  ProductOverviewComponent,
  ProductOverviewTableComponent,
  ProductDetailsComponent,
  ProductExtraDetailsComponent,
  ProductClientActionsComponent,
  ProductFormComponent,
} from './components';
import { TranslateModule } from '@ngx-translate/core';
import { UiModule } from '@shabic/ui';
import { FormControlModule } from '@shabic/form-control';
import { IconModule } from '@shabic/icon';
import { RouterModule } from '@angular/router';
import {
  BannerModule,
  CarouselModule,
  DropzoneModule,
  FiltersModule,
  PaginationModule,
} from '@shabic/shared';
import { ReactiveFormsModule } from '@angular/forms';
import { EventBrokerModule } from '@shabic/event-broker';
import { SearchModule } from '@shabic/search';
import { UtilsModule } from '@shabic/utils';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    UiModule,
    FormControlModule,
    IconModule,
    RouterModule,
    PaginationModule,
    ReactiveFormsModule,
    EventBrokerModule,
    SearchModule,
    DropzoneModule,
    UtilsModule,
    FiltersModule,
    CarouselModule,
    BannerModule,
  ],
  declarations: [
    ProductListComponent,
    ProductStatusesComponent,
    ProductSortingComponent,
    ProductAdminActionsComponent,
    ProductOverviewComponent,
    ProductOverviewTableComponent,
    ProductDetailsComponent,
    ProductClientActionsComponent,
    ProductExtraDetailsComponent,
    ProductFormComponent,
  ],
  exports: [
    ProductListComponent,
    ProductOverviewComponent,
    ProductDetailsComponent,
    ProductExtraDetailsComponent,
    ProductFormComponent,
  ],
})
export class ProductModule {}
