import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { PromotionService } from '@shabic/core';
import { FormComponent } from '@shabic/form-control';
import { Promotion, PromotionType, promotionTypes } from '@shabic/models';
import { zipObj } from 'ramda';
import { BehaviorSubject, tap } from 'rxjs';

@Component({
  selector: 'shabic-marketing',
  templateUrl: './marketing.component.html',
  styleUrls: ['./marketing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarketingComponent
  extends FormComponent<Promotion>
  implements OnInit
{
  private _promotionsLoading = new BehaviorSubject<boolean>(true);
  private _entity?: Record<PromotionType, Promotion>;

  promotionsLoading$ = this._promotionsLoading.asObservable();

  formGroup = this.fb.group({
    companyId: 0,
    types: this.fb.array(promotionTypes.map(type => this.getTypeForm(type))),
  });

  get types() {
    return this.formGroup.controls['types'];
  }

  constructor(
    private fb: FormBuilder,
    private promotionService: PromotionService
  ) {
    super();
  }

  ngOnInit(): void {
    this.fillinForm();
  }

  onSubmit() {
    const value = this.formGroup.value;
    const promotions = value.types?.filter(
      el =>
        this._entity?.[el.type as PromotionType]?.media.url ||
        el.mediaId?.length
    );

    if (promotions?.length) {
      this.submitForms(
        promotions.map(() => this.formGroup),
        promotions.map(promotion =>
          this.promotionService.updatePromotion({
            type: promotion.type as PromotionType,
            description: promotion.description || '',
            companyId: value.companyId as number,
            mediaId: promotion.mediaId?.length
              ? (promotion.mediaId as File[])
              : this._entity?.[promotion.type as PromotionType]?.media.id,
            id: promotion.id,
            active: this._entity?.[promotion.type as PromotionType]?.active,
          })
        )
      ).subscribe(() => {
        this.fillinForm();
      });
    }
  }

  onPicturesChanged(pictures: File[], index: number) {
    this.types.controls[index].get('mediaId')?.patchValue(pictures);
  }

  private getTypeForm(type: PromotionType) {
    return this.fb.group({
      id: 0,
      description: '',
      mediaId: [<File[]>[]],
      type,
      mediaUrl: '',
    });
  }

  private fillinForm() {
    this.promotionService
      .getCompanyPromotions()
      .pipe(tap(() => this._promotionsLoading.next(false)))
      .subscribe(response => {
        if ('promotions' in response) {
          this.formGroup
            .get('companyId')
            ?.patchValue(response.companyId || null);

          const types = response.promotions.map(el => el.type);
          const entity = zipObj(types, response.promotions);
          this._entity = entity;

          this.types.controls.forEach(el => {
            const type = el.value.type as string;

            if (typeof type === 'string') {
              const data = entity[type as PromotionType];

              if (!data) {
                return;
              }

              el.patchValue({
                description: data.description,
                id: data.id,
                mediaUrl: data.media.url,
              });
            }
          });
        }
      });
  }
}
