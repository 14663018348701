<section
  *ngIf="(emptyLayout$ | async) === false; else emptyLayout"
  class="grid min-h-screen transition-all desktop:py-3 ltr:desktop:pr-3 rtr:desktop:pl-3 desktop:bg-primary overflow-hidden"
  [class.desktop:grid-cols-collapse]="(sidebarCollapsed$ | async) === true"
  [class.desktop:grid-cols-client]="(sidebarCollapsed$ | async) === false"
>
  <div class="hidden desktop:block">
    <div class="flex flex-col gap-7">
      <div class="flex items-center flex-wrap gap-5 mt-4">
        <a routerLink="/" class="mx-auto">
          <ng-template
            [size]="logoSize$ | async"
            shabicIcon="icon-logo"
          ></ng-template>
        </a>

        <button
          shabicButton="navigation"
          class="text-white mx-auto"
          (click)="toggleSidebar()"
        >
          <span
            class="text-white"
            [class.ltr:rotate-180]="(sidebarCollapsed$ | async) === true"
            [class.rtl:rotate-180]="(sidebarCollapsed$ | async) === false"
          >
            <ng-template shabicIcon="arrow-left"></ng-template>
          </span>
        </button>
      </div>

      <div class="text-center" *ngIf="account$ | async as account">
        <div class="relative p-1 mx-auto inline-block">
          <img
            shabicImage
            [src]="account.avatar"
            alt=""
            class="avatar rounded-full outline outline-4 outline-primary relative z-1 transition-all"
            [class.md]="(sidebarCollapsed$ | async) === true"
          />
          <div
            class="absolute bg-main-pattern avatar-border rounded-full top-0 left-0 z-0 transition-all animate-avatar"
            [class.md]="(sidebarCollapsed$ | async) === true"
          ></div>
          <div
            class="absolute bg-main-pattern avatar-bg rounded-full top-0 left-0 z-0 blur-xl transition-all animate-avatar"
            [class.md]="(sidebarCollapsed$ | async) === true"
          ></div>
        </div>

        <div
          class="font-semibold text-white text-center truncate mt-4 w-0"
          [class.w-full]="opened$ | async"
        >
          {{ account.fullName }}
        </div>

        <p
          class="truncate mt-2 text-center w-0"
          [class.w-full]="opened$ | async"
        >
          <a
            shabicButton="primary"
            routerLink="/profile"
            class="inline-flex bg-white/[.1] gap-1 truncate"
          >
            <ng-template shabicIcon="profile"></ng-template>

            {{ 'button.view-profile' | translate }}
          </a>
        </p>
      </div>

      <ul class="flex flex-col gap-1 transition mx-[44px]">
        <li *ngFor="let item of navigation | auth | async">
          <a
            class="nav-item"
            routerLinkActive="active"
            [routerLink]="item['route']"
          >
            <span *ngIf="item['icon']">
              <ng-template [shabicIcon]="item['icon']"></ng-template>
            </span>

            <span class="truncate">
              {{ item['label'] | translate }}
            </span>
          </a>
        </li>

        <li class="mt-4" *ngIf="account$ | async">
          <a
            class="nav-item cursor-pointer"
            routerLink="/"
            (click)="onLogout()"
          >
            <span>
              <ng-template shabicIcon="icon-logout"></ng-template>
            </span>

            <span class="truncate">
              {{ 'navigation.logout' | translate }}
            </span>
          </a>
        </li>

        <li class="mt-4" *ngIf="(account$ | async) === null">
          <a class="nav-item cursor-pointer" (click)="onSignIn()">
            <span>
              <ng-template shabicIcon="icon-sign-in"></ng-template>
            </span>

            <span class="truncate">
              {{ 'button.login' | translate }}
            </span>
          </a>
        </li>
      </ul>
    </div>
  </div>

  <main
    class="grow flex flex-col gap-4 bg-white rounded-3xl px-[20px] desktop:px-5 py-4 min-w-0"
  >
    <header class="flex flex-wrap gap-3 justify-between">
      <a routerLink="/" class="desktop:hidden inline-flex items-center">
        <img [src]="logoSourcePrimary" alt="Shabic" class="w-10" />
      </a>

      <div class="flex flex-wrap items-center gap-4 desktop:order-1">
        <a
          routerLink="/cart"
          class="inline-flex gap-1 items-center text-primary group hover:text-secondary"
        >
          <ng-template shabicIcon="cart"></ng-template>

          <span
            class="hidden desktop:inline-flex items-center justify-center bg-secondary/[.15] text-right px-2 py-[7px] leading-[15px] text-secondary rounded-3xl text-xs font-semibold group-hover:bg-secondary/[.3] transition-colors"
          >
            {{ cartCount$ | async }}
          </span>
        </a>

        <shabic-currency-dropdown></shabic-currency-dropdown>

        <shabic-language-dropdown
          class="hidden desktop:block"
        ></shabic-language-dropdown>

        <button shabicButton class="desktop:hidden" (click)="onShowNavBar()">
          <ng-template shabicIcon="icon-nav"></ng-template>
        </button>

        <a
          routerLink="/add-product"
          *shabicHasRole="ROLE_SUPPLIER"
          shabicButton="secondary"
          class="hidden desktop:inline-flex gap-1"
        >
          <ng-template shabicIcon="plus"></ng-template>

          {{ 'button.add-products' | translate }}
        </a>
      </div>

      <a
        *ngIf="route$ | async as route"
        [routerLink]="route.route"
        (click)="route.onClick ? route.onClick($event) : null"
        class="flex desktop:inline-flex items-center gap-[20px] w-full desktop:w-auto"
        [class.hidden]="route.mobileHidden"
      >
        <span
          *ngIf="route.icon"
          class="text-primary"
          [class.rtl:rotate-180]="route.icon === 'arrow-left'"
        >
          <ng-template [shabicIcon]="route.icon"></ng-template>
        </span>

        <shabic-headline variant="h3">
          <span class="font-semibold">{{ route.label | translate }}</span>
        </shabic-headline>
      </a>
    </header>

    <ng-container *ngIf="route$ | async as route">
      <shabic-banner
        *ngIf="route.hasBanner"
        [type]="route.promotionType"
      ></shabic-banner>
    </ng-container>

    <ng-container *ngTemplateOutlet="content"></ng-container>
  </main>
</section>

<ng-template #emptyLayout>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
