<div class="desktop:grid desktop:grid-cols-2 sign-in-wave h-full">
  <div
    class="flex flex-col items-center justify-center py-8 relative overflow-hidden"
  >
    <div class="flex justify-center mb-[130px] desktop:mb-8">
      <img class="desktop:hidden" [src]="logoSourceWhite" alt="Shabic logo" />
      <img
        class="hidden desktop:inline-block w-[220px]"
        [src]="logoSourcePrimary"
        alt="Shabic logo"
      />

      <div
        class="mobile-gradient bg-main-pattern-to-r-b w-[600px] h-[600px] -top-[440px] -z-1 rounded-full absolute desktop:hidden"
      ></div>
    </div>

    <ng-content></ng-content>
  </div>

  <div
    class="hidden relative bg-main-pattern-to-r-b p-10 desktop:flex flex-col justify-between rounded-l-[60px]"
  >
    <ng-template shabicIcon="shabic"></ng-template>

    <div class="text-5xl text-white font-semibold leading-tight">
      {{ 'common.innovate' | translate }}. <br />
      {{ 'common.engage' | translate }}. <br />
      {{ 'common.transform' | translate }}. <br />
    </div>

    <div class="aside"></div>
  </div>
</div>
