import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ApiClient,
  ApiErrorResponse,
  ApiResponse,
  ApiUser,
  ENV,
  IApiUser,
  IEnvironment,
} from '@shabic/models';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiUserService extends ApiClient {
  override readonly url: string = '/api-user';

  constructor(private http: HttpClient, @Inject(ENV) env: IEnvironment) {
    super(env);
  }

  get() {
    return this.http.get<IApiUser[]>(this.apiModelUrl).pipe(
      map(response => {
        const user = response;

        if (user.length) {
          return new ApiResponse(new ApiUser(response[0]));
        }

        return new ApiResponse(null);
      }),
      catchError(response =>
        of(new ApiResponse(new ApiErrorResponse(response)))
      )
    );
  }
}
