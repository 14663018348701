import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private _storage: BehaviorSubject<Map<string, string>> = new BehaviorSubject(
    new Map()
  );

  storage$: Observable<number> = this._storage
    .asObservable()
    .pipe(map(() => Date.now()));

  constructor() {
    const storage = new Map();
    Object.keys(localStorage).forEach(key =>
      storage.set(key, localStorage.getItem(key) as string)
    );
    this._storage.next(new Map(storage));
  }

  store(key: string, value: unknown, local = false): void {
    if (!value) {
      return;
    }

    try {
      const data = JSON.stringify(value);
      const prevValue = this._storage.value;
      prevValue.set(key, data);

      if (local) {
        localStorage.setItem(key, data);
      }

      this._storage.next(new Map(prevValue.entries()));
    } catch (e) {
      throw new Error(key + "can't be stored");
    }
  }

  retrieve<T>(key: string, local = false): T | null {
    const value = this._storage.value;
    const data = value.get(key);
    let result: T | null = null;

    if (typeof data === 'string') {
      result = JSON.parse(data);
    } else if (local) {
      const data = localStorage.getItem(key);

      if (typeof data === 'string') {
        result = JSON.parse(data);
      }
    }

    // if ([TOKEN_KEY, REFRESH_TOKEN_KEY].includes(key) && result && this.removeExpiredToken(result as unknown as string)) {
    //   this.delete(key);
    //   return null;
    // }

    return result;
  }

  delete(key: string, local = false): unknown {
    const value = this._storage.value;

    if (local && localStorage.getItem(key)) {
      localStorage.removeItem(key);
    }

    if (value.has(key)) {
      value.delete(key);

      this._storage.next(new Map(value.entries()));
    }

    return null;
  }
}
