<shabic-dialog #dialog>
  <div class="flex flex-col items-center gap-4">
    <p *ngIf="data.icon">
      <ng-template [shabicIcon]="data.icon"></ng-template>
    </p>

    <p class="text-center text-lg text-primary font-medium max-w-[280px]">
      {{ data.title | translate }}
    </p>

    <div class="flex gap-3 justify-center mb-5.5">
      <button type="button" shabicButton="default" (click)="dialog.close()">
        {{ 'button.cancel' | translate }}
      </button>

      <button
        type="button"
        shabicButton="primary"
        class="gap-1"
        [disabled]="loading$ | async"
        (click)="confirm(dialog)"
      >
        {{ data.confirmTitle | translate }}

        <shabic-spinner *ngIf="loading$ | async"></shabic-spinner>
      </button>
    </div>
  </div>
</shabic-dialog>
