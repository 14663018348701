import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ADMIN_APP } from '@shabic/constants';
import { ADMIN_PROFILE_NAV, PROFILE_NAV } from '@shabic/data';
import { ENV, IEnvironment } from '@shabic/models';
import { BehaviorSubject, filter, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'shabic-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileComponent implements OnInit, OnDestroy {
  private readonly _destroy = new Subject<void>();
  private readonly _collapsed = new BehaviorSubject<boolean>(true);

  readonly navigation =
    this.env.app === ADMIN_APP ? ADMIN_PROFILE_NAV : PROFILE_NAV;
  readonly collapsed$ = this._collapsed.asObservable();

  get isAdminApp() {
    return this.env.app === ADMIN_APP;
  }

  constructor(@Inject(ENV) private env: IEnvironment, private router: Router) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        takeUntil(this._destroy),
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => this._collapsed.next(true));
  }

  onToggle() {
    this._collapsed.next(!this._collapsed.value);
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }
}
