import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Route, RouterModule } from '@angular/router';
import { LoginComponent } from './login.component';
import { FormsModule, LayoutModule, PasscodeModule } from '@shabic/shared';
import { FormControlModule } from '@shabic/form-control';
import { UiModule } from '@shabic/ui';
import { IconModule } from '@shabic/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginFormDirective } from './directives';
import {
  LoginForgotPasswordComponent,
  LoginFormComponent,
  LoginOtpFormComponent,
  LoginLayoutComponent,
} from './components';
import { TranslateModule } from '@ngx-translate/core';
import { AuthGuard, AdminGuard } from '@shabic/core';

const routes: Route[] = [
  {
    path: 'sign-in',
    component: LoginComponent,
    canActivate: [AdminGuard, AuthGuard],
  },
  {
    path: 'forgot-password',
    component: LoginForgotPasswordComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormControlModule,
    UiModule,
    PasscodeModule,
    IconModule,
    ReactiveFormsModule,
    LayoutModule,
    TranslateModule.forChild(),
    FormsModule,
  ],
  declarations: [
    LoginComponent,
    LoginFormComponent,
    LoginOtpFormComponent,
    LoginForgotPasswordComponent,
    LoginFormDirective,
    LoginLayoutComponent,
  ],
})
export class LoginModule {}
