<div class="grid gap-1 grid-cols-6">
  <shabic-form-field *ngFor="let control of controls">
    <input
      type="text"
      #input
      shabicInput
      (keyup)="onChange(input, $event)"
      (keydown)="onBackspace(input, $event)"
      class="bg-transparent border border-grey-400 text-base desktop:text-lg font-semibold max-h-[60px] text-center text-current"
      [formControl]="control"
    />
  </shabic-form-field>
</div>
