import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IOtp } from '../../models';

@Component({
  selector: 'shabic-login-otp-form',
  templateUrl: './login-otp-form.component.html',
  styleUrls: ['./login-otp-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginOtpFormComponent {
  @Input() model!: IOtp;
}
