import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Icon } from '@shabic/icon';

@Component({
  selector: 'shabic-group-title',
  templateUrl: './group-title.component.html',
  styleUrls: ['./group-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupTitleComponent {
  @Input() name!: string;
  @Input() icon!: Icon;
}
