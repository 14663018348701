import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  ADMIN_NAV,
  ADMIN_PROFILE_NAV,
  INavItem,
  NOT_AUTH_NAV,
  PROFILE_NAV,
} from '@shabic/data';
import { AppEvent, EAppEvent, EventBrokerService } from '@shabic/event-broker';
import {
  Contact,
  ENV,
  IEnvironment,
  Language,
  LoginDialogData,
  ROLE_SUPPLIER,
} from '@shabic/models';
import { AccountService, CartService } from '@shabic/core';
import { ADMIN_APP, LOGO_SOURCE } from '@shabic/constants';
import { BehaviorSubject, Observable, filter, takeUntil, Subject } from 'rxjs';

@Component({
  selector: 'shabic-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {
  private _destroy = new Subject<void>();
  private _account = new BehaviorSubject<Contact | null>(null);
  readonly logoSource = LOGO_SOURCE;
  account$: Observable<Contact | null> = this._account.asObservable();
  cartCount$ = this.cartService.cartCount$;

  Role = { ROLE_SUPPLIER };

  @Input() hasNavigation = true;

  get navigation(): INavItem[] {
    return this.isAdminApp ? ADMIN_NAV : NOT_AUTH_NAV;
  }

  get profileNavigation(): INavItem[] {
    return this.isAdminApp ? ADMIN_PROFILE_NAV : PROFILE_NAV;
  }

  get isAdminApp(): boolean {
    return this.env.app === ADMIN_APP;
  }

  constructor(
    private eventBrokerService: EventBrokerService,
    private accountService: AccountService,
    private cartService: CartService,
    @Inject(ENV) private env: IEnvironment
  ) {}

  ngOnInit(): void {
    if (this.hasNavigation) {
      this.getAccount();
    }

    this.subscribeOnEvents();
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  onLogout(): void {
    this.eventBrokerService.emit(new AppEvent(EAppEvent.LogOut, null));
  }

  onLogin(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();

    this.eventBrokerService.emit(
      new AppEvent<LoginDialogData>(EAppEvent.OpenLoginDialog, {
        onSuccess: () =>
          this.eventBrokerService.emit(
            new AppEvent<null>(EAppEvent.Authorized, null)
          ),
      })
    );
  }

  private subscribeOnEvents() {
    this.eventBrokerService.events$
      .pipe(
        filter(event =>
          [EAppEvent.Authorized, EAppEvent.LogOut].includes(event.type)
        ),
        takeUntil(this._destroy)
      )
      .subscribe(event => {
        if (event.type === EAppEvent.LogOut) {
          this._account.next(null);
        } else {
          this.getAccount({ updateLanguage: true });
          this.eventBrokerService.emit(
            new AppEvent(EAppEvent.UpdateCartCount, null)
          );
        }
      });
  }

  private getAccount(config = { updateLanguage: false }) {
    this.accountService.get().subscribe(response => {
      if (response.payload instanceof Contact) {
        if (config.updateLanguage) {
          this.eventBrokerService.emit(
            new AppEvent<Language>(
              EAppEvent.ChangeLanguage,
              response.payload.langKey as Language
            )
          );
        }

        this._account.next(response.payload);
      }
    });
  }
}
