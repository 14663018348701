import { FormControl } from '@angular/forms';
import { Icon } from '@shabic/icon';
import { Translated } from './translated.model';

export interface IFilterOption {
  name: string;
  id: string;
  isGroup?: boolean;
  options?: IFilterOption[];
}

export interface ITag {
  name: Translated<string>;
  key: unknown;
}

export class FilterOption {
  label: string;
  checked: boolean;
  value: string;
  isGroup: boolean;
  options?: FilterOption[];
  control = new FormControl<boolean>(false);

  constructor(config: IFilterOption, checked?: boolean) {
    this.label = config.name;
    this.checked = false;
    this.value = config.id;
    this.isGroup = config.isGroup || false;
    this.options = config.options?.map(option => new FilterOption(option));
    checked && this.control.patchValue(true);
  }

  toggleGroup(): void {
    this.checked = !this.checked;
  }
}

export type SortOption<K> = {
  key: K;
  label: string;
  icon?: Icon;
};

export type SizeOption<K> = {
  key: K;
  label: string;
  icon?: Icon;
};
