<div class="flex gap-4 items-center" [class.flex-col]="size === 'lg'">
  <ng-content select="input"></ng-content>

  <img
    class="image object-contain"
    [class.md]="size === 'md'"
    [class.lg]="size === 'lg'"
    [src]="image$ | async"
  />

  <button
    class="peer-disabled:hidden"
    type="button"
    shabicButton="primary"
    (click)="onUpload()"
  >
    {{ 'button.upload' | translate }}
  </button>
</div>
