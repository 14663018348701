import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Step } from '@shabic/models';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

type StepId = 'form' | 'message';

export type LoginStep = Step<StepId>;

export class Login {
  private _steps: LoginStep[];
  private _currentStep: BehaviorSubject<LoginStep>;
  private _completed: Subject<void> = new Subject<void>();

  formGroup: FormGroup = new FormGroup({
    username: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
    rememberMe: new FormControl(true),
    passcode: new FormControl(''),
  });

  currentStep$: Observable<LoginStep>;
  completed$: Observable<void> = this._completed.asObservable();

  constructor() {
    this._steps = [new Step('form'), new Step('message')];

    this._currentStep = new BehaviorSubject(this._steps[0]);
    this.currentStep$ = this._currentStep.asObservable();
  }

  nextStep(): void {
    const currentStep = this._currentStep.value;
    const nextStepIndex =
      this._steps.findIndex(step => step.id === currentStep.id) + 1;

    if (nextStepIndex >= this._steps.length) {
      return;
    }

    currentStep.complete();

    this._currentStep.next(this._steps[nextStepIndex]);
  }

  submit(): void {
    this._completed.next();
  }
}
