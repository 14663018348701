<div class="relative">
  <div
    [cdkMenuTriggerFor]="menu"
    (cdkMenuClosed)="onClose()"
    (cdkMenuOpened)="onOpen()"
  >
    <ng-content select="input"></ng-content>

    <span
      class="flex absolute ltr:right-3 rtl:left-3 top-2/4 -translate-y-2/4 pointer-events-none"
      [class.rotate-180]="opened$ | async"
    >
      <ng-template shabicIcon="arrow"></ng-template>
    </span>
  </div>

  <ng-template #menu>
    <div
      class="rounded-2xl overflow-hidden bg-white text-primary border border-grey-350 min-w-[184px] w-full my-1"
      cdkMenu
    >
      <ul class="max-h-[240px] overflow-y-auto" *ngIf="options">
        <li
          class="text-primary text-sm px-3 py-2 hover:cursor-pointer hover:bg-primary hover:text-white"
          *ngFor="let option of options$ | async | selectControlFilter: query"
          (click)="onSelect(option)"
        >
          {{ option.label }}
        </li>
      </ul>

      <div *ngIf="loading$ | async" class="text-grey flex justify-center py-2">
        <shabic-spinner></shabic-spinner>
      </div>
    </div>
  </ng-template>
</div>
