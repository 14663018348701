<ng-content select="input"></ng-content>

<span
  class="w-4 h-4 shrink-0 rounded-full flex items-center bg-white justify-center border border-grey-350 peer-checked:border-none peer-checked:bg-secondary after:content-[''] after:w-1 after:h-1 after:bg-white after:rounded-xl after:invisible peer-checked:after:visible"
  (click)="onToggle()"
>
</span>

<span class="label -mb-[10px] mt-[2px]">
  <ng-content select="shabic-form-field-label"></ng-content>
</span>
