import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Category } from '@shabic/models';

@Component({
  selector: 'shabic-search-filter-categories',
  templateUrl: './search-filter-categories.component.html',
  styleUrls: ['./search-filter-categories.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFilterCategoriesComponent {
  @Input() categories!: Category[];
  @Output() changed: EventEmitter<Category> = new EventEmitter();

  onClick(event: MouseEvent, category: Category): void {
    event.preventDefault();
    event.stopPropagation();

    this.changed.emit(category);
  }
}
