import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Optional,
} from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { FormFieldComponent } from '../form-field/form-field.component';

@Component({
  selector: 'shabic-form-field-errors',
  templateUrl: './form-field-errors.component.html',
  styleUrls: ['./form-field-errors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldErrorsComponent implements OnInit {
  @Input() errors?: ValidationErrors | null;
  @Input() params?: Record<string, unknown>;
  label?: string;

  constructor(@Optional() private formField: FormFieldComponent) {}

  ngOnInit(): void {
    this.label = this.formField?.name;
  }
}
