import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { InputDirective } from '../directives/input.directive';
import {
  FormControlName,
  FormControlDirective,
  FormControl,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'shabic-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldComponent implements AfterContentInit, OnDestroy {
  private _errors = new BehaviorSubject<ValidationErrors | null>(null);
  private destroy = new Subject<void>();
  private value = new BehaviorSubject<unknown>(null);
  public control?: FormControl;
  errors$ = this._errors.asObservable();
  value$ = this.value.asObservable();

  @Input() hideErrors = false;
  @Input() name?: string;

  @ContentChild(InputDirective) input?: InputDirective;
  @ContentChild(FormControlName) controlName?: FormControlName;
  @ContentChild(FormControlDirective) controlDirective?: FormControlDirective;

  @Output() changed = new EventEmitter<unknown>();

  constructor(private cd: ChangeDetectorRef) {}

  get isRequired(): boolean {
    return this.control?.hasValidator(Validators.required) || false;
  }

  ngAfterContentInit() {
    const control = this.controlName?.control || this.controlDirective?.control;

    if (control) {
      this.control = control;
      this.subscribe(control);
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  pathValue(value: unknown): void {
    this.control?.patchValue(value);
  }

  private subscribe(control: FormControl): void {
    control.statusChanges.pipe(takeUntil(this.destroy)).subscribe(() => {
      this.cd.detectChanges();
      this._errors.next(control.errors);
    });

    this.input?.blur$?.pipe(takeUntil(this.destroy)).subscribe(() => {
      this.cd.detectChanges();
      this._errors.next(control.errors);
    });

    control?.valueChanges.pipe(takeUntil(this.destroy)).subscribe(val => {
      this.value.next(val);
      this.changed.emit(val);

      if (control.hasError('api')) {
        const errors = {
          ...control.errors,
          api: undefined,
        };

        this._errors.next(errors);
      }
    });
  }
}
