import {
  ApiToken,
  AUTH_ROLE_EMAIL_VERIFIED,
  AUTH_ROLE_ID_VERIFIED,
  AUTH_ROLE_PHONE_VERIFIED,
} from '@shabic/models';
import { StepperPoint } from '@shabic/shared';
import { BehaviorSubject, map, Observable } from 'rxjs';

export class SignUp {
  private _currentStep: BehaviorSubject<StepperPoint>;
  private _apiToken: ApiToken;

  steps: StepperPoint[];

  currentStepIndex$: Observable<number>;
  currentStep$: Observable<StepperPoint>;

  get email() {
    return this._apiToken.email;
  }

  constructor(token: string | null) {
    this.steps = [
      new StepperPoint(
        'create-account.step-1',
        'create-account.step-1-title',
        1
      ),
      new StepperPoint(
        'create-account.step-2',
        'create-account.step-2-title',
        2
      ),
      new StepperPoint(
        'create-account.step-3',
        'create-account.step-3-title',
        3
      ),
      new StepperPoint(
        'create-account.step-4',
        'create-account.step-4-title',
        4
      ),
      new StepperPoint(
        'create-account.step-5',
        'create-account.step-5-title',
        5
      ),
    ];

    this._apiToken = new ApiToken(token);

    const currentStep: StepperPoint = this.steps[0];

    currentStep.activate();
    this._currentStep = new BehaviorSubject(currentStep);
    this.currentStep$ = this._currentStep.asObservable();
    this.currentStepIndex$ = this._currentStep
      .asObservable()
      .pipe(map(step => this.steps.findIndex(el => el === step)));

    if (this._apiToken.expiredAt < Date.now()) {
      return;
    }

    if (this._apiToken.roles.includes(AUTH_ROLE_EMAIL_VERIFIED)) {
      this.nextStep();
    }

    if (this._apiToken.roles.includes(AUTH_ROLE_PHONE_VERIFIED)) {
      this.nextStep();
    }

    if (this._apiToken.roles.includes(AUTH_ROLE_ID_VERIFIED)) {
      this.nextStep();
    }
  }

  nextStep(): void {
    const currentStep = this._currentStep.value;
    const nextStepIndex =
      this.steps.findIndex(el => el === this._currentStep.value) + 1;
    currentStep.complete();

    if (nextStepIndex >= this.steps.length) {
      return;
    }

    const nextStep = this.steps[nextStepIndex];
    nextStep.activate();
    this._currentStep.next(nextStep);
  }

  hasRole(role: string): boolean {
    return (
      this._apiToken.expiredAt <
      Date.now() <
      this._apiToken.roles.includes(role)
    );
  }
}
