<shabic-dropdown [wrapped]="true">
  <span target class="text-primary font-semibold text-sm uppercase">
    {{ (currentCurrency$ | async)?.value }}
  </span>

  <div content>
    <ul
      *ngIf="currentCurrency$ | async as currentCurrency"
      class="grid gap-4 p-4"
    >
      <li *ngFor="let currency of currencies">
        <a
          class="text-sm font-bold w-full block hover:text-primary origin-center hover:cursor-pointer inline-flex items-center gap-2 text-grey"
          (click)="onChangeCurrency(currency.value)"
        >
          <span>{{ getFlag(currency) }}</span>

          {{ currency.value }}
        </a>
      </li>
    </ul>
  </div>
</shabic-dropdown>
