import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CurrencyService } from '@shabic/core';
import { Currency, CurrencyType, PreOrder, Quota } from '@shabic/models';
import { TableDetailsConfig } from '@shabic/ui';
import { convertCurrency } from '../helpers';
import { Observable, of, combineLatest, merge } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'productPrice',
})
export class ProductPricePipe implements PipeTransform {
  constructor(
    private translateService: TranslateService,
    private currencyService: CurrencyService
  ) {}

  transform(
    item: Quota | PreOrder,
    ...args: (string | boolean)[]
  ): Observable<TableDetailsConfig[]> {
    return combineLatest({
      currency: this.currencyService.currentCurrency$,
      locale: merge(
        this.translateService.onLangChange,
        of({ lang: this.translateService.currentLang })
      ),
    }).pipe(
      map(({ currency, locale }) => {
        const price = item instanceof Quota ? item.pricePerUnit : item.price;
        const quantity =
          item instanceof Quota ? item.availableQuantity : item.quantity;
        const quantityConfig: TableDetailsConfig = [
          'common.total-amount',
          quantity + '',
        ];

        const config: TableDetailsConfig[] = [
          [
            'common.unit-price-currency',
            this.parseValue(
              currency,
              locale.lang,
              price,
              new Currency(args[0] as CurrencyType)
            ),
          ],
          [
            'common.vat-currency',
            this.parseValue(
              currency,
              locale.lang,
              item.vat,
              new Currency(args[0] as CurrencyType)
            ),
          ],
          [
            'common.discount-currency',
            this.parseValue(
              currency,
              locale.lang,
              item.discount,
              new Currency(args[0] as CurrencyType)
            ),
          ],
          [
            'common.total',
            this.parseValue(
              currency,
              locale.lang,
              this.getTotal(item, 1),
              new Currency(args[0] as CurrencyType)
            ),
          ],
        ];

        if (args[1]) {
          config.unshift(quantityConfig);
        }

        return config;
      })
    );
  }

  private parseValue(
    currency: Currency,
    locale: string,
    value: number,
    productCurrency: Currency
  ) {
    if (!value) {
      return '-';
    }

    return convertCurrency({
      locale,
      currency,
      price: value,
      priceCurrency: productCurrency,
      hasSymbol: false,
    });
  }

  private getTotal(item: Quota | PreOrder, amount: number) {
    if (item instanceof Quota) {
      return item.getTotal(amount);
    } else {
      return item.getTotal();
    }
  }
}
