import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { equalValidator, patternValidator } from '@shabic/constants';
import { AccountService } from '@shabic/core';
import { FormComponent } from '@shabic/form-control';

@Component({
  selector: 'shabic-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangePasswordComponent extends FormComponent<unknown> {
  readonly formGroup = new FormGroup(
    {
      oldPassword: new FormControl('', [Validators.required]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        patternValidator(/\d/, { hasNumber: true }),
        patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        patternValidator(/[a-z]/, { hasSmallCase: true }),
        patternValidator(/[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/, {
          hasSpecialCharacters: true,
        }),
      ]),
      confirmPassword: new FormControl('', [Validators.required]),
    },
    equalValidator(['password', 'confirmPassword'], 'error.not-equal-password')
  );

  constructor(private accountService: AccountService) {
    super();
  }

  onChangePassword(): void {
    const {
      password = '',
      oldPassword = '',
      confirmPassword = '',
    } = this.formGroup.value;

    this.submitForm(
      this.formGroup,
      this.accountService.changePassword({
        password,
        oldPassword,
        confirmPassword,
      })
    ).subscribe();
  }
}
