<shabic-animate>
  <shabic-headline variant="h3">
    <span class="desktop:text-2xl">
      {{ 'navigation.api-user' | translate }}
    </span>
  </shabic-headline>

  <ng-container *ngIf="fetching$ | async; else userForm">
    <div class="text-grey flex justify-center py-5 desktop:max-w-[400px]">
      <shabic-spinner size="xl"></shabic-spinner>
    </div>
  </ng-container>

  <ng-template #userForm>
    <form
      [formGroup]="formGroup"
      class="desktop:max-w-[400px]"
      autocomplete="off"
    >
      <shabic-form-field class="text-primary mt-5.5">
        <shabic-form-field-label>
          {{ 'common.user-id' | translate }}
        </shabic-form-field-label>

        <input
          type="text"
          autocomplete="new-password"
          class="border border-grey-300"
          shabicInput
          formControlName="id"
          class="ltr:mr-6 rtl:ml-6"
        />

        <ng-container *ngIf="formGroup.get('id')?.value" addon>
          <button type="button" (click)="onCopy('id')">
            <ng-template shabicIcon="icon-copy"></ng-template>
          </button>
        </ng-container>
      </shabic-form-field>

      <shabic-form-field class="text-primary mt-4">
        <shabic-form-field-label>
          {{ 'common.password' | translate }}

          <button
            type="button"
            *ngIf="
              (showPassword$ | async) === false &&
              formGroup.get('password')?.value
            "
            class="text-secondary hover:underline ltr:ml-auto rtl:mr-auto"
            (click)="onShowPassword()"
          >
            {{ 'button.show-password' | translate }}
          </button>
        </shabic-form-field-label>

        <input
          type="password"
          autocomplete="new-password"
          class="border border-grey-300"
          shabicInput
          formControlName="password"
          class="ltr:mr-6 rtl:ml-6"
        />

        <ng-container *ngIf="formGroup.get('password')?.value" addon>
          <button type="button" (click)="onCopy('password')">
            <ng-template shabicIcon="icon-copy"></ng-template>
          </button>
        </ng-container>
      </shabic-form-field>

      <p
        class="inline-block rounded-2xl p-2 text-grey break-all mt-3 border border-grey-400 bg-grey-300"
        *ngIf="showPassword$ | async"
      >
        {{ formGroup.get('password')?.value }}
      </p>
    </form>
  </ng-template>
</shabic-animate>
