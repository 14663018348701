import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ApiErrorResponse,
  ApiMarketplace,
  ApiResponse,
  ENV,
  IEnvironment,
  IListParams,
  IListResponse,
  IPreOrderResponse,
  isErrorResponse,
  List,
  PreOrder,
  Quota,
} from '@shabic/models';
import { StorageService } from './storage.service';
import { BehaviorSubject, fromEvent, of } from 'rxjs';
import { map, catchError, filter } from 'rxjs/operators';
import { EAppEvent, EventBrokerService } from '@shabic/event-broker';
import { CART_STORAGE_KEY } from '@shabic/constants';

@Injectable({
  providedIn: 'root',
})
export class CartService extends ApiMarketplace {
  private _cartCount = new BehaviorSubject<number>(0);
  override readonly url = '/carts';

  cartCount$ = this._cartCount.asObservable();

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private eventBrokerService: EventBrokerService,
    @Inject(ENV) env: IEnvironment
  ) {
    super(env);

    const cartCount = this.storageService.retrieve<number>(
      CART_STORAGE_KEY,
      true
    );

    if (cartCount) {
      this._cartCount.next(cartCount);
    }

    this.eventBrokerService.events$
      .pipe(filter(event => event.type === EAppEvent.UpdateCartCount))
      .subscribe(() => {
        this.updateCartCount();
      });

    fromEvent(window, 'storage').subscribe((event: Event) => {
      if (event instanceof StorageEvent && event.key === CART_STORAGE_KEY) {
        event.newValue
          ? this._cartCount.next(parseInt(event.newValue))
          : undefined;
      }
    });
  }

  getItems(params?: IListParams) {
    const query = new HttpParams({
      fromObject: params || {},
    });

    return this.http
      .get<IListResponse<IPreOrderResponse[]>>(this.apiModelUrl, {
        params: query,
      })
      .pipe(
        map(
          response =>
            new List(response, content => content.map(el => new PreOrder(el)))
        ),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }

  putItem(quota: Quota, amount = 1) {
    return this.http
      .post<unknown>(this.apiModelUrl, {
        productId: quota.product.id,
        quotationId: quota.quotationId,
        quantity: Math.min(quota.availableQuantity, amount),
      })
      .pipe(
        map(response => {
          this.updateCartCount();

          return new ApiResponse(response);
        }),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }

  deleteItem(preOrder: PreOrder) {
    return this.http.delete<unknown>(this.getEndpoint(`/${preOrder.id}`)).pipe(
      map(response => {
        this.updateCartCount();

        return new ApiResponse(response);
      }),
      catchError(response =>
        of(new ApiResponse(new ApiErrorResponse(response)))
      )
    );
  }

  private updateCartCount() {
    this.http
      .get<IListResponse<IPreOrderResponse[]>>(this.apiModelUrl)
      .pipe(
        map(response => new ApiResponse(response)),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      )
      .subscribe(response => {
        if (isErrorResponse(response)) {
          return;
        }

        const count = response.payload.totalElements;

        if (!count) {
          this.storageService.delete(CART_STORAGE_KEY, true);
        } else {
          this.storageService.store(CART_STORAGE_KEY, count, true);
        }

        this._cartCount.next(count);
      });
  }
}
