<svg
  width="80"
  height="80"
  viewBox="0 0 80 80"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_2005_14791)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M67.2132 35.319L69.0032 40.4054L75.4293 49.3618C76.7726 51.2648 77.0362 53.6481 76.1494 55.7579C75.2663 57.858 73.3993 59.3344 71.146 59.7049L59.7935 61.5914L57.9772 72.8804C57.6074 75.1307 56.1206 77.0037 54.0004 77.8838C53.1771 78.2268 52.3171 78.3934 51.4608 78.3934C50.1108 78.3934 48.7809 77.9735 47.6378 77.1569L38.2951 70.4507L29.0323 77.1569C27.1354 78.5002 24.7521 78.7633 22.6355 77.8771C20.536 76.997 19.056 75.127 18.6892 72.8737L16.8027 61.5212L5.51303 59.7049C3.26645 59.3344 1.40009 57.858 0.516963 55.7579C-0.36983 53.6481 -0.10312 51.2648 1.22005 49.385L7.94332 40.019L1.24019 30.7562C-0.096407 28.8929 -0.36983 26.5133 0.510249 24.39C1.39033 22.2734 3.2634 20.7836 5.51974 20.4132L16.8759 18.5267L18.6892 7.23764C19.059 4.99105 20.536 3.12104 22.6355 2.24096C24.749 1.35417 27.1287 1.61783 29.0085 2.94405L38.375 9.66731L47.6378 2.96114C49.5011 1.62454 51.874 1.35112 54.0004 2.23425C56.117 3.11127 57.6074 4.98434 57.9772 7.24435L58.5668 10.7708L59.8472 14.404L40.8346 33.416L33.7384 26.3229C31.8519 24.4333 29.3386 23.3934 26.6685 23.3934C23.999 23.3934 21.4857 24.4333 19.5992 26.3229C17.709 28.2094 16.669 30.7227 16.669 33.3928C16.669 36.0624 17.709 38.5756 19.5992 40.4621L33.7653 54.6282C35.6518 56.5184 38.1651 57.5578 40.8346 57.5578C43.5047 57.5578 46.018 56.5184 47.9045 54.6282L67.2132 35.319ZM38.4783 49.915C39.1283 50.565 39.9815 50.8915 40.8348 50.8915C41.688 50.8915 42.5412 50.565 43.1912 49.915L79.023 14.0832C80.326 12.7802 80.326 10.6734 79.023 9.37034C77.7194 8.06731 75.6131 8.06731 74.3095 9.37034L40.8348 42.8451L29.0257 31.036C27.7221 29.7324 25.6159 29.7324 24.3122 31.036C23.0092 32.3391 23.0092 34.4459 24.3122 35.7489L38.4783 49.915Z"
      fill="url(#paint0_linear_2005_14791)"
    />
  </g>
  <defs>
    <linearGradient
      id="paint0_linear_2005_14791"
      x1="3.47906e-05"
      y1="1.99997"
      x2="64"
      y2="68.5"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#002E53" />
      <stop offset="0.336278" stop-color="#0066AD" />
      <stop offset="0.664309" stop-color="#00A4B9" />
      <stop offset="1" stop-color="#F0FF00" />
    </linearGradient>
    <clipPath id="clip0_2005_14791">
      <rect width="80" height="80" fill="white" />
    </clipPath>
  </defs>
</svg>
