import { DIALOG_DATA } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  ViewChild,
} from '@angular/core';
import { AddProductService } from '@shabic/core';
import {
  ApiErrorResponse,
  ApiResponse,
  IProductPayload,
  isErrorResponse,
  Product,
  ProductFormDialogData,
  Task,
} from '@shabic/models';
import { BehaviorSubject, interval, Observable, Subject } from 'rxjs';
import { switchMap, takeUntil, filter } from 'rxjs/operators';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'shabic-product-form-dialog',
  templateUrl: './product-form-dialog.component.html',
  styleUrls: ['./product-form-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductFormDialogComponent {
  product: Product;

  private _product = new Subject<ApiResponse<unknown>>();
  private _parsing = new BehaviorSubject<boolean>(false);

  @ViewChild(DialogComponent) dialog?: DialogComponent;

  parsing$: Observable<boolean> = this._parsing.asObservable();
  request = (product: IProductPayload, removePictures?: number[]) => {
    this._parsing.next(true);
    this.addProductService
      .addProduct(product, this.product.getExistMedias(removePictures))
      .subscribe(response => {
        if (isErrorResponse(response)) {
          this._parsing.next(false);
          this._product.next(response);
        }

        if (response.payload instanceof Task) {
          this.pingParsing(response.payload.id, removePictures);
        }
      });

    return this._product.asObservable();
  };

  constructor(
    @Inject(DIALOG_DATA) public data: ProductFormDialogData,
    private addProductService: AddProductService
  ) {
    this.product = data.product;
  }

  private pingParsing(id: string, removePictures?: number[]): void {
    interval(1000)
      .pipe(
        switchMap(() => this.addProductService.pingFileParsing(id)),
        takeUntil(this.parsing$.pipe(filter(val => val === false)))
      )
      .subscribe(response => {
        if (isErrorResponse(response)) {
          this._parsing.next(false);
          this._product.next(response);
        }

        if (response.payload instanceof Task) {
          const status = response.payload.status;

          if (status === 'ERROR' || status === 'COMPLETED') {
            this._parsing.next(false);

            if (status === 'ERROR') {
              this._product.next(
                new ApiResponse(
                  (response.payload.data as ApiErrorResponse[])[0]
                )
              );
            } else {
              if (removePictures?.length) {
                this.addProductService
                  .removePictures(this.product, removePictures)
                  .subscribe(response => {
                    if (isErrorResponse(response)) {
                      console.error(response);
                    } else {
                      this._product.next(response);
                      this.dialog?.close();
                      this.data.onUpdate();
                    }
                  });
              } else {
                this._product.next(response);
                this.dialog?.close();
                this.data.onUpdate();
              }
            }
          }
        }
      });
  }
}
