import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'shabic-icon-picture',
  templateUrl: './icon-picture.component.html',
  styleUrls: ['./icon-picture.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconPictureComponent {
  classes = '';
}
