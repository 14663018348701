import { DIALOG_DATA } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BankAccount, RequestAccountStatementDialogData } from '@shabic/models';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'shabic-request-account-statement-dialog',
  templateUrl: './request-account-statement-dialog.component.html',
  styleUrls: ['./request-account-statement-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestAccountStatementDialogComponent {
  private _account: BankAccount;

  formGroup: FormGroup = new FormGroup({
    fromDate: new FormControl('', Validators.required),
    toDate: new FormControl('', Validators.required),
  });

  get today() {
    return new Date();
  }

  get fromDate() {
    return this.formGroup.get('fromDate')?.value;
  }

  get toDate() {
    return this.formGroup.get('toDate')?.value;
  }

  constructor(
    @Inject(DIALOG_DATA) public data: RequestAccountStatementDialogData
  ) {
    this._account = this.data.account;
  }

  onRequest(dialog: DialogComponent) {
    const { fromDate, toDate } = this.formGroup.value;

    const payload = {
      accountId: this._account.id,
      fromDate: fromDate.locale('en').format(),
      toDate: toDate.locale('en').format(),
    };

    dialog.close();
    this.data.onRequest(payload);
  }
}
