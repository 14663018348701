import { DIALOG_DATA } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { PreviewDialogData } from '@shabic/models';

@Component({
  selector: 'shabic-preview-dialog',
  templateUrl: './preview-dialog.component.html',
  styleUrls: ['./preview-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewDialogComponent implements OnInit {
  @ViewChild('iframe', { static: true }) iframe?: ElementRef<HTMLFrameElement>;
  @ViewChild('image', { static: true }) image?: ElementRef<HTMLImageElement>;

  constructor(@Inject(DIALOG_DATA) public data: PreviewDialogData) {}

  ngOnInit(): void {
    const url = URL.createObjectURL(this.data.file);

    if (this.data.file.type.includes('image/')) {
      this.image?.nativeElement.setAttribute('src', url);
      this.image?.nativeElement.removeAttribute('class');
    } else {
      this.iframe?.nativeElement.setAttribute('src', url);
      this.iframe?.nativeElement.removeAttribute('class');
    }
  }
}
