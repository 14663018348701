<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M11.625 8.67499V5.59999H8.52499V4.09999H11.625V1.02499H13.125V4.09999H16.2V5.59999H13.125V8.67499H11.625ZM7.24999 22C6.74999 22 6.32499 21.825 5.97499 21.475C5.62499 21.125 5.44999 20.7 5.44999 20.2C5.44999 19.7 5.62499 19.275 5.97499 18.925C6.32499 18.575 6.74999 18.4 7.24999 18.4C7.74999 18.4 8.17499 18.575 8.52499 18.925C8.87499 19.275 9.04999 19.7 9.04999 20.2C9.04999 20.7 8.87499 21.125 8.52499 21.475C8.17499 21.825 7.74999 22 7.24999 22ZM17.35 22C16.85 22 16.425 21.825 16.075 21.475C15.725 21.125 15.55 20.7 15.55 20.2C15.55 19.7 15.725 19.275 16.075 18.925C16.425 18.575 16.85 18.4 17.35 18.4C17.85 18.4 18.275 18.575 18.625 18.925C18.975 19.275 19.15 19.7 19.15 20.2C19.15 20.7 18.975 21.125 18.625 21.475C18.275 21.825 17.85 22 17.35 22ZM7.24999 16.825C6.54999 16.825 6.03749 16.5417 5.71249 15.975C5.38749 15.4083 5.39165 14.8333 5.72499 14.25L7.24999 11.475L3.49999 3.49999H1.54999V1.99999H4.44999L8.69999 11.1H16L19.9 4.09999L21.2 4.79999L17.375 11.725C17.225 12.0083 17.0208 12.225 16.7625 12.375C16.5042 12.525 16.2 12.6 15.85 12.6H8.34999L6.79999 15.325H19.15V16.825H7.24999Z"
    fill="currentColor"
  />
</svg>
