import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, fromEvent, Subject, takeUntil } from 'rxjs';
import { InputDirective } from '../directives/input.directive';

type ImageSource = string | ArrayBuffer | null;

@Component({
  selector: 'shabic-image-control',
  templateUrl: './image-control.component.html',
  styleUrls: ['./image-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageControlComponent
  implements AfterContentInit, OnInit, OnDestroy, OnChanges
{
  private _destroy = new Subject<void>();
  private _files = new BehaviorSubject<File[]>([]);
  private _image = new BehaviorSubject<ImageSource>(
    '/assets/logo-image-bg.jpg'
  );

  image$ = this._image.asObservable();
  files$ = this._files.asObservable();

  @ContentChild(InputDirective) input?: InputDirective;

  @Input() value?: string | null;
  @Input() size: 'md' | 'lg' = 'md';
  @Output() changed = new EventEmitter<File[]>();

  ngOnInit(): void {
    if (this.value) {
      this._image.next(this.value);
    }

    this.files$.pipe(takeUntil(this._destroy)).subscribe(images => {
      this.changed.next(images);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']?.currentValue) {
      this._image.next(changes['value'].currentValue);
    }
  }

  ngAfterContentInit(): void {
    this.input?.change$?.subscribe(() => {
      const files = this.input?.files();

      if (files) {
        const image = files[0];
        const reader = new FileReader();
        this._files.next([files[0]]);

        fromEvent(reader, 'load').subscribe(() => {
          this._image.next(reader.result);
        });

        if (image) {
          reader.readAsDataURL(image);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  onUpload(): void {
    this.input?.click();
  }
}
