import { dropRepeats } from 'ramda';
import { BehaviorSubject, Observable } from 'rxjs';

export class PaginationItem {
  key: number;
  label?: string;
  active: boolean;
  disabled = false;

  constructor(key: number, label: string, active: boolean = false) {
    this.key = key;
    this.label = label;
    this.active = active;
    this.disabled = isNaN(key);
  }
}

export class Pagination {
  private _items = new BehaviorSubject<PaginationItem[]>([]);
  private _pages: number;

  items$: Observable<PaginationItem[]> = this._items.asObservable();

  constructor(pages: number, page: number | number[]) {
    this._pages = pages;
    this._items.next(this.generateItems(pages, page));
  }

  setActive(page: number | number[]): void {
    this._items.next(this.generateItems(this._pages, page));
  }

  next(): PaginationItem | undefined {
    const index = this._items.value.findIndex(el => el.active);

    if (index > -1 && index + 1 < this._items.value.length) {
      const nextItem = this._items.value[index + 1];
      this.setActive(nextItem.key);
      return nextItem;
    }

    return;
  }

  prev(): PaginationItem | undefined {
    const index = this._items.value.findIndex(el => el.active);

    if (index > 0) {
      const nextItem = this._items.value[index - 1];
      this.setActive(nextItem.key);
      return nextItem;
    }

    return;
  }

  get activePage(): number {
    const item = this._items.value.find(item => item.active);

    return item ? item.key : 0;
  }

  getPage(page: number | number[]): PaginationItem | undefined {
    const key = Array.isArray(page) ? Math.max(...page) : page;
    const item = this._items.value.find(el => el.key === key);

    return item || undefined;
  }

  private generateItems(
    pages: number,
    page: number | number[]
  ): PaginationItem[] {
    const list: number[] = Array(pages)
      .fill('')
      .map((_, index) => index);
    const firstPage = Array.isArray(page) ? Math.min(...page) : page;
    const lastPage = Array.isArray(page) ? Math.max(...page) : page;

    if (list.length > 4) {
      const result: number[] = [];
      const prevPage = firstPage - 1;
      const nextPage = lastPage + 1;

      list.forEach(i => {
        if (i === 0 || i === list.length - 1) {
          result.push(i);
          return;
        }

        if (
          i === prevPage ||
          i === nextPage ||
          (i > prevPage && i < nextPage)
        ) {
          result.push(i);
          return;
        }

        result.push(NaN);
      });

      return dropRepeats(result).map(el =>
        isNaN(el)
          ? new PaginationItem(el, '...', false)
          : new PaginationItem(
              el,
              el + 1 + '',
              Array.isArray(page) ? page.includes(el) : el === page
            )
      );
    }

    return list.map(
      el =>
        new PaginationItem(
          el,
          el + 1 + '',
          Array.isArray(page) ? page.includes(el) : el === page
        )
    );
  }
}
