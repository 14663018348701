import { BankAccountStatement } from '@shabic/models';

export const getBankAccountStatement = () => {
  return new BankAccountStatement({
    balance: 2700,
    date: new Date().toUTCString(),
    credit: 1200,
    debit: 1500,
    description: 'Description',
    id: '#123',
  });
};
