import { AbstractControl, ValidatorFn } from '@angular/forms';

export function equalValidator(fields: string[], message: string): ValidatorFn {
  return (control: AbstractControl) => {
    if (!fields.length) {
      return null;
    }

    const value = control.value;
    const isEqual = fields.every(field => {
      return value[field] === value[fields[0]];
    });

    if (!isEqual) {
      return {
        equal: message || 'Not equal',
      };
    }

    return null;
  };
}
