<form shabicSignUpForm [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <ng-container [ngSwitch]="state$ | async">
    <ng-container *ngSwitchCase="'form'">
      <shabic-headline variant="h2" align="center">
        {{ 'govt-step.title' | translate }}
      </shabic-headline>

      <shabic-form-field class="text-primary mt-5.5">
        <shabic-form-field-label>
          {{ 'common.govt' | translate }}
        </shabic-form-field-label>

        <input
          type="text"
          shabicInput
          formControlName="govId"
          placeholder="XXX XXXX XXXX"
        />
      </shabic-form-field>

      <shabic-form-field class="text-primary mt-4">
        <shabic-form-field-label>
          {{ 'common.birthday' | translate }}
        </shabic-form-field-label>

        <input
          class="ltr:pr-6 rtl:pl-6"
          matInput
          [matDatepicker]="picker"
          type="text"
          shabicInput
          [max]="today"
          formControlName="birthday"
        />

        <button type="button" addon (click)="picker.open()">
          <ng-template shabicIcon="calendar"></ng-template>
        </button>

        <mat-datepicker #picker></mat-datepicker>
      </shabic-form-field>
    </ng-container>

    <ng-container *ngSwitchCase="'passcode'">
      <shabic-headline class="mt-5 block text-center" variant="h2">
        {{ 'verify-govt.subtitle' | translate }}
      </shabic-headline>

      <shabic-sub-headline>
        {{ 'passcode.enter-passcode' | translate }}
      </shabic-sub-headline>

      <shabic-passcode
        formControlName="passcode"
        class="mt-5.5 pb-3 text-primary"
      ></shabic-passcode>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="state$ | async as state">
    <button
      [disabled]="formGroup.invalid || (loading$ | async)"
      class="mt-4 w-full gap-1"
      shabicButton="secondary"
      type="submit"
    >
      {{ (state === 'form' ? 'button.verify' : 'button.confirm') | translate }}

      <shabic-spinner *ngIf="loading$ | async"></shabic-spinner>
    </button>
  </ng-container>
</form>
