<shabic-layout>
  <shabic-login-layout>
    <shabic-login-forgot-password-form
      class="block w-full"
      [model]="forgotPassword"
      *ngIf="(forgotPassword.currentStep$ | async)?.id === 'email'"
    >
      <ng-container message>
        <div class="flex justify-center mb-4 mt-6">
          <ng-template shabicIcon="mail"></ng-template>
        </div>

        <shabic-headline variant="h2" align="center">
          {{ 'message.check-mailbox' | translate }}
        </shabic-headline>

        <shabic-sub-headline>
          {{
            'passcode.email-passcode'
              | translate
                : { email: forgotPassword.formGroup.get('email')?.value }
          }}
        </shabic-sub-headline>
      </ng-container>
    </shabic-login-forgot-password-form>

    <shabic-login-create-new-password-form
      class="block w-full"
      [model]="forgotPassword"
      [token]="token"
      *ngIf="(forgotPassword.currentStep$ | async)?.id === 'password'"
    >
      <ng-container message>
        <shabic-verify-checkmark
          class="flex justify-center mt-6 mb-4"
        ></shabic-verify-checkmark>

        <shabic-headline class="block mt-5" variant="h2" align="center">
          {{ 'message.password-changed' | translate }}
        </shabic-headline>

        <p class="text-center">
          <a class="mt-5" routerLink="/sign-in" shabicButton="secondary">
            {{ 'button.login' | translate }}
          </a>
        </p>
      </ng-container>
    </shabic-login-create-new-password-form>
  </shabic-login-layout>
</shabic-layout>
