<shabic-animate class="block h-full">
  <div class="dialog" [class.p-4]="!fullSize">
    <div
      class="flex justify-end items-center pb-3"
      [class.full-size]="fullSize"
    >
      <shabic-headline
        *ngIf="name"
        variant="h4"
        [class.desktop-hidden]="nameOnDesktopHidden"
      >
        <span class="desktop:text-lg">
          {{ name | translate }}
        </span>
      </shabic-headline>

      <button
        type="button"
        shabicButton="navigation"
        class="text-primary rtl:mr-auto ltr:ml-auto"
        (click)="close()"
      >
        <ng-template shabicIcon="close"></ng-template>
      </button>
    </div>

    <div class="content">
      <ng-content></ng-content>
    </div>
  </div>
</shabic-animate>
