<svg
  width="24"
  height="24"
  viewBox="0 0 420 560"
  xmlns="http://www.w3.org/2000/svg"
>
  <g id="g6" transform="translate(-140.007,0.184)">
    <path
      d="m 175,455 c 0.0273,-45.805 18.016,-89.777 50.098,-122.47 32.086,-32.695 75.707,-51.504 121.5,-52.395 45.801,-0.88672 90.121,16.215 123.45,47.637 l 23.977,-25.375 h -0.004 c -20.207,-19.07 -44,-33.941 -70,-43.75 34.664,-21.59 58.168,-57.238 64.359,-97.605 6.1914,-40.363 -5.5508,-81.418 -32.148,-112.41 -26.598,-30.984 -65.398,-48.816 -106.23,-48.816 -40.832,0 -79.637,17.832 -106.23,48.816 -26.598,30.988 -38.34,72.043 -32.148,112.41 6.1914,40.367 29.695,76.016 64.359,97.605 -39.93,15.043 -74.328,41.887 -98.621,76.965 -24.289,35.074 -37.32,76.719 -37.355,119.39 v 87.5 c 0,4.6406 1.8438,9.0938 5.125,12.375 3.2812,3.2812 7.7344,5.125 12.375,5.125 h 175 v -35 h -157.5 z M 245,140 C 245,112.152 256.062,85.445 275.754,65.754 295.446,46.063 322.152,35 350,35 377.848,35 404.555,46.062 424.246,65.754 443.937,85.446 455,112.152 455,140 c 0,27.848 -11.062,54.555 -30.754,74.246 C 404.554,233.937 377.848,245 350,245 322.152,245 295.445,233.938 275.754,214.246 256.063,194.554 245,167.848 245,140 Z"
      fill="currentColor"
      id="path2"
    />
    <path
      d="m 455,350 c -27.848,0 -54.555,11.062 -74.246,30.754 C 361.063,400.446 350,427.152 350,455 c 0,27.848 11.062,54.555 30.754,74.246 C 400.446,548.937 427.152,560 455,560 c 27.848,0 54.555,-11.062 74.246,-30.754 C 548.937,509.554 560,482.848 560,455 560,427.152 548.938,400.445 529.246,380.754 509.554,361.063 482.848,350 455,350 Z m -70,105 c 0,-18.566 7.375,-36.371 20.504,-49.496 C 418.629,392.375 436.434,385 455,385 c 12.344,0.17969 24.418,3.6211 35,9.9766 l -95.023,95.023 c -6.3555,-10.582 -9.7969,-22.656 -9.9766,-35 z m 70,70 c -12.344,-0.17969 -24.418,-3.6211 -35,-9.9766 l 95.023,-95.023 c 6.3555,10.582 9.7969,22.656 9.9766,35 0,18.566 -7.375,36.371 -20.504,49.496 -13.125,13.129 -30.93,20.504 -49.496,20.504 z"
      fill="currentColor"
      id="path4"
    />
  </g>
</svg>
