import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile.component';
import { ProfileRoutingModule } from './profile-routing.module';
import {
  DropzoneModule,
  FiltersModule,
  LayoutModule,
  MapModule,
} from '@shabic/shared';
import { TranslateModule } from '@ngx-translate/core';
import { IconModule } from '@shabic/icon';
import { UiModule } from '@shabic/ui';
import { FormControlModule } from '@shabic/form-control';
import {
  BankAccountComponent,
  ProfileInfoComponent,
  CompanyInfoComponent,
  ChangePasswordComponent,
  MarketingComponent,
} from './components';
import { EventBrokerModule } from '@shabic/event-broker';
import { ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { NgxMaskModule } from 'ngx-mask';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { UtilsModule } from '@shabic/utils';
import { ApiUserComponent } from './components/api-user/api-user.component';

@NgModule({
  imports: [
    CommonModule,
    LayoutModule,
    ProfileRoutingModule,
    TranslateModule,
    IconModule,
    UiModule,
    FormControlModule,
    MapModule,
    EventBrokerModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatMomentDateModule,
    NgxMaskModule,
    UtilsModule,
    FiltersModule,
    DropzoneModule,
  ],
  declarations: [
    ProfileComponent,
    ProfileInfoComponent,
    BankAccountComponent,
    CompanyInfoComponent,
    ChangePasswordComponent,
    ApiUserComponent,
    MarketingComponent,
  ],
})
export class ProfileModule {}
