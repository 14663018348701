import { Contact } from './contact.model';

export class LegalContact extends Contact {
  constructor() {
    super({
      id: 1,
      login: 'admin@admin.com',
      phone: '',
      email: 'admin@admin.com',
      fullName: '',
      mediaUrl: '',
      langKey: '',
      status: 'ACTIVE',
      supplier: false,
      imageUrl: '',
      company: null,
    });
  }
}
