import { DIALOG_DATA } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { CompanyDocument, DocumentsDialogData } from '@shabic/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'shabic-documents-dialog',
  templateUrl: './documents-dialog.component.html',
  styleUrls: ['./documents-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentsDialogComponent {
  private _loading = new BehaviorSubject<boolean>(false);
  documents$: Observable<CompanyDocument[]>;

  loading$ = this._loading.asObservable();

  constructor(@Inject(DIALOG_DATA) public data: DocumentsDialogData) {
    this.documents$ = data.documents$.pipe(
      map(response => {
        const payload = response.payload;

        if (Array.isArray(payload)) {
          payload.sort(prev => (prev.status === 'CURRENT' ? -1 : 1));

          return payload;
        } else {
          return [];
        }
      })
    );
  }

  onDownload(document: CompanyDocument): void {
    this._loading.next(true);
    this.data.getDocument(document, true).subscribe({
      next: () => this.markAsRead(document),
      complete: () => this._loading.next(false),
    });
  }

  onPreview(document: CompanyDocument) {
    this._loading.next(true);
    this.data.getDocument(document, false).subscribe({
      next: response => {
        if (response.payload instanceof Blob) {
          this.data.onPreviewDocument(document, response.payload);

          this.markAsRead(document);
        }
      },
      complete: () => this._loading.next(false),
    });
  }

  hasPreviewButton(document: CompanyDocument) {
    return document.type.includes('image') || document.type.includes('pdf');
  }

  private markAsRead(document: CompanyDocument) {
    this.data.onRead(document).subscribe(response => {
      if (response.payload instanceof CompanyDocument) {
        document.status = response.payload.status;
      }
    });
  }
}
