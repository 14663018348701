import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  ApiErrorResponse,
  ApiResponse,
  Category,
  ENV,
  ICategoryResponse,
  IEnvironment,
  isErrorResponse,
  Option,
  ICategoryPayload,
  ApiMarketplace,
} from '@shabic/models';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { MediaService } from './media.service';

@Injectable({
  providedIn: 'root',
})
export class CategoriesService extends ApiMarketplace {
  private _initValue: Option[] = [];
  private _categories = new BehaviorSubject<Option[]>(this._initValue);

  override readonly url = '/categories';

  categories$ = this._categories.asObservable();

  constructor(
    private http: HttpClient,
    @Inject(ENV) env: IEnvironment,
    private translateService: TranslateService,
    private mediaService: MediaService
  ) {
    super(env);
  }

  get(): Observable<ApiResponse<ICategoryResponse[] | ApiErrorResponse>> {
    return this.http.get<ICategoryResponse[]>(this.apiModelUrl).pipe(
      map(response => new ApiResponse(response)),
      catchError(response =>
        of(new ApiResponse(new ApiErrorResponse(response)))
      )
    );
  }

  updateCategories() {
    return this.http.get<ICategoryResponse[]>(this.apiModelUrl).pipe(
      map(response => new ApiResponse(this.flating(response))),
      tap(response => {
        if (isErrorResponse(response)) {
          this._categories.next([] as Option[]);
        } else {
          this._categories.next(
            (response.payload as Category[]).map(
              category =>
                new Option(
                  this.translateService,
                  category.name.get('en') || '',
                  category.id
                )
            )
          );
        }
      }),
      catchError(response =>
        of(new ApiResponse(new ApiErrorResponse(response)))
      )
    );
  }

  addCategories(
    categories: ICategoryPayload
  ): Observable<ApiResponse<ICategoryResponse | ApiErrorResponse>> {
    return this.http.put<ICategoryResponse>(this.apiModelUrl, categories).pipe(
      map(response => new ApiResponse(response)),
      catchError(response =>
        of(new ApiResponse(new ApiErrorResponse(response)))
      )
    );
  }

  removeCategory(
    id: number
  ): Observable<ApiResponse<unknown | ApiErrorResponse>> {
    return this.http.delete<unknown>(this.getEndpoint(`/${id}`)).pipe(
      map(response => new ApiResponse(response)),
      catchError(response =>
        of(new ApiResponse(new ApiErrorResponse(response)))
      )
    );
  }

  editCategory(
    payload: ICategoryPayload
  ): Observable<ApiResponse<unknown | ApiErrorResponse>> {
    return this.http
      .put<unknown>(this.getEndpoint(`/${payload.id}`), payload)
      .pipe(
        map(response => new ApiResponse(response)),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }

  getOptionValue(target: string): unknown | null {
    const collection: Option[] = this._categories.value;

    return collection.find(option => option.label === target)?.id;
  }

  public uploadImage(image: File[]) {
    return this.mediaService
      .upload(this.getApiEndpoint(''), image, 'CATEGORY')
      .pipe(
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }

  private flating(categories: ICategoryResponse[]): Category[] {
    const result: Category[] = [];

    categories.forEach(item => {
      result.push(Category.fromResponse(item));

      if (item.subCategories) {
        result.push(...this.flating(item.subCategories));
      }
    });

    return result;
  }
}
