import { DIALOG_DATA } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SUCCESS } from '@shabic/constants';
import { AccountService } from '@shabic/core';
import { FormComponent } from '@shabic/form-control';
import { BlockUserDialogData } from '@shabic/models';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'shabic-block-user-dialog',
  templateUrl: './block-user-dialog.component.html',
  styleUrls: ['./block-user-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlockUserDialogComponent extends FormComponent<unknown> {
  @ViewChild(DialogComponent) dialog?: DialogComponent;

  formGroup = new FormGroup({
    reason: new FormControl(''),
  });

  constructor(
    @Inject(DIALOG_DATA) public data: BlockUserDialogData,
    private accountService: AccountService
  ) {
    super();
  }

  get dialogTitle(): string {
    return (
      this.data.title ||
      (this.data.disable
        ? 'message.block-account-reason'
        : 'message.unblock-account-reason')
    );
  }

  onSubmit() {
    this.submitForm(
      this.formGroup,
      this.accountService.toggleAccountAccess({
        contact: this.data.contact,
        disable: this.data.disable,
        disableReason: this.formGroup.value.reason || '',
      })
    ).subscribe(status => {
      if (status === SUCCESS) {
        this.dialog?.close();
        this.data.onSuccess();
      }
    });
  }
}
