import { API_URL, FAILURE, SUCCESS } from '@shabic/constants';
import { Observable, of } from 'rxjs';
import { IEnvironment } from './env.model';

export type ResponseStatus = typeof SUCCESS | typeof FAILURE;

class Api {
  protected url = '';
  private apiUrl: string;

  constructor(production: boolean, domain: string) {
    this.apiUrl = production ? `${domain}${API_URL}` : API_URL;
  }

  protected get apiModelUrl(): string {
    return `${this.apiUrl}${this.url}`;
  }

  protected getEndpoint(endpoint: string): string {
    return `${this.apiModelUrl}${endpoint}`;
  }

  protected getApiEndpoint(endpoint: string): string {
    return `${this.apiUrl}${endpoint}`;
  }

  protected cathError(
    response: unknown
  ): Observable<ApiResponse<ApiErrorResponse>> {
    return of(new ApiResponse(new ApiErrorResponse(response)));
  }
}

export class ApiClient extends Api {
  constructor(env: IEnvironment) {
    super(env.production, env.clientApiUrl);
  }
}

export class ApiMarketplace extends Api {
  constructor(env: IEnvironment) {
    super(env.production, env.marketplaceApiUrl);
  }
}

export class ApiResponse<T> {
  status: ResponseStatus;
  payload: T;

  static create<T>(payload: T): ApiResponse<T> {
    return new ApiResponse(payload);
  }

  constructor(payload: T) {
    this.status = payload instanceof ApiErrorResponse ? FAILURE : SUCCESS;
    this.payload = payload;
  }
}

export function isErrorResponse(
  response: ApiResponse<unknown>
): response is ApiResponse<ApiErrorResponse> {
  return response.payload instanceof ApiErrorResponse;
}

export class ApiErrorResponse {
  path: string;
  status: number;
  fieldErrors?: Array<{ description: string | string[]; field: string }>;
  violations?: Array<{ field: string; message: string }>;
  message?: string;
  title?: string;
  model?: unknown;
  errorDescription?: string;
  params: {
    error?: string;
    error_description?: string;
    error_key: string;
  };

  constructor(response: any) {
    this.path = response.error?.instance;
    this.fieldErrors = response.error?.['invalid-params']?.map((item: any) => {
      return {
        field: item.name,
        description: item.reason,
      };
    });
    this.status = response.status;
    this.message = response.error?.detail;
    this.title = response.error?.title;
    this.violations = response.error?.violations;
    this.model = response.error?.model;
    this.errorDescription = response.error?.errorDescription;
    this.params =
      response.error?.error_key && typeof response.error?.error_key !== 'string'
        ? {
            ...response.error.error_key,
          }
        : {
            error_key: `error.${response.error.error_key}`,
          };
  }
}
