<shabic-dialog>
  <div class="px-2">
    <h5 class="text-lg text-primary font-medium rtl:text-right">
      {{ 'message.deactivate-product' | translate }}
    </h5>

    <form class="mt-5" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
      <shabic-form-field>
        <shabic-form-field-label forAttribute="reason">
          {{ 'common.description' | translate }}
        </shabic-form-field-label>

        <textarea
          shabicInput
          rows="6"
          id="reason"
          class="resize-none border border-grey-300"
          formControlName="reason"
        ></textarea>
      </shabic-form-field>

      <shabic-form-field-errors
        [errors]="formGroup.errors"
      ></shabic-form-field-errors>

      <div class="flex justify-end">
        <button
          type="submit"
          shabicButton="primary"
          class="mt-5 mb-6 gap-1"
          [disabled]="loading$ | async"
        >
          {{ 'button.confirm' | translate }}

          <shabic-spinner *ngIf="loading$ | async"></shabic-spinner>
        </button>
      </div>
    </form>
  </div>
</shabic-dialog>
