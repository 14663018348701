<form
  [class.grey-bg]="!hasSearchButton"
  [class.bg-white]="hasSearchButton"
  class="relative flex w-full h-full rounded-2xl text-primary"
  [class.focus]="focus$ | async"
  [formGroup]="form"
  (ngSubmit)="onSearch()"
>
  <span
    class="absolute z-1 top-2/4 ltr:left-3 rtl:right-3 -translate-y-2/4"
    [class.text-secondary]="focus$ | async"
  >
    <ng-template shabicIcon="icon-magnifier"></ng-template>
  </span>

  <shabic-form-field class="w-full">
    <input
      type="text"
      [class.border-0]="!hasBorder"
      class="h-[64px] ltr:pl-6.5 rtl:pr-6.5 bg-transparent [&:not(.ng-invalid.ng-touched):focus]:border-grey-350"
      formControlName="query"
      shabicInput
      name="search"
      (focus)="onFocus()"
      (blur)="onBlur()"
      [placeholder]="'common.search' | translate"
    />
  </shabic-form-field>

  <div *ngIf="hasSearchButton" class="p-1">
    <button type="submit" shabicButton="primary" class="py-2 min-h-[48px]">
      {{ 'button.search' | translate }}
    </button>
  </div>
</form>
