<shabic-animate>
  <shabic-headline variant="h3">
    <span class="desktop:text-2xl">
      {{ 'profile.company-information' | translate }}
    </span>
  </shabic-headline>

  <ng-container *ngIf="companyLoading$ | async; else form">
    <ng-container *ngTemplateOutlet="spinner"></ng-container>
  </ng-container>

  <ng-template #form>
    <div class="desktop:max-w-[400px]">
      <form
        [formGroup]="companyForm"
        (ngSubmit)="onSubmit()"
        autocomplete="off"
      >
        <div class="mt-4 desktop:mt-5.5">
          <shabic-group-title
            name="profile.basic-information"
            icon="edit"
          ></shabic-group-title>
        </div>

        <shabic-form-field class="mt-5">
          <shabic-image-control
            [value]="companyForm.get('mediaUrl')?.value"
            (changed)="patchValue('mediaId', $event)"
          >
            <input
              type="file"
              shabicInput
              hidden
              name=""
              class="peer"
              accept="image/png,image/jpeg,image/jpg"
            />
          </shabic-image-control>
        </shabic-form-field>

        <shabic-form-field class="text-primary mt-5.5">
          <shabic-form-field-label forAttribute="name-en">
            {{ 'common.company-name-en' | translate }}
          </shabic-form-field-label>

          <input
            type="text"
            class="border border-grey-300"
            shabicInput
            name=""
            formControlName="nameFirst"
            id="name-en"
          />
        </shabic-form-field>

        <shabic-form-field class="text-primary mt-4">
          <shabic-form-field-label forAttribute="name-ar">
            {{ 'common.company-name-ar' | translate }}
          </shabic-form-field-label>

          <input
            type="text"
            class="border border-grey-300"
            shabicInput
            name=""
            dir="rtl"
            formControlName="nameSecond"
            id="name-ar"
          />
        </shabic-form-field>

        <shabic-form-field class="text-primary mt-4" name="common.cr-number">
          <shabic-form-field-label forAttribute="cr-number">
            {{ 'common.cr-number' | translate }}
          </shabic-form-field-label>

          <input
            type="text"
            shabicInput
            name=""
            class="ltr:pr-6 rtl:pl-6 border border-grey-300"
            formControlName="crNumber"
            id="cr-number"
            mask="000000999999"
            [placeholder]="'common.cr-number' | translate"
          />
        </shabic-form-field>

        <shabic-form-field class="text-primary mt-4">
          <shabic-form-field-label forAttribute="date">
            {{ 'common.formation-date' | translate }}
          </shabic-form-field-label>

          <input
            type="text"
            shabicInput
            name=""
            id="date"
            class="border border-grey-300"
            [placeholder]="'common.formation-date' | translate"
            formControlName="formationDate"
            [max]="today"
            matInput
            [matDatepicker]="picker"
          />

          <button type="button" addon (click)="picker.open()">
            <ng-template shabicIcon="calendar"></ng-template>
          </button>

          <mat-datepicker #picker></mat-datepicker>
        </shabic-form-field>

        <shabic-form-field class="text-primary mt-4">
          <shabic-form-field-label forAttribute="type">
            {{ 'common.company-type' | translate }}
          </shabic-form-field-label>

          <shabic-select-control [options]="types$">
            <input
              type="text"
              shabicInput
              name=""
              class="ltr:pr-6 rtl:pl-6 border border-grey-300"
              id="type"
              formControlName="type"
              [placeholder]="'common.company-type' | translate"
            />
          </shabic-select-control>
        </shabic-form-field>

        <shabic-form-field class="text-primary mt-4">
          <shabic-form-field-label forAttribute="activity">
            {{ 'common.business-activity' | translate }}
          </shabic-form-field-label>

          <shabic-select-control [options]="activities$">
            <input
              type="text"
              shabicInput
              name=""
              class="ltr:pr-6 rtl:pl-6 border border-grey-300"
              id="activity"
              formControlName="businessActivity"
              [placeholder]="'common.business-activity' | translate"
            />
          </shabic-select-control>
        </shabic-form-field>

        <shabic-form-field class="text-primary mt-4">
          <shabic-form-field-label forAttribute="classification">
            {{ 'common.classification' | translate }}
          </shabic-form-field-label>

          <shabic-select-control [options]="classifications$">
            <input
              type="text"
              shabicInput
              name=""
              class="ltr:pr-6 rtl:pl-6 border border-grey-300"
              id="classification"
              formControlName="classification"
              [placeholder]="'common.classification' | translate"
            />
          </shabic-select-control>
        </shabic-form-field>

        <shabic-form-field class="text-primary mt-4">
          <shabic-form-field-label forAttribute="company-email">
            {{ 'common.company-email' | translate }}
          </shabic-form-field-label>

          <input
            type="text"
            class="border border-grey-300"
            shabicInput
            name=""
            formControlName="email"
            id="company-email"
          />
        </shabic-form-field>

        <shabic-form-field
          class="text-primary mt-4"
          name="common.company-phone"
        >
          <shabic-form-field-label forAttribute="company-phone">
            {{ 'common.company-phone' | translate }}
          </shabic-form-field-label>

          <input
            type="text"
            class="border border-grey-300"
            shabicInput
            name=""
            formControlName="phone"
            id="company-phone"
            prefix="+966"
            mask="-00-000-0000"
          />
        </shabic-form-field>

        <!-- <hr class="text-grey-300 my-4" />

      <shabic-form-field class="text-primary mt-4">
        <shabic-form-field-label forAttribute="legal-contact-name">
          {{ 'common.legal-contact-name' | translate }}
        </shabic-form-field-label>

        <input
          type="text"
          class="border border-grey-300"
          shabicInput
          name=""
          id="legal-contact-name"
        />
      </shabic-form-field>

      <shabic-form-field class="text-primary mt-4">
        <shabic-form-field-label forAttribute="legal-contact-phone">
          {{ 'common.legal-contact-phone' | translate }}
        </shabic-form-field-label>

        <input
          type="tel"
          class="border border-grey-300"
          shabicInput
          name=""
          id="legal-contact-phone"
        />
      </shabic-form-field>

      <shabic-form-field class="text-primary mt-4">
        <shabic-form-field-label forAttribute="legal-contact-email">
          {{ 'common.legal-contact-email' | translate }}
        </shabic-form-field-label>

        <input
          type="email"
          class="border border-grey-300"
          shabicInput
          name=""
          id="legal-contact-email"
        />
      </shabic-form-field> -->

        <hr class="text-grey-300 my-4" />

        <shabic-headline variant="h5">
          {{ 'common.company-hq' | translate }}
        </shabic-headline>

        <shabic-map
          [initialAddress]="address"
          [readonly]="companyForm.get('street')?.disabled"
          (address)="onUpdateAddress($event)"
          class="mt-4"
        ></shabic-map>

        <shabic-form-field class="text-primary mt-4">
          <shabic-form-field-label forAttribute="street">
            {{ 'common.street' | translate }}
          </shabic-form-field-label>

          <input
            type="text"
            class="border border-grey-300"
            shabicInput
            name=""
            formControlName="street"
            id="street"
          />
        </shabic-form-field>

        <shabic-form-field class="text-primary mt-4">
          <shabic-form-field-label forAttribute="city">
            {{ 'common.city' | translate }}
          </shabic-form-field-label>

          <input
            type="text"
            class="border border-grey-300"
            shabicInput
            name=""
            formControlName="city"
            id="city"
          />
        </shabic-form-field>

        <shabic-form-field class="text-primary mt-4" name="common.po-box">
          <shabic-form-field-label forAttribute="po-box">
            {{ 'common.po-box' | translate }}
          </shabic-form-field-label>

          <input
            type="text"
            class="border border-grey-300"
            shabicInput
            name=""
            mask="000999"
            formControlName="poBox"
            id="po-box"
          />
        </shabic-form-field>

        <shabic-form-field class="text-primary mt-4" name="common.zipcode">
          <shabic-form-field-label forAttribute="zipcode">
            {{ 'common.zipcode' | translate }}
          </shabic-form-field-label>

          <input
            type="text"
            class="border border-grey-300"
            shabicInput
            name=""
            mask="000999"
            formControlName="zipCode"
            id="zipcode"
          />
        </shabic-form-field>

        <shabic-form-field class="text-primary mt-4">
          <shabic-form-field-label forAttribute="website">
            {{ 'common.company-website' | translate }}
          </shabic-form-field-label>

          <input
            type="text"
            class="border border-grey-300"
            shabicInput
            name=""
            id="website"
            formControlName="webSite"
          />
        </shabic-form-field>

        <shabic-form-field-errors
          [errors]="companyForm.errors"
        ></shabic-form-field-errors>

        <button
          shabicButton="primary"
          class="w-full mt-4 gap-1"
          type="submit"
          [disabled]="companyForm.invalid || (loading$ | async)"
        >
          {{ 'button.save' | translate }}

          <shabic-spinner *ngIf="loading$ | async"></shabic-spinner>
        </button>
      </form>

      <ng-container *ngIf="hasCompany">
        <div class="mt-6 desktop:mt-7">
          <shabic-group-title
            name="common.other-information"
            icon="icon-star"
          ></shabic-group-title>
        </div>
        <form
          [formGroup]="documentsForm"
          (ngSubmit)="uploadDocuments()"
          class="mt-5"
        >
          <shabic-form-field>
            <shabic-form-field-label forAttribute="custom">
              <span class="normal-case text-sm font-normal text-primary">
                {{ 'company-type.custom' | translate }}
              </span>
            </shabic-form-field-label>
          </shabic-form-field>

          <ul *ngIf="documents$ | async as documents; else spinner">
            <ng-container *ngIf="documents.length">
              <li
                class="flex items-center gap-3 border-t border-grey-400 py-1 min-w-0"
                *ngFor="let document of documents"
              >
                <span
                  class="w-full whitespace-nowrap text-ellipsis overflow-hidden flex-1 text-primary font-medium text-sm"
                  [title]="document.name"
                >
                  {{ document.name }}
                </span>

                <button
                  type="button"
                  shabicButton="primary"
                  [disabled]="loading$ | async"
                  [title]="'button.download' | translate"
                  (click)="onDownload(document)"
                >
                  <ng-template shabicIcon="icon-download"></ng-template>
                </button>

                <button
                  *ngIf="hasPreviewButton(document)"
                  type="button"
                  shabicButton="default"
                  [disabled]="loading$ | async"
                  [title]="'button.preview' | translate"
                  (click)="onPreview(document)"
                >
                  <ng-template shabicIcon="icon-preview"></ng-template>
                </button>

                <button
                  *ngIf="hasDeleteButton()"
                  type="button"
                  shabicButton="danger"
                  [disabled]="loading$ | async"
                  [title]="'button.delete' | translate"
                  (click)="onDelete(document)"
                >
                  <ng-template shabicIcon="delete"></ng-template>
                </button>
              </li>
            </ng-container>
          </ul>

          <ul>
            <li
              *ngFor="let control of fileControls"
              class="border-t border-grey-400 py-1"
            >
              <shabic-form-field>
                <shabic-file-control
                  [control]="control"
                  [disabled]="control.disabled"
                >
                  <input
                    type="file"
                    shabicInput
                    class="hidden"
                    accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/png,image/jpeg,image/jpg"
                    name=""
                  />
                </shabic-file-control>
              </shabic-form-field>
            </li>
          </ul>

          <shabic-form-field-errors
            [errors]="documentsForm.errors"
          ></shabic-form-field-errors>

          <button
            shabicButton="primary"
            class="w-full mt-5.5"
            type="button"
            [disabled]="documentsForm.disabled || (loading$ | async)"
            (click)="addControl()"
          >
            {{ 'button.add' | translate }}
          </button>

          <button
            shabicButton="primary"
            class="w-full mt-3 gap-1"
            type="submit"
            [disabled]="
              documentsForm.invalid ||
              documentsForm.disabled ||
              (loading$ | async)
            "
          >
            {{ 'button.save' | translate }}

            <shabic-spinner *ngIf="loading$ | async"></shabic-spinner>
          </button>
        </form>
      </ng-container>
    </div>
  </ng-template>

  <ng-template #spinner>
    <div class="text-grey flex justify-center py-5 desktop:max-w-[400px]">
      <shabic-spinner size="xl"></shabic-spinner>
    </div>
  </ng-template>
</shabic-animate>
