import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { TableDetailsConfig } from '../../models';

@Component({
  selector: 'shabic-table-details',
  templateUrl: './table-details.component.html',
  styleUrls: ['./table-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableDetailsComponent implements OnChanges {
  @Input() config?: TableDetailsConfig[] | null;
  @Input() lastItemColor = 'text-primary';
  @Input() translateParams?: Record<string, unknown> = {};

  labels: string[] = [];
  rows: string[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    const config: TableDetailsConfig[] | null = changes['config']
      .currentValue as TableDetailsConfig[];

    if (!config) {
      return;
    }

    this.labels = [];
    this.rows = [];

    config.forEach(([label, value]) => {
      this.labels.push(label);
      this.rows.push(value + '');
    });
  }

  getItemClasses(last: boolean) {
    return last ? this.lastItemColor : 'text-primary';
  }
}
