<form shabicSignUpForm [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <shabic-verify-checkmark
    class="flex justify-center pb-5.5"
  ></shabic-verify-checkmark>

  <shabic-headline variant="h2" align="center">
    {{ 'profile-created.title' | translate }}
  </shabic-headline>
  <shabic-sub-headline class="block mt-0.5">
    {{ 'profile-created.subtitle' | translate }}
  </shabic-sub-headline>

  <div
    class="border border-grey-400 px-4 py-5 mt-5.5 rounded"
    [class.active]="isActive('owner')"
  >
    <shabic-form-field>
      <shabic-radio-control>
        <input
          shabicInput
          class="peer hidden"
          type="radio"
          name="type"
          id="owner"
          value="owner"
          formControlName="type"
        />

        <shabic-form-field-label forAttribute="owner">
          <span class="normal-case text-sm font-normal text-primary">
            {{ 'company-type.owner' | translate }}
          </span>
        </shabic-form-field-label>
      </shabic-radio-control>
    </shabic-form-field>

    <shabic-form-field class="text-primary mt-4">
      <shabic-form-field-label>
        {{ 'common.commercial-number' | translate }}
      </shabic-form-field-label>

      <input
        type="text"
        shabicInput
        placeholder="XXX XXXX XXXX"
        formControlName="ownerId"
      />
    </shabic-form-field>
  </div>

  <div
    class="border border-grey-400 px-4 py-5 mt-3 rounded"
    [class.active]="isActive('manager')"
  >
    <shabic-form-field>
      <shabic-radio-control>
        <input
          shabicInput
          class="peer hidden"
          type="radio"
          name="type"
          id="manager"
          value="manager"
          formControlName="type"
        />

        <shabic-form-field-label forAttribute="manager">
          <span class="normal-case text-sm font-normal text-primary">
            {{ 'company-type.manager' | translate }}
          </span>
        </shabic-form-field-label>
      </shabic-radio-control>
    </shabic-form-field>

    <shabic-form-field class="text-primary mt-4">
      <shabic-form-field-label>
        {{ 'common.commercial-number' | translate }}
      </shabic-form-field-label>

      <input
        type="text"
        shabicInput
        placeholder="XXX XXXX XXXX"
        formControlName="managerId"
      />
    </shabic-form-field>
  </div>

  <div
    class="border border-grey-400 px-4 py-5 mt-3 rounded"
    [class.active]="isActive('custom')"
  >
    <shabic-form-field>
      <shabic-radio-control>
        <input
          shabicInput
          class="peer hidden"
          type="radio"
          name="type"
          id="custom"
          value="custom"
          formControlName="type"
        />

        <shabic-form-field-label forAttribute="custom">
          <span class="normal-case text-sm font-normal text-primary">
            {{ 'company-type.custom' | translate }}
          </span>
        </shabic-form-field-label>
      </shabic-radio-control>
    </shabic-form-field>

    <shabic-form-field>
      <shabic-form-field-label forAttribute="custom">
        <span class="normal-case text-sm font-normal text-primary">
          {{ 'company-type.custom' | translate }}
        </span>
      </shabic-form-field-label>
    </shabic-form-field>

    <ul class="mt-3">
      <li
        *ngFor="let control of fileControls"
        class="border-t border-grey-400 first:border-t-0 py-1"
      >
        <shabic-form-field>
          <shabic-file-control>
            <input
              type="file"
              shabicInput
              class="hidden"
              name=""
              [formControl]="control"
            />
          </shabic-file-control>
        </shabic-form-field>
      </li>
    </ul>
  </div>

  <p class="mt-5.5">
    <button
      shabicButton="primary"
      class="w-full gap-1"
      type="submit"
      [disabled]="formGroup.invalid || (loading$ | async)"
    >
      {{ 'button.submit-for-approval' | translate }}

      <shabic-spinner *ngIf="loading$ | async"></shabic-spinner>
    </button>
  </p>

  <p class="text-center mt-7">
    <a class="font-semibold text-primary text-sm hover:underline" href="">
      {{ 'button.verify-later' | translate }}
    </a>
  </p>
</form>
