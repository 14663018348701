<ng-content select="shabic-form-field-label"></ng-content>

<div class="relative">
  <ng-content select="input"></ng-content>

  <ng-content></ng-content>

  <div class="flex absolute ltr:right-3 top-2/4 -translate-y-2/4 rtl:left-3">
    <ng-content select="shabic-form-field-icon"></ng-content>

    <ng-content select="[addon]"></ng-content>
  </div>
</div>

<shabic-form-field-errors
  [errors]="errors$ | async"
  *ngIf="(errors$ | async) && hideErrors === false"
></shabic-form-field-errors>
