import { ChangeDetectionStrategy, Component, Optional } from '@angular/core';
import { SearchService } from '../../services';

@Component({
  selector: 'shabic-search-popover',
  templateUrl: './search-popover.component.html',
  styleUrls: ['./search-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchPopoverComponent {
  constructor(@Optional() private searchService: SearchService) {}

  onSearch() {
    this.searchService?.onSearch();
  }

  onClose() {
    this.searchService?.closePopover();
  }
}
