import { Injectable } from '@angular/core';
import { AppEvent, EventBrokerService } from '@shabic/event-broker';
import { SnackBarEvent } from './snack-bar.service';

@Injectable({
  providedIn: 'root',
})
export class ClipboardService {
  constructor(private eventBrokerService: EventBrokerService) {}

  async copy(text: string) {
    try {
      await navigator.clipboard.writeText(text);
      const event = new SnackBarEvent('message.content-copied', 'info');
      this.eventBrokerService.emit(new AppEvent(event.type, event.payload));
    } catch (err) {
      const event = new SnackBarEvent('error.failed-to-copy', 'error');
      this.eventBrokerService.emit(new AppEvent(event.type, event.payload));
    }
  }
}
