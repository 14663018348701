<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    opacity="0.4"
    d="M2 6.447C2 3.996 4.03024 2 6.52453 2H11.4856C13.9748 2 16 3.99 16 6.437V17.553C16 20.005 13.9698 22 11.4744 22H6.51537C4.02515 22 2 20.01 2 17.563V16.623V6.447Z"
    fill="currentColor"
    fill-opacity="0.75"
  />
  <path
    d="M9.01991 11.4541L11.8657 8.54506C12.1598 8.24506 12.6331 8.24506 12.9263 8.54706C13.2185 8.84906 13.2175 9.33606 12.9243 9.63606L11.3651 11.2291H12.8601H21.2504C21.6643 11.2291 22.0003 11.5741 22.0003 11.9991C22.0003 12.4251 21.6643 12.7691 21.2504 12.7691H11.3651L12.9243 14.3621C13.2175 14.6621 13.2185 15.1491 12.9263 15.4511C12.7792 15.6021 12.5874 15.6781 12.3945 15.6781C12.2036 15.6781 12.0118 15.6021 11.8657 15.4531L9.01991 12.5451C8.87869 12.4001 8.79883 12.2041 8.79883 11.9991C8.79883 11.7951 8.87869 11.5991 9.01991 11.4541"
    fill="currentColor"
    fill-opacity="0.75"
  />
</svg>
