<shabic-animate>
  <shabic-headline variant="h3">
    <span class="desktop:text-2xl">
      {{ 'profile.change-password' | translate }}
    </span>
  </shabic-headline>

  <form
    class="mt-4 desktop:mt-5.5 desktop:max-w-[400px]"
    [formGroup]="formGroup"
    (ngSubmit)="onChangePassword()"
    autocomplete="off"
  >
    <shabic-form-field class="text-primary">
      <shabic-form-field-label forAttribute="current-password">
        {{ 'common.current-password' | translate }}
      </shabic-form-field-label>

      <input
        type="password"
        class="border border-grey-300"
        formControlName="oldPassword"
        shabicInput
        id="current-password"
      />
    </shabic-form-field>

    <shabic-form-field class="text-primary mt-4">
      <shabic-form-field-label forAttribute="new-password">
        {{ 'common.new-password' | translate }}
      </shabic-form-field-label>

      <input
        type="password"
        class="border border-grey-300"
        shabicInput
        formControlName="password"
        id="new-password"
      />
    </shabic-form-field>

    <shabic-form-field class="text-primary mt-4">
      <shabic-form-field-label forAttribute="confirm-new-password">
        {{ 'common.confirm-new-password' | translate }}
      </shabic-form-field-label>

      <input
        type="password"
        class="border border-grey-300"
        shabicInput
        formControlName="confirmPassword"
        id="confirm-new-password"
      />
    </shabic-form-field>

    <shabic-form-field-errors
      [errors]="formGroup.errors"
    ></shabic-form-field-errors>

    <button
      shabicButton="primary"
      class="w-full mt-4 desktop:mt-5.5 gap-1"
      type="submit"
      [disabled]="formGroup.invalid"
    >
      {{ 'button.save-new-password' | translate }}

      <shabic-spinner *ngIf="loading$ | async"></shabic-spinner>
    </button>
  </form>
</shabic-animate>
