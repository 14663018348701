<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M12 22.6648C11.6583 22.6648 11.3289 22.541 11.0722 22.3161C10.1026 21.4683 9.16789 20.6717 8.34318 19.9689L8.33897 19.9652C5.92107 17.9048 3.83312 16.1253 2.38037 14.3725C0.756407 12.4129 0 10.5549 0 8.52519C0 6.55315 0.676207 4.73382 1.90393 3.4021C3.1463 2.05463 4.851 1.3125 6.70458 1.3125C8.08995 1.3125 9.35869 1.75049 10.4754 2.61419C11.039 3.05017 11.5499 3.58374 12 4.20611C12.4502 3.58374 12.9609 3.05017 13.5247 2.61419C14.6415 1.75049 15.9102 1.3125 17.2956 1.3125C19.149 1.3125 20.8538 2.05463 22.0962 3.4021C23.3239 4.73382 24 6.55315 24 8.52519C24 10.5549 23.2437 12.4129 21.6198 14.3723C20.167 16.1253 18.0793 17.9046 15.6617 19.9649C14.8355 20.6687 13.8993 21.4667 12.9276 22.3165C12.6711 22.541 12.3415 22.6648 12 22.6648ZM6.70458 2.71838C5.24834 2.71838 3.91058 3.29956 2.93737 4.35497C1.9497 5.42632 1.4057 6.90728 1.4057 8.52519C1.4057 10.2323 2.04016 11.759 3.4627 13.4754C4.83736 15.1342 6.88192 16.8767 9.24923 18.8941L9.25065 18.8954L9.25505 18.899C10.0829 19.6045 11.0213 20.4043 11.998 21.2583C12.9805 20.4027 13.9204 19.6016 14.7499 18.895C14.752 18.8932 14.7541 18.8913 14.7563 18.8895C17.1213 16.8738 19.1637 15.1331 20.5374 13.4754C21.9598 11.759 22.5943 10.2323 22.5943 8.52519C22.5943 6.90728 22.0503 5.42632 21.0626 4.35497C20.0896 3.29956 18.7516 2.71838 17.2956 2.71838C16.2288 2.71838 15.2494 3.05749 14.3846 3.72619C13.6139 4.32238 13.077 5.07604 12.7622 5.60339C12.6004 5.87456 12.3155 6 12 6C11.6845 6 11.3996 5.87456 11.2377 5.60339C10.9231 5.07604 10.3863 4.32238 9.6154 3.72619C8.75059 3.05749 7.77116 2.71838 6.70458 2.71838Z"
    fill="currentColor"
  />
  <path
    d="M6.70458 2.71838C5.24834 2.71838 3.91058 3.29956 2.93737 4.35497C1.9497 5.42632 1.4057 6.90728 1.4057 8.52519C1.4057 10.2323 2.04016 11.759 3.4627 13.4754C4.83736 15.1342 6.88192 16.8767 9.24923 18.8941L9.25065 18.8954L9.25505 18.899C10.0829 19.6045 11.0213 20.4043 11.998 21.2583C12.9805 20.4027 13.9204 19.6016 14.7499 18.895L14.7563 18.8895C17.1213 16.8738 19.1637 15.1331 20.5374 13.4754C21.9598 11.759 22.5943 10.2323 22.5943 8.52519C22.5943 6.90728 22.0503 5.42632 21.0626 4.35497C20.0896 3.29956 18.7516 2.71838 17.2956 2.71838C16.2288 2.71838 15.2494 3.05749 14.3846 3.72619C13.6139 4.32238 13.077 5.07604 12.7622 5.60339C12.6004 5.87456 12.3155 6 12 6C11.6845 6 11.3996 5.87456 11.2377 5.60339C10.9231 5.07604 10.3863 4.32238 9.6154 3.72619C8.75059 3.05749 7.77116 2.71838 6.70458 2.71838Z"
    fill="white"
    stroke="currentColor"
    stroke-width="1"
  />
</svg>
