<svg
  width="24"
  height="25"
  viewBox="0 0 24 25"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M23.25 4.25003H13.5V2.00003C13.5 1.77653 13.401 1.56503 13.2285 1.42253C13.0575 1.28003 12.828 1.21853 12.612 1.26353L0.612 3.51353C0.2565 3.57953 0 3.88853 0 4.25003V20.75C0 21.11 0.2565 21.4205 0.612 21.4865L12.612 23.7365C12.657 23.7455 12.7035 23.75 12.75 23.75C12.924 23.75 13.0935 23.69 13.2285 23.5775C13.401 23.435 13.5 23.222 13.5 23V20.75H23.25C23.664 20.75 24 20.414 24 20V5.00003C24 4.58603 23.664 4.25003 23.25 4.25003ZM10.314 15.0065C10.587 15.317 10.5555 15.791 10.2435 16.064C10.101 16.1885 9.9255 16.25 9.75 16.25C9.5415 16.25 9.3345 16.163 9.186 15.9935L7.005 13.502L5.0925 15.962C4.944 16.151 4.722 16.25 4.5 16.25C4.3395 16.25 4.1775 16.199 4.0395 16.0925C3.7125 15.8375 3.654 15.3665 3.9075 15.0395L5.9985 12.3515L3.936 9.99354C3.663 9.68303 3.6945 9.20903 4.0065 8.93603C4.317 8.66303 4.7895 8.69303 5.0655 9.00653L6.9375 11.1455L9.159 8.28953C9.414 7.96403 9.885 7.90403 10.212 8.15903C10.539 8.41253 10.5975 8.88353 10.3425 9.21203L7.9425 12.296L10.314 15.0065ZM22.5 19.25H13.5V17.75H15.75C16.164 17.75 16.5 17.414 16.5 17C16.5 16.586 16.164 16.25 15.75 16.25H13.5V14.75H15.75C16.164 14.75 16.5 14.414 16.5 14C16.5 13.586 16.164 13.25 15.75 13.25H13.5V11.75H15.75C16.164 11.75 16.5 11.414 16.5 11C16.5 10.586 16.164 10.25 15.75 10.25H13.5V8.75003H15.75C16.164 8.75003 16.5 8.41403 16.5 8.00003C16.5 7.58603 16.164 7.25003 15.75 7.25003H13.5V5.75003H22.5V19.25Z"
    fill="currentColor"
  />
  <path
    d="M20.25 7.25098H18.75C18.336 7.25098 18 7.58698 18 8.00098C18 8.41498 18.336 8.75098 18.75 8.75098H20.25C20.664 8.75098 21 8.41498 21 8.00098C21 7.58698 20.664 7.25098 20.25 7.25098Z"
    fill="currentColor"
  />
  <path
    d="M20.25 10.251H18.75C18.336 10.251 18 10.587 18 11.001C18 11.415 18.336 11.751 18.75 11.751H20.25C20.664 11.751 21 11.415 21 11.001C21 10.587 20.664 10.251 20.25 10.251Z"
    fill="currentColor"
  />
  <path
    d="M20.25 13.251H18.75C18.336 13.251 18 13.587 18 14.001C18 14.415 18.336 14.751 18.75 14.751H20.25C20.664 14.751 21 14.415 21 14.001C21 13.587 20.664 13.251 20.25 13.251Z"
    fill="currentColor"
  />
  <path
    d="M20.25 16.251H18.75C18.336 16.251 18 16.587 18 17.001C18 17.415 18.336 17.751 18.75 17.751H20.25C20.664 17.751 21 17.415 21 17.001C21 16.587 20.664 16.251 20.25 16.251Z"
    fill="currentColor"
  />
</svg>
