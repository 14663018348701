import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import {
  DESKTOP_BREAKPOINT,
  getImageExample,
  LOGO_SOURCE,
  LOGO_SOURCE_PRIMARY,
} from '@shabic/constants';
import { AccountService, CartService } from '@shabic/core';
import { INavItem, NOT_AUTH_NAV } from '@shabic/data';
import { AppEvent, EAppEvent, EventBrokerService } from '@shabic/event-broker';
import {
  Contact,
  Language,
  LoginDialogData,
  ROLE_SUPPLIER,
} from '@shabic/models';
import { getRoute } from '@shabic/utils';
import {
  BehaviorSubject,
  delay,
  filter,
  fromEvent,
  map,
  merge,
  of,
  Subject,
  takeUntil,
} from 'rxjs';

@Component({
  selector: 'shabic-client-layout',
  templateUrl: './client-layout.component.html',
  styleUrls: ['./client-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientLayoutComponent implements OnInit, OnDestroy {
  private readonly _emptyLayout = new BehaviorSubject<boolean>(false);
  private readonly _account = new BehaviorSubject<Contact | null>(null);
  private readonly _route = new BehaviorSubject<INavItem | null>(null);
  private readonly _destroy = new Subject<void>();
  private _sidebarCollapsed = new BehaviorSubject<boolean>(false);

  readonly ROLE_SUPPLIER = ROLE_SUPPLIER;
  readonly cartCount$ = this.cartService.cartCount$;
  readonly logoSource = LOGO_SOURCE;
  readonly logoSourcePrimary = LOGO_SOURCE_PRIMARY;
  readonly avatarSource = getImageExample();
  readonly navigation = NOT_AUTH_NAV;
  readonly account$ = this._account.asObservable();
  readonly route$ = this._route.asObservable();
  readonly sidebarCollapsed$ = this._sidebarCollapsed.asObservable();
  readonly emptyLayout$ = this._emptyLayout.asObservable();
  readonly logoSize$ = this.sidebarCollapsed$.pipe(
    map(val => (val ? 'md' : 'lg'))
  );
  readonly opened$ = this.sidebarCollapsed$.pipe(
    delay(150),
    map(v => !v)
  );

  constructor(
    private eventBrokerService: EventBrokerService,
    private cartService: CartService,
    private accountService: AccountService,
    private router: Router
  ) {}

  ngOnInit() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe(e => {
        if (e instanceof NavigationStart) {
          if (
            (e.url.includes('/sign-up') ||
              e.url.includes('/forgot-password')) &&
            !this._emptyLayout.value
          ) {
            this._emptyLayout.next(true);
          } else if (this._emptyLayout.value) {
            this._emptyLayout.next(false);
          }
        }
      });

    this.getAccount();
    this.subscribeOnEvents();
    this.subscribeOnResize();

    merge(
      of(getRoute(this.router.url)),
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(event => {
          if (event instanceof NavigationEnd) {
            return getRoute(event.url);
          } else {
            return null;
          }
        })
      )
    ).subscribe(route => {
      this._route.next(route);
    });
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  onShowNavBar() {
    this.eventBrokerService.emit(
      new AppEvent<null>(EAppEvent.OpenNavigationDialog, null)
    );
  }

  onLogout(): void {
    this.eventBrokerService.emit(new AppEvent(EAppEvent.LogOut, null));
  }

  collapseSibebar() {
    this._sidebarCollapsed.next(true);
  }

  growSibebar() {
    this._sidebarCollapsed.next(false);
  }

  toggleSidebar() {
    this._sidebarCollapsed.next(!this._sidebarCollapsed.value);
  }

  onSignIn() {
    this.eventBrokerService.emit(
      new AppEvent<LoginDialogData>(EAppEvent.OpenLoginDialog, {
        onSuccess: () =>
          this.eventBrokerService.emit(
            new AppEvent<null>(EAppEvent.Authorized, null)
          ),
      })
    );
  }

  private subscribeOnEvents() {
    this.eventBrokerService.events$
      .pipe(
        filter(event =>
          [EAppEvent.Authorized, EAppEvent.LogOut].includes(event.type)
        ),
        takeUntil(this._destroy)
      )
      .subscribe(event => {
        if (event.type === EAppEvent.LogOut) {
          this._account.next(null);
        } else {
          this.getAccount({ updateLanguage: true });
          this.eventBrokerService.emit(
            new AppEvent(EAppEvent.UpdateCartCount, null)
          );
        }
      });
  }

  private getAccount(config = { updateLanguage: false }) {
    this.accountService.get().subscribe(response => {
      if (response.payload instanceof Contact) {
        if (config.updateLanguage) {
          this.eventBrokerService.emit(
            new AppEvent<Language>(
              EAppEvent.ChangeLanguage,
              response.payload.langKey as Language
            )
          );
        }

        this._account.next(response.payload);
      }
    });
  }

  private subscribeOnResize() {
    this.checkWindowWidth(window);

    fromEvent(window, 'resize')
      .pipe(takeUntil(this._destroy))
      .subscribe(event => {
        if (event.target instanceof Window) {
          this.checkWindowWidth(event.target);
        }
      });
  }

  private checkWindowWidth(target: Window) {
    const innerWidth = target.innerWidth;
    const breakpoint = DESKTOP_BREAKPOINT + 200;
    const isCollapsed = this._sidebarCollapsed.value;

    if (!isCollapsed && innerWidth < breakpoint) {
      this.collapseSibebar();
    }

    if (isCollapsed && innerWidth > breakpoint) {
      this.growSibebar();
    }
  }
}
