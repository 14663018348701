import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ApiClient,
  ApiErrorResponse,
  ApiResponse,
  ENV,
  IEnvironment,
  ILineChartResponse,
  Time,
} from '@shabic/models';
import { catchError, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReportService extends ApiClient {
  override readonly url = `/reports`;

  constructor(private http: HttpClient, @Inject(ENV) env: IEnvironment) {
    super(env);
  }

  getUserActivityReport(
    from?: Time | null,
    to?: Time | null,
    unit: 'DAYS' | 'HOURS' = 'HOURS'
  ) {
    const fromObject: Record<string, string | number> = {
      unit,
      amount: 1,
    };

    from ? (fromObject['from'] = from.toIsoString()) : undefined;
    to ? (fromObject['to'] = to.toIsoString()) : undefined;

    const params = new HttpParams({ fromObject });

    return this.http
      .get<ILineChartResponse>(this.getEndpoint('/user/activity'), { params })
      .pipe(
        map(response => new ApiResponse(response)),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }

  getCompanyReport(from?: Time | null, to?: Time | null) {
    const fromObject: Record<string, string> = {};

    from ? (fromObject['from'] = from.toIsoString()) : undefined;
    to ? (fromObject['to'] = to.toIsoString()) : undefined;

    const params = new HttpParams({ fromObject });

    return this.http
      .get<Record<'true' | 'false', number>>(
        this.getEndpoint('/user/company'),
        { params }
      )
      .pipe(
        map(response => new ApiResponse(response)),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }
}
