<shabic-animate>
  <shabic-headline variant="h3">
    <span class="desktop:text-2xl">
      {{ 'navigation.marketing' | translate }}
    </span>
  </shabic-headline>

  <ng-container *ngIf="promotionsLoading$ | async; else form">
    <ng-container *ngTemplateOutlet="spinner"></ng-container>
  </ng-container>

  <ng-template #form>
    <form
      [formGroup]="formGroup"
      class="grid desktop:max-w-[400px]"
      (ngSubmit)="onSubmit()"
    >
      <ng-container formArrayName="types">
        <ng-container
          *ngFor="let typeForm of types.controls; let index = index"
        >
          <div [formGroup]="typeForm">
            <div class="mt-4 desktop:mt-5.5">
              <shabic-group-title
                [name]="
                  typeForm.get('type')?.value || ''
                    | withPrefix: 'promotion'
                    | translate
                "
                icon="edit"
              ></shabic-group-title>
            </div>

            <shabic-form-field class="mt-5">
              <shabic-image-control
                size="lg"
                [value]="typeForm.get('mediaUrl')?.value"
                (changed)="patchValue(typeForm, 'mediaId', $event)"
              >
                <input
                  type="file"
                  shabicInput
                  hidden
                  name=""
                  class="peer"
                  accept="image/png,image/jpeg,image/jpg"
                />
              </shabic-image-control>
            </shabic-form-field>

            <shabic-form-field class="mt-3">
              <shabic-form-field-label forAttribute="description">
                {{ 'common.link' | translate }}
              </shabic-form-field-label>

              <textarea
                shabicInput
                class="resize-none border border-grey-300"
                rows="6"
                id="description"
                formControlName="description"
              ></textarea>
            </shabic-form-field>
          </div>
        </ng-container>
      </ng-container>

      <button
        shabicButton="primary"
        class="w-full mt-3 gap-1"
        type="submit"
        [disabled]="formGroup.invalid || (loading$ | async)"
      >
        {{ 'button.save' | translate }}

        <shabic-spinner *ngIf="loading$ | async"></shabic-spinner>
      </button>
    </form>
  </ng-template>
</shabic-animate>

<ng-template #spinner>
  <div class="text-grey flex justify-center py-5 desktop:max-w-[400px]">
    <shabic-spinner size="xl"></shabic-spinner>
  </div>
</ng-template>
