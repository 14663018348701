import { ExcelProduct, IProductResponse, Product } from '@shabic/models';
import { CompanyPayload } from './company';

export const PRODUCT_EXAMPLE: IProductResponse = {
  nameFirst: 'Product #1 EN',
  nameSecond: 'Product #1 Ar',
  category: {
    name: 'Agriculture, Forestry & Fishing',
    id: 1,
  },
  status: 'ACTIVE',
  id: '1',
  barCode: '1234567890',
  company: CompanyPayload,
  brand: 'Brand name',
  color: 'blue',
  attributes: {
    weight: 1.57,
    typeOfPackaging: 'Retail',
    dimension: '57.6 x 61 x 180.5',
  },
  currencyFirst: 'USD',
};

export function getProductExample(): Product {
  const product = new Product(PRODUCT_EXAMPLE);

  return product;
}

export interface IProductColumn {
  key: string;
  label: string;
  value: (product: ExcelProduct) => unknown;
  bold?: boolean;
}

export const productColumns: IProductColumn[] = [
  {
    key: 'nameFirst',
    label: 'common.product-name-en',
    value: payload => payload.product.name.get('en'),
    bold: true,
  },
  {
    key: 'nameSecond',
    label: 'common.product-name-ar',
    value: payload => payload.product.name.get('ar'),
    bold: true,
  },
  {
    key: 'descriptionFirst',
    label: 'common.description-en',
    value: payload => payload.product.description.get('en'),
  },
  {
    key: 'descriptionSecond',
    label: 'common.description-ar',
    value: payload => payload.product.description.get('ar'),
  },
  {
    key: 'primaryPacking',
    label: 'common.product-packing-primary',
    value: payload => payload.product.package.primaryUnit,
  },
  {
    key: 'secondaryPacking',
    label: 'common.product-packing-secondary',
    value: payload => payload.product.package.secondaryUnit,
  },
  {
    key: 'quantity',
    label: 'common.product-packing-quantity',
    value: payload => payload.product.package.quantity,
  },
  {
    key: 'measurementsPrimary',
    label: 'common.product-measurements-primary',
    value: payload => payload.product.measurements.primary,
  },
  {
    key: 'measurementsSecondary',
    label: 'common.product-measurements-secondary',
    value: payload => payload.product.measurements.secondary,
  },
  {
    key: 'brand',
    label: 'common.product-brand',
    value: payload => payload.product.brand,
  },
  {
    key: 'code',
    label: 'common.product-code',
    value: payload => payload.product.productCode,
  },
  {
    key: 'barCode',
    label: 'common.bar-code',
    value: payload => payload.product.barCode,
  },
  {
    key: 'govtProductCode',
    label: 'common.product-govt-code',
    value: payload => payload.product.govtProductCode,
  },
  {
    key: 'country',
    label: 'common.country-of-origin',
    value: payload => payload.product.countryOfOrigin,
  },

  {
    key: 'priceFirst',
    label: 'common.product-unit-price-primary',
    value: payload => payload.product.priceFirst,
  },
  {
    key: 'currencyFirst',
    label: 'common.currency-primary',
    value: payload => payload.product.currency,
  },
  {
    key: 'priceSecond',
    label: 'common.product-unit-price-secondary',
    value: payload => payload.product.priceSecond,
  },
  // {
  //   key: 'currency',
  //   label: 'common.currency-secondary',
  //   value: payload => payload.product.currency,
  // },
  {
    key: 'dimension',
    label: 'common.dimension',
    value: payload => payload.product.dimension,
  },
  {
    key: 'weight',
    label: 'common.weight',
    value: payload => payload.product.weight,
  },
  {
    key: 'color',
    label: 'common.color',
    value: payload => payload.product.color,
  },
  {
    key: 'packagingType',
    label: 'common.type-of-packaging',
    value: payload => payload.product.package.type,
  },
  {
    key: 'food',
    label: 'common.is-like-food',
    value: payload => payload.product.food,
  },

  // {
  //   key: 'price',
  //   label: 'common.unit-price',
  //   value: payload => payload.product.price.total,
  // },
  {
    key: 'vat',
    label: 'common.vat',
    value: payload => payload.product.vat,
  },
  // {
  //   key: 'discount',
  //   label: 'common.discount',
  //   value: payload => payload.product.price.discount,
  // },
  // {
  //   key: 'grand',
  //   label: 'common.grand-total',
  //   value: payload => payload.product.price.total,
  // },
  {
    key: 'specialHandling',
    label: 'common.requires-special-handling',
    value: payload => payload.product.isRequiresSpecialHandling,
  },
  {
    key: 'categoryId',
    label: 'common.product-category',
    value: payload => payload.product.category?.name.get('en'),
  },
];

export const products: Product[] = [
  getProductExample(),
  getProductExample(),
  getProductExample(),
  getProductExample(),
  getProductExample(),
  getProductExample(),
];
