import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, IMediaResponse, Media } from '@shabic/models';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

export type FileType =
  | 'USER'
  | 'COMPANY'
  | 'BANK'
  | 'PRODUCT'
  | 'CATEGORY'
  | 'PROMOTION';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  constructor(private http: HttpClient) {}

  upload(domain: string, files: File[], type: FileType) {
    const requests = files.map(file => {
      const body = new FormData();
      if (type) {
        body.append('type', type);
      }
      body.append('file', file);

      return this.http.put<IMediaResponse>(`${domain}/media`, body);
    });

    return forkJoin(requests).pipe(
      map(
        responses =>
          new ApiResponse(responses.map(response => new Media(response)))
      )
    );
  }
}
