import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[shabicSearchFilterDivider]',
})
export class SearchFilterDividerDirective {
  @HostBinding('class')
  get elementClass(): string {
    return 'my-4 text-grey-350';
  }
}
