<ngx-dropzone
  #drop
  (change)="onSelect($event)"
  [accept]="accept"
  [disabled]="disabled || false"
  class="dropzone"
>
  <ng-container *ngIf="!hasFiles || isImage">
    <ng-content></ng-content>
  </ng-container>

  <div class="flex" *ngIf="!isImage">
    <ngx-dropzone-preview
      *ngFor="let f of files$ | async"
      [removable]="true"
      (removed)="onRemove(f)"
    >
      <ngx-dropzone-label>
        <span class="inline-block text-ellipsis overflow-hidden max-w-[120px]">
          {{ f.name }}
        </span>
      </ngx-dropzone-label>
    </ngx-dropzone-preview>
  </div>
</ngx-dropzone>

<div
  *ngIf="isImage"
  class="snap-x scroll-smooth overflow-x-auto flex gap-4 mt-3 empty:mt-0"
>
  <div class="snap-center relative shrink-0" *ngFor="let f of files$ | async">
    <img
      shabicImage
      width="120"
      height="120"
      class="w-12 h-12 border border-grey-350 rounded"
      [shabicImagePreview]="f"
    />

    <button
      type="button"
      class="absolute top-1 right-1 z-1 bg-primary text-white rounded inline-flex items-center justify-center hover:shadow"
      (click)="onRemove(f)"
    >
      <ng-template shabicIcon="close"></ng-template>
    </button>
  </div>
</div>
