import { getImageExample } from '@shabic/constants';
import { Category, ICategoryResponse } from './category.model';
import { Company, ICompanyResponse } from './company.model';
import { Country } from './country.model';
import { CurrencyType } from './currency.model';
import { Dimension } from './dimension.model';
import { ID } from './id.model';
import { Language } from './language.model';
import { Translated } from './translated.model';

export type ProductMediaResponse = {
  id: number;
  url: string;
  order: number;
};

export type ProductStatus =
  | 'ACTIVE'
  | 'UPDATED'
  | 'DISABLED_BY_ADMIN'
  | 'DISABLED_BY_SUPPLIER'
  | 'TO_DELETE';

export const productStatuses: ProductStatus[] = [
  'ACTIVE',
  'UPDATED',
  'DISABLED_BY_ADMIN',
  'DISABLED_BY_SUPPLIER',
  // 'TO_DELETE',
];

export interface IProduct {
  imageSource?: string;
  name?: {
    en: string;
    ar: string;
  };
  selected?: boolean;
  id?: ID;
  link?: string;
  price?: number;
  unit?: string;
  productCode?: string;
  barCode?: string;
  governmentCode?: string;
  countryOfOrigin?: string;
  description?: Record<Language, string>;
  govtProductCode?: string;
  category?: ICategoryResponse;
  status?: ProductStatus;

  vat?: number;
  priceFirst?: number;
  priceSecond?: number;
  code?: string;
  specialHandling?: boolean;
  food?: boolean;
  categoryId?: number;
  barCodeUuid?: string;
  company?: ICompanyResponse;

  attributes?: {
    brand?: string;
    dimension?: string;
    weight?: number;
    color?: string;
    typeOfPackaging?: string;
    packing?: string;
    measurement?: string;
    measurementSecond?: string;
    packingSecond?: string;
    packingQuantity?: number;
  };
}

export interface IProductPayload {
  id?: ID;
  nameFirst?: string;
  nameSecond?: string;
  descriptionFirst?: string;
  descriptionSecond?: string;
  code?: string;
  barCode?: string;
  govtProductCode?: string;
  country?: string;
  priceFirst?: number;
  currencyFirst: CurrencyType;
  priceSecond?: number;
  currencySecond?: CurrencyType;
  vat?: number;
  food?: boolean;
  specialHandling?: boolean;
  categoryId?: number;
  mediaId?: File[];
  color?: string;

  // Move to attributes in response
  brand?: string;
  dimension?: string;
  weight?: number;
  typeOfPackaging?: string;
  packing?: string;
  measurement?: string;
  measurementSecond?: string;
  packingQuantity?: number;
  packingSecond?: string;
}

type MovedToAttributes =
  | 'dimension'
  | 'weight'
  | 'typeOfPackaging'
  | 'packing'
  | 'measurement'
  | 'measurementSecond'
  | 'packingQuantity'
  | 'packingSecond';

export interface IProductResponse
  extends Omit<IProductPayload, MovedToAttributes> {
  categoryFlat?: ICategoryResponse[];
  category?: ICategoryResponse;
  barCodeUuid?: string;
  company?: ICompanyResponse;
  status?: ProductStatus;
  brand?: string;
  medias?: ProductMediaResponse[];
  color?: string;
  attributes?: {
    weight?: number;
    packing?: string;
    dimension?: string;
    measurement?: string;
    typeOfPackaging?: string;
    measurementSecond?: string;
    packingSecond?: string;
    packingQuantity?: number;
  };

  // Removable
  isTopSelection?: boolean;
}

export type ProductSearchContent = Record<string, IProductResponse[]>[];

type ExcelErrors = {
  field: string;
  description: string | string[];
};

export class ExcelProduct {
  private errors: ExcelErrors[];
  product: Product;
  incorrect: boolean;

  constructor(product: Product, errors?: ExcelErrors[]) {
    this.product = product;
    this.errors = errors || [];
    this.incorrect = this.errors.length > 0;
  }

  hasError(key: string) {
    return this.errors.some(el => el.field === key);
  }

  getErrors(key: string): string[] {
    const error = this.errors.find(el => el.field === key);

    if (!error) {
      return [];
    }

    if (typeof error.description === 'string') {
      return [error.description];
    } else {
      return error.description;
    }
  }
}

export class ExcelProductError {
  incorrect = true;
  constructor(public error: string) {}
}

export class Product {
  private _storageValue: IProductResponse;
  private _medias?: ProductMediaResponse[];

  id?: ID;
  images: string[] = [];
  description: Translated<string>;
  name: Translated<string>;
  brand?: string;
  productCode?: string;
  barCode?: string;
  govtProductCode?: string;
  countryOfOrigin?: string;
  category?: Category;
  weight?: number;
  color?: string;
  package: {
    type?: string;
    primaryUnit?: string;
    secondaryUnit?: string;
    quantity?: number;
  };
  dimension?: Dimension;
  supplier: Company | null;
  quantity?: number;
  measurements: {
    primary?: string;
    secondary?: string;
  };
  food: boolean;
  isRequiresSpecialHandling: boolean;
  barCodeUuid: string;
  priceFirst: number;
  priceSecond: number;
  vat?: number;
  currency: CurrencyType;
  status?: ProductStatus;
  isTopSelection: boolean;
  country?: Country;

  constructor(product: IProductResponse, categories: Category[] = []) {
    this.id = product.id;
    this.description = new Translated<string>(
      Translated.prepareConfig({
        en: product.descriptionFirst || '',
        ar: product.descriptionSecond || '',
      })
    );
    this.name = new Translated<string>(
      Translated.prepareConfig({
        en: product.nameFirst || '',
        ar: product.nameSecond || '',
      })
    );
    this.images = product.medias?.length
      ? product.medias
          ?.sort((prev, next) => (prev.order > next.order ? 1 : -1))
          .map(el => el.url)
      : [getImageExample()];
    this.brand = product.brand;
    this.productCode = product.code;
    this.barCode = product.barCode;
    this.govtProductCode = product.govtProductCode;
    this.countryOfOrigin = product.country;
    this.weight = product.attributes?.weight || 0;
    this.color = product?.color;
    this.package = {
      type: product.attributes?.typeOfPackaging,
      primaryUnit: product.attributes?.packing,
      secondaryUnit: product.attributes?.packingSecond,
      quantity: product.attributes?.packingQuantity,
    };
    this.dimension = this.parseDimension(product.attributes?.dimension);
    this.supplier = product.company ? new Company(product.company) : null;
    this.measurements = {
      primary: product.attributes?.measurement,
      secondary: product.attributes?.measurementSecond,
    };
    this.food = product.food || false;
    this.isRequiresSpecialHandling = product.specialHandling || false;
    this.priceFirst = product.priceFirst || 0;
    this.priceSecond = product.priceSecond || 0;
    this.barCodeUuid = product.barCodeUuid || '';
    this._storageValue = product;
    this.vat = product.vat;
    this.currency = product.currencyFirst;
    this.status = product.status;
    this._medias = product.medias;
    this.isTopSelection = product.isTopSelection || false;
    this.country = Country.parse(product.country);
    this.setCategory(product, categories);
  }

  get storageValue() {
    return JSON.parse(JSON.stringify(this._storageValue));
  }

  get totalPrice() {
    return (this.priceFirst + (this.vat || 0) - 0) * 1;
  }

  getProductPayload(): IProductPayload {
    return {
      nameFirst: this.name.get('en'),
      nameSecond: this.name.get('ar'),
      descriptionFirst: this.description.get('en'),
      descriptionSecond: this.description.get('ar'),
      brand: this.brand,
      code: this.productCode,
      barCode: this.barCode,
      govtProductCode: this.govtProductCode,
      country: this.countryOfOrigin,
      priceFirst: this.priceFirst,
      priceSecond: this.priceSecond,
      vat: this.vat,
      food: this.food,
      specialHandling: this.isRequiresSpecialHandling,
      categoryId: this.category?.id,
      dimension: this.dimension?.toString(),
      weight: this.weight,
      color: this.color,
      typeOfPackaging: this.package.type,
      packing: this.package.primaryUnit,
      packingSecond: this.package.secondaryUnit,
      packingQuantity: this.package.quantity,
      measurement: this.measurements.primary,
      measurementSecond: this.measurements.secondary,
      currencyFirst: this.currency,
      currencySecond: this.currency,
    };
  }

  setCategory(product: IProductResponse, categories?: Category[]): void {
    if (product.category) {
      this.category = this.getLastCategory(
        Category.fromResponse(product.category)
      );
    } else if ('categoryId' in product && categories) {
      this.category = categories.find(el => el.id === product.categoryId);
    }
  }

  get isDeactivated() {
    return (
      this.status === 'DISABLED_BY_ADMIN' ||
      this.status === 'DISABLED_BY_SUPPLIER' ||
      this.status === 'UPDATED'
    );
  }

  isStatus(status: ProductStatus) {
    return status === this.status;
  }

  getMediaByUrl(url: string) {
    return this._medias?.find(el => el.url === url);
  }

  getMedias() {
    return [...(this._medias || [])].map(el => el.url);
  }

  getExistMedias(removed: number[] = []) {
    return this._medias?.filter(el => !removed.includes(el.id));
  }

  private parseNumberValue(value: string = ''): number {
    return isNaN(parseFloat(value)) ? 0 : parseFloat(value);
  }

  private parseDimension(value?: string): Dimension | undefined {
    if (typeof value === 'string' && !!value) {
      const dimensionArr = value.split('x');

      return new Dimension(dimensionArr[0], dimensionArr[1], dimensionArr[2]);
    }

    return;
  }

  private getLastCategory(category: Category): Category {
    if (category.subCategories.length) {
      return this.getLastCategory(category.subCategories[0]);
    } else {
      return category;
    }
  }
}

export class ListProduct extends Product {
  suppliers: Product[];

  constructor(product: IProductResponse, suppliers: IProductResponse[] = []) {
    super(product);

    this.suppliers = suppliers.map(product => new Product(product));
  }
}
