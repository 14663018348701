import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginForgotPasswordFormComponent } from './login-forgot-password-form/login-forgot-password-form.component';
import { LoginCreateNewPasswordFormComponent } from './login-create-new-password-form/login-create-new-password-form.component';
import { UiModule } from '@shabic/ui';
import { TranslateModule } from '@ngx-translate/core';
import { FormControlModule } from '@shabic/form-control';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    LoginForgotPasswordFormComponent,
    LoginCreateNewPasswordFormComponent,
  ],
  imports: [
    CommonModule,
    UiModule,
    TranslateModule,
    FormControlModule,
    ReactiveFormsModule,
  ],
  exports: [
    LoginForgotPasswordFormComponent,
    LoginCreateNewPasswordFormComponent,
  ],
})
export class FormsModule {}
