import { getImageExample } from '@shabic/constants';
import { SortOption } from './filter.model';
import { Time } from './time.model';
import { Translated } from './translated.model';

export type BankStatus = 'ACTIVE' | 'DISABLED' | 'DELETED';
export type BankAccountStatus = 'ACTIVE' | 'DISABLED';

export const bankStatuses: SortOption<string>[] = [
  { key: 'ACTIVE', label: 'status.ACTIVE' },
  { key: 'DISABLED', label: 'status.DISABLED' },
  { key: 'DELETED', label: 'status.DELETED' },
];

export const bankAccountStatuses: SortOption<string>[] = [
  { key: 'ACTIVE', label: 'status.ACTIVE' },
  { key: 'DISABLED', label: 'status.DISABLED' },
];

export interface IBankPayload {
  mediaId: File[];
  status: BankStatus;
  name: string;
  accountNumber: string;
}

export interface IBankResponse extends Omit<IBankPayload, 'mediaId'> {
  id: number;
  mediaUrl: string;
}

export class Bank {
  id: number;
  name: Translated<string>;
  logo: string;
  status: BankStatus;
  accountNumber: string;

  static get unknown() {
    return new Bank({
      id: NaN,
      mediaUrl: getImageExample(),
      name: 'Unknown',
      status: 'DISABLED',
      accountNumber: '',
    });
  }

  constructor(payload: IBankResponse) {
    this.id = payload.id;
    this.name = new Translated(
      Translated.prepareConfig({
        en: payload.name,
        ar: payload.name,
      })
    );
    this.status = payload.status;
    this.accountNumber = payload.accountNumber;
    this.logo = payload.mediaUrl || getImageExample();
  }

  get active(): boolean {
    return this.status === 'ACTIVE';
  }

  get deleted(): boolean {
    return this.status === 'DELETED';
  }

  get disabled(): boolean {
    return this.status === 'DISABLED';
  }
}

export interface IBankAccountPayload {
  status: BankAccountStatus;
  name: string;
  // accountNumber: string;
  iban: string;
  bankId: string | null;
}

export interface IBankAccountStatementParams {
  accountId: string;
  fromDate: string;
  toDate: string;
  [key: string]: any;
}

export interface IBankAccountResponse
  extends Omit<IBankAccountPayload, 'bankId'> {
  id: string;
  availableBalance: number;
  ledgerBalance: number;
  lastBalanceUpdateDate: string;
  bank: IBankResponse;
}

export class BankAccount {
  bank: Bank;
  id: string;
  name: string;
  // accountNumber: string;
  iban: string;
  availableBalance: number;
  ledgerBalance: number;
  lastBalanceUpdateDate: Time;
  status: BankAccountStatus;

  constructor(payload: IBankAccountResponse) {
    this.bank = new Bank(payload.bank);
    this.iban = payload.iban;
    // this.accountNumber = payload.accountNumber;
    this.status = payload.status;
    this.lastBalanceUpdateDate = new Time(payload.lastBalanceUpdateDate);
    this.ledgerBalance = payload.ledgerBalance;
    this.availableBalance = payload.availableBalance;
    this.id = payload.id;
    this.name = payload.name;
  }

  get isActive() {
    return this.status === 'ACTIVE';
  }

  get isDiasable() {
    return this.status === 'DISABLED';
  }
}

export interface IBankAccountStatementResponse {
  id: string;
  date: string;
  description: string;
  credit: number;
  debit: number;
  balance: number;
}

export class BankAccountStatement {
  no: string;
  createdAt: Time;
  description: string;
  credit: number;
  debit: number;
  balance: number;

  constructor(payload: IBankAccountStatementResponse) {
    this.createdAt = new Time(payload.date);
    this.description = payload.description;
    this.credit = payload.credit;
    this.debit = payload.debit;
    this.balance = payload.balance;
    this.no = payload.id;
  }
}
