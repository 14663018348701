<div class="flex flex-col items-center gap-3 pt-3">
  <span class="text-grey text-xs font-medium">
    {{
      'status.available-in-suppliers'
        | translate: { count: product.suppliers.length }
    }}
  </span>

  <button
    type="button"
    class="whitespace-nowrap"
    shabicButton="primary"
    (click)="onRequest()"
  >
    {{ 'button.request-for-quotation' | translate }}
  </button>
</div>
