import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { SearchService } from './services/search.service';

@Component({
  selector: 'shabic-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SearchService],
})
export class SearchComponent implements OnInit, OnDestroy {
  private _destroy = new Subject<void>();

  @Input() query?: string;
  @Input() page?: number[] | number = 0;
  @Input() pages = 0;
  @Input() total = 0;
  @Input() hasAside = true;
  @Input() hasPagination?: Nullable<boolean> = false;
  @Input() hasLoadMore?: Nullable<boolean> = false;
  @Input() loadMoreLoading?: Nullable<boolean> = false;
  @Input() amountLabel = 'status.products-amount';

  @Output() search = new EventEmitter<string>();
  @Output() filter = new EventEmitter<void>();
  @Output() changePage = new EventEmitter<number>();
  @Output() loadMore = new EventEmitter<void>();

  constructor(private searchService: SearchService) {}

  ngOnInit(): void {
    this.searchService.search$.pipe(takeUntil(this._destroy)).subscribe(() => {
      this.filter.emit();
    });
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  onSearch(query: string) {
    this.search.emit(query);
  }

  onChangePage(page: number): void {
    this.changePage.emit(page);
  }

  onLoadMore() {
    this.loadMore.emit();
  }

  closeSearchPopover() {
    this.searchService.closePopover();
  }
}
