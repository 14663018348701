import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DEFAULT_COUNTRY } from '@shabic/constants';
import {
  ApiErrorResponse,
  ApiMarketplace,
  ApiResponse,
  Company,
  CustomerAddress,
  ENV,
  ICustomerAddressPayload,
  ICustomerAddressResponse,
  IEnvironment,
  IListParams,
  IListResponse,
  isErrorResponse,
  List,
} from '@shabic/models';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CompanyService } from './company.service';

@Injectable({
  providedIn: 'root',
})
export class ShippingService extends ApiMarketplace {
  override url = '/shipping/address';

  constructor(
    private http: HttpClient,
    private companyService: CompanyService,
    @Inject(ENV) env: IEnvironment
  ) {
    super(env);
  }

  getAddresses(params: IListParams) {
    const query = new HttpParams({
      fromObject: params || {},
    });

    return this.http
      .get<IListResponse<ICustomerAddressResponse[]>>(this.apiModelUrl, {
        params: query,
      })
      .pipe(
        switchMap(response => {
          if (response.totalElements > 0) {
            return of(
              new List(response, content =>
                content.map(el => new CustomerAddress(el))
              )
            );
          } else {
            return this.createDefaultAddress(params);
          }
        }),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }

  create(payload: ICustomerAddressPayload) {
    return this.http
      .post<ICustomerAddressResponse>(this.apiModelUrl, payload)
      .pipe(
        map(response => new ApiResponse(new CustomerAddress(response))),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }

  createDefaultAddress(
    params: IListParams
  ): Observable<
    | List<CustomerAddress, ICustomerAddressResponse>
    | ApiResponse<ApiErrorResponse>
  > {
    return this.companyService.getMyCompany().pipe(
      switchMap(response => {
        if (response.payload instanceof Company) {
          return this.create({
            city: response.payload.address.city,
            country: DEFAULT_COUNTRY,
            poBox: response.payload.address.poBox,
            street: response.payload.address.street,
            zipCode: response.payload.address.zipCode,
          }).pipe(
            switchMap(response => {
              if (isErrorResponse(response)) {
                return of(
                  new List(this.defaultResponse(), content =>
                    content.map(el => new CustomerAddress(el))
                  )
                );
              }

              return this.getAddresses(params);
            })
          );
        }

        return of(
          new List(this.defaultResponse(), content =>
            content.map(el => new CustomerAddress(el))
          )
        );
      })
    );
  }

  removeAddress(address: CustomerAddress) {
    return this.http.delete<unknown>(this.getEndpoint(`/${address.id}`)).pipe(
      map(response => new ApiResponse(response)),
      catchError(response =>
        of(new ApiResponse(new ApiErrorResponse(response)))
      )
    );
  }

  private defaultResponse(): IListResponse<ICustomerAddressResponse[]> {
    return {
      content: [],
      number: 0,
      size: 10,
      totalElements: 0,
      totalPages: 0,
      last: true,
    };
  }
}
