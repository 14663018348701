export interface IMediaResponse {
  id: number;
  type: string;
  url: string;
}

export class Media {
  id: number;
  type: string;
  url: string;

  constructor(payload: IMediaResponse) {
    this.id = payload.id;
    this.type = payload.type;
    this.url = payload.url;
  }
}
