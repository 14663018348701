import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'shabic-icon-star',
  templateUrl: './icon-star.component.html',
  styleUrls: ['./icon-star.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconStarComponent {
  @Input() size?: 'lg' | 'md' | 'xs';
}
