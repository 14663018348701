import { Inject, Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { ADMIN_APP, TOKEN_KEY } from '@shabic/constants';
import { ApiToken, ENV, IEnvironment, ROLE_SUPPLIER } from '@shabic/models';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class SupplierGuard implements CanActivate {
  constructor(
    private storageService: StorageService,
    private router: Router,
    @Inject(ENV) private env: IEnvironment
  ) {}

  get redirectUrl() {
    return this.env.app === ADMIN_APP
      ? this.router.parseUrl('/sign-in')
      : this.router.parseUrl('/');
  }

  canActivate(): boolean | UrlTree {
    return this.isSupplier() || this.redirectUrl;
  }

  canLoad(): boolean | UrlTree {
    return this.isSupplier() || this.redirectUrl;
  }

  private isSupplier() {
    const token = new ApiToken(
      this.storageService.retrieve<string>(TOKEN_KEY, true)
    );

    return token.roles.includes(ROLE_SUPPLIER);
  }
}
