import { Icon } from '@shabic/icon';
import { findLast, propEq } from 'ramda';
import { CustomerAddress, ICustomerAddressResponse } from './address.model';
import { IListParams } from './list.model';
import { IProductResponse, Product } from './product.model';
import { Time } from './time.model';
import { TimelinePoint } from './timeline.model';

export type OrderStatus =
  | 'IDLE'
  | 'VERIFYING'
  | 'ACCEPTED_BY_SUPPLIER'
  | 'ACCEPTED_BY_ERP'
  | 'REQUEST_ON_CANCEL'
  | 'CANCEL_APPROVED_BY_SUPPLIER'
  | 'REJECTED_BY_ERP'
  | 'REJECTED_BY_SUPPLIER'
  | 'IN_DELIVERY'
  | 'DELIVERED'
  | 'SENT_BACK'
  | 'RETURNED'
  | 'COMPLETED';

export type OrderState = 'ACTIVE' | 'ARCHIVED';

export const orderStatuses: Array<{
  label: string;
  statuses: OrderStatus[];
}> = [
  { label: 'PENDING', statuses: ['IDLE', 'VERIFYING', 'ACCEPTED_BY_ERP'] },
  { label: 'ACCEPTED', statuses: ['ACCEPTED_BY_SUPPLIER'] },
  { label: 'REQUEST_ON_CANCEL', statuses: ['REQUEST_ON_CANCEL'] },
  {
    label: 'CANCEL_APPROVED_BY_SUPPLIER',
    statuses: ['CANCEL_APPROVED_BY_SUPPLIER'],
  },
  { label: 'REJECTED', statuses: ['REJECTED_BY_SUPPLIER'] },
  { label: 'IN_DELIVERY', statuses: ['IN_DELIVERY'] },
  { label: 'DELIVERED', statuses: ['DELIVERED'] },
  { label: 'SENT_BACK', statuses: ['SENT_BACK'] },
  { label: 'RETURNED', statuses: ['RETURNED'] },
  { label: 'COMPLETED', statuses: ['COMPLETED'] },
];

export const orderIcons: Record<OrderStatus, Icon> = {
  IDLE: 'check',
  VERIFYING: 'check',
  ACCEPTED_BY_SUPPLIER: 'check',
  ACCEPTED_BY_ERP: 'check',
  REQUEST_ON_CANCEL: 'check',
  CANCEL_APPROVED_BY_SUPPLIER: 'check',
  REJECTED_BY_ERP: 'close',
  REJECTED_BY_SUPPLIER: 'close',
  COMPLETED: 'check',
  DELIVERED: 'check',
  SENT_BACK: 'check',
  RETURNED: 'check',
  IN_DELIVERY: 'check',
};

interface IOrderPrice {
  price: number;
  vat: number;
  discount: number;
}

export interface IPreOrderResponse extends IOrderPrice {
  id: number;
  quotationId: string;
  quotationExpirationDate: string;
  quantity: number;
  createdDate: string;
  lastModifiedDate: string;
  product: IProductResponse;
}

export class PreOrder {
  id: number;
  product: Product;
  quotationId: string;
  quotationExpirationDate: Time;
  quantity: number;
  price: number;
  vat: number;
  discount: number;
  createdDate: Time;
  lastModifiedDate: Time;

  constructor(payload: IPreOrderResponse) {
    this.product = new Product(payload.product);
    this.quotationId = payload.quotationId;
    this.quotationExpirationDate = new Time(payload.quotationExpirationDate);
    this.quantity = payload.quantity;
    this.price = payload.price;
    this.vat = payload.vat;
    this.discount = payload.discount;
    this.createdDate = new Time(payload.createdDate);
    this.lastModifiedDate = new Time(payload.lastModifiedDate);
    this.id = payload.id;
  }

  getTotal() {
    return (this.price + this.vat - this.discount) * this.quantity;
  }

  isExpired() {
    return this.quotationExpirationDate.isPast();
  }
}

export interface IOrderPayload {
  shippingAddressId: number;
  receiverFirstName: string;
  receiverLastName: string;
  receiverEmail: string;
  receiverPhone: string;
  deliveryNotes: string;
  products: Array<{
    productId: string;
    quotationId: string;
    quantity: number;
  }>;
}

type OrderHistoryResponse = Record<OrderStatus, string>;

export interface IOrderResponse {
  id: number;
  status: OrderStatus;
  supplierState: OrderState;
  buyerState: OrderState;
  companyId: number;
  buyerCompanyId: number;
  supplierCompanyId: number;
  createdDate: string;
  shippingAddress: ICustomerAddressResponse;
  reason?: Record<OrderStatus, string>;
  history?: OrderHistoryResponse;
  products: Array<
    {
      quotationId: string;
      quantity: number;
      product: IProductResponse;
    } & IOrderPrice
  >;
  groupId: string;
  receiverFirstName: string;
  receiverLastName: string;
  receiverEmail: string;
  receiverPhone: string;
  deliveryNotes?: string;
}

interface IOrderProducts extends IOrderPrice {
  quotationId: string;
  quantity: number;
  product: Product;
}

export interface IOrderParams extends IListParams {
  forSupplier: boolean;
}

export class Order {
  id: number;
  createdDate: Time;
  status: OrderStatus;
  supplierState: OrderState;
  buyerState: OrderState;
  products: IOrderProducts[];
  address: CustomerAddress;
  companyId: number;
  reason: Map<OrderStatus, string>;
  groupId: string;
  history: TimelinePoint[];
  receiver: OrderReceiver;

  constructor(payload: IOrderResponse, forSupplier: boolean = false) {
    this.id = payload.id;
    this.status = payload.status;
    this.createdDate = new Time(payload.createdDate);
    this.address = new CustomerAddress(payload.shippingAddress);
    this.companyId = forSupplier ? payload.buyerCompanyId : payload.companyId;
    this.products = payload.products.map(el => {
      return {
        ...el,
        product: new Product(el.product),
      };
    });
    this.reason = this.parseReasons(payload.reason);
    this.supplierState = payload.supplierState;
    this.buyerState = payload.buyerState;
    this.groupId = payload.groupId;
    this.history = this.parseHistory(payload.history);
    this.receiver = new OrderReceiver({
      firstName: payload.receiverFirstName,
      lastName: payload.receiverLastName,
      email: payload.receiverEmail,
      phone: payload.receiverPhone,
      notes: payload.deliveryNotes,
    });
  }

  get isPending() {
    return ['IDLE', 'VERIFYING', 'ACCEPTED_BY_ERP'].includes(this.status);
  }

  get isAccepted() {
    return this.status === 'ACCEPTED_BY_SUPPLIER';
  }

  get isRejected() {
    return this.status === 'REJECTED_BY_SUPPLIER';
  }

  get isCompleted() {
    return this.status === 'COMPLETED';
  }

  get isDelivered() {
    return this.status === 'DELIVERED';
  }

  get inDelivery() {
    return this.status === 'IN_DELIVERY';
  }

  get isSentBack() {
    return this.status === 'SENT_BACK';
  }

  get isReturned() {
    return this.status === 'RETURNED';
  }

  canBeArchived(role: 'buyer' | 'supplier') {
    const allowStatuses: OrderStatus[] = [
      'COMPLETED',
      'REJECTED_BY_SUPPLIER',
      'REJECTED_BY_ERP',
      'CANCEL_APPROVED_BY_SUPPLIER',
      'RETURNED',
    ];
    const state = role === 'buyer' ? this.buyerState : this.supplierState;

    return state !== 'ARCHIVED' && allowStatuses.includes(this.status);
  }

  statusIs(status: OrderStatus) {
    return this.status === status;
  }

  totalPrice() {
    return this.products.reduce((prev, curr) => {
      return prev + (curr.price - curr.discount) * curr.quantity;
    }, 0);
  }

  totalVat() {
    return this.products.reduce((prev, curr) => {
      return prev + curr.vat * curr.quantity;
    }, 0);
  }

  private parseReasons(reason?: Record<OrderStatus, string>) {
    const map = new Map<OrderStatus, string>();

    for (const status in reason) {
      const key = status as OrderStatus;

      map.set(key, reason[key]);
    }

    return map;
  }

  private parseHistory(history?: OrderHistoryResponse) {
    const points: Array<{ status: OrderStatus; icon: Icon }> = [
      { status: 'ACCEPTED_BY_SUPPLIER', icon: 'icon-home' },
      { status: 'IN_DELIVERY', icon: 'icon-home' },
      { status: 'DELIVERED', icon: 'icon-home' },
      { status: 'SENT_BACK', icon: 'icon-home' },
      { status: 'RETURNED', icon: 'icon-home' },
      { status: 'COMPLETED', icon: 'icon-home' },
    ];

    let timelinePoints = points.map(({ status, icon }) => {
      const point = new TimelinePoint(status, `status.${status}`, icon);

      history?.[status] && point.complete(history?.[status]);

      return point;
    });

    const sentBackPoint = timelinePoints.find(el => el.id === 'SENT_BACK');

    if (sentBackPoint?.completed) {
      timelinePoints = timelinePoints.filter(el => el.id !== 'COMPLETED');
    } else {
      timelinePoints = timelinePoints.filter(
        el => !['SENT_BACK', 'RETURNED'].includes(el.id)
      );
    }

    const lastActivated = findLast<TimelinePoint>(propEq('completed', true))(
      timelinePoints
    );

    lastActivated?.activate();

    return timelinePoints;
  }
}

export class OrderBulk {
  groupId: string;
  orders: Order[];

  constructor(payload: {
    orders: IOrderResponse[];
    groupId: string;
    forSupplier: boolean;
  }) {
    this.groupId = payload.groupId;
    this.orders = payload.orders.map(el => new Order(el, payload.forSupplier));
  }
}

export interface IOrderReceiver {
  firstName: Nullable<string>;
  lastName: Nullable<string>;
  email: Nullable<string>;
  phone: Nullable<string>;
  notes?: Nullable<string>;
}

export class OrderReceiver {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  notes?: string;

  constructor(payload: IOrderReceiver) {
    this.firstName = payload.firstName || '';
    this.lastName = payload.lastName || '';
    this.email = payload.email || '';
    this.phone = payload.phone || '';
    this.notes = payload.notes || '';
  }
}
