import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[shabicImage]',
})
export class ImageDirective {
  @HostBinding('class')
  get elementClass(): string {
    return 'bg-white object-contain';
  }
}
