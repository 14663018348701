import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { INavItem } from '@shabic/data';

@Component({
  selector: 'shabic-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent {
  @Input() navigation?: INavItem[];
  @Input() vertical?: boolean;
}
