import { Observable } from 'rxjs';
import { ApiErrorResponse, ApiResponse } from './api.model';
import {
  Bank,
  BankAccount,
  BankStatus,
  IBankAccountPayload,
  IBankAccountStatementParams,
  IBankPayload,
} from './bank-account.model';
import { Category, ICategoryPayload } from './category.model';
import { Company, CompanyDocument } from './company.model';
import { Contact } from './contact.model';
import { FilterOption } from './filter.model';
import { Order } from './order.model';
import { ListProduct, Product, ProductStatus } from './product.model';
import { Icon } from '@shabic/icon';

export enum EReturnOrderReason {
  DamagedGoods = 'damaged',
  NotMeetingRequiredSpecifications = 'specifications',
}

export enum ECancelOrderReason {
  WrongProduct = 'wrong',
  NotEnoughFunds = 'funds',
}

export type ReasonKey = EReturnOrderReason | ECancelOrderReason;

export const returnReasonMap = new Map<ReasonKey, string>();

returnReasonMap.set(ECancelOrderReason.WrongProduct, 'common.wrong-product');
returnReasonMap.set(
  ECancelOrderReason.NotEnoughFunds,
  'common.not-enough-funds'
);
returnReasonMap.set(EReturnOrderReason.DamagedGoods, 'common.damaged-goods');
returnReasonMap.set(
  EReturnOrderReason.NotMeetingRequiredSpecifications,
  'common.not-meeting-required-specifications'
);

export type ConfirmDialogData = {
  title: string;
  icon?: Icon;
  confirmTitle: string;
  onConfirm: () => Observable<unknown>;
};

export type RequestProductsDialogData = {
  products: ListProduct[];
  onConfirm: () => Observable<unknown>;
};

export class ReturnOrderDialogData {
  constructor(
    public onConfirm: (config: {
      description: string;
    }) => Observable<ApiResponse<ApiErrorResponse | Order>>
  ) {}
}

export class CancelOrderDialogData {
  constructor(
    public onConfirm: (config: {
      description: string;
    }) => Observable<ApiResponse<ApiErrorResponse | Order>>
  ) {}
}

export type RequestAccountStatementDialogData = {
  account: BankAccount;
  onRequest: (config: {
    accountId: string;
    fromDate: string;
    toDate: string;
  }) => void;
};

export type BankBalanceDialogData = {
  account: BankAccount;
};

export type AccountStatementDialogData = {
  params: IBankAccountStatementParams;
};

export type DocumentsDialogData = {
  documents$: Observable<ApiResponse<ApiErrorResponse | CompanyDocument[]>>;
  getDocument: (
    document: CompanyDocument,
    download: boolean
  ) => Observable<ApiResponse<ApiErrorResponse | Blob>>;
  removeDocument: (
    document: CompanyDocument
  ) => Observable<ApiResponse<unknown>>;
  onPreviewDocument: (document: CompanyDocument, file: Blob) => any;
  onRead: (
    document: CompanyDocument
  ) => Observable<ApiResponse<ApiErrorResponse | CompanyDocument>>;
};

export type ProductFormDialogData = {
  product: Product;
  onUpdate: () => void;
};

export type PreviewDialogData = {
  document: CompanyDocument;
  file: Blob;
};

export type LoginDialogData = {
  onSuccess: () => void;
};

export type ForgotPasswordDialogData = {
  token?: string;
  data?: string;
};

export type RejectCompanyDialogData = {
  company: Company;
  onRejected: () => void;
};

export type BlockUserDialogData = {
  contact: Contact;
  disable: boolean;
  title?: string;
  onSuccess: () => void;
};

export class CreateBankAccountDialogData {
  constructor(
    public onConnect: (
      payload: IBankAccountPayload
    ) => Observable<ApiResponse<ApiErrorResponse | BankAccount>>,
    public onSuccess: () => void
  ) {}
}

export class EditBankAccountDialogData {
  constructor(
    public account: BankAccount,
    public onEdit: (
      payload: Partial<IBankAccountPayload>
    ) => Observable<ApiResponse<ApiErrorResponse | BankAccount>>,
    public onSuccess: () => void
  ) {}
}

export type CreateBankDialogData = {
  onCreate: (
    payload: IBankPayload
  ) => Observable<ApiResponse<ApiErrorResponse | Bank>>;
  onSuccess: () => void;
};

export type EditBankDialogData = {
  onEdit: (
    status: BankStatus
  ) => Observable<ApiResponse<ApiErrorResponse | Bank>>;
  onSuccess: () => void;
  bank: Bank;
};

export class CategoryDialogData {
  constructor(
    public onSuccess: (media: ICategoryPayload) => void,
    public category?: Category
  ) {}
}

export class DeactivateProductDialogData {
  constructor(
    public product: Product,
    public status: ProductStatus,
    public onSuccess: () => void
  ) {}
}

export interface FilterPayload {
  options: Observable<null | (FilterOption | Category)[]>;
  key?: string;
  label: string;
  value?: unknown;
  colorBox?: boolean;
}

export type DrawerDialogConfig = {
  isFullSize?: boolean;
  isPrimaryBg?: boolean;
  hasBackBtn?: boolean;
  hasCloseBtn?: boolean;
  title?: string;
};

export class FilterDialogData {
  constructor(
    public filters: FilterPayload[],
    public categories: Observable<Category[]> | null,
    public onClose: (result: Record<string, any>) => void
  ) {}
}

export class GalleryDialogData {
  constructor(public images: string[]) {}
}
