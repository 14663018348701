<form shabicSignUpForm [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <ng-container [ngSwitch]="state$ | async">
    <ng-container *ngSwitchCase="'form'">
      <shabic-headline align="center" variant="h2">
        {{ 'create-account.title' | translate }}
      </shabic-headline>
      <shabic-sub-headline class="block mt-0.5">
        {{ 'create-account.subtitle' | translate }}
      </shabic-sub-headline>
      <shabic-form-field class="text-primary mt-5.5">
        <shabic-form-field-label>
          {{ 'common.email' | translate }}
        </shabic-form-field-label>

        <input type="email" shabicInput formControlName="email" />
      </shabic-form-field>

      <shabic-form-field class="text-primary mt-4">
        <shabic-form-field-label>
          {{ 'common.password' | translate }}
        </shabic-form-field-label>

        <input type="password" shabicInput formControlName="password" />
      </shabic-form-field>

      <shabic-form-field class="text-primary mt-4">
        <shabic-form-field-label>
          {{ 'common.confirm-password' | translate }}
        </shabic-form-field-label>

        <input type="password" shabicInput formControlName="confirmPassword" />
      </shabic-form-field>
    </ng-container>

    <ng-container *ngSwitchCase="'passcode'">
      <div class="flex justify-center">
        <ng-template shabicIcon="mail"></ng-template>
      </div>

      <shabic-headline variant="h2" class="mt-5 block text-center">
        {{ 'passcode.email-passcode' | translate: { email: '' } }}
      </shabic-headline>

      <shabic-sub-headline>
        {{ 'passcode.enter-passcode' | translate }}
      </shabic-sub-headline>

      <shabic-passcode
        formControlName="passcode"
        class="mt-5.5 pb-3 text-primary"
      ></shabic-passcode>
    </ng-container>
  </ng-container>

  <shabic-form-field-errors
    [errors]="formGroup.errors"
  ></shabic-form-field-errors>

  <ng-container *ngIf="state$ | async as state">
    <button
      [disabled]="formGroup.invalid || (loading$ | async)"
      class="mt-4 w-full gap-1"
      [shabicButton]="state === 'form' ? 'gradient' : 'secondary'"
      type="submit"
    >
      {{ (state === 'form' ? 'button.verify' : 'button.confirm') | translate }}

      <shabic-spinner *ngIf="loading$ | async"></shabic-spinner>
    </button>

    <p
      *ngIf="(state$ | async) === 'form'"
      class="mt-5.5 text-grey text-center text-sm font-medium"
    >
      {{ 'message.already-have-an-account' | translate }}
      <a
        class="text-secondary font-semibold hover:underline focus:underline"
        routerLink="/sign-in"
      >
        {{ 'button.login' | translate }}
      </a>
    </p>
  </ng-container>
</form>
