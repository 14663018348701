import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CurrencyService } from '@shabic/core';
import { Currency, CurrencyType } from '@shabic/models';
import { combineLatest, map, Observable, merge, of } from 'rxjs';
import { convertCurrency } from '../helpers';

@Pipe({
  name: 'convert',
})
export class ConvertPipe implements PipeTransform {
  constructor(
    private currencyService: CurrencyService,
    private translateService: TranslateService
  ) {}

  transform(value: number, ...args: unknown[]): Observable<string> {
    return combineLatest({
      currency: this.currencyService.currentCurrency$,
      locale: merge(
        this.translateService.onLangChange,
        of({ lang: this.translateService.currentLang })
      ),
    }).pipe(
      map(({ currency, locale }) => {
        const priceCurrency = args[0] as CurrencyType;
        const hasSymbol = args[1] as boolean;

        return convertCurrency({
          locale: locale.lang,
          currency,
          price: value,
          priceCurrency: new Currency(priceCurrency),
          hasSymbol,
        });
      })
    );
  }
}
