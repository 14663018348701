<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M3.875 22C3.375 22 2.9375 21.8125 2.5625 21.4375C2.1875 21.0625 2 20.625 2 20.125V15.6563H3.875V20.125H20.125V15.6563H22V20.125C22 20.625 21.8125 21.0625 21.4375 21.4375C21.0625 21.8125 20.625 22 20.125 22H3.875ZM12 17.2187L5.96875 11.1875L7.3125 9.84375L11.0625 13.5937V2H12.9375V13.5937L16.6875 9.84375L18.0313 11.1875L12 17.2187Z"
    fill="currentColor"
  />
</svg>
