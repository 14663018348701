<div class="flex items-center gap-5.5 pt-3">
  <!-- <span
    class="inline-flex items-center gap-0.5"
    [class.text-green]="product.status === 'ACTIVE'"
    [class.text-grey]="product.isDeactivated"
  >
    <ng-container *ngIf="product.status === 'ACTIVE'">
      <ng-template shabicIcon="check"></ng-template>
    </ng-container>

    <ng-container *ngIf="product.isDeactivated">
      <ng-template shabicIcon="close"></ng-template>
    </ng-container>

    <span class="text-sm first-letter:uppercase whitespace-nowrap">
      {{ status | translate }}
    </span>
  </span> -->

  <button
    shabicButton="danger"
    *ngIf="product.status === 'ACTIVE'"
    (click)="onDeactivate()"
    type="button"
  >
    {{ 'button.deactivate' | translate }}
  </button>
  <button
    shabicButton="green"
    *ngIf="product.isDeactivated"
    type="button"
    (click)="onActivate()"
  >
    {{ 'button.activate' | translate }}
  </button>
</div>
