import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Observable } from 'rxjs';
import { Pagination, PaginationItem } from './models';

@Component({
  selector: 'shabic-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() pages!: number;
  @Input() page?: number | number[];

  @Output() nextPage: EventEmitter<number> = new EventEmitter();

  private pagination!: Pagination;
  items$!: Observable<PaginationItem[]>;

  get backArrowDisabled(): boolean {
    return this.pagination.activePage === 0;
  }

  get nextArrowDisabled(): boolean {
    return this.pagination.activePage + 1 === this.pages;
  }

  ngOnInit(): void {
    this.pagination = new Pagination(this.pages, this.page || 0);
    this.items$ = this.pagination.items$;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['page']) {
      const item = this.pagination?.getPage(changes['page'].currentValue);

      if (item) {
        this.pagination?.setActive(changes['page'].currentValue);
      }
    }

    if (changes['pages']) {
      this.pagination = new Pagination(this.pages, this.page || 0);
      this.items$ = this.pagination.items$;
    }
  }

  onNext(): void {
    const item = this.pagination.next();
    if (item) {
      this.setActive(item);
    }
  }

  onBack(): void {
    const item = this.pagination.prev();
    if (item) {
      this.setActive(item);
    }
  }

  setActive(page: PaginationItem): void {
    this.pagination.setActive(page.key);
    this.nextPage.emit(page.key);
  }
}
