import {
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const hiddenInputs = ['radio', 'checkbox'];

@Directive({
  selector: '[shabicInput]',
})
export class InputDirective implements OnInit, OnDestroy {
  private destroy: Subject<void> = new Subject();
  @Input() class?: string;

  change$?: Observable<unknown>;
  focus$?: Observable<unknown>;
  blur$?: Observable<unknown>;

  @HostBinding('class')
  get elementClass(): string {
    if (hiddenInputs.includes(this.el.nativeElement.type)) {
      return 'hidden';
    }

    const initClasses =
      'text-sm p-3 rounded-2xl border-2 border-grey-350 w-full h-full rtl:text-right peer disabled:text-grey disabled:bg-grey-300 [&:not(.ng-invalid.ng-touched):focus]:border-primary focus:outline-none transtition-colors';

    if (this.class) {
      return `${initClasses} ${this.class}`;
    }

    return initClasses;
  }

  get type(): string {
    return this.el.nativeElement.type;
  }

  constructor(
    private el: ElementRef<HTMLInputElement>,
    private translateService: TranslateService,
    private render: Renderer2
  ) {}

  ngOnInit() {
    this.subscribe();
    this.setLanguage();
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  click(): void {
    if (
      this.type === 'radio' ||
      this.type === 'checkbox' ||
      this.type === 'file'
    ) {
      this.el.nativeElement.click();
    }
  }

  files(): FileList | null | undefined {
    if (this.type === 'file') {
      return this.el.nativeElement.files;
    }

    return;
  }

  private subscribe(): void {
    this.change$ = fromEvent(this.el.nativeElement, 'change').pipe(
      takeUntil(this.destroy)
    );

    this.focus$ = fromEvent(this.el.nativeElement, 'focus').pipe(
      takeUntil(this.destroy)
    );

    this.blur$ = fromEvent(this.el.nativeElement, 'blur').pipe(
      takeUntil(this.destroy)
    );

    this.subscribeOnLanguageChange();
  }

  private subscribeOnLanguageChange(): void {
    this.translateService.onLangChange
      .pipe(takeUntil(this.destroy))
      .subscribe(() => {
        this.setLanguage();
      });
  }

  private setLanguage(): void {
    const lang = this.translateService.currentLang;

    this.render.setAttribute(this.el.nativeElement, 'lang', lang);
  }
}
