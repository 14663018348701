import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ITag } from '@shabic/models';

@Component({
  selector: 'shabic-search-filter-tags',
  templateUrl: './search-filter-tags.component.html',
  styleUrls: ['./search-filter-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFilterTagsComponent {
  @Input() tags?: ITag[] | null;
  @Output() changed: EventEmitter<ITag> = new EventEmitter();

  onSelect(event: MouseEvent, tag: ITag): void {
    event.preventDefault();
    event.stopPropagation();

    this.changed.emit(tag);
  }
}
