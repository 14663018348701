<svg
  width="80"
  height="80"
  viewBox="0 0 80 80"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [ngClass]="classes"
>
  <path
    d="M46.9927 6.20535L3.32506 20.6552C2.2567 21.0087 1.67672 22.1629 2.02963 23.2331L16.4544 66.9765C16.8073 68.0467 17.9595 68.6277 19.0278 68.2742L62.6955 53.8244C63.7638 53.4709 64.3438 52.3167 63.9909 51.2465L49.5661 7.50303C49.2132 6.43282 48.061 5.85183 46.9927 6.20535Z"
    fill="white"
  />
  <path
    d="M46.1899 10.9688L6.75 24.0196L19.7782 63.5279L59.2181 50.4771L46.1899 10.9688Z"
    fill="#B4DFFD"
  />
  <path
    opacity="0.47"
    d="M19.4462 63.8604L16.4961 54.9143L20.8172 44.4955C21.1014 43.8023 21.635 43.2412 22.3124 42.9232C22.9898 42.6053 23.7617 42.5538 24.4753 42.7788L32.4641 45.2624C32.6926 45.3344 32.9388 45.327 33.1626 45.2414C33.3864 45.1558 33.5749 44.997 33.6973 44.7907L40.3568 33.7044C41.0462 32.5637 42.1232 31.7105 43.3901 31.3014C44.657 30.8924 46.0287 30.9549 47.2533 31.4775L54.579 34.6349L54.6463 34.8403L59.8131 50.5055L59.355 50.6563L19.4462 63.8604ZM17.5243 54.9529L20.0613 62.6539L58.583 49.8926L53.7782 35.3473L46.8529 32.3631C45.8418 31.9329 44.7097 31.8826 43.6645 32.2216C42.6194 32.5605 41.7315 33.2659 41.164 34.2082L34.5141 45.2945C34.2813 45.6858 33.9235 45.9871 33.4986 46.1495C33.0737 46.312 32.6065 46.3261 32.1726 46.1897L24.1838 43.7029C23.6987 43.5506 23.1743 43.5861 22.7141 43.8024C22.2538 44.0186 21.8913 44.3999 21.6981 44.8709L17.5243 54.9529Z"
    fill="white"
  />
  <path
    opacity="0.47"
    d="M32.6102 33.053C31.3213 33.0551 30.079 32.5707 29.1307 31.6964C28.1825 30.822 27.5979 29.6218 27.4934 28.335C27.389 27.0482 27.7724 25.7692 28.5672 24.7528C29.362 23.7365 30.5099 23.0575 31.7821 22.851C33.0544 22.6445 34.3576 22.9258 35.432 23.6388C36.5065 24.3518 37.2734 25.4441 37.5798 26.6982C37.8863 27.9522 37.7099 29.2759 37.0857 30.4054C36.4615 31.535 35.4353 32.3875 34.2118 32.7931C33.6951 32.9643 33.1544 33.0521 32.6102 33.053ZM32.6102 23.7732C32.1691 23.773 31.731 23.8445 31.3129 23.985C30.3238 24.3142 29.4948 25.0046 28.9911 25.9186C28.4873 26.8326 28.3458 27.9032 28.5947 28.917C28.8437 29.9308 29.4647 30.8135 30.3342 31.3892C31.2037 31.9649 32.2578 32.1914 33.2864 32.0234C34.3151 31.8555 35.2429 31.3055 35.8849 30.4831C36.5269 29.6607 36.8361 28.6261 36.7508 27.5856C36.6655 26.545 36.192 25.5748 35.4247 24.8683C34.6573 24.1618 33.6524 23.7708 32.6102 23.7732Z"
    fill="white"
  />
  <path
    d="M77.118 23.1411H43.0294C41.9043 23.1411 40.9922 24.0548 40.9922 25.1819V59.3296C40.9922 60.4567 41.9043 61.3704 43.0294 61.3704H77.118C78.2431 61.3704 79.1552 60.4567 79.1552 59.3296V25.1819C79.1552 24.0548 78.2431 23.1411 77.118 23.1411Z"
    fill="white"
  />
  <path
    opacity="0.39"
    d="M75.8825 26.398H44.2188V58.1167H75.8825V26.398Z"
    fill="#00A4B9"
  />
  <path
    d="M76.402 58.3927H43.7773V51.0542L49.415 44.5147C49.8048 44.0619 50.3494 43.7714 50.9421 43.7001C51.5347 43.6289 52.1325 43.7821 52.6182 44.1297L57.8074 47.8422C57.9349 47.9342 58.0896 47.9804 58.2465 47.9734C58.4034 47.9665 58.5534 47.9067 58.6723 47.8037L66.1325 41.3702C66.9246 40.6916 67.9346 40.3223 68.9767 40.33C70.0189 40.3376 71.0233 40.7218 71.8054 41.4119L76.3924 45.487L76.402 58.3927ZM44.7383 57.43H75.441V45.9202L71.1776 42.1338C70.5701 41.5973 69.7896 41.2986 68.9798 41.2927C68.1699 41.2868 67.3852 41.5741 66.77 42.1018L59.3097 48.5193C59.0282 48.7634 58.6728 48.9054 58.3008 48.9222C57.9288 48.9391 57.562 48.8299 57.2596 48.6123L52.0704 44.8998C51.7788 44.6918 51.4203 44.6002 51.0649 44.6429C50.7095 44.6857 50.3827 44.8596 50.1485 45.1308L44.7447 51.4008L44.7383 57.43Z"
    fill="white"
  />
  <path
    d="M62.0012 39.4449C61.2071 39.4455 60.4307 39.2101 59.7701 38.7686C59.1096 38.3271 58.5947 37.6992 58.2905 36.9644C57.9863 36.2296 57.9066 35.4209 58.0613 34.6407C58.2161 33.8605 58.5984 33.1438 59.1599 32.5813C59.7214 32.0188 60.4369 31.6358 61.2158 31.4808C61.9946 31.3258 62.8019 31.4056 63.5354 31.7104C64.2689 32.0151 64.8958 32.5309 65.3365 33.1926C65.7773 33.8542 66.0122 34.632 66.0116 35.4275C66.0099 36.4924 65.5869 37.5133 64.8351 38.2663C64.0834 39.0194 63.0643 39.4432 62.0012 39.4449V39.4449ZM62.0012 32.3856C61.3973 32.3849 60.8068 32.5637 60.3044 32.8994C59.8019 33.235 59.4102 33.7124 59.1786 34.2711C58.9471 34.8298 58.8862 35.4447 59.0036 36.0381C59.121 36.6315 59.4115 37.1767 59.8383 37.6047C60.2651 38.0327 60.809 38.3242 61.4013 38.4425C61.9935 38.5607 62.6075 38.5004 63.1655 38.269C63.7234 38.0377 64.2004 37.6457 64.536 37.1428C64.8715 36.6398 65.0506 36.0485 65.0506 35.4435C65.0523 35.041 64.9748 34.6421 64.8223 34.2696C64.6699 33.8971 64.4457 33.5585 64.1624 33.2729C63.8792 32.9874 63.5425 32.7606 63.1716 32.6056C62.8008 32.4506 62.403 32.3704 62.0012 32.3695V32.3856Z"
    fill="white"
  />
  <path
    d="M44.6328 43.0542L25.29 50.2688C24.2324 50.6632 23.6942 51.8419 24.088 52.9014L31.2901 72.2777C31.6839 73.3372 32.8605 73.8762 33.9182 73.4818L53.2609 66.2672C54.3185 65.8727 54.8567 64.694 54.4629 63.6346L47.2608 44.2582C46.867 43.1988 45.6904 42.6597 44.6328 43.0542Z"
    fill="white"
  />
  <path
    d="M45.1171 44.7273L25.9648 51.9158L33.1408 71.1013L52.2931 63.9128L45.1171 44.7273Z"
    fill="#0066AD"
  />
  <path
    opacity="0.47"
    d="M32.8441 71.5871L31.1016 66.9376L33.0555 61.6431C33.2027 61.2389 33.496 60.9047 33.8775 60.7068C34.2589 60.5089 34.7007 60.4617 35.1152 60.5746L39.0936 61.6431C39.1547 61.6595 39.2195 61.6547 39.2776 61.6297C39.3357 61.6047 39.3837 61.5608 39.4139 61.5052L42.4729 55.9187C42.8188 55.2926 43.3785 54.8125 44.0494 54.5667C44.7202 54.3209 45.4571 54.3258 46.1246 54.5806L49.8916 56.0278L52.8994 64.0497L32.8441 71.5871ZM32.1266 66.9376L33.4079 70.3614L51.6662 63.5074L49.1484 56.769L45.7851 55.4855C45.3345 55.3141 44.8373 55.3112 44.3848 55.4774C43.9323 55.6436 43.5549 55.9678 43.3218 56.3904L40.2627 61.9736C40.1293 62.2188 39.9174 62.4118 39.6612 62.5218C39.405 62.6318 39.1194 62.6523 38.8501 62.5801L34.8653 61.4827C34.6827 61.4319 34.4876 61.4524 34.3194 61.5399C34.1512 61.6274 34.0224 61.7755 33.9588 61.9544L32.1266 66.9376Z"
    fill="white"
  />
  <path
    opacity="0.47"
    d="M38.7694 56.1016C38.2652 56.1024 37.7703 55.9648 37.3385 55.7038C36.9068 55.4428 36.5546 55.0684 36.3202 54.6211C36.0859 54.1739 35.9783 53.6708 36.0091 53.1666C36.0399 52.6624 36.2079 52.1763 36.495 51.761C36.7821 51.3457 37.1772 51.0171 37.6375 50.8108C38.0978 50.6045 38.6057 50.5284 39.1062 50.5908C39.6066 50.6532 40.0804 50.8517 40.4761 51.1647C40.8719 51.4778 41.1746 51.8934 41.3512 52.3665V52.3665C41.5089 52.7846 41.5629 53.2347 41.5086 53.6783C41.4542 54.1219 41.2931 54.5456 41.0391 54.913C40.7852 55.2804 40.4459 55.5805 40.0506 55.7876C39.6552 55.9946 39.2156 56.1024 38.7694 56.1016ZM38.7534 51.5355C38.3699 51.5362 37.9966 51.6596 37.6879 51.8877C37.3793 52.1158 37.1515 52.4367 37.0376 52.8036C36.9238 53.1705 36.9299 53.5643 37.0551 53.9274C37.1803 54.2906 37.4181 54.6042 37.7337 54.8226C38.0492 55.041 38.4262 55.1526 38.8096 55.1414C39.193 55.1301 39.5628 54.9964 39.865 54.7599C40.1673 54.5233 40.3862 54.1963 40.4899 53.8264C40.5936 53.4565 40.5766 53.0631 40.4415 52.7035C40.3131 52.3592 40.0825 52.0627 39.7806 51.8539C39.4788 51.645 39.1203 51.5339 38.7534 51.5355V51.5355Z"
    fill="white"
  />
</svg>
