import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'withPrefix',
})
export class WithPrefixPipe implements PipeTransform {
  transform(value: unknown, prefix: string): string {
    if (typeof value === 'string') {
      const result: string[] = [prefix, value];

      return result.join('.');
    }

    return '';
  }
}
