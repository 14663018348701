import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Address } from '@shabic/models';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'shabic-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapComponent {
  private _coordinates = new BehaviorSubject<google.maps.LatLngLiteral | null>(
    null
  );
  private _map!: google.maps.Map;

  readonly options: google.maps.MapOptions = {
    zoomControl: false,
    scaleControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    zoom: 13,
    center: {
      lat: 24.700989110046233,
      lng: 46.65241241455078,
    },
  };
  readonly markers: google.maps.Marker[] = [];
  readonly coordinates$ = this._coordinates.asObservable();

  @Input() readonly?: boolean;
  @Input() initialAddress?: Address;
  @Output() address = new EventEmitter<Address>();

  onInit(event: google.maps.Map) {
    this._map = event;

    this.parseUserAddress();
  }

  onClick(event: google.maps.MapMouseEvent | google.maps.IconMouseEvent) {
    if (this.readonly) {
      return;
    }

    const geocoder = new google.maps.Geocoder();
    const infowindow = new google.maps.InfoWindow();

    geocoder
      .geocode({ location: event.latLng?.toJSON() })
      .then(response => {
        if (response.results[0]) {
          const address = Address.fromGoogleApi(response.results[0]);
          const marker = this.updateMarker({
            lat: address.latitude as number,
            lng: address.longitude as number,
          });

          infowindow.setContent(response.results[0].formatted_address);
          infowindow.open(this._map, marker);
          this.address.emit(address);
        } else {
          window.alert('No results found');
        }
      })
      .catch(e => {
        window.alert('Geocoder failed due to: ' + e);
      });
  }

  private setCurrentMarker(position: google.maps.LatLngLiteral) {
    this._map.setCenter(position);
    this.updateMarker(position);
  }

  private getCurrentLocation() {
    const successCallback: PositionCallback = position => {
      this._map.setCenter({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    };

    const errorCallback: PositionErrorCallback = error => {
      console.log(error);
    };

    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  }

  private updateMarker(position?: google.maps.LatLngLiteral) {
    this.markers.forEach(marker => {
      marker.setMap(null);
    });

    const marker = new google.maps.Marker({
      position,
      map: this._map,
    });
    this.markers.push(marker);

    if (position) {
      this._coordinates.next(position);
    }

    return marker;
  }

  private parseUserAddress() {
    const address = this.initialAddress;
    const longitude = address?.longitude;
    const latitude = address?.latitude;
    const geocoder = new google.maps.Geocoder();

    if (longitude && latitude) {
      this.setCurrentMarker({
        lat: latitude,
        lng: longitude,
      });
    } else if (address) {
      geocoder.geocode({ address: address.formatedName }).then(response => {
        const parsedAddress = Address.fromGoogleApi(response.results[0]);

        this.setCurrentMarker({
          lat: parsedAddress.latitude as number,
          lng: parsedAddress.longitude as number,
        });
      });
    }
  }
}
