<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    opacity="0.4"
    d="M16.334 2H7.665C4.276 2 2 4.378 2 7.917V16.084C2 19.622 4.276 22 7.665 22H16.333C19.722 22 22 19.622 22 16.084V7.917C22 4.378 19.723 2 16.334 2Z"
    fill="currentColor"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M11.3144 11.2489H17.0144C17.4244 11.2489 17.7644 11.5889 17.7644 11.9989V13.8489C17.7644 14.2689 17.4244 14.5989 17.0144 14.5989C16.5944 14.5989 16.2644 14.2689 16.2644 13.8489V12.7489H14.9344V13.8489C14.9344 14.2689 14.5944 14.5989 14.1844 14.5989C13.7644 14.5989 13.4344 14.2689 13.4344 13.8489V12.7489H11.3144C10.9944 13.8189 10.0144 14.5989 8.84437 14.5989C7.40437 14.5989 6.23438 13.4389 6.23438 11.9989C6.23438 10.5689 7.40437 9.39893 8.84437 9.39893C10.0144 9.39893 10.9944 10.1789 11.3144 11.2489ZM7.73438 11.9989C7.73438 12.6089 8.23438 13.0989 8.84438 13.0989C9.44438 13.0989 9.94438 12.6089 9.94438 11.9989C9.94438 11.3889 9.44438 10.8989 8.84438 10.8989C8.23438 10.8989 7.73438 11.3889 7.73438 11.9989Z"
    fill="currentColor"
  />
</svg>
