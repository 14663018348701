<table
  class="text-primary text-sm font-medium border-spacing-x-[30px] border-spacing-y-[2px] border-separate ltr:-ml-[30px] rtl:-mr-[30px] my-3"
>
  <thead>
    <tr class="uppercase text-xs text-grey text-left">
      <th
        class="font-medium rtl:text-right"
        *ngFor="let label of config?.labels"
      >
        {{ label | translate }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td
        class="text-sm text-primary rtl:text-right"
        *ngFor="let cell of config?.columns"
      >
        {{ cell }}
      </td>
    </tr>
  </tbody>
</table>
