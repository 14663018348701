<ng-container *ngIf="errors">
  <p *ngIf="errors['api']" class="text-red text-xs mt-1 first-letter:uppercase">
    {{ errors['api'] }}
  </p>

  <p *ngIf="errors['equal']" class="text-red text-xs mt-1">
    {{ errors['equal'] | translate }}
  </p>

  <p *ngIf="errors['form']" class="text-red text-xs mt-1">
    {{ errors['form'] | translate: params }}
  </p>

  <p *ngIf="errors['notNumber']" class="text-red text-xs mt-1">
    {{ errors['notNumber'] | translate }}
  </p>

  <p *ngIf="errors['minlength']" class="text-red text-xs mt-1">
    {{ 'error.min-length' | translate: errors['minlength'] }}
  </p>

  <p *ngIf="errors['hasNumber']" class="text-red text-xs mt-1">
    {{ 'error.has-number' | translate }}
  </p>

  <p *ngIf="errors['hasCapitalCase']" class="text-red text-xs mt-1">
    {{ 'error.has-capital-case' | translate }}
  </p>

  <p *ngIf="errors['hasSmallCase']" class="text-red text-xs mt-1">
    {{ 'error.has-small-case' | translate }}
  </p>

  <p *ngIf="errors['hasSpecialCharacters']" class="text-red text-xs mt-1">
    {{ 'error.has-special-characters' | translate }}
  </p>

  <p *ngIf="errors['required']" class="text-red text-xs mt-1">
    {{ 'error.required' | translate }}
  </p>

  <p *ngIf="errors['mask']" class="text-red text-xs mt-1">
    {{ 'error.invalid-mask' | translate: { label: label || '' | translate } }}
  </p>

  <p *ngIf="errors['notIBAN']" class="text-red text-xs mt-1">
    {{ errors['notIBAN'] | translate }}
  </p>
</ng-container>
