import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'shabic-animate',
  templateUrl: './animate.component.html',
  styleUrls: ['./animate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnimateComponent {
  @Input() animation = 'animate__fadeIn';
  @Input() classes = '';
}
