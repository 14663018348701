<svg
  width="335"
  height="300"
  viewBox="0 0 335 300"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <mask
    id="mask0_3359_5952"
    style="mask-type: luminance"
    maskUnits="userSpaceOnUse"
    x="0"
    y="0"
    width="335"
    height="289"
  >
    <path
      d="M167.479 288.965C259.975 288.965 334.957 224.278 334.957 144.482C334.957 64.687 259.975 0 167.479 0C74.9827 0 0 64.687 0 144.482C0 224.278 74.9827 288.965 167.479 288.965Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask0_3359_5952)">
    <path
      d="M335 144.482C335 224.251 260.019 288.965 167.521 288.965C75.0236 288.965 0 224.251 0 144.482C0 64.7132 74.9809 160.522 167.479 160.522C259.976 160.522 335 64.6704 335 144.482Z"
      fill="url(#paint0_linear_3359_5952)"
    />
  </g>
  <mask
    id="mask1_3359_5952"
    style="mask-type: luminance"
    maskUnits="userSpaceOnUse"
    x="0"
    y="0"
    width="335"
    height="289"
  >
    <path
      d="M167.479 288.965C259.975 288.965 334.957 224.278 334.957 144.482C334.957 64.687 259.975 0 167.479 0C74.9827 0 0 64.687 0 144.482C0 224.278 74.9827 288.965 167.479 288.965Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask1_3359_5952)">
    <path
      d="M229.215 289.264H71.7337C64.4706 289.264 58.7456 283.062 59.3437 275.791L73.9981 100.513C74.5535 94.0548 79.9368 89.0933 86.3881 89.0933H210.972L229.215 289.264Z"
      fill="url(#paint1_linear_3359_5952)"
    />
  </g>
  <mask
    id="mask2_3359_5952"
    style="mask-type: luminance"
    maskUnits="userSpaceOnUse"
    x="0"
    y="0"
    width="335"
    height="289"
  >
    <path
      d="M167.479 288.965C259.975 288.965 334.957 224.278 334.957 144.482C334.957 64.687 259.975 0 167.479 0C74.9827 0 0 64.687 0 144.482C0 224.278 74.9827 288.965 167.479 288.965Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask2_3359_5952)">
    <path
      d="M73.7852 102.909L88.6104 119.419L118.346 89.1362H86.3461C79.8947 89.1362 74.5115 94.0977 73.9561 100.556L73.7852 102.909Z"
      fill="url(#paint2_linear_3359_5952)"
    />
  </g>
  <mask
    id="mask3_3359_5952"
    style="mask-type: luminance"
    maskUnits="userSpaceOnUse"
    x="0"
    y="0"
    width="335"
    height="289"
  >
    <path
      d="M167.479 288.965C259.975 288.965 334.957 224.278 334.957 144.482C334.957 64.687 259.975 0 167.479 0C74.9827 0 0 64.687 0 144.482C0 224.278 74.9827 288.965 167.479 288.965Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask3_3359_5952)">
    <path
      d="M118.345 89.0933V300H88.6094V119.418L118.345 89.0933Z"
      fill="url(#paint3_linear_3359_5952)"
    />
  </g>
  <mask
    id="mask4_3359_5952"
    style="mask-type: luminance"
    maskUnits="userSpaceOnUse"
    x="0"
    y="0"
    width="335"
    height="289"
  >
    <path
      d="M167.479 288.965C259.975 288.965 334.957 224.278 334.957 144.482C334.957 64.687 259.975 0 167.479 0C74.9827 0 0 64.687 0 144.482C0 224.278 74.9827 288.965 167.479 288.965Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask4_3359_5952)">
    <path
      d="M124.841 109.709C122.662 109.709 120.91 107.955 120.91 105.774V72.9682C120.91 54.6192 135.821 39.6919 154.15 39.6919C156.328 39.6919 158.08 41.4455 158.08 43.6269C158.08 45.8082 156.328 47.5619 154.15 47.5619C140.136 47.5619 128.771 58.9819 128.771 72.9682V105.774C128.771 107.955 127.02 109.709 124.841 109.709Z"
      fill="url(#paint4_linear_3359_5952)"
    />
  </g>
  <mask
    id="mask5_3359_5952"
    style="mask-type: luminance"
    maskUnits="userSpaceOnUse"
    x="0"
    y="0"
    width="335"
    height="289"
  >
    <path
      d="M167.479 288.965C259.975 288.965 334.957 224.278 334.957 144.482C334.957 64.687 259.975 0 167.479 0C74.9827 0 0 64.687 0 144.482C0 224.278 74.9827 288.965 167.479 288.965Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask5_3359_5952)">
    <path
      d="M258.181 289.265H100.828L103.434 89.1362H242.16C248.568 89.1362 253.951 94.055 254.55 100.428L270.571 275.706C271.212 282.977 265.487 289.265 258.181 289.265Z"
      fill="url(#paint5_linear_3359_5952)"
    />
  </g>
  <mask
    id="mask6_3359_5952"
    style="mask-type: luminance"
    maskUnits="userSpaceOnUse"
    x="0"
    y="0"
    width="335"
    height="289"
  >
    <path
      d="M167.479 288.965C259.975 288.965 334.957 224.278 334.957 144.482C334.957 64.687 259.975 0 167.479 0C74.9827 0 0 64.687 0 144.482C0 224.278 74.9827 288.965 167.479 288.965Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask6_3359_5952)">
    <path
      d="M210.245 113.559C214.539 113.559 218.02 110.073 218.02 105.774C218.02 101.475 214.539 97.9897 210.245 97.9897C205.95 97.9897 202.469 101.475 202.469 105.774C202.469 110.073 205.95 113.559 210.245 113.559Z"
      fill="url(#paint6_linear_3359_5952)"
    />
  </g>
  <mask
    id="mask7_3359_5952"
    style="mask-type: luminance"
    maskUnits="userSpaceOnUse"
    x="0"
    y="0"
    width="335"
    height="289"
  >
    <path
      d="M167.479 288.965C259.975 288.965 334.957 224.278 334.957 144.482C334.957 64.687 259.975 0 167.479 0C74.9827 0 0 64.687 0 144.482C0 224.278 74.9827 288.965 167.479 288.965Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask7_3359_5952)">
    <path
      d="M151.627 113.559C155.922 113.559 159.403 110.073 159.403 105.774C159.403 101.475 155.922 97.9897 151.627 97.9897C147.333 97.9897 143.852 101.475 143.852 105.774C143.852 110.073 147.333 113.559 151.627 113.559Z"
      fill="url(#paint7_linear_3359_5952)"
    />
  </g>
  <mask
    id="mask8_3359_5952"
    style="mask-type: luminance"
    maskUnits="userSpaceOnUse"
    x="0"
    y="0"
    width="335"
    height="289"
  >
    <path
      d="M167.479 288.965C259.975 288.965 334.957 224.278 334.957 144.482C334.957 64.687 259.975 0 167.479 0C74.9827 0 0 64.687 0 144.482C0 224.278 74.9827 288.965 167.479 288.965Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask8_3359_5952)">
    <path
      d="M210.247 109.709C208.068 109.709 206.317 107.955 206.317 105.774V72.9682C206.317 58.9391 194.909 47.5619 180.939 47.5619C166.925 47.5619 155.56 58.9819 155.56 72.9682V105.774C155.56 107.955 153.809 109.709 151.63 109.709C149.451 109.709 147.699 107.955 147.699 105.774V72.9682C147.699 54.6192 162.61 39.6919 180.939 39.6919C199.267 39.6919 214.178 54.6192 214.178 72.9682V105.774C214.135 107.955 212.384 109.709 210.247 109.709Z"
      fill="url(#paint8_linear_3359_5952)"
    />
  </g>
  <mask
    id="mask9_3359_5952"
    style="mask-type: luminance"
    maskUnits="userSpaceOnUse"
    x="0"
    y="0"
    width="335"
    height="289"
  >
    <path
      d="M167.479 288.965C259.975 288.965 334.957 224.278 334.957 144.482C334.957 64.687 259.975 0 167.479 0C74.9827 0 0 64.687 0 144.482C0 224.278 74.9827 288.965 167.479 288.965Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask9_3359_5952)">
    <path
      d="M160.813 178.315C164.494 178.315 167.478 175.328 167.478 171.643C167.478 167.958 164.494 164.97 160.813 164.97C157.132 164.97 154.148 167.958 154.148 171.643C154.148 175.328 157.132 178.315 160.813 178.315Z"
      fill="url(#paint9_linear_3359_5952)"
    />
  </g>
  <mask
    id="mask10_3359_5952"
    style="mask-type: luminance"
    maskUnits="userSpaceOnUse"
    x="0"
    y="0"
    width="335"
    height="289"
  >
    <path
      d="M167.479 288.965C259.975 288.965 334.957 224.278 334.957 144.482C334.957 64.687 259.975 0 167.479 0C74.9827 0 0 64.687 0 144.482C0 224.278 74.9827 288.965 167.479 288.965Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask10_3359_5952)">
    <path
      d="M202.47 178.315C206.151 178.315 209.135 175.328 209.135 171.643C209.135 167.958 206.151 164.97 202.47 164.97C198.789 164.97 195.805 167.958 195.805 171.643C195.805 175.328 198.789 178.315 202.47 178.315Z"
      fill="url(#paint10_linear_3359_5952)"
    />
  </g>
  <mask
    id="mask11_3359_5952"
    style="mask-type: luminance"
    maskUnits="userSpaceOnUse"
    x="0"
    y="0"
    width="335"
    height="289"
  >
    <path
      d="M167.479 288.965C259.975 288.965 334.957 224.278 334.957 144.482C334.957 64.687 259.975 0 167.479 0C74.9827 0 0 64.687 0 144.482C0 224.278 74.9827 288.965 167.479 288.965Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask11_3359_5952)">
    <path
      d="M206.356 237.254C204.134 237.254 202.297 235.458 202.297 233.191C202.297 228.443 194.308 223.097 182.858 223.097C171.408 223.097 163.418 228.4 163.418 233.191C163.418 235.415 161.624 237.254 159.36 237.254C157.095 237.254 155.301 235.458 155.301 233.191C155.301 223.011 167.392 215.013 182.815 215.013C198.239 215.013 210.329 223.011 210.329 233.191C210.372 235.415 208.578 237.254 206.356 237.254Z"
      fill="url(#paint11_linear_3359_5952)"
    />
  </g>
  <mask
    id="mask12_3359_5952"
    style="mask-type: luminance"
    maskUnits="userSpaceOnUse"
    x="0"
    y="0"
    width="335"
    height="289"
  >
    <path
      d="M167.479 288.965C259.975 288.965 334.957 224.278 334.957 144.482C334.957 64.687 259.975 0 167.479 0C74.9827 0 0 64.687 0 144.482C0 224.278 74.9827 288.965 167.479 288.965Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask12_3359_5952)">
    <path
      d="M273.947 55.0044C253.781 55.0044 237.46 71.3431 237.46 91.5313C237.46 98.6314 239.511 105.218 243.015 110.821L233.273 128.058L257.968 124.38L257.883 124.294C262.753 126.69 268.179 128.058 273.99 128.058C294.155 128.058 310.476 111.72 310.476 91.5313C310.476 71.3431 294.113 55.0044 273.947 55.0044Z"
      fill="url(#paint12_linear_3359_5952)"
    />
  </g>
  <mask
    id="mask13_3359_5952"
    style="mask-type: luminance"
    maskUnits="userSpaceOnUse"
    x="0"
    y="0"
    width="335"
    height="289"
  >
    <path
      d="M167.479 288.965C259.975 288.965 334.957 224.278 334.957 144.482C334.957 64.687 259.975 0 167.479 0C74.9827 0 0 64.687 0 144.482C0 224.278 74.9827 288.965 167.479 288.965Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask13_3359_5952)">
    <path
      d="M274.589 68.6484C277.751 68.6484 280.485 69.4183 282.792 71.0009C285.099 72.5407 286.851 74.9359 288.133 78.1437C289.372 81.3516 290.012 85.8426 290.012 91.574C290.012 97.3482 289.371 101.882 288.09 105.09C286.808 108.298 285.099 110.693 282.963 112.19C280.827 113.687 278.092 114.457 274.803 114.457C271.513 114.457 268.778 113.687 266.557 112.19C264.335 110.65 262.583 108.34 261.302 105.261C260.02 102.139 259.422 97.7331 259.422 92.0017C259.422 84.0034 260.661 78.1865 263.139 74.5937C265.916 70.6159 269.718 68.6484 274.589 68.6484ZM274.803 76.5184C273.393 76.5184 272.154 76.9889 271.086 77.8871C270.017 78.7853 269.163 80.3251 268.608 82.5064C268.052 84.6878 267.753 87.8957 267.753 92.13C267.753 97.6476 268.394 101.454 269.718 103.55C271.043 105.646 272.709 106.715 274.803 106.715C276.811 106.715 278.391 105.731 279.588 103.721C281.04 101.326 281.724 97.2626 281.724 91.6168C281.724 85.8854 281.083 81.9504 279.801 79.7691C278.52 77.6305 276.853 76.5184 274.803 76.5184Z"
      fill="#00A4B9"
    />
  </g>
  <defs>
    <linearGradient
      id="paint0_linear_3359_5952"
      x1="0"
      y1="200.831"
      x2="334.988"
      y2="200.831"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="1.22e-06" stop-color="#E9F7FE" />
      <stop offset="1" stop-color="#F1FAFE" />
    </linearGradient>
    <linearGradient
      id="paint1_linear_3359_5952"
      x1="59.3319"
      y1="189.172"
      x2="229.205"
      y2="189.172"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="1.09e-06" stop-color="#B6D7E3" />
      <stop offset="1" stop-color="#AFD3F0" />
    </linearGradient>
    <linearGradient
      id="paint2_linear_3359_5952"
      x1="73.7797"
      y1="104.249"
      x2="118.363"
      y2="104.249"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="1.09e-06" stop-color="#B6D7E3" />
      <stop offset="0.0847576" stop-color="#ACD2E3" />
      <stop offset="0.2944" stop-color="#96CAE6" />
      <stop offset="0.5113" stop-color="#84C5E9" />
      <stop offset="0.7382" stop-color="#7AC4EC" />
      <stop offset="0.9911" stop-color="#76C3ED" />
    </linearGradient>
    <linearGradient
      id="paint3_linear_3359_5952"
      x1="88.6091"
      y1="194.538"
      x2="118.361"
      y2="194.538"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="1.09e-06" stop-color="#A2D1E2" />
      <stop offset="1" stop-color="#AFD3F0" />
    </linearGradient>
    <linearGradient
      id="paint4_linear_3359_5952"
      x1="120.94"
      y1="74.698"
      x2="158.081"
      y2="74.698"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="1.09e-06" stop-color="#B6D7E3" />
      <stop offset="1" stop-color="#AFD3F0" />
    </linearGradient>
    <linearGradient
      id="paint5_linear_3359_5952"
      x1="100.848"
      y1="189.172"
      x2="270.614"
      y2="189.172"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="1.22e-06" stop-color="#E9F7FE" />
      <stop offset="1" stop-color="#DFF3FD" />
    </linearGradient>
    <linearGradient
      id="paint6_linear_3359_5952"
      x1="210.231"
      y1="113.56"
      x2="210.231"
      y2="97.9929"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="1.09e-06" stop-color="#B6D7E3" />
      <stop offset="0.0847576" stop-color="#ACD2E3" />
      <stop offset="0.2944" stop-color="#96CAE6" />
      <stop offset="0.5113" stop-color="#84C5E9" />
      <stop offset="0.7382" stop-color="#7AC4EC" />
      <stop offset="0.9911" stop-color="#76C3ED" />
    </linearGradient>
    <linearGradient
      id="paint7_linear_3359_5952"
      x1="151.61"
      y1="113.56"
      x2="151.61"
      y2="97.9929"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="1.09e-06" stop-color="#B6D7E3" />
      <stop offset="0.0847576" stop-color="#ACD2E3" />
      <stop offset="0.2944" stop-color="#96CAE6" />
      <stop offset="0.5113" stop-color="#84C5E9" />
      <stop offset="0.7382" stop-color="#7AC4EC" />
      <stop offset="0.9911" stop-color="#76C3ED" />
    </linearGradient>
    <linearGradient
      id="paint8_linear_3359_5952"
      x1="147.698"
      y1="74.698"
      x2="214.148"
      y2="74.698"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="1.09e-06" stop-color="#B6D7E3" />
      <stop offset="1" stop-color="#AFD3F0" />
    </linearGradient>
    <linearGradient
      id="paint9_linear_3359_5952"
      x1="154.165"
      y1="171.662"
      x2="167.493"
      y2="171.662"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="1.09e-06" stop-color="#B6D7E3" />
      <stop offset="1" stop-color="#AFD3F0" />
    </linearGradient>
    <linearGradient
      id="paint10_linear_3359_5952"
      x1="195.793"
      y1="171.662"
      x2="209.121"
      y2="171.662"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="1.09e-06" stop-color="#B6D7E3" />
      <stop offset="1" stop-color="#AFD3F0" />
    </linearGradient>
    <linearGradient
      id="paint11_linear_3359_5952"
      x1="155.338"
      y1="226.12"
      x2="210.389"
      y2="226.12"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="5.4e-07" stop-color="#C5E8F4" />
      <stop offset="1" stop-color="#C4D8E0" />
    </linearGradient>
    <linearGradient
      id="paint12_linear_3359_5952"
      x1="233.269"
      y1="91.536"
      x2="310.454"
      y2="91.536"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="2.17e-06" stop-color="#CFE0EE" />
      <stop offset="1" stop-color="#E1F4FD" />
    </linearGradient>
  </defs>
</svg>
