import { DEFAULT_COUNTRY, getImageExample } from '@shabic/constants';

type LocationType =
  | 'ROOFTOP'
  | 'RANGE_INTERPOLATED'
  | 'GEOMETRIC_CENTER'
  | 'APPROXIMATE';

export interface IAddress {
  street: string;
  city: string;
  poBox: string;
  zipCode: string;
  country?: string;
  latitude?: Nullable<number>;
  longitude?: Nullable<number>;
  locationType?: LocationType;
}

type AddressKeys = keyof Omit<
  IAddress,
  'latitude' | 'longitude' | 'locationType'
>;

const fieldMap: Record<string, AddressKeys> = {
  plus_code: 'street',
  street_number: 'street',
  route: 'street',
  postal_code: 'zipCode',
  locality: 'city',
  country: 'country',
};

export interface ICustomerAddressResponse extends IAddress {
  id: number;
  state: 'ARCHIVED' | 'ACTIVE';
}

export type ICustomerAddressPayload = Omit<
  ICustomerAddressResponse,
  'id' | 'state'
>;

export class Address {
  flagUrl?: string;
  street: string;
  poBox: string;
  zipCode: string;
  city: string;
  country?: string;
  latitude: Nullable<number>;
  longitude: Nullable<number>;
  locationType?: LocationType;

  constructor(payload: IAddress) {
    this.street = payload.street;
    this.poBox = payload.poBox;
    this.zipCode = payload.zipCode;
    this.city = payload.city;
    this.country = payload.country || DEFAULT_COUNTRY;
    this.flagUrl = getImageExample();
    this.latitude = payload.latitude || null;
    this.longitude = payload.longitude || null;
    this.locationType = payload.locationType;
  }

  get fullName() {
    return `${this.street}, ${this.city} ${this.zipCode}, ${this.country}`;
  }

  get formatedName() {
    return `${this.poBox}, ${this.street}, ${this.city} ${this.zipCode}, ${this.country}`;
  }

  static fromGoogleApi(payload: google.maps.GeocoderResult) {
    const latlng = payload.geometry.location.toJSON();

    const address: IAddress = {
      latitude: latlng?.lat,
      longitude: latlng?.lng,
      locationType: payload.geometry.location_type,
    } as IAddress;

    payload.address_components.forEach(el => {
      el.types.forEach(type => {
        const key = fieldMap[type] as AddressKeys;
        if (key) {
          address[key] = address[key]
            ? address[key] + ' ' + el.long_name
            : el.long_name;
        }
      });
    });

    return new Address(address);
  }
}

export class CustomerAddress extends Address {
  id: number;
  state: 'ACTIVE' | 'ARCHIVED';

  constructor(payload: ICustomerAddressResponse) {
    super(payload);

    this.id = payload.id;
    this.state = payload.state;
  }
}

export class CompanyAddress extends Address {
  constructor(payload: IAddress) {
    super(payload);
  }
}

// Google geocoding
// location_type
// ROOFTOP - indicates that the returned result reflects a precise geocode.
// RANGE_INTERPOLATED - indicates that the returned result reflects an approximation (usually on a road) interpolated between two precise points (such as intersections). Interpolated results are generally returned when rooftop geocodes are unavailable for a street address.
// GEOMETRIC_CENTER - indicates that the returned result is the geometric center of a result such as a polyline (for example, a street) or polygon (region).
// APPROXIMATE - indicates that the returned result is approximate.
