import { Icon } from '@shabic/icon';
import { Time } from './time.model';

export class TimelinePoint {
  private _time?: Time;

  private _completed = false;
  private _isActive = false;

  get isActive() {
    return this._isActive;
  }

  get completed() {
    return this._completed;
  }

  get date() {
    return this._time;
  }

  constructor(public id: string, public name: string, public icon: Icon) {}

  activate() {
    this._isActive = true;
  }

  complete(time: string) {
    this._time = new Time(time);
    this._completed = true;
  }
}
