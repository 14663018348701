<div class="flex gap-6 desktop:gap-8 my-1">
  <div class="flex items-center gap-2 text-secondary min-w-0">
    <ng-template [shabicIcon]="icon"></ng-template>

    <shabic-headline classes="truncate" variant="h4">
      <span class="text-secondary">
        {{ name | translate }}
      </span>
    </shabic-headline>
  </div>

  <div class="flex items-center grow">
    <hr class="text-secondary grow h-[2px]" />
  </div>
</div>
