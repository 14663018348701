<shabic-dropdown [wrapped]="true">
  <span target class="text-primary font-semibold text-sm uppercase">
    {{ currentLanguage$ | async }}
  </span>

  <div content>
    <ul *ngIf="currentLanguage$ | async as lang" class="grid gap-4 p-4">
      <li *ngFor="let language of languages">
        <a
          class="text-sm font-medium w-full block hover:scale-125 origin-center hover:cursor-pointer uppercase"
          [class.font-bold]="lang === language"
          (click)="onChangeLanguage(language)"
        >
          {{ language }}
        </a>
      </li>
    </ul>
  </div>
</shabic-dropdown>
