import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { DropzoneComponent } from './dropzone.component';
import { UiModule } from '@shabic/ui';
import { IconModule } from '@shabic/icon';

@NgModule({
  declarations: [DropzoneComponent],
  imports: [CommonModule, NgxDropzoneModule, UiModule, IconModule],
  exports: [DropzoneComponent],
})
export class DropzoneModule {}
