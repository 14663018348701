<shabic-layout>
  <shabic-login-layout>
    <shabic-login-form
      [model]="login"
      *ngIf="(currentStep$ | async)?.id === 'form'"
    ></shabic-login-form>

    <div shabicLoginForm *ngIf="(currentStep$ | async)?.id === 'message'">
      <shabic-icon-verify-checkmark-invert
        class="flex justify-center"
      ></shabic-icon-verify-checkmark-invert>

      <p class="font-semibold text-lg text-white text-center mt-5.5">
        {{ 'message.account_not_confirmed' | translate }}
      </p>
    </div>
  </shabic-login-layout>
</shabic-layout>
