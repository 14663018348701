import { IProductResponse, Product } from './product.model';
import { Time } from './time.model';

export interface IQuotaResponse {
  quotationId: string;
  expirationTime: string;
  barCode: string;
  availableQuantity: number;
  pricePerUnit: number;
  vat: number;
  discount: number;
  specialNotes: string;
  errorCode: string;
  companyId: number;
}

export class Quota {
  quotationId: string;
  expirationTime: Time;
  barCode: string;
  availableQuantity: number;
  pricePerUnit: number;
  vat: number;
  discount: number;
  specialNotes: string;
  errorCode?: string;
  product: Product;
  storageValue: {
    quota: IQuotaResponse;
    product: IProductResponse;
  };
  requestedAmount?: number;

  constructor(
    quota: IQuotaResponse,
    product: Product,
    requestedAmount?: number
  ) {
    this.quotationId = quota.quotationId;
    this.expirationTime = new Time(quota.expirationTime);
    this.barCode = quota.barCode;
    this.availableQuantity = quota.availableQuantity;
    this.pricePerUnit = quota.pricePerUnit;
    this.vat = quota.vat;
    this.discount = quota.discount;
    this.specialNotes = quota.specialNotes;
    this.product = product;
    this.storageValue = { quota, product: product.storageValue };
    this.requestedAmount = requestedAmount;
  }

  getTotal(requestedAmount: number | null | undefined) {
    const amount = Math.min(requestedAmount || 1, this.availableQuantity);

    return (this.pricePerUnit + this.vat - this.discount) * amount;
  }

  get isExpired() {
    return this.expirationTime.isPast();
  }
}
