import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { GalleryDialogData } from '@shabic/models';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'shabic-gallery-dialog',
  templateUrl: './gallery-dialog.component.html',
  styleUrls: ['./gallery-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryDialogComponent implements OnInit {
  private _image = new BehaviorSubject<string | null>(null);

  image$ = this._image.asObservable();

  get images() {
    return this.data.images;
  }

  constructor(
    @Inject(DIALOG_DATA) private data: GalleryDialogData,
    public dialogRef: DialogRef
  ) {}

  ngOnInit(): void {
    this._image.next(this.images[0]);
  }

  setImage(index: number) {
    this._image.next(this.images[index]);
  }

  close() {
    this.dialogRef.close();
  }
}
