import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProductService, SnackBarService } from '@shabic/core';
import { AppEvent, EAppEvent, EventBrokerService } from '@shabic/event-broker';
import {
  ListProduct,
  isErrorResponse,
  DeactivateProductDialogData,
} from '@shabic/models';

@Component({
  selector: 'shabic-product-admin-actions',
  templateUrl: './product-admin-actions.component.html',
  styleUrls: ['./product-admin-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductAdminActionsComponent {
  @Input() product!: ListProduct;

  constructor(
    private productService: ProductService,
    private snackbarService: SnackBarService,
    private eventBrokerService: EventBrokerService
  ) {}

  get status() {
    return `status.${this.product.status}`;
  }

  onDeactivate() {
    this.eventBrokerService.emit(
      new AppEvent<DeactivateProductDialogData>(
        EAppEvent.OpenDeactivateProductDialog,
        new DeactivateProductDialogData(this.product, 'DISABLED_BY_ADMIN', () =>
          this.snackbarService.openSnackBar('status.status-will-change')
        )
      )
    );
  }

  onActivate() {
    this.productService
      .changeStatus(this.product, 'ACTIVE')
      .subscribe(response => {
        if (!isErrorResponse(response)) {
          this.snackbarService.openSnackBar('status.status-will-change');
        }
      });
  }
}
