<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    opacity="0.4"
    d="M16.0756 2H19.4616C20.8639 2 22.0001 3.14585 22.0001 4.55996V7.97452C22.0001 9.38864 20.8639 10.5345 19.4616 10.5345H16.0756C14.6734 10.5345 13.5371 9.38864 13.5371 7.97452V4.55996C13.5371 3.14585 14.6734 2 16.0756 2Z"
    fill="currentColor"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M4.53852 2H7.92449C9.32676 2 10.463 3.14585 10.463 4.55996V7.97452C10.463 9.38864 9.32676 10.5345 7.92449 10.5345H4.53852C3.13626 10.5345 2 9.38864 2 7.97452V4.55996C2 3.14585 3.13626 2 4.53852 2ZM4.53852 13.4648H7.92449C9.32676 13.4648 10.463 14.6107 10.463 16.0248V19.4394C10.463 20.8525 9.32676 21.9993 7.92449 21.9993H4.53852C3.13626 21.9993 2 20.8525 2 19.4394V16.0248C2 14.6107 3.13626 13.4648 4.53852 13.4648ZM19.4616 13.4648H16.0756C14.6734 13.4648 13.5371 14.6107 13.5371 16.0248V19.4394C13.5371 20.8525 14.6734 21.9993 16.0756 21.9993H19.4616C20.8639 21.9993 22.0001 20.8525 22.0001 19.4394V16.0248C22.0001 14.6107 20.8639 13.4648 19.4616 13.4648Z"
    fill="currentColor"
  />
</svg>
