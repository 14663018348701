import * as moment from 'moment';

export enum Period {
  Daily = 'day',
  Weekly = 'week',
  Monthly = 'month',
}

export const getPeriodRange = (period: Period, date: Time) => {
  switch (period) {
    case Period.Daily:
      return getDailyPeriod(date);
    case Period.Weekly:
      return getWeeklyPeriod(date);
    case Period.Monthly:
      return getMonthlyPeriod(date);
  }
};

const getDailyPeriod = (date: Time) => ({
  from: date.clone()?.startOf('day'),
  to: date.clone()?.endOf('day'),
});
const getWeeklyPeriod = (date: Time) => ({
  from: date.clone()?.startOf('week')?.startOf('day'),
  to: date.clone()?.endOf('week')?.endOf('day'),
});
const getMonthlyPeriod = (date: Time) => ({
  from: date.clone()?.startOf('month')?.startOf('day'),
  to: date.clone()?.endOf('month')?.endOf('day'),
});

export const changeRange = (
  period: Period,
  date: Time,
  direction: 'back' | 'forward'
) => {
  return date.clone()?.add(direction === 'back' ? -1 : 1, period);
};

export class Time {
  private _value?: moment.Moment;

  static now() {
    return new Time(new Date().toUTCString());
  }

  constructor(value?: string) {
    this._value = value ? moment(value) : undefined;
  }

  toIsoString(): string {
    if (!this._value) {
      return '';
    }

    return this._value.toISOString();
  }

  toFormDateString(): string {
    if (!this._value) {
      return '';
    }

    return this._value.format('YYYY-MM-DD');
  }

  format(format: string): string {
    if (!this._value) {
      return '';
    }

    return this._value.format(format);
  }

  clone() {
    if (!this._value) {
      return null;
    }

    return new Time(this._value?.clone().toISOString());
  }

  add(
    amount: moment.DurationInputArg1,
    unit?: moment.unitOfTime.DurationConstructor
  ) {
    if (!this._value) {
      return null;
    }

    this._value.add(amount, unit);

    return this;
  }

  startOf(unitOfTime: moment.unitOfTime.StartOf) {
    if (!this._value) {
      return null;
    }

    this._value.startOf(unitOfTime);
    return this;
  }

  endOf(unitOfTime: moment.unitOfTime.StartOf) {
    if (!this._value) {
      return null;
    }

    this._value.endOf(unitOfTime);
    return this;
  }

  toString() {
    return this.toIsoString();
  }

  isPast() {
    return this._value ? this._value.isBefore(moment()) : true;
  }
}
