import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { LoginModule } from '@shabic/login';
import { SignUpModule } from '@shabic/sign-up';
import { AppRoutingModule } from './app-routing.module';
import { ProfileModule } from '@shabic/profile';
import { CoreModule } from '@shabic/core';

import { AppComponent } from './app.component';

import { environment } from '../environments/environment';
import { ENV } from '@shabic/models';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { DialogModule } from '@shabic/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';

export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    LoginModule,
    SignUpModule,
    HttpClientModule,
    CoreModule,
    ProfileModule,
    DialogModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxMaskModule.forRoot(),
    MatSnackBarModule,
  ],
  providers: [
    {
      provide: ENV,
      useValue: environment,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
