import { Directive, HostBinding, Input } from '@angular/core';
import { Align } from '../models';

@Directive({
  selector: '[shabicTextAlign]',
})
export class TextAlignDirective {
  @Input() shabicTextAlign?: Align;

  @HostBinding('class')
  get elementClass(): string {
    if (!this.shabicTextAlign) {
      return '';
    }

    switch (this.shabicTextAlign) {
      case 'left':
        return 'text-left justify-self-start';
      case 'right':
        return 'text-right justify-self-end';
      case 'center':
        return 'text-center justify-self-center';
      default:
        return '';
    }
  }
}
