import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'shabic-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFormComponent implements OnChanges {
  private _focus = new BehaviorSubject<boolean>(false);

  @Input() query?: string;
  @Input() hasSearchButton = true;
  @Input() hasBorder = true;
  @Output() search: EventEmitter<string> = new EventEmitter();

  focus$ = this._focus.asObservable();

  form: FormGroup = new FormGroup({
    query: new FormControl(),
  });

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['query'] && typeof changes['query'].currentValue === 'string') {
      this.form
        .get('query')
        ?.patchValue(changes['query'].currentValue, { emitEvent: false });
    }
  }

  onSearch(): void {
    this.search.emit(this.form.value.query);
  }

  onFocus() {
    this._focus.next(true);
  }

  onBlur() {
    this._focus.next(false);
  }
}
