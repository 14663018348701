<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [class.w-3]="size === 'xs'"
  [class.h-3]="size === 'xs'"
>
  <path
    d="M22.0014 9.67002C21.9383 9.48711 21.8234 9.32645 21.6708 9.20753C21.5181 9.0886 21.3342 9.01652 21.1414 9.00002L15.4514 8.17002L12.9014 3.00002C12.8196 2.83095 12.6917 2.68837 12.5325 2.5886C12.3734 2.48883 12.1893 2.43591 12.0014 2.43591C11.8136 2.43591 11.6295 2.48883 11.4704 2.5886C11.3112 2.68837 11.1833 2.83095 11.1014 3.00002L8.55144 8.16002L2.86144 9.00002C2.67636 9.02633 2.50237 9.10399 2.35919 9.22418C2.21602 9.34438 2.1094 9.5023 2.05144 9.68002C1.99839 9.8537 1.99363 10.0385 2.03767 10.2147C2.08171 10.3909 2.1729 10.5517 2.30144 10.68L6.43144 14.68L5.43144 20.36C5.39107 20.5484 5.406 20.7445 5.47443 20.9247C5.54286 21.1048 5.66188 21.2613 5.81717 21.3754C5.97246 21.4895 6.15741 21.5563 6.34977 21.5678C6.54213 21.5792 6.7337 21.5349 6.90144 21.44L12.0014 18.77L17.1014 21.44C17.2418 21.5192 17.4003 21.5606 17.5614 21.56C17.7733 21.5608 17.9799 21.4942 18.1514 21.37C18.3066 21.2589 18.4267 21.1057 18.4976 20.9285C18.5684 20.7513 18.5871 20.5575 18.5514 20.37L17.5514 14.69L21.6814 10.69C21.8258 10.5677 21.9325 10.4069 21.9892 10.2264C22.0458 10.0458 22.0501 9.8529 22.0014 9.67002ZM15.8514 13.67C15.7357 13.7824 15.6489 13.9212 15.5984 14.0744C15.5479 14.2276 15.5352 14.3908 15.5614 14.55L16.2814 18.75L12.5214 16.75C12.3753 16.6777 12.2145 16.6401 12.0514 16.6401C11.8884 16.6401 11.7276 16.6777 11.5814 16.75L7.82144 18.75L8.54144 14.55C8.56768 14.3908 8.55501 14.2276 8.50451 14.0744C8.45401 13.9212 8.36719 13.7824 8.25144 13.67L5.25144 10.67L9.46144 10.06C9.62344 10.0375 9.77744 9.97556 9.90994 9.87967C10.0424 9.78379 10.1494 9.65686 10.2214 9.51002L12.0014 5.70002L13.8814 9.52002C13.9535 9.66686 14.0604 9.79379 14.1929 9.88968C14.3254 9.98556 14.4794 10.0475 14.6414 10.07L18.8514 10.68L15.8514 13.67Z"
    fill="currentColor"
  />
</svg>
