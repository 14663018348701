import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[shabicSignUpForm]',
})
export class SignUpFormDirective {
  @HostBinding('class')
  get elementClasses(): string {
    return 'max-w-[400px] mx-auto';
  }
}
