export const ADMIN_APP = 'admin';
export const CLIENT_APP = 'client';
export const getImageExample = () => `/assets/logo-image-bg.jpg`;
export const EN = 'en';
export const AR = 'ar';
export const API_URL = '/api';
export const TOKEN_KEY = 'token';
export const REFRESH_TOKEN_KEY = 'refreshToken';
export const CART_STORAGE_KEY = 'cart';
export const CURRENCY_STORAGE_KEY = 'currency';
export const LANGUAGE_STORAGE_KEY = 'langKey';
export const MY_QUOTAS_STORAGE_KEY = 'quotas';
export const BIRTHDAY_STORAGE_KEY = 'birthday';
export const GOV_ID_STORAGE_KEY = 'govId';
export const DEFAULT_COUNTRY = 'Saudi Arabia';
export const SUCCESS = 'success';
export const FAILURE = 'failure';
export const NOT_AUTH_PATHS = [
  '/sign-in',
  '/sign-up',
  '/forgot-password',
  '/gov-api',
];
export const NOT_VERIFIED_PATHS = ['/profile', '/profile/company-info'];
export const PHONE_PATTERN =
  /^[\+][0-9]{3}[-\s\.][0-9]{2}[-\s\.][0-9]{3}[-\s\.][0-9]{4}$/im; // eslint-disable-line
export const LIST_SIZE = 12;
export const getCompanyProductsQuery = (company: number) =>
  btoa(
    JSON.stringify({
      attributeFilter: {
        COMPANY: [company],
      },
      companyIds: [company],
      page: 0,
      skipMyProducts: true,
      sort: 'lastModifiedDate,asc',
      statuses: ['ACTIVE'],
    })
  );
export const getProductRoute = (id: string) => `/catalog/${id}`;
export const SNACK_BAR_DURATION = 5000;
export const LOGO_SOURCE = '/assets/shabic-logo-white.svg';
export const LOGO_SOURCE_PRIMARY = '/assets/shabic-logo-primary.svg';
export const SIGN_UP_WAVE = '/assets/wave-one.svg';
export const SIGN_IN_WAVE = '/assets/wave-two.svg';
export const SEARCH_BG = '/assets/search-page-bg.png';
export const DESKTOP_BREAKPOINT = 996;
export const DESKTOP_LAYOUT = `(min-width: ${DESKTOP_BREAKPOINT}px)`;
export const MAX_BANNER_COUNT = 10;
export const DEFAULT_BANNER_DURATION = 5000;

export * from './validators';
