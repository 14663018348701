import { ChangeDetectionStrategy, Component } from '@angular/core';
import { I18nService, SnackBarService } from '@shabic/core';
import { DialogService } from '@shabic/dialog';

@Component({
  selector: 'shabic-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  constructor(
    private i18nService: I18nService,
    private dialogService: DialogService,
    private snackBarService: SnackBarService
  ) {
    this.i18nService.init();
    this.dialogService.init();
    this.snackBarService.init();
  }
}
