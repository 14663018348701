<ul
  *ngIf="items$ | async as list"
  class="flex items-center justify-center gap-1"
>
  <li>
    <button
      shabicButton="navigation"
      type="button"
      [disabled]="backArrowDisabled"
      (click)="onBack()"
    >
      <span class="ltr:rotate-90 rtl:-rotate-90">
        <ng-template shabicIcon="arrow"></ng-template>
      </span>
    </button>
  </li>

  <li *ngFor="let page of list">
    <button
      shabicButton="navigation"
      type="button"
      [class.bg-grey-300]="!page.disabled"
      [class.active]="page.active"
      [disabled]="page.disabled"
      (click)="setActive(page)"
    >
      <span class="font-medium">{{ page.label }}</span>
    </button>
  </li>

  <li>
    <button
      shabicButton="navigation"
      type="button"
      [disabled]="nextArrowDisabled"
      (click)="onNext()"
    >
      <span class="ltr:-rotate-90 rtl:rotate-90">
        <ng-template shabicIcon="arrow"></ng-template>
      </span>
    </button>
  </li>
</ul>
