import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TimelinePoint } from '@shabic/models';

@Component({
  selector: 'shabic-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineComponent {
  @Input() steps!: TimelinePoint[];

  getPointStyles(point: TimelinePoint) {
    return {
      'bg-secondary': point.isActive || point.completed,
      'bg-grey-350': !point.completed && !point.isActive,
    };
  }

  getPointLineStyles(point: TimelinePoint) {
    return {
      active: point.isActive,
      'bg-secondary': point.isActive || point.completed,
      'bg-grey-350': !point.completed || point.isActive,
    };
  }
}
