import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import {
  ADMIN_APP,
  NOT_AUTH_PATHS,
  NOT_VERIFIED_PATHS,
  TOKEN_KEY,
} from '@shabic/constants';
import { AppEvent, EAppEvent, EventBrokerService } from '@shabic/event-broker';
import {
  ApiToken,
  AuthResponse,
  ENV,
  IEnvironment,
  isErrorResponse,
} from '@shabic/models';
import { map, Observable, tap } from 'rxjs';
import { AuthService, StorageService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanLoad, CanActivate {
  constructor(
    private storageService: StorageService,
    private authService: AuthService,
    private eventBrokerService: EventBrokerService,
    private router: Router,
    @Inject(ENV) private env: IEnvironment
  ) {}

  get redirectUrl() {
    return this.env.app === ADMIN_APP
      ? this.router.parseUrl('/sign-in')
      : this.router.parseUrl('/');
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> {
    if (NOT_AUTH_PATHS.some(path => state.url.includes(path))) {
      return !this.isAuthorized(state) || this.redirectUrl;
    } else {
      return this.isAuthorized(state) || this.redirectUrl;
    }
  }

  canLoad(): boolean | UrlTree | Observable<boolean | UrlTree> {
    return this.isAuthorized() || this.redirectUrl;
  }

  private isAuthorized(state?: RouterStateSnapshot) {
    const token = this.storageService.retrieve<string>(TOKEN_KEY, true);
    const tokenPayload = token ? new ApiToken(token) : null;

    const notVerifiedPath = NOT_VERIFIED_PATHS.some(path =>
      state?.url.includes(path)
    );

    if (
      tokenPayload &&
      tokenPayload.isExpired &&
      this.authService.hasRefreshTokenAndNotExpired
    ) {
      return this.authService.refreshToken().pipe(
        tap(response => {
          if (isErrorResponse(response)) {
            this.eventBrokerService.emit(
              new AppEvent(EAppEvent.LogOut, 'message.session-expired')
            );
          }
        }),
        map(
          response =>
            response.payload instanceof AuthResponse || this.redirectUrl
        )
      );
    }

    const result = tokenPayload
      ? !tokenPayload.isExpired &&
        (notVerifiedPath ? true : tokenPayload.verified)
      : false;

    return result;
  }
}
