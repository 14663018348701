import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { PromotionService } from '@shabic/core';
import { List, Promotion, PromotionType } from '@shabic/models';
import {
  BehaviorSubject,
  map,
  Subject,
  Subscription,
  takeUntil,
  timer,
} from 'rxjs';

@Component({
  selector: 'shabic-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerComponent implements OnInit, OnChanges, OnDestroy {
  private _destroy = new Subject<void>();
  private _banners = new BehaviorSubject<Promotion[] | null>(null);
  private _activeBanner = new BehaviorSubject<number>(0);
  private _swapSubscription?: Subscription;

  @Input() type?: PromotionType;

  activeBanner$ = this._activeBanner.asObservable().pipe(
    map(index => index + 1),
    map(index => {
      const banners = this._banners.value;

      if (Array.isArray(banners)) {
        return index < banners.length ? index : index % banners.length;
      } else {
        return 0;
      }
    })
  );
  banners$ = this._banners.asObservable();

  constructor(private promotionService: PromotionService) {}

  ngOnInit(): void {
    this.getPromotions();

    this.activeBanner$.pipe(takeUntil(this._destroy)).subscribe(index => {
      const banners = this._banners.value;

      if (Array.isArray(banners)) {
        const promotion = banners[index];
        this.nextBanner(promotion);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('type' in changes) {
      if (!changes['type'].isFirstChange()) {
        this.getPromotions();
      }
    }
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  private nextBanner(promotion?: Promotion) {
    if (!promotion) {
      return;
    }

    const duration = promotion.duration;

    this._swapSubscription = timer(duration)
      .pipe(takeUntil(this._destroy))
      .subscribe(() => {
        this._activeBanner.next(this._activeBanner.value + 1);
      });
  }

  private getPromotions() {
    if (!this.type) {
      return;
    }

    this.promotionService
      .getPromotions({
        types: [this.type],
        active: true,
        page: 0,
        size: 100,
      })
      .subscribe(response => {
        if (response instanceof List) {
          this._banners.next(response.content);

          this._swapSubscription?.unsubscribe();
          this._activeBanner.next(0);
        }
      });
  }
}
