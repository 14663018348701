<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  [class.w-12]="size === 'lg'"
  [class.h-12]="size === 'lg'"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M10.7574 4.97613L3.77081 17.0764C3.6446 17.295 3.57815 17.5428 3.57813 17.7952C3.5781 18.0475 3.64451 18.2954 3.77067 18.514C3.89683 18.7325 4.07831 18.914 4.29684 19.0401C4.51538 19.1663 4.76328 19.2327 5.01562 19.2327H18.9877C19.2401 19.2327 19.488 19.1663 19.7065 19.0401C19.9251 18.914 20.1065 18.7325 20.2327 18.514C20.3589 18.2954 20.4253 18.0475 20.4252 17.7952C20.4252 17.5428 20.3588 17.295 20.2326 17.0764L13.2467 4.97613C13.1205 4.75766 12.9391 4.57624 12.7206 4.45011C12.5022 4.32397 12.2543 4.25757 12.0021 4.25757C11.7498 4.25757 11.502 4.32397 11.2835 4.45011C11.065 4.57624 10.8836 4.75766 10.7574 4.97613Z"
    fill="currentColor"
  />
  <path
    d="M12.0923 8.7085H11.9101C11.4614 8.7085 11.0977 9.07224 11.0977 9.52093V13.4042C11.0977 13.8529 11.4614 14.2167 11.9101 14.2167H12.0923C12.541 14.2167 12.9048 13.8529 12.9048 13.4042V9.52093C12.9048 9.07224 12.541 8.7085 12.0923 8.7085Z"
    fill="white"
  />
  <path
    d="M12.0012 17.2966C12.5002 17.2966 12.9048 16.892 12.9048 16.393C12.9048 15.894 12.5002 15.4894 12.0012 15.4894C11.5022 15.4894 11.0977 15.894 11.0977 16.393C11.0977 16.892 11.5022 17.2966 12.0012 17.2966Z"
    fill="white"
  />
</svg>
