<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_487_5799)">
    <path
      d="M10.5938 22.5469C11.7587 22.5469 12.7031 21.6025 12.7031 20.4375C12.7031 19.2725 11.7587 18.3281 10.5938 18.3281C9.42877 18.3281 8.48438 19.2725 8.48438 20.4375C8.48438 21.6025 9.42877 22.5469 10.5938 22.5469Z"
      fill="currentColor"
      class="transition-colors"
    />
    <path
      d="M17.6719 22.5469C18.8368 22.5469 19.7812 21.6025 19.7812 20.4375C19.7812 19.2725 18.8368 18.3281 17.6719 18.3281C16.5069 18.3281 15.5625 19.2725 15.5625 20.4375C15.5625 21.6025 16.5069 22.5469 17.6719 22.5469Z"
      fill="currentColor"
      class="transition-colors"
    />
    <path
      d="M0.703121 2.85941H3.63528L7.02985 14.7428L6.7636 15.2753C6.06254 16.6759 7.08024 18.3281 8.6505 18.3281H20.4843C20.8729 18.3281 21.1874 18.0136 21.1874 17.6249C21.1874 17.2363 20.8729 16.9218 20.4843 16.9218H8.6505C8.12836 16.9218 7.78711 16.372 8.02153 15.9042L8.21587 15.5156H20.4843C20.798 15.5156 21.0741 15.3076 21.1606 15.0054L23.9731 5.16171C24.0335 4.94956 23.991 4.72156 23.8584 4.54512C23.7252 4.36934 23.5172 4.26565 23.2968 4.26565H5.49948L4.84169 1.96331C4.75516 1.6612 4.47916 1.45312 4.16533 1.45312H0.703121C0.314482 1.45312 0 1.76761 0 2.15625C0 2.54488 0.314482 2.85941 0.703121 2.85941Z"
      fill="currentColor"
      class="transition-colors"
    />
  </g>
  <defs>
    <clipPath id="clip0_487_5799">
      <rect width="24" height="24" fill="white" />
    </clipPath>
  </defs>
</svg>
