import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormArray,
  FormControl,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { fromEvent, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'shabic-passcode',
  templateUrl: './passcode.component.html',
  styleUrls: ['./passcode.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasscodeComponent),
      multi: true,
    },
  ],
})
export class PasscodeComponent
  implements ControlValueAccessor, OnInit, OnDestroy, AfterViewInit
{
  private destroy: Subject<void> = new Subject();
  private formArray = new FormArray(
    Array(6)
      .fill(null)
      .map(
        () =>
          new FormControl('', [Validators.required, Validators.maxLength(1)])
      )
  );

  controls: FormControl[] = this.formArray.controls as FormControl[];

  @ViewChildren('input') inputs?: QueryList<ElementRef<HTMLInputElement>>;

  private _onChange?: (value: string | null) => undefined;
  private _onTouched?: () => undefined;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.formArray.valueChanges
      .pipe(takeUntil(this.destroy))
      .subscribe(value => {
        if (this._onChange) {
          this._onChange(value.join(''));
        }
      });

    fromEvent<ClipboardEvent>(this.el.nativeElement, 'paste').subscribe(
      event => {
        event.preventDefault();
        const code = event.clipboardData?.getData('text');

        if (code && code.length === 6) {
          this.formArray.patchValue(code.split(''));
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  ngAfterViewInit(): void {
    if (!this.inputs) {
      return;
    }

    Array.from(this.inputs)[0].nativeElement.focus();
    if (this._onTouched) {
      this._onTouched();
    }
  }

  onBackspace(input: HTMLInputElement, event: KeyboardEvent): void {
    if (event.keyCode === 8 && input.value === '') {
      event.preventDefault();
      event.stopPropagation();

      if (!this.inputs) {
        return;
      }

      const index = this.inputs
        ?.toArray()
        .findIndex(el => el.nativeElement === input);

      if (index === undefined) {
        return;
      }

      if (index < this.inputs.length && index > 0) {
        this.inputs.get(index - 1)?.nativeElement.focus();
      }
    }
  }

  onChange(input: HTMLInputElement, event: KeyboardEvent): void {
    const isAvailableCode = (code: number) =>
      (code >= 48 && code <= 57) || (code >= 96 && code <= 105);
    if (!this.inputs || !isAvailableCode(event.keyCode)) {
      return;
    }

    const index = this.inputs
      ?.toArray()
      .findIndex(el => el.nativeElement === input);

    if (index === undefined) {
      return;
    }

    if (index < this.inputs.length && input.value) {
      const control = this.formArray.controls[index];
      const controlValue = control.value || '';
      this.inputs.get(index + 1)?.nativeElement.focus();

      if (controlValue.length > 1) {
        control.patchValue(controlValue[0], { emitEvent: false });
      }
    }
  }

  writeValue(value: string | null): void {
    if (typeof value === 'string' && value.length) {
      this.controls.forEach((el, index) => el.patchValue(value[index]));
    }
  }

  registerOnChange(fn: (value: string | null) => undefined): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => undefined): void {
    this._onTouched = fn;
  }
}
