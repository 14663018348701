import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AccountService } from '@shabic/core';
import { FormComponent } from '@shabic/form-control';
import { ForgotPassword } from '@shabic/models';
import { SUCCESS } from '@shabic/constants';

@Component({
  selector: 'shabic-login-forgot-password-form',
  templateUrl: './login-forgot-password-form.component.html',
  styleUrls: ['./login-forgot-password-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginForgotPasswordFormComponent extends FormComponent<unknown> {
  @Input() model!: ForgotPassword<AccountService>;
  @Input() completed = false;

  onSubmit(): void {
    this.submitForm(this.model.formGroup, this.model.sendEmail()).subscribe(
      response => {
        if (response === SUCCESS) {
          this.completed = true;
        }
      }
    );
  }
}
