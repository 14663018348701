import { CdkMenuTrigger } from '@angular/cdk/menu';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Input,
  OnDestroy,
  OnInit,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, takeUntil } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { InputDirective } from '../directives/input.directive';
import { FormFieldComponent } from '../form-field/form-field.component';
import { Option } from '@shabic/models';

@Component({
  selector: 'shabic-select-control',
  templateUrl: './select-control.component.html',
  styleUrls: ['./select-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectControlComponent
  implements AfterContentInit, OnDestroy, OnInit
{
  private _loading = new BehaviorSubject<boolean>(false);
  private _eventDelay = 200;
  private _destroy: Subject<void> = new Subject();
  private _opened = new BehaviorSubject<boolean>(false);
  lastValue?: Option;
  opened$ = this._opened.asObservable();
  @Input() options: Observable<Option[]> = of<Option[]>([]);

  @ContentChild(InputDirective) input?: InputDirective;
  @ViewChild(CdkMenuTrigger) trigger?: CdkMenuTrigger;

  options$!: Observable<Option[]>;
  loading$ = this._loading.asObservable();

  query = '';

  get isOpen(): boolean | undefined {
    return this.trigger?.isOpen();
  }

  get previewClasses(): string {
    return this.input?.class + 'inline-block';
  }

  constructor(
    @SkipSelf() private formField: FormFieldComponent,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.options$ = this.options.pipe(tap(() => this._loading.next(false)));
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  ngAfterContentInit(): void {
    const input = this.input;

    if (!input) {
      return;
    }

    this.formField.value$.pipe(takeUntil(this._destroy)).subscribe(val => {
      if (typeof val === 'string') {
        this.query = val;
      } else {
        this.query = '';
      }

      this.cd.detectChanges();
    });

    input.focus$
      ?.pipe(debounceTime(this._eventDelay), takeUntil(this._destroy))
      .subscribe(() => {
        if (!this.trigger?.isOpen()) {
          this.trigger?.open();
        }
      });

    input.blur$
      ?.pipe(debounceTime(this._eventDelay), takeUntil(this._destroy))
      .subscribe(() => {
        if (this.trigger?.isOpen()) {
          this.trigger?.close();
        }
        this.formField.pathValue(this.lastValue?.label);
      });
  }

  onOpen() {
    this._opened.next(true);
    this._loading.next(true);
    this.formField.pathValue('');
  }

  onClose() {
    this._opened.next(false);
  }

  onSelect(option: Option) {
    this.lastValue = option;
    this.formField.pathValue(option.label);
    this.trigger?.close();
    this.onClose();
  }
}
