<div
  class="rounded-2xl flex gap-3 desktop:gap-4 items-center shadow-3xl bg-grey-350 px-3 py-1"
>
  <ng-template
    classes="h-5.5 w-5.5 desktop:h-8 desktop:w-8"
    shabicIcon="picture"
  ></ng-template>

  <div class="grid gap-0.5">
    <shabic-headline variant="h5">
      <span class="desktop text-base">
        {{ 'message.add-pictures-to-products' | translate }}
      </span>
    </shabic-headline>

    <p class="text-grey font-medium text-xs desktop:text-sm">
      {{ 'message.add-pictures-to-products.description' | translate }}
    </p>
  </div>

  <button
    type="button"
    shabicButton="navigation"
    class="text-primary ml-auto self-start"
    (click)="onClose()"
  >
    <ng-template shabicIcon="close"></ng-template>
  </button>
</div>
