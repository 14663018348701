import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  ApiClient,
  ApiErrorResponse,
  ApiResponse,
  AuthResponse,
  ENV,
  IAuthResponse,
  IEnvironment,
} from '@shabic/models';
import { StorageService } from '@shabic/core';
import { TOKEN_KEY } from '@shabic/constants';

type CreateAccountPayload = {
  email: string;
  password: string;
};

@Injectable()
export class SignUpService extends ApiClient {
  override readonly url: string = '/register';

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    @Inject(ENV) env: IEnvironment
  ) {
    super(env);
  }

  createAccount(
    body: CreateAccountPayload
  ): Observable<ApiResponse<AuthResponse | ApiErrorResponse>> {
    return this.http.post<IAuthResponse>(this.apiModelUrl, body).pipe(
      map(response => {
        const apiResponse = new ApiResponse(new AuthResponse(response));
        this.storageService.store(TOKEN_KEY, apiResponse.payload.token, true);
        // this.storageService.store(
        //   REFRESH_TOKEN_KEY,
        //   apiResponse.payload.refreshToken,
        //   true
        // );

        return apiResponse;
      }),
      catchError(response =>
        of(new ApiResponse(new ApiErrorResponse(response)))
      )
    );
  }
}
