import { AbstractControl, ValidatorFn } from '@angular/forms';
import { values, sum } from 'ramda';

export function quantityValidator(quantity: number): ValidatorFn {
  return (control: AbstractControl) => {
    if (!control) {
      return null;
    }

    const value = sum(values(control.value));

    if (control.touched && value < quantity) {
      return {
        form: 'error.total-quantity-less-than-should-be',
      };
    } else if (control.touched && value > quantity) {
      return {
        form: 'error.total-quantity-more-than-should-be',
      };
    } else {
      return null;
    }
  };
}
