<footer class="bg-primary text-white min-h-[132px]">
  <div class="container mx-auto p-3 flex justify-between gap-3">
    <div>
      <img
        class="max-w-[152px] mt-4 mb-2"
        [src]="logoSource"
        alt="Shabic logo"
      />

      <shabic-navigation
        *ngIf="hasNav"
        class="block mb-4 mt-5"
        [navigation]="navigation"
      ></shabic-navigation>
    </div>

    <p class="font-medium text-xs text-grey uppercase self-end mb-4">
      {{ 'copyrights' | translate }}
    </p>
  </div>
</footer>
