import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ApiUserService, ClipboardService } from '@shabic/core';
import { FormComponent } from '@shabic/form-control';
import { isErrorResponse } from '@shabic/models';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'shabic-api-user',
  templateUrl: './api-user.component.html',
  styleUrls: ['./api-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApiUserComponent
  extends FormComponent<unknown>
  implements OnInit, OnDestroy
{
  private _showPassword = new BehaviorSubject<boolean>(false);
  private _fetching = new BehaviorSubject<boolean>(false);

  readonly users$ = this.apiUserService.get();
  readonly showPassword$ = this._showPassword.asObservable();
  readonly fetching$ = this._fetching.asObservable();

  formGroup = new FormGroup({
    id: new FormControl(),
    password: new FormControl(),
  });

  constructor(
    private apiUserService: ApiUserService,
    private clipboardService: ClipboardService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getApiUser();
  }

  ngOnDestroy(): void {
    this.onDestoy();
  }

  onShowPassword() {
    this._showPassword.next(true);
  }

  onCopy(key: string) {
    const value = this.formGroup.get(key)?.value;

    this.clipboardService.copy(value);
  }

  private getApiUser() {
    this._fetching.next(true);

    this.apiUserService.get().subscribe(response => {
      this._fetching.next(false);
      if (!isErrorResponse(response) && response.payload) {
        this.formGroup.patchValue(response.payload);
      }
      this.formGroup.disable();
    });
  }
}
