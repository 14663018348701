import { Pipe, PipeTransform } from '@angular/core';
import { sortBy } from '../helpers';

@Pipe({
  name: 'sortBy',
})
export class SortByPipe implements PipeTransform {
  transform(
    value: Record<string, any>[],
    ...args: [string, 'desc' | 'asc']
  ): unknown {
    const [property, direction] = args;

    return sortBy(value, { property, direction });
  }
}
