<div
  [class.rtl:desktop:-mr-3]="hasPreview"
  [class.ltr:desktop:-ml-3]="hasPreview"
  class="flex -my-3"
>
  <ul
    *ngIf="hasPreview"
    class="hidden desktop:flex flex-col gap-1 snap-y overflow-y-auto empty:hidden shrink-0 max-h-[400px] p-3 mb-3"
  >
    <li
      class="snap-center"
      *ngFor="let media of product.getMedias(); let index = index"
      (click)="setImage(index)"
    >
      <img
        shabicImage
        class="w-10 h-10 outline outline-2 rounded-2xl hover:shadow-primary-150 transition-shadow z-10 hover:z-1 relative"
        [class.outline-primary]="(image$ | async) === media"
        [class.outline-grey-350]="(image$ | async) !== media"
        [src]="media"
      />
    </li>
  </ul>

  <div class="relative py-3 desktop:w-full">
    <img
      shabicImage
      class="rounded-3xl image border border-grey-350 cursor-zoom-in"
      [class.lg]="size === 'lg'"
      [class.md]="size === 'md'"
      [src]="image$ | async"
      (click)="onZoom()"
    />
  </div>
</div>
