import { ApiErrorResponse } from './api.model';

type PHASE = 'PENDING' | 'ERROR' | 'COMPLETED';

interface IReportError {
  reasonKey: string;
}

interface IReportField {
  value: unknown;
  errors: IReportError[];
  fieldName: string;
}

interface IReportItem<T> {
  row: number;
  model: T;
  fields: IReportField[];
}

interface IParsingErrors<T> {
  limited: number;
  reportItems: IReportItem<T>[];
  error?: string;
}

export interface ITask<T> {
  id: string;
  phase: PHASE;
  data: IParsingErrors<T> | T;
}

export class Task<T> {
  id: string;
  status: PHASE;
  data: ApiErrorResponse[] | T;

  constructor(payload: ITask<T>) {
    this.id = payload.id;
    this.status = payload.phase;
    if (payload.phase === 'ERROR') {
      this.data = this.parseErrors(payload.data as IParsingErrors<T>);
    } else {
      this.data = payload.data as T;
    }
  }

  private parseErrors(data: IParsingErrors<T>): ApiErrorResponse[] {
    if (data.error) {
      return [
        new ApiErrorResponse({
          error: {
            message: data.error,
          },
        }),
      ];
    }

    return data.reportItems.map(el => {
      const error = {
        model: el.model,
        'invalid-params': el.fields.map(error => {
          return {
            name: error.fieldName,
            reason: error.errors.map(e => e.reasonKey),
          };
        }),
      };

      return new ApiErrorResponse({ error });
    });
  }
}
