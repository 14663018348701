import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SIGN_UP_WAVE } from '@shabic/constants';

@Component({
  selector: 'shabic-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent {
  signUpWave = SIGN_UP_WAVE;

  @Input() hasGradient = false;
  @Input() hasFooterNav = true;
  @Input() hasSignUpWave = false;
}
