import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { AR, EN, LANGUAGE_STORAGE_KEY } from '@shabic/constants';
import { EAppEvent, EventBrokerService } from '@shabic/event-broker';
import { Language, Languages } from '@shabic/models';
import { filter } from 'rxjs/operators';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  constructor(
    private translateService: TranslateService,
    private eventBrokerService: EventBrokerService,
    private storageService: StorageService,
    private dateAdapter: DateAdapter<any>
  ) {}

  init(): void {
    let language = this.translateService.getBrowserCultureLang();

    if (language) {
      language = language.split('-')[0] || EN;
    }

    const storedLanguage = this.storageService.retrieve<string>(
      LANGUAGE_STORAGE_KEY,
      true
    );

    language =
      storedLanguage && [EN, AR].includes(storedLanguage)
        ? storedLanguage
        : !Languages.some(el => language?.includes(el))
        ? EN
        : language;

    this.translateService.use(language as Language).subscribe(() => {
      document.documentElement.setAttribute('dir', this.textDirection);
      this.subscribeOnChangeLanguage();
    });
  }

  private subscribeOnChangeLanguage(): void {
    this.eventBrokerService.events$
      .pipe(filter(event => event.type === EAppEvent.ChangeLanguage))
      .subscribe(event => {
        const lang: Language = event.payload as Language;
        this.translateService.use(lang).subscribe(() => {
          this.dateAdapter.setLocale(lang);
          this.storageService.store(LANGUAGE_STORAGE_KEY, lang, true);
          document.documentElement.setAttribute('lang', lang);
          document.documentElement.setAttribute('dir', this.textDirection);
        });
      });
  }

  private get textDirection(): 'rtl' | 'ltr' {
    const lang = this.translateService.currentLang;

    return lang === 'en' ? 'ltr' : 'rtl';
  }
}
