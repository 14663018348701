import { DIALOG_DATA } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Company } from '@shabic/models';

@Component({
  selector: 'shabic-company-profile-dialog',
  templateUrl: './company-profile-dialog.component.html',
  styleUrls: ['./company-profile-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyProfileDialogComponent {
  get company(): Company {
    return this.data;
  }

  constructor(@Inject(DIALOG_DATA) public data: Company) {}
}
