import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DIALOG_DATA } from '@angular/cdk/dialog';
import { LOGO_SOURCE } from '@shabic/constants';
import { AccountService } from '@shabic/core';
import { ForgotPassword, ForgotPasswordDialogData } from '@shabic/models';

@Component({
  selector: 'shabic-login-forgot-password',
  templateUrl: './login-forgot-password.component.html',
  styleUrls: ['./login-forgot-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginForgotPasswordComponent {
  readonly logoSource = LOGO_SOURCE;
  forgotPassword: ForgotPassword<AccountService>;
  token?: string;

  constructor(
    public accountService: AccountService,
    @Inject(DIALOG_DATA) private dialogData: ForgotPasswordDialogData
  ) {
    this.forgotPassword = new ForgotPassword(accountService);
    const { token, data } = this.dialogData;

    if (token && data) {
      this.token = token;

      try {
        const payload = JSON.parse(atob(data));

        this.forgotPassword.formGroup.patchValue(payload);
      } catch (e) {
        // console.log(e);
      }

      this.forgotPassword.nextStep();
    }
  }
}
