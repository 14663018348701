import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LOGO_SOURCE } from '@shabic/constants';
import { FOOTER_NAV } from '@shabic/data';

@Component({
  selector: 'shabic-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  @Input() hasNav = true;

  readonly logoSource = LOGO_SOURCE;
  readonly navigation = FOOTER_NAV;
}
