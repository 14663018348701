import { Languages, Language } from './language.model';

export type TranslatedType<T> = Record<Language, T>;

export class Translated<T> {
  private _map: Map<Language, T>;

  constructor(config?: TranslatedType<T>) {
    this._map = new Map();

    if (config) {
      for (const lang in config) {
        const key = lang as Language;
        this._map.set(key, config[key]);
      }
    }
  }

  static prepareConfig<T>(config: Record<string, T>): TranslatedType<T> {
    const languageMap = new Map();
    Array.from(Languages)
      .map(lang => [lang, ''])
      .forEach(([key, value]) => languageMap.set(key, value));

    for (const lang in config) {
      languageMap.set(lang, config[lang]);
    }

    return Object.fromEntries(languageMap);
  }

  get(lang: Language): T | undefined {
    return this._map.get(lang);
  }
}
