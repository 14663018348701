<shabic-dialog [fullSize]="true">
  <div class="desktop:grid desktop:grid-cols-2">
    <div class="pt-8 pb-[200px]">
      <div class="flex justify-center mb-10">
        <img [src]="logoSource" class="w-[170px]" alt="Shabic logo" />
      </div>

      <shabic-login-forgot-password-form
        class="block w-full"
        [model]="forgotPassword"
        *ngIf="(forgotPassword.currentStep$ | async)?.id === 'email'"
      >
        <ng-container message>
          <div class="flex justify-center mb-4 mt-6">
            <ng-template shabicIcon="mail"></ng-template>
          </div>

          <shabic-headline variant="h2" align="center">
            {{ 'message.check-mailbox' | translate }}
          </shabic-headline>

          <shabic-sub-headline>
            {{
              'passcode.email-passcode'
                | translate
                  : { email: forgotPassword.formGroup.get('email')?.value }
            }}
          </shabic-sub-headline>
        </ng-container>
      </shabic-login-forgot-password-form>

      <shabic-login-create-new-password-form
        class="block w-full"
        [model]="forgotPassword"
        [token]="token"
        *ngIf="(forgotPassword.currentStep$ | async)?.id === 'password'"
      >
        <ng-container message>
          <shabic-verify-checkmark
            class="flex justify-center mt-6 mb-4"
          ></shabic-verify-checkmark>

          <shabic-headline class="block mt-5" variant="h2" align="center">
            {{ 'message.password-changed' | translate }}
          </shabic-headline>

          <p class="text-center">
            <a class="mt-5" (click)="onLogin()" shabicButton="secondary">
              {{ 'button.login' | translate }}
            </a>
          </p>
        </ng-container>
      </shabic-login-create-new-password-form>
    </div>

    <div
      class="hidden relative bg-main-pattern-to-r-b p-8 desktop:flex flex-col justify-between"
    >
      <ng-template shabicIcon="shabic"></ng-template>

      <div class="text-[40px] text-white font-semibold leading-tight">
        {{ 'common.innovate' | translate }}. <br />
        {{ 'common.engage' | translate }}. <br />
        {{ 'common.transform' | translate }}. <br />
      </div>

      <div class="aside"></div>
    </div>
  </div>
</shabic-dialog>
