import { Directive, HostBinding, Input } from '@angular/core';

type Variant = '' | 'primary' | 'secondary' | 'danger' | 'warning' | 'green';

@Directive({
  selector: '[shabicBadge]',
})
export class BadgeDirective {
  @Input() shabicBadge?: Variant;

  @HostBinding('class')
  get elementClass(): string {
    const initClasses =
      'inline-flex text-xs font-medium whitespace-nowrap rounded-xl p-2';

    switch (this.shabicBadge) {
      case 'primary':
        return `${initClasses} bg-primary text-white`;
      case 'secondary':
        return `${initClasses} text-secondary bg-secondary/[0.1] rounded-xl`;
      case 'danger':
        return `${initClasses} bg-red text-white`;
      case 'warning':
        return `${initClasses} bg-yellow text-primary`;
      case 'green':
        return `${initClasses} bg-green text-white`;
      default:
        return `${initClasses} text-grey`;
    }
  }
}
