import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  ApiErrorResponse,
  ApiMarketplace,
  ApiResponse,
  BarChartConfig,
  ENV,
  IEnvironment,
  IListResponse,
  IOrderParams,
  IOrderPayload,
  IOrderResponse,
  List,
  Order,
  OrderState,
  OrderStatus,
  orderStatuses,
  Time,
  OrderBulk,
} from '@shabic/models';
import { collectBy, prop } from 'ramda';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OrderService extends ApiMarketplace {
  override readonly url = '/orders';
  constructor(
    private http: HttpClient,
    @Inject(ENV) env: IEnvironment,
    private translateService: TranslateService
  ) {
    super(env);
  }

  create(payload: IOrderPayload) {
    return this.http.post<IOrderResponse[]>(this.apiModelUrl, payload).pipe(
      map(response => new ApiResponse(response.map(el => new Order(el)))),
      catchError(response =>
        of(new ApiResponse(new ApiErrorResponse(response)))
      )
    );
  }

  createBulk(payload: IOrderPayload[]) {
    return this.http
      .post<IOrderResponse[]>(this.getEndpoint('/bulk'), payload)
      .pipe(
        map(response => new ApiResponse(response.map(el => new Order(el)))),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }

  getItems(params: IOrderParams) {
    const query = new HttpParams({
      fromObject: params || {},
    });

    return this.http
      .get<IListResponse<IOrderResponse[]>>(this.apiModelUrl, {
        params: query,
      })
      .pipe(
        map(response => {
          return new List(response, content => {
            const bulks = collectBy(prop('groupId'), content);

            return bulks.map(
              el =>
                new OrderBulk({
                  forSupplier: params.forSupplier,
                  groupId: el[0].groupId,
                  orders: el,
                })
            );
          });
        }),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }

  changeStatus(payload: {
    order: Order;
    status: OrderStatus;
    forSupplier?: boolean;
    reason?: string;
  }) {
    const body = {
      forSupplier: payload.forSupplier || false,
      status: payload.status,
      reason: payload.reason || '',
    };

    return this.http
      .put<IOrderResponse>(
        this.getEndpoint(`/${payload.order.id}/status`),
        body
      )
      .pipe(
        map(response => new ApiResponse(new Order(response))),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }

  returnOrder(payload: { order: Order; reason?: string }) {
    const body = {
      returns: payload.order.products.map(el => {
        return {
          productId: el.product.id,
          quotationId: el.quotationId,
          quantity: el.quantity,
        };
      }),
      reason: payload.reason || '',
    };

    return this.http
      .post<IOrderResponse>(
        this.getEndpoint(`/${payload.order.id}/return`),
        body
      )
      .pipe(
        map(response => new ApiResponse(new Order(response))),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }

  changeState(payload: {
    order: Order;
    state: OrderState;
    forSupplier: boolean;
  }) {
    const body = {
      state: payload.state,
      forSupplier: payload.forSupplier,
    };

    return this.http
      .put<IOrderResponse>(this.getEndpoint(`/${payload.order.id}/state`), body)
      .pipe(
        map(response => new ApiResponse(new Order(response))),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }

  getOrderReport(from?: Time | null, to?: Time | null) {
    const fromObject: Record<string, string | number> = {};

    from ? (fromObject['from'] = from.toIsoString()) : undefined;
    to ? (fromObject['to'] = to.toIsoString()) : undefined;

    const params = new HttpParams({ fromObject });

    return this.http
      .get<Record<string, number>>(this.getApiEndpoint('/reports/orders'), {
        params,
      })
      .pipe(
        map(response => {
          const labels = orderStatuses.map(el =>
            this.translateService.instant(`status.${el.label}`)
          );
          const values = orderStatuses.map(el => {
            return el.statuses.reduce(
              (acc, curr) => (response[curr] || 0) + acc,
              0
            );
          });

          return new ApiResponse({
            label: 'common.orders-report',
            config: new BarChartConfig(values, labels),
          });
        }),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }
}
