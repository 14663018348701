import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BannerComponent } from './banner.component';
import { UiModule } from '@shabic/ui';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [BannerComponent],
  imports: [CommonModule, UiModule, RouterModule],
  exports: [BannerComponent],
})
export class BannerModule {}
