import { Pipe, PipeTransform } from '@angular/core';
import { Time } from '@shabic/models';

@Pipe({
  name: 'time',
})
export class TimePipe implements PipeTransform {
  transform(value: Time | unknown, ...args: string[]): string {
    const format = args[0];
    if (value instanceof Time) {
      return value.format(format);
    }

    return typeof value === 'string' ? value : '';
  }
}
