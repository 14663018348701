import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from './layout.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { NavigationComponent } from './navigation/navigation.component';
import { LayoutContentDirective } from './directives/layout-content.directive';
import { UiModule } from '@shabic/ui';
import { IconModule } from '@shabic/icon';
import { TranslateModule } from '@ngx-translate/core';
import { EventBrokerModule } from '@shabic/event-broker';
import { UtilsModule } from '@shabic/utils';
import { CurrencyDropdownComponent } from './currency-dropdown/currency-dropdown.component';
import { LanguageDropdownComponent } from './language-dropdown/language-dropdown.component';

import { ClientLayoutComponent } from './components';
import { BannerModule } from '../banner/banner.module';

@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    NavigationComponent,
    LayoutContentDirective,
    CurrencyDropdownComponent,
    LanguageDropdownComponent,
    ClientLayoutComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    UiModule,
    IconModule,
    TranslateModule,
    EventBrokerModule,
    UtilsModule,
    BannerModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    NavigationComponent,
    LayoutComponent,
    LayoutContentDirective,
    CurrencyDropdownComponent,
    ClientLayoutComponent,
  ],
})
export class LayoutModule {}
