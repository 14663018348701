import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, takeUntil } from 'rxjs';
import { Login, LoginStep } from './models';

@Component({
  selector: 'shabic-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject();

  login: Login = new Login();
  currentStep$: Observable<LoginStep> = this.login.currentStep$;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.login.completed$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.router.navigate(['/']));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
