<form
  shabicSignUpForm
  [formGroup]="formGroup"
  autocomplete="off"
  (ngSubmit)="onSubmit()"
>
  <ng-container [ngSwitch]="state$ | async">
    <ng-container *ngSwitchCase="'form'">
      <div class="flex justify-center mb-5.5">
        <img
          src="/assets/create-company-image.svg"
          class="w-[155px] desktop:w-auto"
        />
      </div>

      <shabic-headline variant="h2" align="center">
        {{ 'profile-created.title' | translate }}
      </shabic-headline>
      <shabic-sub-headline class="block mt-0.5">
        {{ 'company.add-info' | translate }}
      </shabic-sub-headline>

      <div [formGroup]="companyForm">
        <div class="flex justify-center">
          <shabic-form-field class="mt-5.5">
            <shabic-image-control
              (changed)="patchValue(companyForm, 'mediaId', $event)"
            >
              <input
                type="file"
                name=""
                hidden
                shabicInput
                accept="image/png,image/jpeg,image/jpg"
              />
            </shabic-image-control>
          </shabic-form-field>
        </div>

        <shabic-form-field class="text-primary mt-5.5">
          <shabic-form-field-label forAttribute="name-en">
            {{ 'common.company-name-en' | translate }}
          </shabic-form-field-label>

          <input
            type="text"
            shabicInput
            name=""
            formControlName="nameFirst"
            id="name-en"
          />
        </shabic-form-field>

        <shabic-form-field class="text-primary mt-4">
          <shabic-form-field-label forAttribute="name-ar">
            {{ 'common.company-name-ar' | translate }}
          </shabic-form-field-label>

          <input
            type="text"
            shabicInput
            name=""
            dir="rtl"
            formControlName="nameSecond"
            id="name-ar"
          />
        </shabic-form-field>

        <shabic-form-field class="text-primary mt-4" name="common.cr-number">
          <shabic-form-field-label forAttribute="cd-number">
            {{ 'common.cr-number' | translate }}
          </shabic-form-field-label>

          <input
            type="text"
            shabicInput
            name=""
            class="ltr:pr-6 rtl:pl-6"
            id="cd-number"
            [placeholder]="'common.cr-number' | translate"
            mask="000000999999"
            formControlName="crNumber"
          />
        </shabic-form-field>

        <shabic-form-field class="text-primary mt-4">
          <shabic-form-field-label forAttribute="date">
            {{ 'common.formation-date' | translate }}
          </shabic-form-field-label>

          <input
            type="text"
            shabicInput
            name=""
            id="date"
            [placeholder]="'common.formation-date' | translate"
            formControlName="formationDate"
            [max]="today"
            matInput
            [matDatepicker]="picker"
          />

          <button type="button" addon (click)="picker.open()">
            <ng-template shabicIcon="calendar"></ng-template>
          </button>

          <mat-datepicker #picker></mat-datepicker>
        </shabic-form-field>

        <shabic-form-field class="text-primary mt-4">
          <shabic-form-field-label forAttribute="type">
            {{ 'common.company-type' | translate }}
          </shabic-form-field-label>

          <shabic-select-control [options]="types$">
            <input
              type="text"
              shabicInput
              name=""
              class="ltr:pr-6 rtl:pl-6"
              id="type"
              formControlName="type"
              [placeholder]="'common.company-type' | translate"
            />
          </shabic-select-control>
        </shabic-form-field>

        <shabic-form-field class="text-primary mt-4">
          <shabic-form-field-label forAttribute="activity">
            {{ 'common.business-activity' | translate }}
          </shabic-form-field-label>

          <shabic-select-control [options]="activities$">
            <input
              type="text"
              shabicInput
              name=""
              class="ltr:pr-6 rtl:pl-6"
              id="activity"
              formControlName="businessActivity"
              [placeholder]="'common.business-activity' | translate"
            />
          </shabic-select-control>
        </shabic-form-field>

        <shabic-form-field class="text-primary mt-4">
          <shabic-form-field-label forAttribute="classification">
            {{ 'common.classification' | translate }}
          </shabic-form-field-label>

          <shabic-select-control [options]="classifications$">
            <input
              type="text"
              shabicInput
              name=""
              class="ltr:pr-6 rtl:pl-6"
              id="classification"
              formControlName="classification"
              [placeholder]="'common.classification' | translate"
            />
          </shabic-select-control>
        </shabic-form-field>

        <shabic-form-field class="text-primary mt-4">
          <shabic-form-field-label forAttribute="company-email">
            {{ 'common.company-email' | translate }}
          </shabic-form-field-label>

          <input
            type="text"
            shabicInput
            name=""
            formControlName="email"
            id="company-email"
          />
        </shabic-form-field>

        <shabic-form-field
          class="text-primary mt-4"
          name="common.company-phone"
        >
          <shabic-form-field-label forAttribute="company-phone">
            {{ 'common.company-phone' | translate }}
          </shabic-form-field-label>

          <input
            type="text"
            shabicInput
            formControlName="phone"
            id="company-phone"
            prefix="+966"
            mask="-00-000-0000"
          />
        </shabic-form-field>

        <hr class="my-4 text-grey-350" />

        <shabic-headline variant="h5">
          {{ 'common.company-hq' | translate }}
        </shabic-headline>

        <shabic-map
          (address)="onAddressUpdate($event)"
          class="mt-4"
        ></shabic-map>

        <shabic-form-field class="text-primary mt-5">
          <shabic-form-field-label forAttribute="street">
            {{ 'common.street' | translate }}
          </shabic-form-field-label>

          <input
            type="text"
            shabicInput
            name=""
            formControlName="street"
            id="street"
          />
        </shabic-form-field>

        <shabic-form-field class="text-primary mt-4">
          <shabic-form-field-label forAttribute="city">
            {{ 'common.city' | translate }}
          </shabic-form-field-label>

          <input
            type="text"
            shabicInput
            name=""
            formControlName="city"
            id="city"
          />
        </shabic-form-field>

        <shabic-form-field class="text-primary mt-4" name="common.po-box">
          <shabic-form-field-label forAttribute="po-box">
            {{ 'common.po-box' | translate }}
          </shabic-form-field-label>

          <input
            type="text"
            shabicInput
            name=""
            mask="000999"
            formControlName="poBox"
            id="po-box"
          />
        </shabic-form-field>

        <shabic-form-field class="text-primary mt-4" name="common.zipcode">
          <shabic-form-field-label forAttribute="zipcode">
            {{ 'common.zipcode' | translate }}
          </shabic-form-field-label>

          <input
            type="text"
            shabicInput
            name=""
            formControlName="zipCode"
            mask="000999"
            id="zipcode"
          />
        </shabic-form-field>

        <shabic-form-field class="text-primary mt-4">
          <shabic-form-field-label forAttribute="website">
            {{ 'common.company-website' | translate }}
          </shabic-form-field-label>

          <input
            type="text"
            shabicInput
            name=""
            id="website"
            formControlName="webSite"
          />
        </shabic-form-field>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'verify'">
      <div class="flex justify-center mb-5.5">
        <img
          src="/assets/create-company-image.svg"
          class="w-[155px] desktop:w-auto"
        />
      </div>

      <shabic-headline variant="h2" align="center">
        {{ 'profile-created.title' | translate }}
      </shabic-headline>

      <shabic-sub-headline class="block mt-0.5">
        {{ 'company.add-info' | translate }}
      </shabic-sub-headline>

      <div
        [formGroup]="verifyForm"
        class="rounded-2xl border border-grey-350 px-4 py-5 mt-4"
      >
        <shabic-form-field>
          <shabic-form-field-label forAttribute="custom">
            <span class="normal-case text-sm font-normal text-primary">
              {{ 'company-type.custom' | translate }}
            </span>
          </shabic-form-field-label>
        </shabic-form-field>

        <ul class="mt-3">
          <li
            *ngFor="let control of fileControls"
            class="border-t border-grey-350 first:border-t-0 py-1"
          >
            <shabic-form-field>
              <shabic-file-control [control]="control">
                <input
                  type="file"
                  shabicInput
                  class="hidden"
                  accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/png,image/jpeg,image/jpg"
                  name=""
                />
              </shabic-file-control>
            </shabic-form-field>
          </li>
        </ul>

        <shabic-form-field-errors
          [errors]="verifyForm.errors"
        ></shabic-form-field-errors>
      </div>
    </ng-container>

    <div shabicLoginForm *ngSwitchCase="'message'">
      <div class="flex justify-center">
        <ng-template shabicIcon="verify-checkmark"></ng-template>
      </div>

      <p class="font-semibold text-lg text-primary text-center mt-5.5">
        {{ 'message.profile_has_been_created' | translate }}
      </p>

      <p class="text-center mt-5.5">
        <a routerLink="/sign-in" shabicButton="default" (click)="onLogout()">
          {{ 'button.login' | translate }}
        </a>
      </p>
    </div>
  </ng-container>

  <button
    type="submit"
    *ngIf="(state$ | async) !== 'message'"
    shabicButton="secondary"
    class="w-full mt-4 gap-1"
    [disabled]="formGroup.invalid || (loading$ | async)"
  >
    {{ 'button.confirm' | translate }}

    <shabic-spinner *ngIf="loading$ | async"></shabic-spinner>
  </button>

  <p *ngIf="(state$ | async) === 'form'" class="text-center mt-3">
    <a
      class="font-semibold text-primary text-sm hover:underline"
      routerLink="/"
      (click)="onLogout()"
    >
      {{ 'button.create-company-later' | translate }}
    </a>
  </p>
</form>
