import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as ibantools from 'ibantools';

export function ibanValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    return ibantools.isValidIBAN(control.value)
      ? null
      : { notIBAN: 'error.not-a-iban' };
  };
}
