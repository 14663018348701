<div class="grid grid-cols-extra-details">
  <div class="flex flex-col gap-4 px-4">
    <shabic-property-with-label label="common.product-code">
      {{ product.productCode }}
    </shabic-property-with-label>

    <shabic-property-with-label label="common.bar-code">
      {{ product.barCode }}
    </shabic-property-with-label>

    <shabic-property-with-label label="common.government-code">
      {{ product.govtProductCode }}
    </shabic-property-with-label>

    <shabic-property-with-label label="common.country-of-origin">
      {{ product.countryOfOrigin }}
    </shabic-property-with-label>

    <shabic-property-with-label label="common.color">
      {{ product.color }}
    </shabic-property-with-label>

    <shabic-property-with-label
      class="block desktop:hidden"
      label="common.product-measurements-primary"
    >
      {{ product.measurements.primary }}
    </shabic-property-with-label>
  </div>

  <div
    class="flex flex-col gap-4 px-4 border-grey-400 ltr:border-l rtl:border-r"
  >
    <shabic-property-with-label label="common.weight">
      {{ product.weight }}
    </shabic-property-with-label>

    <shabic-property-with-label label="common.dimension">
      {{ product.dimension }}
    </shabic-property-with-label>

    <shabic-property-with-label label="common.type-of-packaging">
      {{ product.package.type }}
    </shabic-property-with-label>

    <shabic-property-with-label label="common.product-packing-primary">
      {{ product.package.primaryUnit }}
    </shabic-property-with-label>

    <shabic-property-with-label label="common.product-packing-secondary">
      {{ product.package.secondaryUnit }}
    </shabic-property-with-label>

    <shabic-property-with-label
      class="block desktop:hidden"
      label="common.product-measurements-secondary"
    >
      {{ product.measurements.secondary }}
    </shabic-property-with-label>
  </div>

  <div
    class="hidden desktop:flex flex-col gap-4 px-4 border-grey-400 ltr:border-l rtl:border-r"
  >
    <shabic-property-with-label label="common.product-measurements-primary">
      {{ product.measurements.primary }}
    </shabic-property-with-label>

    <shabic-property-with-label label="common.product-measurements-secondary">
      {{ product.measurements.secondary }}
    </shabic-property-with-label>
  </div>
</div>

<div class="flex flex-col gap-3 text-primary p-3 bg-white rounded-2xl mx-4">
  <div class="inline-flex items-center gap-0.5">
    <span [class]="product.food ? 'text-green' : 'text-red'">
      <ng-template
        [shabicIcon]="product.food ? 'check' : 'close'"
      ></ng-template>
    </span>

    {{ 'common.product-like-food-item' | translate }}
  </div>

  <div class="inline-flex items-center gap-0.5">
    <span
      [class]="product.isRequiresSpecialHandling ? 'text-green' : 'text-red'"
    >
      <ng-template
        [shabicIcon]="product.isRequiresSpecialHandling ? 'check' : 'close'"
      ></ng-template>
    </span>

    {{ 'common.product-require-special-handling' | translate }}
  </div>
</div>
