import { DIALOG_DATA } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SUCCESS } from '@shabic/constants';
import { FormComponent } from '@shabic/form-control';
import {
  Bank,
  CreateBankDialogData,
  EditBankDialogData,
  IBankPayload,
} from '@shabic/models';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'shabic-bank-dialog',
  templateUrl: './bank-dialog.component.html',
  styleUrls: ['./bank-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BankDialogComponent extends FormComponent<Bank> implements OnInit {
  formGroup = new FormGroup({
    media: new FormControl(''),
    mediaId: new FormControl(''),
    name: new FormControl('', Validators.required),
    accountNumber: new FormControl('', Validators.required),
    status: new FormControl('yes', Validators.required),
  });

  constructor(
    @Inject(DIALOG_DATA) public data: CreateBankDialogData | EditBankDialogData
  ) {
    super();
  }
  ngOnInit(): void {
    if ('bank' in this.data) {
      const bank = this.data.bank;

      this.formGroup.patchValue({
        media: bank.logo,
        mediaId: bank.logo,
        name: bank.name.get('en'),
        accountNumber: bank.accountNumber,
        status: bank.status === 'ACTIVE' ? 'yes' : 'no',
      });

      this.formGroup.get('name')?.disable();
      this.formGroup.get('mediaId')?.disable();
      this.formGroup.get('accountNumber')?.disable();
    }
  }

  onSubmit(dialog: DialogComponent) {
    const body = {
      ...this.formGroup.value,
      status: this.formGroup.value.status === 'yes' ? 'ACTIVE' : 'DISABLED',
      mediaId: this.formGroup.value.mediaId as unknown as File[],
    };

    const request =
      'bank' in this.data
        ? (body: IBankPayload) => this.update(body)
        : (body: IBankPayload) => this.create(body);

    request(body as IBankPayload).subscribe(status => {
      if (status === SUCCESS) {
        this.data.onSuccess();
        dialog.close();
      }
    });
  }

  private create(body: IBankPayload) {
    if ('onCreate' in this.data) {
      return this.submitForm(this.formGroup, this.data.onCreate(body));
    }

    throw 'Method not provided';
  }

  private update(bank: IBankPayload) {
    if ('onEdit' in this.data) {
      return this.submitForm(this.formGroup, this.data.onEdit(bank.status));
    }

    throw 'Method not provided';
  }
}
