<div
  class="dialog"
  [class.bg-primary]="config?.isPrimaryBg"
  [class.bg-white]="!config?.isPrimaryBg"
  [class.h-full]="config?.isFullSize"
  [class.sized]="!config?.isFullSize"
>
  <div class="flex items-center gap-3 pb-3 rtl:flex-row-reverse">
    <button
      shabicButton
      type="button"
      *ngIf="config?.hasBackBtn"
      (click)="onBack()"
    >
      <ng-template shabicIcon="arrow-left"></ng-template>
    </button>

    <shabic-headline *ngIf="config?.title" variant="h4">
      {{ config?.title || '' | translate }}
    </shabic-headline>

    <button
      *ngIf="config?.hasCloseBtn"
      type="button"
      shabicButton="navigation"
      class="ml-auto"
      (click)="close()"
    >
      <ng-template shabicIcon="close"></ng-template>
    </button>
  </div>

  <div class="content">
    <ng-content></ng-content>
  </div>
</div>
