<shabic-dialog
  #dialog
  name="dialog.request-for-statement"
  [nameOnDesktopHidden]="true"
>
  <div class="hidden desktop:block">
    <shabic-headline variant="h3" align="center">
      {{ 'dialog.request-for-statement' | translate }}
    </shabic-headline>
  </div>

  <form
    class="mt-1 desktop:mt-5"
    action=""
    [formGroup]="formGroup"
    (ngSubmit)="onRequest(dialog)"
  >
    <!-- <div>
      <shabic-form-field>
        <shabic-form-field-label>
          {{ 'common.account-number' | translate }}
        </shabic-form-field-label>

        <input
          type="text"
          formControlName="accountId"
          shabicInput
          class="border border-grey-300"
        />
      </shabic-form-field>
    </div> -->

    <div class="flex gap-3">
      <p class="w-full">
        <shabic-form-field class="text-primary">
          <shabic-form-field-label forAttribute="from-date">
            {{ 'common.from' | translate }}
          </shabic-form-field-label>

          <input
            type="text"
            shabicInput
            id="from-date"
            class="border border-grey-300"
            formControlName="fromDate"
            [max]="toDate || today"
            matInput
            [matDatepicker]="fromPicker"
          />

          <button type="button" addon (click)="fromPicker.open()">
            <ng-template shabicIcon="calendar"></ng-template>
          </button>

          <mat-datepicker #fromPicker></mat-datepicker>
        </shabic-form-field>
      </p>

      <p class="w-full">
        <shabic-form-field class="text-primary">
          <shabic-form-field-label forAttribute="to-date">
            {{ 'common.to' | translate }}
          </shabic-form-field-label>

          <input
            type="text"
            shabicInput
            id="to-date"
            class="border border-grey-300"
            formControlName="toDate"
            [min]="fromDate"
            [max]="today"
            matInput
            [matDatepicker]="toPicker"
          />

          <button type="button" addon (click)="toPicker.open()">
            <ng-template shabicIcon="calendar"></ng-template>
          </button>

          <mat-datepicker #toPicker></mat-datepicker>
        </shabic-form-field>
      </p>
    </div>

    <!-- <div class="mt-5.5">
      <shabic-form-field class="text-primary">
        <shabic-form-field-label>
          <span class="normal-case text-sm">
            {{ 'common.date-type' | translate }}
          </span>
        </shabic-form-field-label>

        <div class="flex gap-5.5 mt-3">
          <shabic-radio-control>
            <input
              shabicInput
              class="peer"
              name="type"
              type="radio"
              id="value"
            />

            <shabic-form-field-label forAttribute="value">
              <span class="normal-case text-sm">
                {{ 'common.value-date' | translate }}
              </span>
            </shabic-form-field-label>
          </shabic-radio-control>

          <shabic-radio-control>
            <input
              shabicInput
              class="peer"
              name="type"
              type="radio"
              id="transaction"
            />

            <shabic-form-field-label forAttribute="transaction">
              <span class="normal-case text-sm">
                {{ 'common.transaction-date' | translate }}
              </span>
            </shabic-form-field-label>
          </shabic-radio-control>
        </div>
      </shabic-form-field>
    </div> -->

    <div class="text-center mt-4">
      <button
        class="w-full"
        type="submit"
        shabicButton="primary"
        [disabled]="formGroup.invalid"
      >
        {{ 'button.request' | translate }}
      </button>
    </div>
  </form>
</shabic-dialog>
