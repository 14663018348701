import { DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'shabic-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent {
  @Input() name?: string;
  @Input() nameOnDesktopHidden = false;
  @Input() fullSize = false;

  constructor(public dialogRef: DialogRef) {}

  close() {
    this.dialogRef.close();
  }
}
