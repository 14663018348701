export type CurrencyType = 'USD' | 'SAR';
export const currencies: CurrencyType[] = ['USD', 'SAR'];

const currencyCountry = {
  USD: 'US',
  SAR: 'SA',
};

export class Currency {
  private _value: CurrencyType;
  private _country: string;

  constructor(payload: CurrencyType) {
    this._value = payload;
    this._country = currencyCountry[payload];
  }

  get value() {
    return this._value;
  }

  get country() {
    return this._country;
  }
}
