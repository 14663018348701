<shabic-drawer-dialog [config]="drawerDialogConfig">
  <ul class="flex flex-col gap-1 px-3 mb-6">
    <li *ngFor="let item of navigation | auth | async">
      <a
        class="flex items-center gap-[20px] text-white/[.75] text-sm font-semibold px-5 py-[20px] rounded-[20px] hover:text-secondary"
        routerLinkActive="active"
        [routerLink]="item['route']"
      >
        <span *ngIf="item['icon']">
          <ng-template [shabicIcon]="item['icon']"></ng-template>
        </span>

        <span>{{ item['label'] | translate }}</span>
      </a>
    </li>

    <li *ngFor="let item of userNavigation | auth | async">
      <a
        class="flex items-center gap-[20px] text-white/[.75] text-sm font-semibold px-5 py-[20px] hover:text-secondary"
        (click)="item['onClick'] && item['onClick']($event)"
        [routerLink]="item['route']"
      >
        <span *ngIf="item['icon']">
          <ng-template [shabicIcon]="item['icon']"></ng-template>
        </span>

        <span>{{ item['label'] | translate }}</span>
      </a>
    </li>
  </ul>

  <a
    routerLink="/add-product"
    *shabicHasRole="ROLE_SUPPLIER"
    shabicButton="secondary"
    class="button gap-1 mx-3"
  >
    <ng-template shabicIcon="plus"></ng-template>

    {{ 'button.add-products' | translate }}
  </a>
</shabic-drawer-dialog>
