import { ProductStatus } from '@shabic/models';

export interface IProductStatus {
  name: string;
  status: ProductStatus;
  active: boolean;
}

export interface IPage {
  name?: number;
  active: boolean;
  disabled: boolean;
}

export interface IOption {
  name: string;
  checked: boolean;
  value: string;
}

export type TableConfig = {
  labels: string[];
  columns: string[];
};
