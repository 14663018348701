<div class="relative">
  <div
    class="flex rounded-2xl items-center justify-center gap-1 cursor-pointer hover:bg-grey-400 transition-colors"
    [class.bg-grey-350]="wrapped"
    [class.px-2]="wrapped"
    [class.py-2]="wrapped"
    [cdkMenuTriggerFor]="menu"
    [cdkMenuPosition]="positions"
    (cdkMenuClosed)="onClose()"
    (cdkMenuOpened)="onOpen()"
  >
    <ng-content select="[target]"></ng-content>

    <span [class.rotate-180]="opened$ | async">
      <ng-template shabicIcon="arrow"></ng-template>
    </span>
  </div>

  <ng-template #menu>
    <div
      class="rounded-2xl overflow-hidden bg-white text-primary border border-grey-350 my-3"
      cdkMenu
    >
      <ng-content select="[content]"></ng-content>
    </div>
  </ng-template>
</div>
