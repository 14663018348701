import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AuthPipe,
  TimePipe,
  ConvertPipe,
  SafeHtmlPipe,
  RangeFormatPipe,
  CurrentLangPipe,
  SortByPipe,
  ProductPricePipe,
  LinkPipe,
  WithPrefixPipe,
} from './pipes';
import { HasRoleDirective } from './directives';

@NgModule({
  imports: [CommonModule],
  declarations: [
    AuthPipe,
    TimePipe,
    ConvertPipe,
    HasRoleDirective,
    SafeHtmlPipe,
    RangeFormatPipe,
    CurrentLangPipe,
    SortByPipe,
    ProductPricePipe,
    LinkPipe,
    WithPrefixPipe,
  ],
  exports: [
    AuthPipe,
    TimePipe,
    ConvertPipe,
    HasRoleDirective,
    SafeHtmlPipe,
    RangeFormatPipe,
    CurrentLangPipe,
    SortByPipe,
    ProductPricePipe,
    LinkPipe,
    WithPrefixPipe,
  ],
})
export class UtilsModule {}
