<form class="grid gap-4" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <div class="grid desktop:grid-cols-2 gap-y-4 gap-x-3">
    <p>
      <shabic-form-field>
        <shabic-form-field-label forAttribute="nameFirst">
          {{ 'common.product-name-en' | translate }}
        </shabic-form-field-label>

        <input
          type="text"
          id="nameFirst"
          class="border border-grey-300"
          shabicInput
          formControlName="nameFirst"
        />
      </shabic-form-field>
    </p>

    <p>
      <shabic-form-field>
        <shabic-form-field-label forAttribute="nameSecond">
          {{ 'common.product-name-ar' | translate }}
        </shabic-form-field-label>

        <input
          type="text"
          shabicInput
          id="nameSecond"
          class="border border-grey-300"
          formControlName="nameSecond"
          dir="rtl"
        />
      </shabic-form-field>
    </p>

    <p>
      <shabic-form-field>
        <shabic-form-field-label forAttribute="brand">
          {{ 'common.product-brand' | translate }}
        </shabic-form-field-label>

        <input
          type="text"
          class="border border-grey-300"
          shabicInput
          id="brand"
          formControlName="brand"
        />
      </shabic-form-field>
    </p>

    <p>
      <shabic-form-field>
        <shabic-form-field-label forAttribute="code">
          {{ 'common.product-code' | translate }}
        </shabic-form-field-label>

        <input
          type="text"
          shabicInput
          class="border border-grey-300"
          id="code"
          formControlName="code"
        />
      </shabic-form-field>
    </p>

    <p>
      <shabic-form-field>
        <shabic-form-field-label forAttribute="barCode">
          {{ 'common.product-bar-code' | translate }}
        </shabic-form-field-label>

        <input
          type="text"
          shabicInput
          class="border border-grey-300"
          id="barCode"
          formControlName="barCode"
        />
      </shabic-form-field>
    </p>

    <p>
      <shabic-form-field>
        <shabic-form-field-label forAttribute="govtProductCode">
          {{ 'common.product-govt-code' | translate }}
        </shabic-form-field-label>

        <input
          type="text"
          shabicInput
          class="border border-grey-300"
          id="govtProductCode"
          formControlName="govtProductCode"
        />
      </shabic-form-field>
    </p>

    <p>
      <shabic-form-field>
        <shabic-form-field-label forAttribute="country">
          {{ 'common.country-of-origin' | translate }}
        </shabic-form-field-label>

        <shabic-select-control [options]="countries$">
          <input
            type="text"
            shabicInput
            class="border border-grey-300 ltr:pr-6 rtl:pl-6"
            id="categoryId"
            formControlName="country"
            [placeholder]="'common.country-of-origin' | translate"
          />
        </shabic-select-control>
      </shabic-form-field>
    </p>
  </div>

  <shabic-group-title
    name="common.product-details"
    icon="edit"
  ></shabic-group-title>

  <div class="grid desktop:grid-cols-2 gap-y-4 gap-x-3">
    <p>
      <shabic-form-field>
        <shabic-form-field-label forAttribute="categoryId">
          {{ 'common.product-category' | translate }}
        </shabic-form-field-label>

        <shabic-select-control [options]="categories$">
          <input
            type="text"
            shabicInput
            class="border border-grey-300 ltr:pr-6 rtl:pl-6"
            id="categoryId"
            formControlName="categoryId"
            [placeholder]="'common.product-category' | translate"
          />
        </shabic-select-control>
      </shabic-form-field>
    </p>

    <p>
      <shabic-form-field>
        <shabic-form-field-label forAttribute="packing">
          {{ 'common.product-packing-primary' | translate }}
        </shabic-form-field-label>

        <input
          type="text"
          shabicInput
          class="border border-grey-300"
          id="packing"
          formControlName="packing"
        />
      </shabic-form-field>
    </p>

    <p>
      <shabic-form-field>
        <shabic-form-field-label forAttribute="packingSecond">
          {{ 'common.product-packing-secondary' | translate }}
        </shabic-form-field-label>

        <input
          type="text"
          shabicInput
          class="border border-grey-300"
          id="packingSecond"
          formControlName="packingSecond"
        />
      </shabic-form-field>
    </p>

    <p>
      <shabic-form-field>
        <shabic-form-field-label forAttribute="priceFirst">
          {{ 'common.product-unit-price-primary' | translate }}
        </shabic-form-field-label>

        <input
          type="number"
          shabicInput
          class="border border-grey-300 ltr:pr-10 rtl:pl-10"
          id="priceFirst"
          formControlName="priceFirst"
        />

        <ng-container addon *ngTemplateOutlet="currencyButtons"></ng-container>
      </shabic-form-field>
    </p>

    <p>
      <shabic-form-field>
        <shabic-form-field-label forAttribute="priceSecond">
          {{ 'common.product-unit-price-secondary' | translate }}
        </shabic-form-field-label>

        <input
          type="number"
          shabicInput
          class="border border-grey-300 ltr:pr-10 rtl:pl-10"
          id="priceSecond"
          formControlName="priceSecond"
        />

        <ng-container addon *ngTemplateOutlet="currencyButtons"></ng-container>
      </shabic-form-field>
    </p>

    <p>
      <shabic-form-field>
        <shabic-form-field-label forAttribute="measurement">
          {{ 'common.product-measurements-primary' | translate }}
        </shabic-form-field-label>

        <input
          type="text"
          shabicInput
          class="border border-grey-300"
          id="measurement"
          formControlName="measurement"
        />
      </shabic-form-field>
    </p>

    <p>
      <shabic-form-field>
        <shabic-form-field-label forAttribute="measurementSecond">
          {{ 'common.product-measurements-secondary' | translate }}
        </shabic-form-field-label>

        <input
          type="text"
          shabicInput
          class="border border-grey-300"
          id="measurementSecond"
          formControlName="measurementSecond"
        />
      </shabic-form-field>
    </p>

    <p>
      <shabic-form-field>
        <shabic-form-field-label forAttribute="packingQuantity">
          {{ 'common.product-packing-quantity' | translate }}
        </shabic-form-field-label>

        <input
          type="text"
          shabicInput
          class="border border-grey-300"
          id="packingQuantity"
          formControlName="packingQuantity"
        />
      </shabic-form-field>
    </p>

    <p>
      <shabic-form-field>
        <shabic-form-field-label forAttribute="dimension">
          {{ 'common.dimension' | translate }}
        </shabic-form-field-label>

        <input
          type="text"
          shabicInput
          class="border border-grey-300"
          id="dimension"
          formControlName="dimension"
        />
      </shabic-form-field>
    </p>

    <p>
      <shabic-form-field>
        <shabic-form-field-label forAttribute="weight">
          {{ 'common.weight' | translate }}
        </shabic-form-field-label>

        <input
          type="number"
          class="border border-grey-300"
          shabicInput
          id="weight"
          formControlName="weight"
        />
      </shabic-form-field>
    </p>

    <p>
      <shabic-form-field>
        <shabic-form-field-label forAttribute="color">
          {{ 'common.color' | translate }}
        </shabic-form-field-label>

        <input
          type="text"
          class="border border-grey-300"
          shabicInput
          id="color"
          formControlName="color"
        />
      </shabic-form-field>
    </p>
  </div>

  <shabic-group-title
    name="common.product-description"
    icon="icon-file"
  ></shabic-group-title>

  <p>
    <shabic-form-field>
      <shabic-form-field-label forAttribute="descriptionFirst">
        {{ 'common.description-en' | translate }}
      </shabic-form-field-label>

      <textarea
        shabicInput
        class="resize-none border border-grey-300"
        rows="6"
        class="border border-grey-300"
        id="descriptionFirst"
        formControlName="descriptionFirst"
      ></textarea>
    </shabic-form-field>
  </p>

  <p>
    <shabic-form-field>
      <shabic-form-field-label forAttribute="descriptionSecond">
        {{ 'common.description-ar' | translate }}
      </shabic-form-field-label>

      <textarea
        shabicInput
        class="resize-none border border-grey-300"
        rows="6"
        id="descriptionSecond"
        formControlName="descriptionSecond"
        dir="rtl"
      ></textarea>
    </shabic-form-field>
  </p>

  <div class="mt-3">
    <shabic-headline variant="h5">
      {{ 'common.upload-images' | translate }}
    </shabic-headline>

    <shabic-dropzone
      #drop
      class="min-h-[150px] mt-4 flex flex-col"
      accept="image/png,image/jpg,image/jpeg"
      (changed)="onPicturesChanged($event)"
    >
      <p
        class="text-sm text-gray font-semibold"
        [innerHTML]="'common.drag-and-drop' | translate | safeHtml"
      ></p>
    </shabic-dropzone>
  </div>

  <div *ngIf="existPictures">
    <shabic-form-field-label>
      <span class="text-sm">
        {{ 'common.product-pictures' | translate }}
      </span>
    </shabic-form-field-label>

    <div class="snap-x scroll-smooth overflow-x-auto flex gap-4 empty:mt-0">
      <div
        class="snap-center relative shrink-0"
        *ngFor="let url of existPictures"
      >
        <img
          [src]="url"
          class="w-12 h-12 border border-grey-350 rounded"
          width="120"
          height="120"
          shabicImage
        />

        <button
          class="absolute top-1 right-1 z-1 bg-primary text-white rounded inline-flex items-center justify-center hover:shadow"
          type="button"
          (click)="onPictureRemove(url)"
        >
          <ng-template shabicIcon="close"></ng-template>
        </button>
      </div>
    </div>
  </div>

  <shabic-group-title
    name="common.other-information"
    icon="icon-star"
  ></shabic-group-title>

  <div class="grid desktop:grid-cols-2 gap-y-4 gap-x-3">
    <div class="flex flex-col gap-4">
      <p>
        <shabic-form-field class="text-primary">
          <shabic-form-field-label>
            <span class="normal-case text-sm">
              {{ 'common.type-of-packaging' | translate }}
            </span>
          </shabic-form-field-label>

          <div class="flex gap-5.5 mt-3">
            <shabic-radio-control>
              <input
                shabicInput
                class="peer"
                type="radio"
                id="retail"
                value="retail"
                formControlName="typeOfPackaging"
              />

              <shabic-form-field-label forAttribute="retail">
                <span class="normal-case text-sm">
                  {{ 'common.retail' | translate }}
                </span>
              </shabic-form-field-label>
            </shabic-radio-control>

            <shabic-radio-control>
              <input
                shabicInput
                class="peer"
                type="radio"
                id="wholesale"
                value="wholesale"
                formControlName="typeOfPackaging"
              />

              <shabic-form-field-label forAttribute="wholesale">
                <span class="normal-case text-sm">
                  {{ 'common.wholesale' | translate }}
                </span>
              </shabic-form-field-label>
            </shabic-radio-control>
          </div>
        </shabic-form-field>
      </p>

      <p>
        <shabic-form-field class="text-primary">
          <shabic-form-field-label>
            <span class="normal-case text-sm">
              {{ 'common.is-like-food' | translate }}
            </span>
          </shabic-form-field-label>

          <div class="flex gap-5.5 mt-3">
            <shabic-radio-control>
              <input
                shabicInput
                class="peer"
                type="radio"
                id="food-yes"
                value="yes"
                formControlName="food"
              />

              <shabic-form-field-label forAttribute="food-yes">
                <span class="normal-case text-sm">
                  {{ 'common.yes' | translate }}
                </span>
              </shabic-form-field-label>
            </shabic-radio-control>

            <shabic-radio-control>
              <input
                shabicInput
                class="peer"
                type="radio"
                id="food-no"
                value="no"
                formControlName="food"
              />

              <shabic-form-field-label forAttribute="food-no">
                <span class="normal-case text-sm">
                  {{ 'common.no' | translate }}
                </span>
              </shabic-form-field-label>
            </shabic-radio-control>
          </div>
        </shabic-form-field>
      </p>

      <p>
        <shabic-form-field class="text-primary">
          <shabic-form-field-label>
            <span class="normal-case text-sm">
              {{ 'common.requires-special-handling' | translate }}
            </span>
          </shabic-form-field-label>

          <div class="flex gap-5.5 mt-3">
            <shabic-radio-control>
              <input
                shabicInput
                class="peer"
                type="radio"
                id="special-handling-yes"
                value="yes"
                formControlName="specialHandling"
              />

              <shabic-form-field-label forAttribute="special-handling-yes">
                <span class="normal-case text-sm">
                  {{ 'common.yes' | translate }}
                </span>
              </shabic-form-field-label>
            </shabic-radio-control>

            <shabic-radio-control>
              <input
                shabicInput
                class="peer"
                type="radio"
                id="special-handling-no"
                value="no"
                formControlName="specialHandling"
              />

              <shabic-form-field-label forAttribute="special-handling-no">
                <span class="normal-case text-sm">
                  {{ 'common.no' | translate }}
                </span>
              </shabic-form-field-label>
            </shabic-radio-control>
          </div>
        </shabic-form-field>
      </p>
    </div>

    <div class="flex flex-col gap-4">
      <p>
        <shabic-form-field>
          <shabic-form-field-label forAttribute="vat">
            {{ 'common.vat' | translate }}
          </shabic-form-field-label>

          <input
            type="number"
            class="border border-grey-300"
            shabicInput
            id="vat"
            formControlName="vat"
          />
        </shabic-form-field>
      </p>

      <p>
        <shabic-form-field>
          <shabic-form-field-label forAttribute="discount">
            {{ 'common.discount' | translate }}
          </shabic-form-field-label>

          <input
            type="number"
            shabicInput
            class="border border-grey-300"
            id="discount"
            formControlName="discount"
          />
        </shabic-form-field>
      </p>

      <p>
        <shabic-form-field>
          <shabic-form-field-label forAttribute="grand">
            {{ 'common.grand-total' | translate }}
          </shabic-form-field-label>

          <input
            type="number"
            class="border border-grey-300"
            shabicInput
            id="grand"
            formControlName="grand"
          />
        </shabic-form-field>
      </p>

      <p>
        <shabic-form-field>
          <shabic-form-field-label forAttribute="quantity">
            {{ 'common.available-quantity' | translate }}
          </shabic-form-field-label>

          <input
            type="number"
            shabicInput
            class="border border-grey-300"
            id="quantity"
            formControlName="quantity"
          />
        </shabic-form-field>
      </p>
    </div>
  </div>

  <shabic-form-field-errors
    [errors]="formGroup.errors"
    *ngIf="formGroup.errors"
  ></shabic-form-field-errors>

  <button
    type="submit"
    class="mt-3 gap-1 max-w-[400px] mx-auto w-full"
    shabicButton="primary"
    [disabled]="formGroup.invalid || (loading$ | async)"
  >
    {{ (!product ? 'button.add-a-product' : 'button.save') | translate }}

    <shabic-spinner *ngIf="loading$ | async"></shabic-spinner>
  </button>
</form>

<ng-template #currencyButtons>
  <span class="flex items-center gap-3">
    <button
      *ngFor="let currency of currencies"
      type="button"
      class="text-sm text-grey"
      [class.font-semibold]="currentCurrency === currency"
      [class.text-primary]="currentCurrency === currency"
      (click)="setCurrency(currency)"
    >
      {{ currency }}
    </button>
  </span>
</ng-template>
