<svg
  width="30"
  height="32"
  viewBox="0 0 30 32"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M28.333 2.66675H19.2664V0.533444C19.2664 0.378507 19.1992 0.231132 19.0815 0.12982C18.9643 0.0285078 18.8081 -0.0170546 18.6554 0.00588284L1.43359 2.53838C0.653905 2.65319 0.0664062 3.33369 0.0664062 4.12144V27.8787C0.0664062 28.6665 0.653905 29.347 1.43359 29.4618L18.6554 31.9943C18.6815 31.9982 18.707 32.0001 18.7331 32.0001C18.8601 32.0001 18.9841 31.9545 19.0815 31.8704C19.1992 31.7691 19.2664 31.6217 19.2664 31.4668V29.3335H28.333C29.2154 29.3335 29.933 28.6158 29.933 27.7335V4.26675C29.933 3.38444 29.2154 2.66675 28.333 2.66675ZM18.1997 3.20006V6.93337V9.06668V12.2667V14.4V17.6V19.7333V22.9333V25.0666V28.7999V30.8491L1.58884 28.4066C1.32897 28.3683 1.13309 28.1415 1.13309 27.8787V4.12144C1.13309 3.85869 1.3289 3.63188 1.58884 3.59356L18.1997 1.15088V3.20006ZM19.2664 7.46674H21.9331V8.53343H19.2664V7.46674ZM19.2664 12.8H21.9331V13.8667H19.2664V12.8ZM19.2664 18.1334H21.9331V19.2001H19.2664V18.1334ZM19.2664 23.4667H21.9331V24.5334H19.2664V23.4667ZM28.8664 27.7334C28.8664 28.0274 28.6273 28.2667 28.333 28.2667H19.2664V25.6H25.6664C26.2544 25.6 26.733 25.1216 26.733 24.5333V23.4667C26.733 22.8783 26.2544 22.4 25.6664 22.4H19.2664V20.2667H25.6664C26.2544 20.2667 26.733 19.7883 26.733 19.2V18.1333C26.733 17.545 26.2544 17.0666 25.6664 17.0666H19.2664V14.9333H25.6664C26.2544 14.9333 26.733 14.4549 26.733 13.8666V12.7999C26.733 12.2117 26.2544 11.7332 25.6664 11.7332H19.2664V9.59993H25.6664C26.2544 9.59993 26.733 9.12155 26.733 8.53324V7.46656C26.733 6.87824 26.2544 6.39987 25.6664 6.39987H19.2664V3.73344H28.333C28.6273 3.73344 28.8664 3.97275 28.8664 4.26675V27.7334ZM22.9997 24.5334V23.4667H25.6664L25.6669 24.5334H22.9997ZM22.9997 19.2V18.1333H25.6664L25.6669 19.2H22.9997ZM22.9997 13.8667V12.8H25.6664L25.6669 13.8667H22.9997ZM22.9997 8.53343V7.46674H25.6664L25.6669 8.53343H22.9997Z"
    fill="currentColor"
  />
  <path
    d="M8.75486 16.0001L5.52037 19.9277C5.33287 20.155 5.36518 20.4912 5.59274 20.6784C5.69224 20.7602 5.81199 20.8 5.9313 20.8C6.08549 20.8 6.23805 20.7339 6.3433 20.6058L9.44555 16.8388L12.987 21.1392C13.0922 21.2673 13.2449 21.3334 13.399 21.3334C13.5183 21.3334 13.6381 21.2935 13.7376 21.2118C13.9652 21.0245 13.9975 20.6884 13.81 20.461L10.1363 16.0001L13.81 11.5391C13.9975 11.3117 13.9652 10.9756 13.7376 10.7884C13.5095 10.6011 13.174 10.6339 12.9871 10.861L9.44561 15.1614L6.34337 11.3944C6.15693 11.167 5.82043 11.1342 5.59287 11.3217C5.36524 11.5089 5.33299 11.8451 5.52049 12.0724L8.75486 16.0001Z"
    fill="currentColor"
  />
</svg>
