import { TranslateService } from '@ngx-translate/core';

export class Option {
  constructor(
    private translateService: TranslateService,
    public value: string,
    public id?: string | number
  ) {}

  get label(): string {
    return this.translateService.instant(this.value);
  }
}
