import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ApiClient,
  ApiErrorResponse,
  ApiResponse,
  BankAccount,
  BankAccountStatement,
  ENV,
  IBankAccountPayload,
  IBankAccountResponse,
  IBankAccountStatementResponse,
  IEnvironment,
  IListParams,
  IListResponse,
  List,
} from '@shabic/models';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BankAccountService extends ApiClient {
  override readonly url = '/banks/accounts';

  constructor(private http: HttpClient, @Inject(ENV) env: IEnvironment) {
    super(env);
  }

  accounts(params: IListParams) {
    return this.http
      .get<IListResponse<IBankAccountResponse[]>>(this.apiModelUrl, { params })
      .pipe(
        map(
          response =>
            new List(response, content =>
              content.map(el => new BankAccount(el))
            )
        ),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }

  connect(payload: IBankAccountPayload) {
    return this.http.put<IBankAccountResponse>(this.apiModelUrl, payload).pipe(
      map(response => new ApiResponse(new BankAccount(response))),
      catchError(response =>
        of(new ApiResponse(new ApiErrorResponse(response)))
      )
    );
  }

  update(account: BankAccount, payload: Partial<IBankAccountPayload>) {
    return this.http
      .put<IBankAccountResponse>(this.getEndpoint(`/${account.id}`), payload)
      .pipe(
        map(response => new ApiResponse(new BankAccount(response))),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }

  activate(account: BankAccount) {
    const body: Partial<IBankAccountPayload> = {
      status: 'ACTIVE',
    };

    return this.http
      .put<IBankAccountResponse>(this.getEndpoint(`/${account.id}`), body)
      .pipe(
        map(response => new ApiResponse(new BankAccount(response))),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }

  disconnect(account: BankAccount) {
    return this.http.delete<unknown>(this.getEndpoint(`/${account.id}`)).pipe(
      map(response => new ApiResponse(response)),
      catchError(response =>
        of(new ApiResponse(new ApiErrorResponse(response)))
      )
    );
  }

  getAccountStatement(payload: IListParams) {
    const params = new HttpParams({
      fromObject: payload,
    });

    return this.http
      .get<IListResponse<IBankAccountStatementResponse[]>>(
        this.getEndpoint('/statements'),
        { params }
      )
      .pipe(
        map(response => {
          const list = new List(response, content =>
            content.map(el => new BankAccountStatement(el))
          );

          list.pages = Math.min(2, list.pages);

          return list;
        }),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }
}
