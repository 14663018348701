import { BreakpointObserver } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DESKTOP_LAYOUT } from '@shabic/constants';
import { INavItem, NOT_AUTH_NAV } from '@shabic/data';
import { AppEvent, EAppEvent, EventBrokerService } from '@shabic/event-broker';
import {
  DrawerDialogConfig,
  LoginDialogData,
  ROLE_SUPPLIER,
} from '@shabic/models';
import { Subject, takeUntil } from 'rxjs';
import { DrawerDialogComponent } from '../drawer-dialog/drawer-dialog.component';

@Component({
  selector: 'shabic-navigation-dialog',
  templateUrl: './navigation-dialog.component.html',
  styleUrls: ['./navigation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationDialogComponent implements OnInit, OnDestroy {
  private readonly _destroy = new Subject<void>();
  readonly navigation = NOT_AUTH_NAV;
  readonly ROLE_SUPPLIER = ROLE_SUPPLIER;
  readonly userNavigation: INavItem[] = [
    {
      label: 'navigation.profile',
      route: '/profile',
      icon: 'icon-person',
      onlyForAuthorized: true,
    },
    {
      route: '/',
      label: 'navigation.logout',
      onlyForAuthorized: true,
      icon: 'icon-logout',
      onClick: (event: MouseEvent | TouchEvent) => {
        event.preventDefault();

        this.onLogout();
      },
    },
    {
      route: '/',
      label: 'button.login',
      onlyForAuthorized: false,
      icon: 'icon-sign-in',
      onClick: (event: MouseEvent | TouchEvent) => {
        event.preventDefault();

        this.onSignIn();
      },
    },
  ];
  readonly drawerDialogConfig: DrawerDialogConfig = {
    isFullSize: true,
    isPrimaryBg: true,
    hasCloseBtn: true,
  };

  @ViewChild(DrawerDialogComponent) dialog?: DrawerDialogComponent;

  constructor(
    private eventBrokerService: EventBrokerService,
    private router: Router,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this.router.events.pipe(takeUntil(this._destroy)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.dialog?.close();
      }
    });

    this.breakpointObserver
      .observe([DESKTOP_LAYOUT])
      .pipe(takeUntil(this._destroy))
      .subscribe(event => {
        if (event.matches) {
          this.dialog?.close();
        }
      });
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  onLogout(): void {
    this.eventBrokerService.emit(new AppEvent(EAppEvent.LogOut, null));
  }

  private onSignIn(): void {
    this.eventBrokerService.emit(
      new AppEvent<LoginDialogData>(EAppEvent.OpenLoginDialog, {
        onSuccess: () =>
          this.eventBrokerService.emit(
            new AppEvent<null>(EAppEvent.Authorized, null)
          ),
      })
    );
  }
}
