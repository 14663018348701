<svg
  width="17"
  height="17"
  viewBox="0 0 17 17"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_2431_5839)">
    <path
      d="M14.1594 2.84109C11.0379 -0.279713 5.96155 -0.279713 2.84009 2.84109C-0.28003 5.9619 -0.28003 11.0396 2.84009 14.1604C4.40082 15.7205 6.45044 16.5001 8.50009 16.5001C10.5497 16.5001 12.5987 15.7204 14.1594 14.1604C17.2802 11.0396 17.2802 5.9619 14.1594 2.84109ZM11.8009 10.8588C12.0617 11.1196 12.0617 11.5412 11.8009 11.8019C11.6709 11.932 11.5001 11.9974 11.3294 11.9974C11.1586 11.9974 10.9879 11.932 10.8578 11.8019L8.50006 9.4435L6.14295 11.8013C6.01223 11.9313 5.84146 11.9967 5.67139 11.9967C5.50065 11.9967 5.32988 11.9313 5.19982 11.8013C4.93904 11.5405 4.93904 11.1183 5.19982 10.8582L7.55693 8.5004L5.19916 6.14264C4.93839 5.88186 4.93839 5.45966 5.19916 5.19954C5.45929 4.93876 5.88148 4.93876 6.14226 5.19954L8.50002 7.5573L10.8578 5.19954C11.1186 4.93876 11.5401 4.93876 11.8009 5.19954C12.0617 5.45966 12.0617 5.88186 11.8009 6.14264L9.44312 8.5004L11.8009 10.8588Z"
      fill="currentColor"
    />
  </g>
  <defs>
    <clipPath id="clip0_2431_5839">
      <rect
        width="16"
        height="16"
        fill="white"
        transform="translate(0.5 0.5)"
      />
    </clipPath>
  </defs>
</svg>
