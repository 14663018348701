import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@shabic/core';

const routes: Routes = [
  {
    path: 'user-management',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./user-management/user-management.module').then(
        m => m.UserManagementModule
      ),
  },
  {
    path: 'catalog',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./catalog/catalog.module').then(m => m.CatalogModule),
  },
  {
    path: 'products-classification',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./products-classification/products-classification.module').then(
        m => m.ProductsClassificationModule
      ),
  },
  {
    path: 'reports',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./reports/reports.module').then(m => m.ReportsModule),
  },
  {
    path: 'my-banks',
    canLoad: [AuthGuard],
    loadChildren: () => import('./bank/bank.module').then(m => m.BankModule),
  },
  {
    path: 'marketplace',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./marketplace/marketplace.module').then(m => m.MarketplaceModule),
  },
  {
    path: '**',
    redirectTo: 'user-management',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
