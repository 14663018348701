import { Component, Input } from '@angular/core';

@Component({
  selector: 'shabic-icon-logo',
  templateUrl: './icon-logo.component.html',
  styleUrls: ['./icon-logo.component.scss'],
})
export class IconLogoComponent {
  @Input() size?: 'lg' | 'md';
}
