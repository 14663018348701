<div class="relative">
  <span
    class="flex items-center justify-center desktop:border-8 border-white rounded-full w-0 h-0 desktop:w-6.5 desktop:h-6.5 text-grey text-sm text-semibold"
    [class.bg-grey-350]="!isActive && !completed"
    [class.bg-primary]="isActive"
    [class.text-white]="isActive || completed"
    [class.bg-secondary]="completed"
  >
    <ng-container *ngIf="!completed">{{ index | number: '2.0' }}</ng-container>

    <ng-template *ngIf="completed" shabicIcon="check"></ng-template>
  </span>

  <div class="absolute bottom-0 left-2/4 translate-y-full -translate-x-2/4">
    <ng-content></ng-content>
  </div>
</div>
