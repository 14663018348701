import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LOGO_SOURCE, LOGO_SOURCE_PRIMARY } from '@shabic/constants';

@Component({
  selector: 'shabic-login-layout',
  templateUrl: './login-layout.component.html',
  styleUrls: ['./login-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginLayoutComponent {
  readonly logoSourcePrimary = LOGO_SOURCE_PRIMARY;
  readonly logoSourceWhite = LOGO_SOURCE;
}
