<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M17.7102 11.29C17.6172 11.1963 17.5066 11.1219 17.3848 11.0711C17.2629 11.0203 17.1322 10.9942 17.0002 10.9942C16.8682 10.9942 16.7375 11.0203 16.6156 11.0711C16.4937 11.1219 16.3831 11.1963 16.2902 11.29L13.0002 14.59V7C13.0002 6.73478 12.8948 6.48043 12.7073 6.29289C12.5198 6.10536 12.2654 6 12.0002 6C11.735 6 11.4806 6.10536 11.2931 6.29289C11.1055 6.48043 11.0002 6.73478 11.0002 7V14.59L7.71019 11.29C7.52188 11.1017 7.26649 10.9959 7.00019 10.9959C6.73388 10.9959 6.47849 11.1017 6.29019 11.29C6.10188 11.4783 5.99609 11.7337 5.99609 12C5.99609 12.2663 6.10188 12.5217 6.29019 12.71L11.2902 17.71C11.3853 17.801 11.4974 17.8724 11.6202 17.92C11.7399 17.9729 11.8693 18.0002 12.0002 18.0002C12.1311 18.0002 12.2605 17.9729 12.3802 17.92C12.5029 17.8724 12.6151 17.801 12.7102 17.71L17.7102 12.71C17.8039 12.617 17.8783 12.5064 17.9291 12.3846C17.9798 12.2627 18.006 12.132 18.006 12C18.006 11.868 17.9798 11.7373 17.9291 11.6154C17.8783 11.4936 17.8039 11.383 17.7102 11.29Z"
    fill="currentColor"
  />
</svg>
