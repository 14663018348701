import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { SUCCESS } from '@shabic/constants';
import { FormComponent } from '@shabic/form-control';
import { ApiResponse } from '@shabic/models';
import { of } from 'rxjs';
import { delay, map } from 'rxjs/operators';

type ContactType = 'owner' | 'manager' | 'custom';

@Component({
  selector: 'shabic-sign-up-success-step',
  templateUrl: './sign-up-success-step.component.html',
  styleUrls: ['./sign-up-success-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignUpSuccessStepComponent extends FormComponent<unknown> {
  @Output() completed: EventEmitter<void> = new EventEmitter();

  formGroup: FormGroup = new FormGroup({
    type: new FormControl('owner', [Validators.required]),
    ownerId: new FormControl(''),
    managerId: new FormControl(''),
    files: new FormArray([
      new FormControl(''),
      new FormControl(''),
      new FormControl(''),
      new FormControl(''),
      new FormControl(''),
    ]),
  });

  get fileControls(): FormControl[] {
    return (this.formGroup.get('files') as FormArray).controls as FormControl[];
  }

  isActive(type: ContactType): boolean {
    return this.formGroup.value.type === type;
  }

  onSubmit(): void {
    this.submitForm(
      this.formGroup,
      of(true).pipe(
        delay(1000),
        map(() => new ApiResponse(true))
      )
    ).subscribe(response => {
      if (response === SUCCESS) {
        this.completed.next();
      }
    });
  }
}
