import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SUCCESS } from '@shabic/constants';
import { OtpService } from '@shabic/core';
import { FormComponent } from '@shabic/form-control';
import { AuthResponse } from '@shabic/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { SignUp, StepState } from '../../models';

@Component({
  selector: 'shabic-sign-up-phone-number-step',
  templateUrl: './sign-up-phone-number-step.component.html',
  styleUrls: ['./sign-up-phone-number-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignUpPhoneNumberStepComponent extends FormComponent<
  unknown | AuthResponse
> {
  private _state = new BehaviorSubject<StepState>('form');

  @Input() signUp!: SignUp;
  @Output() completed = new EventEmitter<void>();

  state$: Observable<StepState> = this._state.asObservable();

  formGroup: FormGroup = new FormGroup({
    phone: new FormControl('', [Validators.required]),
    passcode: new FormControl(''),
  });

  constructor(
    private otpService: OtpService,
    protected override cd: ChangeDetectorRef
  ) {
    super();
  }

  onSubmit(): void {
    const state: StepState = this._state.value;
    const formValue = this.formGroup.value;

    if (state === 'form') {
      this.verifyPhone(formValue.phone);
    } else {
      this.verifyPasscode(formValue.passcode);
    }
  }

  private verifyPhone(phone: string): void {
    this.submitForm(
      this.formGroup,
      this.otpService.send('+9665' + phone, 'PHONE')
    ).subscribe(status => {
      if (status === SUCCESS) {
        this.formGroup
          .get('passcode')
          ?.setValidators([Validators.required, Validators.minLength(6)]);
        this._state.next('passcode');
      }
    });
  }

  private verifyPasscode(passcode: string) {
    this.submitForm(
      this.formGroup,
      this.otpService.verify(passcode, 'PHONE')
    ).subscribe(status => {
      if (status === SUCCESS) {
        this.completed.emit();
      }
    });
  }
}
