import jwt_decode from 'jwt-decode';
import { ROLE_ADMIN, ROLE_USER } from './role.model';

export interface IAuthResponse {
  roles: string[];
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: string;
  token_type: string;
  id_token: string;
  'not-before-policy': number;
  session_state: string;
  scope: string;
  error: string;
  error_description: string;
  error_uri: string;
}

export class AuthResponse {
  roles: string[];
  token: string;
  expires_in: number;
  refresh_expires_in: number;
  refreshToken: string;
  token_type: string;
  id_token: string;
  notBeforePolicy: number;
  session_state: string;
  scope: string;
  error: string;
  error_description: string;
  error_uri: string;

  tokenPayload: ApiToken;
  refreshTokenPayload?: ApiToken;

  constructor(response: IAuthResponse) {
    this.roles = response.roles;
    this.token = response.access_token;
    this.expires_in = response.expires_in;
    this.refresh_expires_in = response.refresh_expires_in;
    this.refreshToken = response.refresh_token;
    this.token_type = response.token_type;
    this.id_token = response.id_token;
    this.notBeforePolicy = response['not-before-policy'];
    this.session_state = response.session_state;
    this.scope = response.scope;
    this.error = response.error;
    this.error_description = response.error_description;
    this.error_uri = response.error_uri;
    this.tokenPayload = new ApiToken(response.access_token);
    if (response.refresh_token) {
      this.refreshTokenPayload = new ApiToken(response.refresh_token);
    }
  }
}

export class ApiToken {
  expiredAt = 0;
  roles: string[] = [];
  email?: string;

  get verified() {
    return this.roles.includes(ROLE_USER) || this.roles.includes(ROLE_ADMIN);
  }

  get isExpired() {
    return this.expiredAt < Date.now();
  }

  constructor(token: string | null) {
    const payload = token
      ? jwt_decode<{
          email_verified: boolean;
          email: string;
          exp: number;
          typ: 'Refresh' | 'Bearer';
          realm_access: { roles: string[] };
        }>(token)
      : null;

    if (payload) {
      if (payload.typ === 'Bearer') {
        this.roles = payload.realm_access.roles;
      }
      this.expiredAt = payload.exp * 1000;
      this.email = payload.email;
    }
  }
}
