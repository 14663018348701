import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ButtonDirective,
  InViewportDirective,
  TextAlignDirective,
  ImageDirective,
  BadgeDirective,
  DividerDirective,
  ImagePreviewDirective,
} from './directives';
import {
  AvatarComponent,
  DropdownComponent,
  HeadlineComponent,
  SubHeadlineComponent,
  TableDetailsComponent,
  PropertyWithLabelComponent,
  SpinnerComponent,
  GroupTitleComponent,
  AnimateComponent,
} from './components';
import { IconModule } from '@shabic/icon';
import { TranslateModule } from '@ngx-translate/core';
import { CdkMenuModule } from '@angular/cdk/menu';

@NgModule({
  imports: [CommonModule, IconModule, TranslateModule, CdkMenuModule],
  declarations: [
    ButtonDirective,
    HeadlineComponent,
    SubHeadlineComponent,
    TextAlignDirective,
    AvatarComponent,
    DropdownComponent,
    TableDetailsComponent,
    ImageDirective,
    PropertyWithLabelComponent,
    BadgeDirective,
    DividerDirective,
    SpinnerComponent,
    ImagePreviewDirective,
    GroupTitleComponent,
    AnimateComponent,
    InViewportDirective,
  ],
  exports: [
    ButtonDirective,
    HeadlineComponent,
    SubHeadlineComponent,
    AvatarComponent,
    DropdownComponent,
    TableDetailsComponent,
    ImageDirective,
    PropertyWithLabelComponent,
    BadgeDirective,
    DividerDirective,
    SpinnerComponent,
    ImagePreviewDirective,
    GroupTitleComponent,
    AnimateComponent,
    InViewportDirective,
  ],
})
export class UiModule {}
