import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[shabicDivider]',
})
export class DividerDirective {
  @HostBinding('class')
  get elementClass(): string {
    return 'my-3 text-grey-400';
  }
}
