export type Icon =
  | 'arrow'
  | 'cart'
  | 'check'
  | 'close'
  | 'close'
  | 'delete'
  | 'edit'
  | 'plus'
  | 'sub-item'
  | 'verify-checkmark-invert'
  | 'verify-checkmark'
  | 'pdf'
  | 'excel'
  | 'csv'
  | 'manual'
  | 'excel-large'
  | 'picture'
  | 'alert'
  | 'location'
  | 'image'
  | 'calendar'
  | 'view-documents'
  | 'view-company'
  | 'block-user'
  | 'bookmark'
  | 'add-to-cart'
  | 'mail'
  | 'phone'
  | 'shabic'
  | 'icon-home'
  | 'arrow-left'
  | 'profile'
  | 'icon-users'
  | 'icon-search'
  | 'icon-document'
  | 'icon-basket'
  | 'icon-logout'
  | 'icon-nav'
  | 'icon-box'
  | 'icon-sign-in'
  | 'icon-order-place'
  | 'icon-add'
  | 'icon-excel-large-mono'
  | 'icon-file'
  | 'icon-star'
  | 'icon-bag'
  | 'icon-close-circle'
  | 'icon-check-circle'
  | 'icon-bulk'
  | 'icon-person'
  | 'icon-password'
  | 'icon-download'
  | 'icon-preview'
  | 'icon-logo'
  | 'icon-refresh'
  | 'icon-magnifier'
  | 'icon-arrow-down'
  | 'icon-arrow-up'
  | 'icon-empty-basket'
  | 'icon-empty-orders'
  | 'icon-copy';
