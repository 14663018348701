import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SNACK_BAR_DURATION } from '@shabic/constants';
import { EAppEvent, EventBrokerService } from '@shabic/event-broker';
import { filter } from 'rxjs';

type SnackBarType = 'error' | 'info';
type SnackBarPayload = {
  message: string;
  messageType: SnackBarType;
};

export class SnackBarEvent {
  type = EAppEvent.ShowSnackbar;
  payload: SnackBarPayload;

  constructor(message: string, messageType: SnackBarType) {
    this.payload = {
      message,
      messageType,
    };
  }
}

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private eventBrokerService: EventBrokerService
  ) {}

  init() {
    this.eventBrokerService.events$
      .pipe(filter(event => event.type === EAppEvent.ShowSnackbar))
      .subscribe(event => {
        const { message, messageType } = event.payload as SnackBarPayload;

        this.openSnackBar(message, messageType);
      });
  }

  openSnackBar(message: string, type?: SnackBarType) {
    const config = new MatSnackBarConfig();
    config.duration = SNACK_BAR_DURATION;
    config.panelClass = type === 'error' ? 'text-red' : 'text-primary';

    this.snackBar.open(this.translateService.instant(message), '', config);
  }
}
