import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ApiErrorResponse,
  ApiResponse,
  ENV,
  IEnvironment,
  IProductPayload,
  Task,
  ITask,
  ApiMarketplace,
  Product,
  ProductMediaResponse,
} from '@shabic/models';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MediaService } from './media.service';
import { TasksService } from './tasks.service';

@Injectable({
  providedIn: 'root',
})
export class AddProductService extends ApiMarketplace {
  override readonly url = '/products';

  constructor(
    private http: HttpClient,
    @Inject(ENV) env: IEnvironment,
    private tasksService: TasksService,
    private mediaService: MediaService
  ) {
    super(env);
  }

  addProduct(
    product: IProductPayload,
    existPictures: ProductMediaResponse[] = []
  ): Observable<ApiResponse<Task<string> | ApiErrorResponse>> {
    return this.uploadPicture(product).pipe(
      switchMap(response => {
        const pictures = response.payload;

        return this.http
          .put<ITask<string>>(this.apiModelUrl, [
            {
              ...product,
              medias: [
                ...pictures.map((el, index) => ({
                  mediaId: el.id,
                  order: index,
                })),
                ...existPictures.map(el => ({
                  mediaId: el.id,
                  order: el.order,
                })),
              ],
            },
          ])
          .pipe(
            map(response => new ApiResponse(new Task(response))),
            catchError(response =>
              of(new ApiResponse(new ApiErrorResponse(response)))
            )
          );
      })
    );
  }

  parseFile(
    file: File
  ): Observable<ApiResponse<Task<IProductPayload> | ApiErrorResponse>> {
    const body = new FormData();
    body.append('file', file);
    body.append('type', 'EXCEL');

    return this.http
      .put<ITask<IProductPayload>>(this.getEndpoint('/parse'), body)
      .pipe(
        map(response => new ApiResponse(new Task(response))),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }

  pingFileParsing(
    id: string
  ): Observable<ApiResponse<Task<IProductPayload> | ApiErrorResponse>> {
    return this.tasksService.ping<IProductPayload>(id);
  }

  private uploadPicture(payload: IProductPayload) {
    if (payload.mediaId) {
      return this.mediaService.upload(
        this.getApiEndpoint(''),
        payload.mediaId,
        'PRODUCT'
      );
    } else {
      return of(new ApiResponse([]));
    }
  }

  removePictures(product: Product, pictures: number[]) {
    const body = {
      medias: pictures.map(id => {
        return {
          mediaId: id,
          order: 0,
        };
      }),
      remove: true,
    };

    return this.http
      .put<unknown>(this.getEndpoint(`/${product.id}/media/update`), body)
      .pipe(
        map(response => new ApiResponse(response)),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }
}
