<shabic-dialog #dialog>
  <shabic-headline align="center" variant="h3">
    {{ 'dialog.bank-information' | translate }}
  </shabic-headline>

  <form class="px-4" [formGroup]="formGroup" (ngSubmit)="onSubmit(dialog)">
    <shabic-form-field class="mt-4">
      <shabic-image-control
        [value]="formGroup.get('media')?.value"
        (changed)="patchValue(formGroup, 'mediaId', $event)"
      >
        <input
          type="file"
          shabicInput
          hidden
          name=""
          class="peer"
          accept="image/png,image/jpeg,image/jpg"
        />
      </shabic-image-control>
    </shabic-form-field>

    <shabic-form-field class="text-primary mt-4">
      <shabic-form-field-label forAttribute="bank-name">
        {{ 'common.bank-name' | translate }}
      </shabic-form-field-label>

      <input
        type="text"
        shabicInput
        class="ltr:pr-6 rtl:pl-6 border border-grey-300"
        formControlName="name"
        id="bank-name"
      />
    </shabic-form-field>

    <shabic-form-field class="text-primary mt-4">
      <shabic-form-field-label forAttribute="account-number">
        {{ 'common.account-number' | translate }}
      </shabic-form-field-label>

      <input
        type="text"
        shabicInput
        class="ltr:pr-6 rtl:pl-6 border border-grey-300"
        formControlName="accountNumber"
        id="account-number"
      />
    </shabic-form-field>

    <shabic-form-field class="text-primary mt-4">
      <shabic-form-field-label>
        <span class="normal-case text-sm">
          {{ 'common.bank-is-active' | translate }}
        </span>
      </shabic-form-field-label>

      <div class="flex gap-5.5 mt-3">
        <shabic-radio-control>
          <input
            shabicInput
            class="peer"
            type="radio"
            id="yes"
            value="yes"
            formControlName="status"
          />

          <shabic-form-field-label forAttribute="yes">
            <span class="normal-case text-sm">
              {{ 'common.yes' | translate }}
            </span>
          </shabic-form-field-label>
        </shabic-radio-control>

        <shabic-radio-control>
          <input
            shabicInput
            class="peer"
            type="radio"
            id="no"
            value="no"
            formControlName="status"
          />

          <shabic-form-field-label forAttribute="no">
            <span class="normal-case text-sm">
              {{ 'common.no' | translate }}
            </span>
          </shabic-form-field-label>
        </shabic-radio-control>
      </div>
    </shabic-form-field>

    <div class="flex flex-col my-6">
      <button
        type="submit"
        class="gap-1"
        shabicButton="primary"
        [disabled]="formGroup.invalid || (loading$ | async)"
      >
        {{ 'button.save' | translate }}

        <shabic-spinner *ngIf="loading$ | async"></shabic-spinner>
      </button>
    </div>
  </form>
</shabic-dialog>
