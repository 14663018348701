import { ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  ApiErrorResponse,
  ApiResponse,
  isErrorResponse,
  ResponseStatus,
} from '@shabic/models';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

export class FormComponent<T> {
  protected cd?: ChangeDetectorRef;
  private _destroy = new Subject<void>();
  protected destroy$ = this._destroy.asObservable();
  private _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  loading$: Observable<boolean> = this._loading.asObservable();

  submitForm(
    form: FormGroup,
    request: Observable<ApiResponse<T | ApiErrorResponse>>,
    config = {
      stopLoading: true,
      returnResponse: false,
    }
  ): Observable<ResponseStatus | ApiResponse<T | ApiErrorResponse>> {
    this._loading.next(true);

    return request.pipe(
      map(response => {
        if (isErrorResponse(response)) {
          this.setFormErrors(form, response);

          return !config.returnResponse ? response.status : response;
        }

        return !config.returnResponse ? response.status : response;
      }),
      tap(() => (config.stopLoading ? this._loading.next(false) : ''))
    );
  }

  submitForms(
    forms: FormGroup[],
    requests: Observable<ApiResponse<T | ApiErrorResponse>>[],
    config = {
      stopLoading: true,
      returnResponse: false,
    }
  ) {
    return forkJoin(
      forms.map((form, index) => this.submitForm(form, requests[index], config))
    ).pipe(tap(() => this._loading.next(false)));
  }

  patchValue(form: FormGroup, key: string, value: unknown) {
    form.get(key)?.patchValue(value);
  }

  getFormFieldGroup(name: string, group: FormGroup) {
    const field = group.get(name);

    if (field) {
      return field as FormGroup;
    }

    return null;
  }

  getFormField(name: string, group: FormGroup) {
    const field = group.get(name);

    if (field) {
      return field as FormControl;
    }

    return null;
  }

  protected onDestoy() {
    this._destroy.next();
    this._destroy.complete();
  }

  private setFormErrors(
    form: FormGroup,
    response: ApiResponse<ApiErrorResponse>
  ): void {
    updateFormErrorsByApiResponse(form, response);

    this.cd?.detectChanges();
  }
}

function updateFormErrorsByApiResponse(
  form: FormGroup,
  response: ApiResponse<ApiErrorResponse>
): void {
  const { fieldErrors, violations, message, title, errorDescription, params } =
    response.payload;

  if (fieldErrors) {
    fieldErrors.forEach(item => {
      const control = form.get(item.field);

      if (control) {
        control.setErrors({
          api: item.description,
        });
      }
    });
  }

  if (violations) {
    violations.forEach(item => {
      const field = item.field.split('.').pop() as string;
      const control = form.get(field);

      if (control) {
        control.setErrors({
          api: item.message,
        });
      } else {
        form.setErrors({
          form: item.message,
        });
      }
    });
  }

  if (message || title) {
    const errorKey =
      params?.error_key === 'error.undefined' && message
        ? undefined
        : params?.error_key;

    form.setErrors({
      form:
        form.getError('form') ||
        errorKey ||
        errorDescription ||
        message ||
        title,
    });
  }
}
