<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    opacity="0.4"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M5.91016 20.5903C5.91016 19.7503 6.59016 19.0703 7.43016 19.0703C8.26016 19.0703 8.94016 19.7503 8.94016 20.5903C8.94016 21.4203 8.26016 22.1003 7.43016 22.1003C6.59016 22.1003 5.91016 21.4203 5.91016 20.5903ZM17.1602 20.5903C17.1602 19.7503 17.8402 19.0703 18.6802 19.0703C19.5102 19.0703 20.1902 19.7503 20.1902 20.5903C20.1902 21.4203 19.5102 22.1003 18.6802 22.1003C17.8402 22.1003 17.1602 21.4203 17.1602 20.5903Z"
    fill="currentColor"
    fill-opacity="0.75"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M20.1907 6.34933C20.8007 6.34933 21.2007 6.55933 21.6007 7.01933C22.0007 7.47933 22.0707 8.13933 21.9807 8.73833L21.0307 15.2983C20.8507 16.5593 19.7707 17.4883 18.5007 17.4883H7.59074C6.26074 17.4883 5.16074 16.4683 5.05074 15.1493L4.13074 4.24833L2.62074 3.98833C2.22074 3.91833 1.94074 3.52833 2.01074 3.12833C2.08074 2.71833 2.47074 2.44833 2.88074 2.50833L5.26574 2.86833C5.60574 2.92933 5.85574 3.20833 5.88574 3.54833L6.07574 5.78833C6.10574 6.10933 6.36574 6.34933 6.68574 6.34933H20.1907ZM14.1309 11.5469H16.9009C17.3209 11.5469 17.6509 11.2069 17.6509 10.7969C17.6509 10.3769 17.3209 10.0469 16.9009 10.0469H14.1309C13.7109 10.0469 13.3809 10.3769 13.3809 10.7969C13.3809 11.2069 13.7109 11.5469 14.1309 11.5469Z"
    fill="currentColor"
    fill-opacity="0.75"
  />
</svg>
