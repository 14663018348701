import { getImageExample } from '@shabic/constants';
import { Translated } from './translated.model';

export interface ICategoryResponse {
  id: number;
  parentId?: number;
  name: string;
  secondName?: string;
  subCategories?: ICategoryResponse[];
  mediaUrl?: string;
}

export interface ICategoryPayload {
  id?: number;
  name: string;
  secondName?: string;
  mediaId?: number;
  subCategory?: ICategoryPayload;
}

export class Category {
  id: number;
  parentId?: number;
  name: Translated<string>;
  count: number | null;
  active: boolean;
  image: string;
  subCategories: Category[];

  static fromResponse(category: ICategoryResponse) {
    return new Category(category);
  }

  constructor(category: ICategoryResponse) {
    this.id = category.id;
    this.parentId = category.parentId;
    this.name = new Translated(
      Translated.prepareConfig({
        en: category.name,
        ar: category.secondName || '',
      })
    );
    this.count = 0;
    this.active = false;
    this.image = category.mediaUrl || getImageExample();
    this.subCategories =
      category.subCategories?.map(el => Category.fromResponse(el)) || [];
  }
}
