<ng-container [ngSwitch]="variant">
  <h1
    class="font-semibold text-2xl"
    [class.text-primary]="!invert"
    [class.text-white]="invert"
    [shabicTextAlign]="align"
    [ngClass]="classes"
    *ngSwitchCase="'h1'"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </h1>

  <h2
    class="font-semibold text-xl"
    [class.text-primary]="!invert"
    [class.text-white]="invert"
    [shabicTextAlign]="align"
    [ngClass]="classes"
    *ngSwitchCase="'h2'"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </h2>

  <h3
    class="font-medium text-lg"
    [class.text-primary]="!invert"
    [class.text-white]="invert"
    [shabicTextAlign]="align"
    [ngClass]="classes"
    *ngSwitchCase="'h3'"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </h3>
  <h4 class="font-medium text-primary" *ngSwitchCase="'h4'">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </h4>
  <h5
    class="font-medium text-sm"
    [class.text-primary]="!invert"
    [class.text-white]="invert"
    [shabicTextAlign]="align"
    [ngClass]="classes"
    *ngSwitchCase="'h5'"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </h5>
  <h6 *ngSwitchCase="'h6'">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </h6>
</ng-container>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
