import {
  ComponentRef,
  Directive,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  Type,
  ViewContainerRef,
} from '@angular/core';
import {
  IconArrowComponent,
  IconCartComponent,
  IconCheckComponent,
  IconCloseComponent,
  IconDeleteComponent,
  IconEditComponent,
  IconPlusComponent,
  IconSubItemComponent,
  IconVerifyCheckmarkInvertComponent,
  VerifyCheckmarkComponent,
  IconPdfComponent,
  IconExcelComponent,
  IconCsvComponent,
  IconManualComponent,
  IconExcelLargeComponent,
  IconPictureComponent,
  IconAlertComponent,
  IconLocationComponent,
  IconImageComponent,
  IconCalendarComponent,
  IconViewCompanyComponent,
  IconViewDocumentsComponent,
  IconBlockUserComponent,
  IconBookmarkComponent,
  IconAddToCartComponent,
  IconMailComponent,
  IconPhoneComponent,
  IconShabicComponent,
  IconHomeComponent,
  IconArrowLeftComponent,
  IconBasketComponent,
  IconDocumentComponent,
  IconSearchComponent,
  IconLogoutComponent,
  IconNavComponent,
  IconBoxComponent,
  IconUsersComponent,
  IconSignInComponent,
  IconOrderPlaceComponent,
  IconAddComponent,
  IconExcelLargeMonoComponent,
  IconFileComponent,
  IconStarComponent,
  IconBagComponent,
  IconCloseCircleComponent,
  IconCheckCircleComponent,
  IconBulkComponent,
  IconPersonComponent,
  IconPasswordComponent,
  IconDownloadComponent,
  IconPreviewComponent,
  IconLogoComponent,
  IconRefreshComponent,
  IconMagnifierComponent,
  IconProfileComponent,
  IconArrowDownComponent,
  IconArrowUpComponent,
  IconEmptyBasketComponent,
  IconEmptyOrdersComponent,
  IconCopyComponent,
} from '../components';
import { Icon } from '../models';

const icons: Record<Icon, Type<unknown>> = {
  arrow: IconArrowComponent,
  cart: IconCartComponent,
  check: IconCheckComponent,
  close: IconCloseComponent,
  delete: IconDeleteComponent,
  edit: IconEditComponent,
  'verify-checkmark': VerifyCheckmarkComponent,
  'verify-checkmark-invert': IconVerifyCheckmarkInvertComponent,
  plus: IconPlusComponent,
  'sub-item': IconSubItemComponent,
  pdf: IconPdfComponent,
  excel: IconExcelComponent,
  csv: IconCsvComponent,
  manual: IconManualComponent,
  'excel-large': IconExcelLargeComponent,
  picture: IconPictureComponent,
  alert: IconAlertComponent,
  location: IconLocationComponent,
  image: IconImageComponent,
  calendar: IconCalendarComponent,
  'view-company': IconViewCompanyComponent,
  'view-documents': IconViewDocumentsComponent,
  'block-user': IconBlockUserComponent,
  bookmark: IconBookmarkComponent,
  'add-to-cart': IconAddToCartComponent,
  mail: IconMailComponent,
  phone: IconPhoneComponent,
  shabic: IconShabicComponent,
  'icon-home': IconHomeComponent,
  'arrow-left': IconArrowLeftComponent,
  profile: IconProfileComponent,

  'icon-basket': IconBasketComponent,
  'icon-document': IconDocumentComponent,
  'icon-search': IconSearchComponent,
  'icon-users': IconUsersComponent,
  'icon-logout': IconLogoutComponent,
  'icon-nav': IconNavComponent,
  'icon-box': IconBoxComponent,
  'icon-sign-in': IconSignInComponent,
  'icon-order-place': IconOrderPlaceComponent,
  'icon-add': IconAddComponent,
  'icon-excel-large-mono': IconExcelLargeMonoComponent,
  'icon-file': IconFileComponent,
  'icon-star': IconStarComponent,
  'icon-bag': IconBagComponent,
  'icon-close-circle': IconCloseCircleComponent,
  'icon-check-circle': IconCheckCircleComponent,
  'icon-bulk': IconBulkComponent,
  'icon-person': IconPersonComponent,
  'icon-password': IconPasswordComponent,
  'icon-download': IconDownloadComponent,
  'icon-preview': IconPreviewComponent,
  'icon-logo': IconLogoComponent,
  'icon-refresh': IconRefreshComponent,
  'icon-magnifier': IconMagnifierComponent,
  'icon-arrow-down': IconArrowDownComponent,
  'icon-arrow-up': IconArrowUpComponent,
  'icon-empty-basket': IconEmptyBasketComponent,
  'icon-empty-orders': IconEmptyOrdersComponent,
  'icon-copy': IconCopyComponent,
};

@Directive({
  selector: '[shabicIcon]',
})
export class IconDirective implements OnInit, OnDestroy, OnChanges {
  @Input() shabicIcon?: Icon;
  @Input() classes?: string;
  @Input() size?: Nullable<'lg' | 'md' | 'xs'>;

  componentRef!: ComponentRef<unknown>;

  constructor(public viewContainerRef: ViewContainerRef) {}

  ngOnInit(): void {
    this.renderIcon(this.shabicIcon);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const property = changes['shabicIcon'];
    const size = changes['size'];

    if (property?.previousValue) {
      this.renderIcon(property?.currentValue);
    }

    if (size?.previousValue) {
      (this.componentRef.instance as any).size = size.currentValue;
    }
  }

  ngOnDestroy(): void {
    this.componentRef?.destroy();
  }

  private renderIcon(icon?: Icon) {
    if (!icon) {
      return;
    }

    this.viewContainerRef.clear();
    const component = icons[icon];

    this.componentRef = this.viewContainerRef.createComponent(component);
    (this.componentRef.instance as any).classes = this.classes;
    (this.componentRef.instance as any).size = this.size;
  }
}
