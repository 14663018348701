<google-map
  class="block rounded max-w-full w-full"
  height="200px"
  width="100%"
  [options]="options"
  (mapClick)="onClick($event)"
  (mapInitialized)="onInit($event)"
></google-map>

<ul *ngIf="coordinates$ | async as coordinates" class="grid gap-0.5">
  <li class="text-grey inline-flex gap-2 items-center">
    <span class="text-xs">Latitude:</span>
    <b class="font-medium text-primary text-sm">
      {{ coordinates.lat }}
    </b>
  </li>
  <li class="text-grey text-xs inline-flex gap-2 items-center">
    <span class="text-xs">Longitude:</span>
    <b class="font-medium text-primary text-sm">
      {{ coordinates.lng }}
    </b>
  </li>
</ul>
