import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { AppEvent, EAppEvent, EventBrokerService } from '@shabic/event-broker';
import { GalleryDialogData, ListProduct, Product } from '@shabic/models';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'shabic-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent implements OnInit {
  private _image = new BehaviorSubject<string | null>(null);

  @Input() product!: ListProduct | Product;
  @Input() hasPreview = false;
  @Input() size: 'lg' | 'md' = 'lg';

  image$ = this._image.asObservable();

  constructor(private eventBrokerService: EventBrokerService) {}

  ngOnInit(): void {
    this._image.next(this.product.getMedias()[0] || this.product.images[0]);
  }

  setImage(index: number) {
    this._image.next(this.product.getMedias()[index]);
  }

  onZoom() {
    this.eventBrokerService.emit(
      new AppEvent(
        EAppEvent.OpenGalleryDialog,
        new GalleryDialogData(this.product.getMedias())
      )
    );
  }
}
