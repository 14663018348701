<shabic-dialog #dialog>
  <shabic-headline align="center" variant="h3">
    {{ 'dialog.create-bank-account' | translate }}
  </shabic-headline>

  <form (ngSubmit)="onSubmit(dialog)" [formGroup]="formGroup" class="px-4">
    <shabic-form-field class="text-primary mt-4">
      <shabic-form-field-label forAttribute="bank">
        {{ 'common.bank-name' | translate }}
      </shabic-form-field-label>

      <shabic-select-control [options]="banks$">
        <input
          [placeholder]="'common.bank-name' | translate"
          autocomplete="off"
          class="ltr:pr-6 rtl:pl-6 border border-grey-300"
          formControlName="bank"
          id="bank"
          shabicInput
          type="text"
        />
      </shabic-select-control>
    </shabic-form-field>

    <shabic-form-field class="text-primary mt-4">
      <shabic-form-field-label forAttribute="iban">
        {{ 'common.iban-number' | translate }}
      </shabic-form-field-label>

      <input
        class="ltr:pr-6 rtl:pl-6 border border-grey-300"
        formControlName="iban"
        id="iban"
        shabicInput
        type="text"
      />
      <p *ngIf="formGroup.errors?.['form'] === 'error.bank.ibanExists'" class="text-red text-xs mt-1">
        {{ 'error.bank.ibanExists' | translate }}
      </p>
    </shabic-form-field>

    <!-- <shabic-form-field class="text-primary mt-4">
      <shabic-form-field-label forAttribute="account-number">
        {{ 'common.account-number' | translate }}
      </shabic-form-field-label>

      <input
        type="text"
        shabicInput
        class="ltr:pr-6 rtl:pl-6 border border-grey-300"
        formControlName="accountNumber"
        id="account-number"
      />
    </shabic-form-field> -->

    <shabic-form-field class="text-primary mt-5.5">
      <shabic-form-field-label>
        <span class="normal-case text-sm">
          {{ 'common.is-bank-account-active' | translate }}
        </span>
      </shabic-form-field-label>

      <div class="flex gap-5.5 mt-3">
        <shabic-radio-control>
          <input
            class="peer"
            formControlName="active"
            id="yes"
            shabicInput
            type="radio"
            value="yes"
          />

          <shabic-form-field-label forAttribute="yes">
            <span class="normal-case text-sm">
              {{ 'common.yes' | translate }}
            </span>
          </shabic-form-field-label>
        </shabic-radio-control>

        <shabic-radio-control>
          <input
            class="peer"
            formControlName="active"
            id="no"
            shabicInput
            type="radio"
            value="no"
          />

          <shabic-form-field-label forAttribute="no">
            <span class="normal-case text-sm">
              {{ 'common.no' | translate }}
            </span>
          </shabic-form-field-label>
        </shabic-radio-control>
      </div>
    </shabic-form-field>

    <div class="flex flex-col my-6">
      <button
        [disabled]="(loading$ | async) || formGroup.invalid"
        class="gap-1"
        shabicButton="primary"
        type="submit"
      >
        {{ (isEditMode ? 'button.save' : 'button.connect') | translate }}

        <shabic-spinner *ngIf="loading$ | async"></shabic-spinner>
      </button>
    </div>
  </form>
</shabic-dialog>
