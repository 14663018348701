<section
  class="flex flex-col gap-3 desktop:flex-row justify-between desktop:items-center desktop:p-2 desktop:bg-grey-350 desktop:rounded-3xl"
>
  <div
    class="flex items-center justify-between desktop:justify-start gap-2 desktop:px-2"
  >
    <shabic-headline variant="h4" class="text-semibold">
      <ng-content select="[title]"></ng-content>
    </shabic-headline>

    <span
      class="border border-grey-350 desktop:border-0 text-semibold text-xs text-grey px-2 py-1 rounded-3xl bg-white whitespace-nowrap"
    >
      {{ amountLabel | translate: { amount: total } }}
    </span>
  </div>

  <div class="desktop:max-w-[400px] grow">
    <shabic-search-form
      [query]="query"
      [hasSearchButton]="false"
      (search)="onSearch($event)"
    ></shabic-search-form>
  </div>
</section>

<section
  class="grid gap-3 desktop:gap-5 desktop:my-5.5"
  [class.desktop:grid-cols-catalog]="hasAside"
>
  <aside class="hidden desktop:flex flex-col gap-3 empty:hidden min-w-0">
    <ng-content select="[aside]"></ng-content>
  </aside>

  <div *ngIf="hasAside" class="bg-grey-350"></div>

  <div>
    <ng-content select="[content]"></ng-content>

    <ng-container *ngIf="loadMoreLoading">
      <ng-container *ngTemplateOutlet="loader"></ng-container>
    </ng-container>

    <div class="mt-5.5 flex items-center justify-center">
      <div class="empty:hidden">
        <ng-content select="[startAction]"></ng-content>
      </div>

      <div class="grow empty:hidden">
        <shabic-pagination
          *ngIf="hasPagination"
          class="hidden desktop:block mx-auto"
          [page]="page"
          [pages]="pages"
          (nextPage)="onChangePage($event)"
        ></shabic-pagination>

        <button
          *ngIf="hasLoadMore"
          shabicButton="default"
          class="desktop:hidden w-full"
          type="button"
          (click)="onLoadMore()"
        >
          {{ 'button.show-more' | translate }}
        </button>
      </div>

      <div class="empty:hidden">
        <ng-content select="[endAction]"></ng-content>
      </div>
    </div>
  </div>
</section>

<ng-template #loader>
  <div class="text-grey flex justify-center py-5">
    <shabic-spinner size="xl"></shabic-spinner>
  </div>
</ng-template>
