import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
} from '@angular/core';
import { FormComponent } from '@shabic/form-control';
import { Login } from '../../models';
import { CLIENT_APP } from '@shabic/constants';
import { AuthService } from '@shabic/core';
import { ApiResponse, AuthResponse, ENV, IEnvironment } from '@shabic/models';

@Component({
  selector: 'shabic-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginFormComponent extends FormComponent<AuthResponse> {
  @Input() model!: Login;

  get isClientApp(): boolean {
    return this.env.app === CLIENT_APP;
  }

  constructor(
    private authService: AuthService,
    @Inject(ENV) private env: IEnvironment
  ) {
    super();
  }

  onSubmit(): void {
    const value = this.model.formGroup.value;

    this.submitForm(
      this.model.formGroup,
      this.authService.login({
        username: value.username,
        password: value.password,
        rememberMe: value.rememberMe,
      }),
      {
        stopLoading: true,
        returnResponse: true,
      }
    ).subscribe(response => {
      if (response instanceof ApiResponse) {
        if (response.payload instanceof AuthResponse) {
          if (response.payload.tokenPayload.verified) {
            this.model.submit();
          } else {
            this.authService.logout().subscribe(() => {
              this.model.nextStep();
            });
          }
        }
      }
    });
  }
}
