<div
  class="flex flex-wrap gap-4 desktop:gap-8 animate__animated"
  shabicInViewport
  inViewportClass="animate__fadeIn"
>
  <div
    class="flex flex-col desktop:flex-col-reverse items-center desktop:items-stretch desktop:justify-end gap-4 w-full desktop:max-w-[516px]"
  >
    <shabic-banner class="block w-full" type="PRODUCT_DETAILS"></shabic-banner>

    <shabic-carousel
      class="desktop:block desktop:w-full"
      [hasPreview]="true"
      [product]="product"
    ></shabic-carousel>
  </div>

  <div class="flex flex-col gap-5.5 grow">
    <shabic-product-details
      (requested)="onProductRequested($event)"
      [product]="product"
    ></shabic-product-details>
    <shabic-product-extra-details
      [product]="product"
    ></shabic-product-extra-details>

    <div>
      <shabic-headline variant="h4">
        {{ 'common.description-en' | translate }}
      </shabic-headline>

      <p class="mt-2 text-grey text-sm">{{ product.description.get('en') }}</p>
    </div>

    <div>
      <shabic-headline variant="h4">
        {{ 'common.description-ar' | translate }}
      </shabic-headline>

      <p class="mt-2 text-grey text-sm">{{ product.description.get('ar') }}</p>
    </div>
  </div>
</div>
