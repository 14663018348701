import { ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  ApiErrorResponse,
  ApiResponse,
  isErrorResponse,
  ResponseStatus,
} from './api.model';

export class FormModel<T> {
  private _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  protected cd?: ChangeDetectorRef;

  loading$: Observable<boolean> = this._loading.asObservable();

  submitForm(
    form: FormGroup,
    request: Observable<ApiResponse<T | ApiErrorResponse>>,
    config = {
      stopLoading: true,
      returnResponse: false,
    }
  ): Observable<ResponseStatus | ApiResponse<T | ApiErrorResponse>> {
    this._loading.next(true);
    return request.pipe(
      map(response => {
        if (isErrorResponse(response)) {
          this.setFormErrors(form, response);
        }

        return !config.returnResponse ? response.status : response;
      }),
      tap(() => (config.stopLoading ? this._loading.next(false) : ''))
    );
  }

  submitForms(
    forms: FormGroup[],
    requests: Observable<ApiResponse<T | ApiErrorResponse>>[],
    config = {
      stopLoading: true,
      returnResponse: false,
    }
  ) {
    return forkJoin(
      forms.map((form, index) => this.submitForm(form, requests[index], config))
    ).pipe(tap(() => this._loading.next(false)));
  }

  private setFormErrors(
    form: FormGroup,
    response: ApiResponse<ApiErrorResponse>
  ): void {
    updateFormErrorsByApiResponse(form, response);

    this.cd?.detectChanges();
  }
}

function updateFormErrorsByApiResponse(
  form: FormGroup,
  response: ApiResponse<ApiErrorResponse>
): void {
  const { fieldErrors, violations, message, title } = response.payload;

  if (fieldErrors) {
    fieldErrors.forEach(item => {
      const control = form.get(item.field);

      if (control) {
        control.setErrors({
          api: item.description,
        });
      }
    });
  }

  if (violations) {
    violations.forEach(item => {
      const field = item.field.split('.').pop() as string;
      const control = form.get(field);

      if (control) {
        control.setErrors({
          api: item.message,
        });
      } else {
        form.setErrors({
          form: item.message,
        });
      }
    });
  }

  if (message || title) {
    form.setErrors({
      form: form.getError('form') || message || title,
    });
  }
}

export type DynamicForm<TValue> = { [x: string]: AbstractControl<TValue> };
