import { Inject, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppEvent } from './models';
import { ENV, IEnvironment } from '@shabic/models';

@Injectable({
  providedIn: 'root',
})
export class EventBrokerService {
  private _event: Subject<AppEvent<unknown>> = new Subject<AppEvent<unknown>>();

  events$: Observable<AppEvent<unknown>>;

  constructor(@Inject(ENV) env: IEnvironment) {
    this.events$ = this._event.asObservable().pipe(
      tap(() => {
        if (!env.production) {
          // Log events
        }
      })
    );
  }

  emit(event: AppEvent<unknown>): void {
    this._event.next(event);
  }
}
