<shabic-dialog [fullSize]="true">
  <div class="desktop:grid desktop:grid-cols-2">
    <div class="py-8 overflow-y">
      <div class="flex justify-center mb-5.5">
        <img [src]="logoSource" class="w-[170px]" alt="Shabic logo" />
      </div>

      <form
        class="px-3 w-full max-w-[432px] mx-auto"
        [formGroup]="model.formGroup"
        (ngSubmit)="onSubmit()"
      >
        <shabic-headline variant="h2" align="center">
          {{ 'login.title' | translate }}
        </shabic-headline>

        <shabic-sub-headline>
          {{ 'login.sub-title' | translate }}
        </shabic-sub-headline>

        <shabic-form-field class="mt-5.5">
          <shabic-form-field-label forAttribute="email">
            {{ 'common.email' | translate }}
          </shabic-form-field-label>

          <input
            type="email"
            id="email"
            shabicInput
            formControlName="username"
          />
        </shabic-form-field>

        <shabic-form-field class="mt-4">
          <shabic-form-field-label forAttribute="password">
            {{ 'common.password' | translate }}
          </shabic-form-field-label>
          <input
            type="password"
            id="password"
            shabicInput
            formControlName="password"
          />
        </shabic-form-field>

        <p class="rtl:text-right mt-3">
          <a
            class="inline-block font-semibold text-xs text-secondary hover:underline"
            (click)="onForgotPassword($event)"
          >
            {{ 'login.forgot-password' | translate }}
          </a>
        </p>

        <shabic-form-field-errors
          [errors]="model.formGroup.errors"
        ></shabic-form-field-errors>

        <button
          class="w-full gap-1 mt-3"
          shabicButton="gradient"
          type="submit"
          [disabled]="model.formGroup.invalid || (loading$ | async)"
        >
          {{ 'button.login' | translate }}

          <shabic-spinner *ngIf="loading$ | async"></shabic-spinner>
        </button>

        <hr class="text-grey-350 my-3" />

        <a routerLink="/gov-api/login" shabicButton="default" class="w-full">
          {{ 'button.login-gov-api' | translate }}
        </a>

        <p class="mt-5.5 text-grey text-center text-sm font-medium">
          {{ 'login.register-lead' | translate }}
          <a
            class="text-secondary font-semibold hover:underline focus:underline"
            routerLink="/sign-up"
          >
            {{ 'button.create-account' | translate }}
          </a>
        </p>
      </form>
    </div>

    <div
      class="hidden relative bg-main-pattern-to-r-b p-8 desktop:flex flex-col justify-between"
    >
      <ng-template shabicIcon="shabic"></ng-template>

      <div class="text-[40px] text-white font-semibold leading-tight">
        {{ 'common.innovate' | translate }}. <br />
        {{ 'common.engage' | translate }}. <br />
        {{ 'common.transform' | translate }}. <br />
      </div>

      <div class="aside"></div>
    </div>
  </div>
</shabic-dialog>
