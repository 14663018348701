import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationComponent } from './pagination.component';
import { IconModule } from '@shabic/icon';
import { UiModule } from '@shabic/ui';

export * from './models';

@NgModule({
  declarations: [PaginationComponent],
  imports: [CommonModule, IconModule, UiModule],
  exports: [PaginationComponent],
})
export class PaginationModule {}
