import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { Router } from '@angular/router';
import { LOGO_SOURCE_PRIMARY, TOKEN_KEY } from '@shabic/constants';
import { StorageService } from '@shabic/core';
import { StepperPoint } from '@shabic/shared';
import { BehaviorSubject, Observable } from 'rxjs';
import { SignUp } from './models';

@Component({
  selector: 'shabic-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignUpComponent {
  companyForm = false;
  signUp: SignUp;
  logoSource = LOGO_SOURCE_PRIMARY;

  constructor(
    private storageService: StorageService,
    private router: Router,
    private cd: ChangeDetectorRef
  ) {
    this.signUp = new SignUp(
      this.storageService.retrieve<string>(TOKEN_KEY, true)
    );
  }

  onNextStep(): void {
    this.signUp.nextStep();
  }

  showCompanyForm(): void {
    this.companyForm = true;
  }

  onCompanyCreated(): void {
    this.router.navigateByUrl('/');
  }
}
