import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiErrorResponse, ApiResponse } from '@shabic/models';
import { saveAs } from 'file-saver';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private http: HttpClient) {}

  downloadFile(
    url: string,
    name: string,
    download: boolean
  ): Observable<ApiResponse<Blob | ApiErrorResponse>> {
    return this.http
      .get<Blob>(url, { responseType: 'blob' as 'json', observe: 'response' })
      .pipe(
        map(response => {
          if (download) {
            saveAs(response.body as Blob, name);
          }

          return new ApiResponse(response.body as Blob);
        }),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }
}
