export class Dimension {
  constructor(
    public readonly width: string,
    public readonly height: string,
    public readonly thickness: string
  ) {}

  toString() {
    return `${this.width} x ${this.height} x ${this.thickness} cm`;
  }
}
