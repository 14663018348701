import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TOKEN_KEY } from '@shabic/constants';
import {
  ApiClient,
  ApiErrorResponse,
  ApiResponse,
  AuthResponse,
  ENV,
  IAuthResponse,
  IEnvironment,
} from '@shabic/models';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class OtpService extends ApiClient {
  override readonly url: string = '/one-time-code';

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    @Inject(ENV) env: IEnvironment
  ) {
    super(env);
  }

  verify(
    passcode: string,
    type: 'EMAIL' | 'PHONE' | 'ID'
  ): Observable<ApiResponse<AuthResponse | ApiErrorResponse>> {
    return this.http
      .post<IAuthResponse>(this.getEndpoint('/verify'), {
        code: passcode,
        type,
      })
      .pipe(
        map(response => {
          const apiResponse = new ApiResponse(new AuthResponse(response));
          this.storageService.store(TOKEN_KEY, apiResponse.payload.token, true);
          // this.storageService.store(
          //   REFRESH_TOKEN_KEY,
          //   apiResponse.payload.refreshToken,
          //   true
          // );

          return apiResponse;
        }),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }

  send(
    value: string,
    type: 'EMAIL' | 'PHONE' | 'GOV_ID'
  ): Observable<ApiResponse<unknown | ApiErrorResponse>> {
    let request: Observable<unknown>;

    switch (type) {
      case 'PHONE': {
        request = this.http.post<unknown>(this.getEndpoint('/send/phone'), {
          phone: value,
        });
        break;
      }

      case 'GOV_ID': {
        request = this.http.post<unknown>(this.getEndpoint('/send/gov-id'), {
          govId: value,
        });
        break;
      }

      case 'EMAIL': {
        request = this.http.post<unknown>(this.getEndpoint('/send/email'), {
          govId: value,
        });
        break;
      }
    }

    return request.pipe(
      map(response => new ApiResponse(response)),
      catchError(response =>
        of(new ApiResponse(new ApiErrorResponse(response)))
      )
    );
  }
}
