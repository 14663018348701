import { getCompanyProductsQuery } from '@shabic/constants';
import { CompanyAddress } from './address.model';
import { Company } from './company.model';
import { Translated } from './translated.model';

export class Supplier {
  id: number;
  logo: string | null;
  name: Translated<string>;
  type: string;
  classification: string;
  activity: string;
  website?: string;
  phone: string;
  address: CompanyAddress;
  productsQuery: string;
  email: string;

  constructor(company: Company) {
    this.id = company.id;
    this.logo = company.logo;
    this.name = company.name;
    this.type = company.type;
    this.classification = company.classification;
    this.activity = company.activity;
    this.website = company.webSite;
    this.phone = company.phone;
    this.address = new CompanyAddress({
      city: company.address.city,
      poBox: company.address.poBox,
      street: company.address.street,
      zipCode: company.address.zipCode,
    });
    this.productsQuery = getCompanyProductsQuery(company.id);
    this.email = company.email;
  }
}
