<shabic-dialog #dialog>
  <!-- <shabic-headline align="center" variant="h3">
    {{ 'dialog.create-bank-account' | translate }}
  </shabic-headline> -->

  <form class="px-4" [formGroup]="formGroup" (ngSubmit)="onSubmit(dialog)">
    <shabic-form-field class="mt-4">
      <shabic-image-control
        [value]="formGroup.get('media')?.value"
        (changed)="patchValue(formGroup, 'mediaId', $event)"
      >
        <input
          type="file"
          shabicInput
          hidden
          name=""
          formControlName="media"
          class="peer"
          accept="image/png,image/jpeg,image/jpg"
        />
      </shabic-image-control>
    </shabic-form-field>

    <shabic-form-field class="text-primary mt-4">
      <shabic-form-field-label forAttribute="name">
        {{ 'common.name-en' | translate }}
      </shabic-form-field-label>

      <input
        type="text"
        shabicInput
        class="border border-grey-300"
        formControlName="name"
        id="name"
      />
    </shabic-form-field>

    <shabic-form-field class="text-primary mt-4">
      <shabic-form-field-label forAttribute="secondName">
        {{ 'common.name-ar' | translate }}
      </shabic-form-field-label>

      <input
        type="text"
        shabicInput
        class="border border-grey-300"
        formControlName="secondName"
        id="secondName"
      />
    </shabic-form-field>

    <div class="flex flex-col my-6">
      <button
        class="gap-1"
        type="submit"
        shabicButton="primary"
        [disabled]="(loading$ | async) || formGroup.invalid"
      >
        {{ 'button.save' | translate }}

        <shabic-spinner *ngIf="loading$ | async"></shabic-spinner>
      </button>
    </div>
  </form>
</shabic-dialog>
