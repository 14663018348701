import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function numberValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const parsedValue = Number(control.value);

    return isNaN(parsedValue) ? { notNumber: 'error.not-a-number' } : null;
  };
}
