import { Time } from './time.model';

export interface IApiUser {
  id: string;
  password: string;
  createdDate: string;
}

export class ApiUser {
  id: string;
  password: string;
  createdDate: Time;

  constructor(payload: IApiUser) {
    this.createdDate = new Time(payload.createdDate);
    this.id = payload.id;
    this.password = payload.password;
  }
}
