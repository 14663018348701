export class StepperPoint {
  id: number;
  title: string;
  subtitle: string;
  isActive: boolean;
  completed: boolean;

  constructor(title: string, subtitle: string, id: number) {
    this.title = title;
    this.subtitle = subtitle;
    this.isActive = false;
    this.completed = false;
    this.id = id;
  }

  complete(): void {
    this.isActive = false;
    this.completed = true;
  }

  activate(): void {
    this.isActive = true;
    this.completed = false;
  }
}
