<form shabicSignUpForm [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <shabic-headline variant="h2" align="center">
    {{ 'government-id.title' | translate }}
  </shabic-headline>

  <shabic-form-field class="text-primary mt-5.5">
    <shabic-form-field-label>
      {{ 'common.email' | translate }}
    </shabic-form-field-label>

    <input type="email" shabicInput formControlName="email" />
  </shabic-form-field>

  <shabic-form-field class="text-primary mt-4">
    <shabic-form-field-label>
      {{ 'common.phone-number' | translate }}
    </shabic-form-field-label>

    <input type="text" shabicInput formControlName="phone" />
  </shabic-form-field>

  <shabic-form-field class="text-primary mt-4">
    <shabic-form-field-label>
      {{ 'common.nationality' | translate }}
    </shabic-form-field-label>

    <input type="text" shabicInput formControlName="nationality" />
  </shabic-form-field>

  <shabic-form-field class="text-primary mt-4">
    <shabic-form-field-label>
      {{ 'common.birthday' | translate }}
    </shabic-form-field-label>

    <input
      type="text"
      matInput
      [matDatepicker]="picker"
      shabicInput
      formControlName="birthday"
    />

    <button type="button" addon (click)="picker.open()">
      <ng-template shabicIcon="calendar"></ng-template>
    </button>

    <mat-datepicker #picker></mat-datepicker>
  </shabic-form-field>

  <shabic-form-field class="text-primary mt-4">
    <shabic-form-field-label>
      {{ 'common.gender' | translate }}
    </shabic-form-field-label>

    <div class="flex gap-5.5 mt-3">
      <shabic-radio-control>
        <input
          shabicInput
          class="peer"
          type="radio"
          name="gender"
          id="female"
          value="female"
          formControlName="gender"
        />

        <shabic-form-field-label forAttribute="female">
          <span class="normal-case text-sm">
            {{ 'common.female' | translate }}
          </span>
        </shabic-form-field-label>
      </shabic-radio-control>

      <shabic-radio-control>
        <input
          shabicInput
          class="peer"
          type="radio"
          name="gender"
          id="male"
          value="male"
          formControlName="gender"
        />

        <shabic-form-field-label forAttribute="male">
          <span class="normal-case text-sm">
            {{ 'common.male' | translate }}
          </span>
        </shabic-form-field-label>
      </shabic-radio-control>
    </div>
  </shabic-form-field>

  <hr class="text-grey-350 my-5" />

  <shabic-headline variant="h5">
    {{ 'govt.details' | translate }}
  </shabic-headline>

  <shabic-form-field class="text-primary mt-5">
    <shabic-form-field-label>
      {{ 'common.govt' | translate }}
    </shabic-form-field-label>

    <input type="text" shabicInput formControlName="govId" />
  </shabic-form-field>

  <shabic-form-field class="text-primary mt-4">
    <shabic-form-field-label>
      {{ 'common.name' | translate }}
    </shabic-form-field-label>

    <input type="text" shabicInput formControlName="fullName" />
  </shabic-form-field>

  <button
    class="mt-4 w-full gap-1"
    shabicButton="secondary"
    type="submit"
    [disabled]="formGroup.invalid || (loading$ | async)"
  >
    {{ 'button.confirm' | translate }}

    <shabic-spinner *ngIf="loading$ | async"></shabic-spinner>
  </button>
</form>
