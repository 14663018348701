import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'shabic-product-overview-table',
  templateUrl: './product-overview-table.component.html',
  styleUrls: ['./product-overview-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductOverviewTableComponent {
  @Input() config?: {
    labels: string[];
    columns: string[];
  };
}
