import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { InputDirective } from '../directives/input.directive';

@Component({
  selector: 'shabic-file-control',
  templateUrl: './file-control.component.html',
  styleUrls: ['./file-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileControlComponent implements AfterContentInit {
  private _file: BehaviorSubject<File | null> =
    new BehaviorSubject<File | null>(null);

  @Input() control!: FormControl;
  @Input() disabled?: boolean;

  file$: Observable<File | null> = this._file.asObservable();

  @ContentChild(InputDirective) input?: InputDirective;

  ngAfterContentInit(): void {
    this.input?.change$?.subscribe(() => {
      const files = this.input?.files();

      if (files) {
        this.control?.patchValue(files);

        const file = files[0];

        this._file.next(file);
      }
    });
  }

  onUpload(): void {
    this.input?.click();
  }
}
