<shabic-dialog #dialog>
  <ul
    class="flex flex-col gap-4"
    *ngIf="documents$ | async as documents; else spinner"
  >
    <ng-container *ngIf="documents.length; else noDocuments">
      <li
        class="flex justify-between items-center gap-3 rtl:text-right rtl:flex-row-reverse"
        *ngFor="let document of documents"
      >
        <span
          class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-primary font-medium text-sm"
          [title]="document.name"
        >
          {{ document.name }}
        </span>

        <span
          *ngIf="document.status === 'CURRENT'"
          class="bg-green text-white text-sm rounded px-1 inline-block"
        >
          {{ 'status.new' | translate }}
        </span>

        <button
          type="button"
          shabicButton="primary"
          [disabled]="loading$ | async"
          (click)="onDownload(document)"
        >
          {{ 'button.download' | translate }}
        </button>

        <button
          *ngIf="hasPreviewButton(document)"
          type="button"
          shabicButton="default"
          [disabled]="loading$ | async"
          (click)="onPreview(document)"
        >
          {{ 'button.preview' | translate }}
        </button>
      </li>
    </ng-container>
  </ul>
</shabic-dialog>

<ng-template #spinner>
  <div class="flex justify-center py-4">
    <shabic-spinner></shabic-spinner>
  </div>
</ng-template>

<ng-template #noDocuments>
  <div class="text-sm text-center text-grey">
    {{ 'message.no_documents' | translate }}
  </div>
</ng-template>
