<ul *ngIf="tags" class="flex flex-wrap gap-1">
  <li *ngFor="let tag of tags">
    <a
      shabicBadge="secondary"
      class="hover:bg-secondary/[0.2] transition-colors"
      href="#"
      (click)="onSelect($event, tag)"
    >
      {{ tag.name | currentLang | async }}
    </a>
  </li>
</ul>
