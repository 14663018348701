import { Injectable } from '@angular/core';
import { EAppEvent, EventBrokerService } from '@shabic/event-broker';
import { Currency, CurrencyType } from '@shabic/models';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  private _currentCurrency = new BehaviorSubject<Currency>(new Currency('SAR'));

  currentCurrency$ = this._currentCurrency.asObservable();

  constructor(private eventBrokerService: EventBrokerService) {
    this.subscribeOnChangeCurrency();
  }

  private subscribeOnChangeCurrency(): void {
    this.eventBrokerService.events$
      .pipe(filter(event => event.type === EAppEvent.ChangeCurrency))
      .subscribe(event => {
        const type = event.payload as CurrencyType;

        this._currentCurrency.next(new Currency(type));
      });
  }
}
