<svg
  width="24"
  height="24"
  version="1.1"
  viewBox="0 0 458 448"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <defs>
    <symbol id="m" overflow="visible">
      <path
        d="m 31.266,-1.875 c -1.6172,0.84375 -3.2969,1.4766 -5.0469,1.8906 -1.7422,0.42578 -3.5586,0.64062 -5.4531,0.64062 -5.668,0 -10.156,-1.582 -13.469,-4.75 -3.3125,-3.1641 -4.9688,-7.4609 -4.9688,-12.891 0,-5.4375 1.6562,-9.7383 4.9688,-12.906 3.3125,-3.1641 7.8008,-4.75 13.469,-4.75 1.8945,0 3.7109,0.21484 5.4531,0.64062 1.75,0.42969 3.4297,1.0586 5.0469,1.8906 v 7.0469 c -1.625,-1.1133 -3.2305,-1.9258 -4.8125,-2.4375 -1.5742,-0.51953 -3.2344,-0.78125 -4.9844,-0.78125 -3.1367,0 -5.6016,1.0078 -7.3906,3.0156 -1.793,2 -2.6875,4.7617 -2.6875,8.2812 0,3.5117 0.89453,6.2734 2.6875,8.2812 1.7891,2 4.2539,3 7.3906,3 1.75,0 3.4102,-0.25391 4.9844,-0.76562 1.582,-0.51953 3.1875,-1.332 4.8125,-2.4375 z"
        fill="currentColor"
        id="path2"
      />
    </symbol>
    <symbol id="d" overflow="visible">
      <path
        d="m 22.875,-18.578 c -0.71094,-0.33203 -1.418,-0.57812 -2.125,-0.73438 -0.71094,-0.15625 -1.418,-0.23438 -2.125,-0.23438 -2.1055,0 -3.7266,0.67188 -4.8594,2.0156 -1.125,1.3438 -1.6875,3.2656 -1.6875,5.7656 V 4.4e-4 H 3.9219 v -25.531 h 8.1562 v 4.2031 c 1.0508,-1.6758 2.2539,-2.8945 3.6094,-3.6562 1.3633,-0.76953 2.9922,-1.1562 4.8906,-1.1562 0.26953,0 0.56641,0.01172 0.89062,0.03125 0.32031,0.02344 0.78516,0.07422 1.3906,0.15625 z"
        fill="currentColor"
        id="path5"
      />
    </symbol>
    <symbol id="b" overflow="visible">
      <path
        d="m 29.391,-12.828 v 2.3281 H 10.329 c 0.19531,1.9062 0.88281,3.3398 2.0625,4.2969 1.1875,0.96094 2.8438,1.4375 4.9688,1.4375 1.7188,0 3.4766,-0.25391 5.2812,-0.76562 1.8008,-0.50781 3.6484,-1.2812 5.5469,-2.3125 v 6.2969 c -1.9297,0.73047 -3.8594,1.2773 -5.7969,1.6406 -1.9297,0.375 -3.8555,0.5625 -5.7812,0.5625 -4.6172,0 -8.2031,-1.1719 -10.766,-3.5156 -2.5629,-2.3437 -3.8438,-5.6328 -3.8438,-9.875 0,-4.1641 1.2539,-7.4414 3.7656,-9.8281 2.5195,-2.3828 5.9844,-3.5781 10.391,-3.5781 4.0078,0 7.2188,1.2109 9.625,3.625 2.4062,2.418 3.6094,5.6484 3.6094,9.6875 z m -8.375,-2.7188 c 0,-1.5391 -0.45312,-2.7852 -1.3594,-3.7344 -0.90625,-0.95703 -2.0898,-1.4375 -3.5469,-1.4375 -1.5859,0 -2.8711,0.44922 -3.8594,1.3438 -0.98047,0.88672 -1.5898,2.1641 -1.8281,3.8281 z"
        fill="currentColor"
        id="path8"
      />
    </symbol>
    <symbol id="c" overflow="visible">
      <path
        d="m 15.359,-11.484 c -1.6992,0 -2.9805,0.29297 -3.8438,0.875 -0.85547,0.57422 -1.2812,1.4219 -1.2812,2.5469 0,1.0312 0.34375,1.8398 1.0312,2.4219 0.69531,0.58594 1.6602,0.875 2.8906,0.875 1.5312,0 2.8164,-0.54688 3.8594,-1.6406 1.0508,-1.1016 1.5781,-2.4844 1.5781,-4.1406 v -0.9375 z m 12.469,-3.0781 V -1e-4 h -8.2344 v -3.7812 c -1.0938,1.5547 -2.3242,2.6836 -3.6875,3.3906 -1.3672,0.69531 -3.0273,1.0469 -4.9844,1.0469 -2.6484,0 -4.7969,-0.76953 -6.4531,-2.3125 -1.6484,-1.5391 -2.4688,-3.5391 -2.4688,-6 0,-2.9883 1.0312,-5.1797 3.0938,-6.5781 2.0625,-1.4062 5.2969,-2.1094 9.7031,-2.1094 h 4.7969 v -0.64062 c 0,-1.2891 -0.51172,-2.2344 -1.5312,-2.8281 -1.0117,-0.60156 -2.5938,-0.90625 -4.75,-0.90625 -1.75,0 -3.3828,0.17969 -4.8906,0.53125 -1.5,0.34375 -2.8984,0.86719 -4.1875,1.5625 v -6.2188 c 1.75,-0.42578 3.5039,-0.75 5.2656,-0.96875 1.7578,-0.21875 3.5234,-0.32812 5.2969,-0.32812 4.5938,0 7.9102,0.91406 9.9531,2.7344 2.0508,1.8125 3.0781,4.7617 3.0781,8.8438 z"
        fill="currentColor"
        id="path11"
      />
    </symbol>
    <symbol id="a" overflow="visible">
      <path
        d="m 12.828,-32.766 v 7.2344 h 8.4062 v 5.8438 H 12.828 v 10.828 c 0,1.1797 0.23438,1.9805 0.70312,2.4062 0.47656,0.41797 1.4141,0.625 2.8125,0.625 h 4.1875 V -5e-4 h -7 c -3.2188,0 -5.5,-0.67188 -6.8438,-2.0156 -1.3438,-1.34372 -2.0156,-3.625 -2.0156,-6.8438 v -10.828 h -4.0625 v -5.8438 h 4.0625 v -7.2344 z"
        fill="currentColor"
        id="path14"
      />
    </symbol>
    <symbol id="i" overflow="visible">
      <path
        d="m 21.281,-21.781 v -13.672 h 8.2031 V 0 H 21.281 v -3.6875 c -1.125,1.5 -2.3672,2.6016 -3.7188,3.2969 -1.3438,0.69531 -2.9062,1.0469 -4.6875,1.0469 -3.1484,0 -5.7305,-1.25 -7.75,-3.75 -2.0234,-2.5 -3.0312,-5.7109 -3.0312,-9.6406 0,-3.9375 1.0078,-7.1562 3.0312,-9.6562 2.0195,-2.5 4.6016,-3.75 7.75,-3.75 1.7578,0 3.3164,0.35547 4.6719,1.0625 1.3633,0.71094 2.6094,1.8086 3.7344,3.2969 z m -5.375,16.516 c 1.75,0 3.082,-0.63281 4,-1.9062 0.91406,-1.2812 1.375,-3.1328 1.375,-5.5625 0,-2.4375 -0.46094,-4.2891 -1.375,-5.5625 -0.91797,-1.2812 -2.25,-1.9219 -4,-1.9219 -1.7305,0 -3.0586,0.64062 -3.9844,1.9219 -0.91797,1.2734 -1.375,3.125 -1.375,5.5625 0,2.4297 0.45703,4.2812 1.375,5.5625 0.92578,1.2734 2.2539,1.9062 3.9844,1.9062 z"
        fill="currentColor"
        id="path17"
      />
    </symbol>
    <symbol id="h" overflow="visible">
      <path
        d="m 17.5,-5.2656 c 1.75,0 3.082,-0.63281 4,-1.9062 0.91406,-1.2812 1.375,-3.1328 1.375,-5.5625 0,-2.4375 -0.46094,-4.2891 -1.375,-5.5625 -0.91797,-1.2812 -2.25,-1.9219 -4,-1.9219 -1.75,0 -3.0938,0.64844 -4.0312,1.9375 -0.92969,1.2812 -1.3906,3.1328 -1.3906,5.5469 0,2.418 0.46094,4.2656 1.3906,5.5469 0.9375,1.2812 2.2812,1.9219 4.0312,1.9219 z m -5.4219,-16.516 c 1.125,-1.4883 2.3672,-2.5859 3.7344,-3.2969 1.3633,-0.70703 2.9375,-1.0625 4.7188,-1.0625 3.1445,0 5.7266,1.25 7.75,3.75 2.0195,2.5 3.0312,5.7188 3.0312,9.6562 0,3.9297 -1.0117,7.1406 -3.0312,9.6406 -2.0234,2.5 -4.6055,3.75 -7.75,3.75 -1.7812,0 -3.3555,-0.35156 -4.7188,-1.0469 -1.3672,-0.70703 -2.6094,-1.8047 -3.7344,-3.2969 V -5e-4 H 3.9219 v -35.453 h 8.1562 z"
        fill="currentColor"
        id="path20"
      />
    </symbol>
    <symbol id="g" overflow="visible">
      <path
        d="m 0.5625,-25.531 h 8.1719 l 6.8594,17.328 5.8281,-17.328 h 8.1562 L 18.8441,2.422 C 17.7699,5.254 16.516,7.2384 15.0785,8.3751 13.641,9.5079 11.7426,10.0782 9.391,10.0782 H 4.6722 V 4.7188 h 2.5469 c 1.3828,0 2.3906,-0.22656 3.0156,-0.67188 0.63281,-0.4375 1.1289,-1.2266 1.4844,-2.3594 L 11.93785,0.9844 Z"
        fill="currentColor"
        id="path23"
      />
    </symbol>
    <symbol id="f" overflow="visible">
      <path
        d="m 0.23438,-34.031 h 8.8125 l 9.0312,25.125 9,-25.125 h 8.8125 L 23.29658,0 h -10.469 z"
        fill="currentColor"
        id="path26"
      />
    </symbol>
    <symbol id="l" overflow="visible">
      <path
        d="m 24.547,-24.719 v 6.6406 c -1.1172,-0.75781 -2.2305,-1.3203 -3.3438,-1.6875 -1.1172,-0.36328 -2.2734,-0.54688 -3.4688,-0.54688 -2.2812,0 -4.0586,0.66797 -5.3281,2 -1.2734,1.3359 -1.9062,3.1953 -1.9062,5.5781 0,2.3867 0.63281,4.2461 1.9062,5.5781 1.2695,1.3242 3.0469,1.9844 5.3281,1.9844 1.2695,0 2.4766,-0.1875 3.625,-0.5625 1.1445,-0.38281 2.207,-0.94531 3.1875,-1.6875 v 6.6719 C 23.2657,-0.28153 21.9688,0.06613 20.6563,0.29662 19.3438,0.5349 18.0235,0.656 16.7032,0.656 c -4.6055,0 -8.2109,-1.1758 -10.812,-3.5312 -2.5938,-2.3633 -3.8906,-5.6484 -3.8906,-9.8594 0,-4.207 1.2969,-7.4922 3.8906,-9.8594 2.6016,-2.3633 6.207,-3.5469 10.812,-3.5469 1.332,0 2.6484,0.12109 3.9531,0.35938 1.3008,0.23047 2.5977,0.58594 3.8906,1.0625 z"
        fill="currentColor"
        id="path29"
      />
    </symbol>
    <symbol id="k" overflow="visible">
      <path
        d="m 16.062,-20.312 c -1.8047,0 -3.1836,0.65234 -4.1406,1.9531 -0.94922,1.3047 -1.4219,3.1797 -1.4219,5.625 0,2.4492 0.47266,4.3242 1.4219,5.625 0.95703,1.293 2.3359,1.9375 4.1406,1.9375 1.7812,0 3.1406,-0.64453 4.0781,-1.9375 0.94531,-1.3008 1.4219,-3.1758 1.4219,-5.625 0,-2.4453 -0.47656,-4.3203 -1.4219,-5.625 -0.9375,-1.3008 -2.2969,-1.9531 -4.0781,-1.9531 z m 0,-5.8281 c 4.3945,0 7.8281,1.1875 10.297,3.5625 2.4688,2.3672 3.7031,5.6484 3.7031,9.8438 0,4.1875 -1.2344,7.4688 -3.7031,9.8438 -2.4688,2.3672 -5.9023,3.5469 -10.297,3.5469 -4.4062,0 -7.8555,-1.1797 -10.344,-3.5469 -2.4805,-2.375 -3.7188,-5.6562 -3.7188,-9.8438 0,-4.1953 1.2383,-7.4766 3.7188,-9.8438 2.4883,-2.375 5.9375,-3.5625 10.344,-3.5625 z"
        fill="currentColor"
        id="path32"
      />
    </symbol>
    <symbol id="j" overflow="visible">
      <path
        d="m 23.859,-24.719 v 6.1875 c -1.75,-0.72656 -3.4375,-1.2734 -5.0625,-1.6406 -1.625,-0.36328 -3.1562,-0.54688 -4.5938,-0.54688 -1.5547,0 -2.7109,0.19922 -3.4688,0.59375 -0.75,0.38672 -1.125,0.98047 -1.125,1.7812 0,0.65625 0.28516,1.1641 0.85938,1.5156 0.57031,0.34375 1.5938,0.60156 3.0625,0.76562 l 1.4375,0.20312 c 4.1758,0.53125 6.9844,1.4062 8.4219,2.625 1.4453,1.2109 2.1719,3.1172 2.1719,5.7188 0,2.7188 -1.0078,4.7617 -3.0156,6.125 -2,1.3672 -4.9922,2.0469 -8.9688,2.0469 -1.6875,0 -3.4336,-0.13672 -5.2344,-0.40625 -1.793,-0.25781 -3.6406,-0.65625 -5.5469,-1.1875 v -6.2031 c 1.625,0.79297 3.2891,1.3867 5,1.7812 1.7188,0.39844 3.457,0.59375 5.2188,0.59375 1.5938,0 2.7891,-0.21875 3.5938,-0.65625 0.8125,-0.4375 1.2188,-1.0938 1.2188,-1.9688 0,-0.72656 -0.28125,-1.2695 -0.84375,-1.625 -0.55469,-0.35156 -1.6562,-0.62891 -3.3125,-0.82812 l -1.4375,-0.1875 c -3.625,-0.44531 -6.168,-1.2852 -7.625,-2.5156 -1.4609,-1.2383 -2.1875,-3.1094 -2.1875,-5.6094 0,-2.707 0.92188,-4.7109 2.7656,-6.0156 1.8516,-1.3125 4.6953,-1.9688 8.5312,-1.9688 1.5,0 3.0781,0.11719 4.7344,0.34375 1.6562,0.23047 3.457,0.58984 5.4062,1.0781 z"
        fill="currentColor"
        id="path35"
      />
    </symbol>
    <symbol id="e" overflow="visible">
      <path
        d="m 3.9219,-35.453 h 8.1562 V 0 H 3.9219 Z"
        id="path38"
        fill="currentColor"
      />
    </symbol>
  </defs>
  <g id="g55" transform="translate(-126.5418,-51.301)">
    <path
      d="m 438.26,62.559 c -7.1367,-7.2578 -16.707,-11.258 -26.953,-11.258 -21.008,0 -38.098,17.09 -38.098,38.098 v 13.207 l -105.41,0.004 c -5.1602,0 -9.332,4.1797 -9.332,9.332 v 183.53 c -49.348,4.8594 -95.086,32.914 -130.1,80.371 -2.4336,3.293 -2.4336,7.793 0,11.09 35.105,47.516 80.848,75.543 130.1,80.301 v 22.801 c 0,5.1562 4.1758,9.332 9.332,9.332 h 287 c 5.1602,0 9.332,-4.1797 9.332,-9.332 l 0.004,-378.09 c 0,-5.1562 -4.1758,-9.332 -9.332,-9.332 H 449.323 V 89.405906 c 0,-10.18 -3.9453,-19.73 -11.062,-26.844 z m -26.953,7.4102 c 5.1953,0 10.043,2.0195 13.699,5.7344 3.6445,3.6445 5.6523,8.5078 5.6523,13.699 v 46.555 h -38.781 v -23.746 c 0.004,-0.0937 0.0547,-0.17188 0.0547,-0.26563 0,-0.0937 -0.0508,-0.17187 -0.0547,-0.26562 l -0.004,-22.277 c 0,-10.715 8.7148,-19.434 19.434,-19.434 z m -137.39,246.79 c 35.609,0 64.578,28.965 64.578,64.57 0,35.605 -28.965,64.57 -64.578,64.57 -35.602,0 -64.566,-28.965 -64.566,-64.57 0,-35.602 28.965,-64.57 64.566,-64.57 z m 64.926,12.609 c 22.496,11.527 43.332,29.027 61.387,52.016 -18.105,23.051 -38.977,40.574 -61.508,52.078 11.512,-14.281 18.441,-32.402 18.441,-52.129 0,-19.656 -6.8867,-37.711 -18.32,-51.965 z m -191.23,52.012 c 18.059,-23.016 38.91,-40.531 61.43,-52.059 -11.457,14.262 -18.355,32.336 -18.355,52.012 0,19.73 6.9336,37.855 18.449,52.141 -22.531,-11.508 -43.414,-29.035 -61.523,-52.094 z m 397.86,-260.11 v 359.43 h -268.33 v -12.754 c 53.902,-0.97656 104.35,-29.602 142.34,-81.012 2.4336,-3.293 2.4336,-7.7969 0,-11.094 -37.93,-51.336 -88.363,-79.984 -142.34,-81.102 v -173.46 h 96.078 v 14.68 h -22.422 c -5.1602,0 -9.332,4.1797 -9.332,9.332 0,5.1562 4.1758,9.332 9.332,9.332 h 120.96 c 5.1602,0 9.332,-4.1797 9.332,-9.332 0,-5.1562 -4.1758,-9.332 -9.332,-9.332 h -22.422 v -14.68 z"
      fill="currentColor"
      id="path43"
    />
    <path
      d="m 411.26,104.12 c 5.1602,0 9.332,-4.1797 9.332,-9.332 l 0.004,-7.2852 c 0,-5.1562 -4.1758,-9.332 -9.332,-9.332 -5.1602,0 -9.332,4.1797 -9.332,9.332 l -0.004,7.2852 c 0,5.1523 4.1758,9.332 9.332,9.332 z"
      fill="currentColor"
      id="path45"
    />
    <path
      d="m 316.68,195.95 c 0,5.1562 4.1758,9.332 9.332,9.332 h 177.6 c 5.1602,0 9.332,-4.1797 9.332,-9.332 0,-5.1562 -4.1758,-9.332 -9.332,-9.332 h -177.6 c -5.1562,0 -9.332,4.1797 -9.332,9.332 z"
      fill="currentColor"
      id="path47"
    />
    <path
      d="m 503.61,225.09 h -177.6 c -5.1602,0 -9.332,4.1797 -9.332,9.332 0,5.1562 4.1758,9.332 9.332,9.332 h 177.6 c 5.1602,0 9.332,-4.1797 9.332,-9.332 0.004,-5.1523 -4.1719,-9.332 -9.332,-9.332 z"
      fill="currentColor"
      id="path49"
    />
    <path
      d="M 461.27,263.55 H 326.01 c -5.1602,0 -9.332,4.1797 -9.332,9.332 0,5.1562 4.1758,9.332 9.332,9.332 h 135.26 c 5.1602,0 9.332,-4.1797 9.332,-9.332 0,-5.1562 -4.1719,-9.332 -9.332,-9.332 z"
      fill="currentColor"
      id="path51"
    />
    <path
      d="m 254.73,408.96 c 1.8242,1.8242 4.2109,2.7344 6.5977,2.7344 2.3868,0 4.7773,-0.91016 6.5977,-2.7344 l 42.047,-42.051 c 3.6445,-3.6445 3.6445,-9.5508 0,-13.199 -3.6445,-3.6445 -9.5508,-3.6445 -13.199,0 l -35.445,35.449 -10.273,-10.27 c -3.6445,-3.6445 -9.5508,-3.6445 -13.199,0 -3.6445,3.6445 -3.6445,9.5586 0,13.199 z"
      fill="currentColor"
      id="path53"
    />
  </g>
</svg>
