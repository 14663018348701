import {
  ChangeDetectorRef,
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { AuthService, StorageService } from '@shabic/core';
import { Subject } from 'rxjs';

@Directive({
  selector: '[shabicHasRole]',
})
export class HasRoleDirective implements OnInit, OnDestroy {
  private _destroy = new Subject<void>();
  private hasView = false;
  private role = '';

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService,
    private storageService: StorageService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subscribeOnTokenChange();
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  @Input() set shabicHasRole(role: string) {
    this.role = role;
    this.render();
  }

  private subscribeOnTokenChange() {
    this.storageService.storage$.subscribe(() => {
      this.render();
    });
  }

  private render() {
    const hasRole = this.authService.currentUser.roles.includes(this.role);

    if (hasRole && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!hasRole && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }

    this.cd.markForCheck();
  }
}
