<svg
  xmlns="http://www.w3.org/2000/svg"
  height="24"
  viewBox="0 96 960 960"
  width="24"
>
  <path
    d="M180 975q-24 0-42-18t-18-42V312h60v603h474v60H180Zm120-120q-24 0-42-18t-18-42V235q0-24 18-42t42-18h440q24 0 42 18t18 42v560q0 24-18 42t-42 18H300Zm0-60h440V235H300v560Zm0 0V235v560Z"
    fill="currentColor"
  />
</svg>
