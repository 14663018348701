<table
  class="text-primary text-sm font-medium border-spacing-x-[18px] desktop:border-spacing-x-[30px] border-spacing-y-[2px] border-separate -mx-[18px] desktop:-mx-[30px]"
>
  <thead>
    <tr class="uppercase text-[10px] leading-3 text-grey text-left">
      <th class="font-semibold rtl:text-right" *ngFor="let label of labels">
        {{ label | translate: translateParams }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td
        class="text-sm font-semibold rtl:text-right"
        [ngClass]="getItemClasses(last)"
        *ngFor="let cell of rows; let last = last"
      >
        {{ cell | translate: translateParams }}
      </td>
    </tr>
  </tbody>
</table>
