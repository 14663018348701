import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SUCCESS } from '@shabic/constants';
import { AccountService } from '@shabic/core';
import { FormComponent } from '@shabic/form-control';
import { ForgotPassword } from '@shabic/models';

@Component({
  selector: 'shabic-login-create-new-password-form',
  templateUrl: './login-create-new-password-form.component.html',
  styleUrls: ['./login-create-new-password-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginCreateNewPasswordFormComponent extends FormComponent<unknown> {
  @Input() model!: ForgotPassword<AccountService>;
  @Input() completed = false;
  @Input() token?: string;

  onSubmit(): void {
    if (!this.token) {
      return;
    }

    this.submitForm(
      this.model.formGroup,
      this.model.savePassword(this.token)
    ).subscribe(response => {
      if (response === SUCCESS) {
        this.completed = true;
      }
    });
  }
}
