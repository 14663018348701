import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { SUCCESS } from '@shabic/constants';
import { CompanyService } from '@shabic/core';
import { AppEvent, EAppEvent, EventBrokerService } from '@shabic/event-broker';
import { FormComponent, FORM_CONFIG } from '@shabic/form-control';
import {
  Address,
  Company,
  CompanyDocument,
  CompanyPayload,
  Option,
} from '@shabic/models';
import { BehaviorSubject, Observable } from 'rxjs';

type State = 'form' | 'verify' | 'message';

@Component({
  selector: 'shabic-sign-up-company-verification',
  templateUrl: './sign-up-company-verification.component.html',
  styleUrls: ['./sign-up-company-verification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: FORM_CONFIG, useValue: { markRequiredFields: true } }],
})
export class SignUpCompanyVerificationComponent
  extends FormComponent<Company | CompanyDocument[]>
  implements OnInit
{
  private _state = new BehaviorSubject<State>('form');

  state$ = this._state.asObservable();

  formGroup: FormGroup = new FormGroup({
    company: new FormGroup({
      mediaId: new FormControl(''),
      logo: new FormControl(null, []),
      nameFirst: new FormControl(null, [Validators.required]),
      nameSecond: new FormControl(null, [Validators.required]),
      crNumber: new FormControl(null, [Validators.required]),
      formationDate: new FormControl(null, [Validators.required]),
      type: new FormControl(null, [Validators.required]),
      businessActivity: new FormControl(null, [Validators.required]),
      classification: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      phone: new FormControl(null, [Validators.required]),
      street: new FormControl(null, [Validators.required]),
      city: new FormControl(null, [Validators.required]),
      poBox: new FormControl(null, [Validators.required]),
      zipCode: new FormControl(null, [Validators.required]),
      webSite: new FormControl(null),
      latitude: new FormControl(),
      longitude: new FormControl(),
    }),
    verify: new FormGroup({
      files: new FormArray(
        [
          new FormControl(null),
          new FormControl(null),
          new FormControl(null),
          new FormControl(null),
          new FormControl(null),
        ],
        [Validators.required]
      ),
    }),
  });

  get companyForm(): FormGroup {
    return this.formGroup.get('company') as FormGroup;
  }

  get verifyForm(): FormGroup {
    return this.formGroup.get('verify') as FormGroup;
  }

  get fileControls(): FormControl[] {
    return (this.verifyForm.get('files') as FormArray)
      .controls as FormControl[];
  }

  get today(): Date {
    return new Date();
  }

  types$: Observable<Option[]>;
  activities$: Observable<Option[]>;
  classifications$: Observable<Option[]>;

  @Output() completed: EventEmitter<void> = new EventEmitter();

  constructor(
    private companyService: CompanyService,
    protected override cd: ChangeDetectorRef,
    private eventBrokerService: EventBrokerService
  ) {
    super();

    this.types$ = this.companyService.types$;
    this.activities$ = this.companyService.activities$;
    this.classifications$ = this.companyService.classifications$;
  }

  ngOnInit() {
    this.companyService.updateCompanyValues().subscribe();
  }

  onSubmit() {
    const value = this.formGroup.value;
    const company = value.company;
    const verify = value.verify;

    if (this._state.value === 'form') {
      const body = {
        ...company,
        phone: '+966' + company.phone,
        type: this.companyService.getOptionValue(company.type, 'types'),
        classification: this.companyService.getOptionValue(
          company.classification,
          'classifications'
        ),
        businessActivity: this.companyService.getOptionValue(
          company.businessActivity,
          'businessActivities'
        ),
        formationDate: company.formationDate
          ? new Date(company.formationDate).toISOString()
          : '',
      };

      this.registerCompany(body);
    } else {
      this.verifyCompanyFiles(verify.files);
    }
  }

  onLogout() {
    this.eventBrokerService.emit(new AppEvent<null>(EAppEvent.LogOut, null));
  }

  onAddressUpdate(event: Address) {
    this.companyForm.patchValue(event);
  }

  private verifyCompanyFiles(payload: File[][]): void {
    const files = payload.filter(el => !!el && el[0]).map(el => el[0]);

    if (!files.length) {
      this.verifyForm.setErrors({
        form: 'error.choose-files',
      });
      return;
    }

    this.submitForm(
      this.verifyForm,
      this.companyService.updateCompanyDocuments(files)
    ).subscribe(status => {
      if (status === SUCCESS) {
        this._state.next('message');
      }
    });
  }

  private registerCompany(payload: CompanyPayload): void {
    this.submitForm(
      this.companyForm,
      this.companyService.register(payload)
    ).subscribe(status => {
      if (status === SUCCESS) {
        this._state.next('verify');
      }
    });
  }
}
