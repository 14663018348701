import { TranslateService } from '@ngx-translate/core';
import {
  getAllCountries,
  getCountryByAlpha2,
  getCountryByName,
} from 'country-locale-map';
import { Option } from './option.model';

export interface ICountry {
  name: string;
  alpha2: string;
  alpha3: string;
  numeric: string;
  locales: string[];
  default_locale: string;
  currency: string;
  currency_name: string;
  languages: string[];
  capital: string;
  emoji: string;
  emojiU: string;
  fips: string;
  internet: string;
  continent: string;
  region: string;
  alternate_names?: string[];
}

export const countries: (
  translateService: TranslateService
) => Country[] = () => getAllCountries().map(el => new Country(el));

export const countryOptions: (
  translateService: TranslateService
) => Option[] = translateService =>
  getAllCountries().map(el => new Option(translateService, el.name, el.name));

export const getCountryFlag = (alpha2: string) => {
  return Country.parse(undefined, alpha2)?.flag;
};

export class Country {
  private _instance: ICountry;

  static parse(name?: string, alpha2?: string) {
    if (!name && !alpha2) {
      return;
    }

    let country;

    if (name) {
      country = getCountryByName(name);
    }

    if (alpha2) {
      country = getCountryByAlpha2(alpha2);
    }

    return country ? new Country(country) : undefined;
  }

  constructor(payload: ICountry) {
    this._instance = payload;
  }

  get name() {
    return this._instance.name;
  }

  get flag() {
    return this._instance.emoji;
  }

  get isSaudi() {
    return this._instance.alpha2 === 'SA';
  }
}
