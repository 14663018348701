import { Pipe, PipeTransform } from '@angular/core';
import { TOKEN_KEY } from '@shabic/constants';
import { EAppEvent, EventBrokerService } from '@shabic/event-broker';
import { ApiToken, ROLE_SUPPLIER } from '@shabic/models';
import { StorageService } from '@shabic/core';
import { merge, Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';

type Records = Array<
  Record<string, any> & {
    onlyForSupplier?: boolean;
    onlyForAuthorized?: boolean;
  }
>;

@Pipe({
  name: 'auth',
})
export class AuthPipe implements PipeTransform {
  constructor(
    private eventBrokerService: EventBrokerService,
    private storageService: StorageService
  ) {}

  transform(value: Records): Observable<Records> {
    return merge(this.authRequests(), of({} as any)).pipe(
      map(() => {
        const token = this.storageService.retrieve<string>(TOKEN_KEY, true);
        const tokenPayload = token ? new ApiToken(token) : null;

        return value.filter(el => {
          if (el.onlyForSupplier) {
            return tokenPayload
              ? tokenPayload.verified &&
                  !tokenPayload.isExpired &&
                  tokenPayload.roles.includes(ROLE_SUPPLIER)
              : false;
          } else if (el.onlyForAuthorized) {
            return tokenPayload
              ? tokenPayload.verified && !tokenPayload.isExpired
              : false;
          } else if (el.onlyForAuthorized === false) {
            return !tokenPayload || tokenPayload.isExpired || false;
          } else {
            return true;
          }
        });
      })
    );
  }

  private authRequests() {
    const authEvents = [EAppEvent.Authorized, EAppEvent.LogOut];

    return this.eventBrokerService.events$.pipe(
      filter(event => authEvents.includes(event.type))
    );
  }
}
