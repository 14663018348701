import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiErrorResponse, ApiResponse, isErrorResponse } from './api.model';
import { IListParams, List } from './list.model';
import { PreOrder } from './order.model';
import { Quota } from './quota.model';

export interface ICartService {
  getItems(
    params: IListParams
  ): Observable<ApiResponse<ApiErrorResponse | List<PreOrder, PreOrder[]>>>;
  putItem(quota: Quota): Observable<ApiResponse<ApiErrorResponse | unknown>>;
  deleteItem(
    preOrder: PreOrder
  ): Observable<ApiResponse<ApiErrorResponse | unknown>>;
}

export class Cart {
  private _preOrders = new BehaviorSubject<PreOrder[]>([]);

  preOrders$ = this._preOrders.asObservable();

  constructor(private service: ICartService) {}

  deleteItem(item: PreOrder) {
    const preOrders = this._preOrders.value;

    return this.service.deleteItem(item).pipe(
      map(response => {
        if (isErrorResponse(response)) {
          return null;
        }

        this._preOrders.next([
          ...preOrders.filter(el => el.quotationId !== item.quotationId),
        ]);

        return item;
      })
    );
  }

  fetchProducts(params: IListParams) {
    return this.service.getItems(params).pipe(
      map(response => {
        if (response.payload instanceof ApiErrorResponse) {
          return [];
        }

        this._preOrders.next(response.payload.content);
        return response.payload;
      })
    );
  }
}
