import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimelineComponent } from './timeline.component';
import { IconModule } from '@shabic/icon';
import { UiModule } from '@shabic/ui';
import { UtilsModule } from '@shabic/utils';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [TimelineComponent],
  imports: [CommonModule, IconModule, UiModule, UtilsModule, TranslateModule],
  exports: [TimelineComponent],
})
export class TimelineModule {}
