<shabic-animate>
  <div
    class="hidden desktop:flex items-center gap-4 rounded-xl px-3 py-2 bg-white border border-grey-350"
  >
    <button
      type="button"
      class="text-primary text-sm font-semibold hover:underline"
      (click)="onSearch()"
    >
      {{ 'button.apply-filters' | translate }}
    </button>
  </div>
</shabic-animate>
