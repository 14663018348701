import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepperComponent } from './stepper.component';
import { StepperPointComponent } from './stepper-point/stepper-point.component';
import { StepperLineComponent } from './stepper-line/stepper-line.component';
import { TranslateModule } from '@ngx-translate/core';
import { IconModule } from '@shabic/icon';

@NgModule({
  declarations: [StepperComponent, StepperPointComponent, StepperLineComponent],
  imports: [CommonModule, TranslateModule, IconModule],
  exports: [StepperComponent],
})
export class StepperModule {}
