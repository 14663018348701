import { DIALOG_DATA } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { ListProduct, RequestProductsDialogData } from '@shabic/models';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'shabic-request-products-dialog',
  templateUrl: './request-products-dialog.component.html',
  styleUrls: ['./request-products-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestProductsDialogComponent {
  readonly products: ListProduct[];

  constructor(@Inject(DIALOG_DATA) public data: RequestProductsDialogData) {
    this.products = data.products;
  }

  confirm(dialog: DialogComponent) {
    this.data.onConfirm().subscribe(() => {
      dialog.close();
    });
  }
}
