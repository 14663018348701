<form
  class="px-[20px] rounded w-full max-w-[432px] mx-auto"
  [formGroup]="model.formGroup"
  (ngSubmit)="onSubmit()"
>
  <ng-container *ngIf="!completed">
    <shabic-headline align="center" variant="h2">
      {{ 'forgot-password.title' | translate }}
    </shabic-headline>

    <shabic-sub-headline>
      {{ 'forgot-password.subtitle' | translate }}
    </shabic-sub-headline>

    <shabic-form-field class="mt-5.5">
      <shabic-form-field-label>
        {{ 'common.email' | translate }}
      </shabic-form-field-label>

      <input type="email" formControlName="email" shabicInput name="" />
    </shabic-form-field>

    <shabic-form-field-errors
      [errors]="model.formGroup.errors"
    ></shabic-form-field-errors>

    <p class="mt-3">
      <button
        type="submit"
        shabicButton="secondary"
        class="w-full gap-1"
        [disabled]="(loading$ | async) || model.formGroup.invalid"
      >
        {{ 'button.continue' | translate }}

        <shabic-spinner *ngIf="loading$ | async"></shabic-spinner>
      </button>
    </p>
  </ng-container>

  <ng-container *ngIf="completed">
    <ng-content select="[message]"></ng-content>
  </ng-container>
</form>
