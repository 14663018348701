import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
} from '@angular/core';
import { InputDirective } from '../directives/input.directive';
import { FormFieldLabelComponent } from '../form-field-label/form-field-label.component';

@Component({
  selector: 'shabic-radio-control',
  templateUrl: './radio-control.component.html',
  styleUrls: ['./radio-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioControlComponent {
  @ContentChild(InputDirective) input?: InputDirective;
  @ContentChild(FormFieldLabelComponent) label?: FormFieldLabelComponent;

  onToggle(): void {
    this.input?.click();
  }
}
