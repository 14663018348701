import { DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { DrawerDialogConfig } from '@shabic/models';

@Component({
  selector: 'shabic-drawer-dialog',
  templateUrl: './drawer-dialog.component.html',
  styleUrls: ['./drawer-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrawerDialogComponent {
  @Input() config!: DrawerDialogConfig | null;

  @Output() back = new EventEmitter<void>();

  onClose$ = this.dialogRef.closed;

  constructor(public dialogRef: DialogRef) {}

  close(result?: Record<string, any>) {
    this.dialogRef.close(result);
  }

  onBack() {
    this.back.emit();
  }
}
