import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CategoriesService } from '@shabic/core';
import { FormComponent, FORM_CONFIG } from '@shabic/form-control';
import {
  ApiErrorResponse,
  ApiResponse,
  countries,
  countryOptions,
  currencies,
  IProductPayload,
  isErrorResponse,
  Option,
  Product,
} from '@shabic/models';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'shabic-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: FORM_CONFIG, useValue: { markRequiredFields: true } }],
})
export class ProductFormComponent
  extends FormComponent<unknown>
  implements OnInit
{
  private _pictures = new BehaviorSubject<File[]>([]);
  private _removePictures = new Set<number>();

  @Input() product?: Product;
  @Input() request!: (
    product: IProductPayload,
    removePictures?: number[]
  ) => Observable<ApiResponse<unknown | ApiErrorResponse>>;
  existPictures!: string[];

  readonly formGroup = new FormGroup<any>({
    nameFirst: new FormControl(null, [Validators.required]),
    nameSecond: new FormControl(null, [Validators.required]),
    descriptionFirst: new FormControl(null, [Validators.required]),
    descriptionSecond: new FormControl(null, [Validators.required]),
    brand: new FormControl(null, [Validators.required]),
    code: new FormControl(null, [Validators.required]),
    barCode: new FormControl(null, [Validators.required]),
    govtProductCode: new FormControl(null, [Validators.required]),
    country: new FormControl(null, [Validators.required]),
    categoryId: new FormControl(null, [Validators.required]),
    packing: new FormControl(null, [Validators.required]),
    packingSecond: new FormControl(null),
    priceFirst: new FormControl(null, [Validators.required]),
    priceSecond: new FormControl(null),
    measurement: new FormControl(null, [Validators.required]),
    measurementSecond: new FormControl(null),
    packingQuantity: new FormControl(null),
    dimension: new FormControl(null),
    weight: new FormControl(null),
    color: new FormControl(null),
    images: new FormControl(null),
    typeOfPackaging: new FormControl('retail'),
    food: new FormControl('yes'),
    specialHandling: new FormControl('yes'),
    vat: new FormControl(null),
    discount: new FormControl(null),
    grand: new FormControl(null),
    quantity: new FormControl(null),
    currency: new FormControl('USD'),
  });

  get currentCurrency(): string {
    return this.formGroup.get('currency')?.value;
  }

  categories$!: Observable<Option[]>;
  readonly currencies = currencies;
  readonly countries$ = new BehaviorSubject(
    countryOptions(this.translateService)
  );

  constructor(
    private categoriesService: CategoriesService,
    private translateService: TranslateService
  ) {
    super();

    this.categories$ = this.categoriesService.categories$;
    this.countries$ = new BehaviorSubject(
      countryOptions(this.translateService)
    );
  }

  ngOnInit(): void {
    this.categoriesService.updateCategories().subscribe(response => {
      if (this.product) {
        const categories = !isErrorResponse(response) ? response.payload : [];
        const payload = this.product.getProductPayload();

        this.formGroup.patchValue({
          ...payload,
          categoryId:
            typeof payload.categoryId === 'number'
              ? categories
                  .find(el => el.id === payload.categoryId)
                  ?.name.get('en')
              : payload.categoryId,
          food: payload.food ? 'yes' : 'no',
          specialHandling: payload.specialHandling ? 'yes' : 'no',
          currency: payload.currencyFirst,
        });
        this.formGroup.get('barCode')?.disable();
        this.existPictures = this.product.getMedias();
      }
    });
  }

  onSubmit(): void {
    const value = this.formGroup.value;
    const body = {
      ...value,
      food: value.food === 'yes',
      specialHandling: value.specialHandling === 'yes',
      priceSecond: value.priceSecond || 0,
      vat: value.vat || 0,
      categoryId: value.categoryId
        ? this.categoriesService.getOptionValue(value.categoryId)
        : null,
      barCode: this.product ? this.product.barCode : value.barCode,
      currencyFirst: value.currency,
      currencySecond: value.currency,
      mediaId: this._pictures.value.length ? this._pictures.value : null,
    };

    this.submitForm(
      this.formGroup,
      this.request(
        body as IProductPayload,
        Array.from(this._removePictures.values())
      )
    ).subscribe();
  }

  setCurrency(currency: string): void {
    this.formGroup.patchValue({
      currency,
    });
  }

  onPicturesChanged(pictures: File[]) {
    this._pictures.next(pictures);
  }

  onPictureRemove(url: string) {
    const media = this.product?.getMediaByUrl(url);

    if (media) {
      this._removePictures.add(media.id);
      this.existPictures = this.existPictures.filter(el => el !== url);
    }
  }
}
