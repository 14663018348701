import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PasscodeComponent } from './passcode.component';
import { FormControlModule } from '@shabic/form-control';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [PasscodeComponent],
  imports: [CommonModule, FormControlModule, ReactiveFormsModule],
  exports: [PasscodeComponent],
})
export class PasscodeModule {}
