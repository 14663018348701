<shabic-form-field>
  <div class="flex gap-3 items-center justify-end">
    <label class="font-medium text-xs text-primary uppercase">
      {{ 'common.sort-by' | translate }}
    </label>

    <shabic-dropdown>
      <div
        class="text-sm text-primary font-medium mr-1 text-xs uppercase"
        target
      >
        {{ 'common.filters' | translate }}
      </div>

      <div content>
        <ul class="max-h-[240px] overflow-y-auto" *ngIf="options">
          <li
            class="text-primary text-sm px-3 py-2 hover:cursor-pointer hover:bg-primary hover:text-white"
            [class.font-semibold]="option.key === value"
            *ngFor="let option of options"
            (click)="onSelect(option)"
          >
            {{ option.label | translate }}
          </li>
        </ul>
      </div>
    </shabic-dropdown>
  </div>
</shabic-form-field>
