import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ApiErrorResponse,
  ApiMarketplace,
  ApiResponse,
  ENV,
  IEnvironment,
  ITask,
  Task,
} from '@shabic/models';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TasksService extends ApiMarketplace {
  override readonly url = '/tasks';

  constructor(private http: HttpClient, @Inject(ENV) env: IEnvironment) {
    super(env);
  }

  ping<T>(id: string): Observable<ApiResponse<Task<T> | ApiErrorResponse>> {
    return this.http.get<ITask<T>>(this.getEndpoint(`/${id}`)).pipe(
      map(response => new ApiResponse(new Task(response))),
      catchError(response =>
        of(new ApiResponse(new ApiErrorResponse(response)))
      )
    );
  }
}
