import { Dialog } from '@angular/cdk/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EAppEvent, EventBrokerService } from '@shabic/event-broker';
import { filter } from 'rxjs/operators';
import {
  AccountStatementDialogComponent,
  BankBalanceDialogComponent,
  ConfirmDialogComponent,
  DocumentsDialogComponent,
  LoginDialogComponent,
  PreviewDialogComponent,
  ProductFormDialogComponent,
  RequestAccountStatementDialogComponent,
  RequestProductsDialogComponent,
  ReturnOrderDialogComponent,
  LoginRequireDialogComponent,
  RejectCompanyDialogComponent,
  CompanyProfileDialogComponent,
  BlockUserDialogComponent,
  BankAccountDialogComponent,
  BankDialogComponent,
  CategoryDialogComponent,
  NavigationDialogComponent,
  DeactivateProductDialogComponent,
  FilterDialogComponent,
  GalleryDialogComponent,
  ForgotPasswordDialogComponent,
} from '../components';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(
    private dialog: Dialog,
    private eventBrokerService: EventBrokerService,
    private translateService: TranslateService,
    private overlay: Overlay
  ) {}

  init() {
    this.onConfirmDialogOpen();
    this.onRequestProductsDialogOpen();
    this.onReturnOrderDialogOpen();
    this.onBankBalanceDialogOpen();
    this.onRequestAccountStatementDialogOpen();
    this.onAccountStatementDialogOpen();
    this.onViewDocumentsDialogOpen();
    this.onProductFormDialogOpen();
    this.onPreviewDialogOpen();
    this.onLoginDialogOpen();
    this.onLoginRequiredDialogOpen();
    this.onRejectCompanyDialogOpen();
    this.onCompanyProfileDialogOpen();
    this.onBlockUserDialogOpen();
    this.onBankAccountDialogOpen();
    this.onBankDialogOpen();
    this.onCategoryDialogOpen();
    this.onNavigationDialogOpen();
    this.onDeactivateProductDialogOpen();
    this.onFilterDialogOpen();
    this.onGalleryDialogOpen();
    this.onForgotPasswordDialogOpen();
  }

  get direction() {
    return this.translateService.currentLang === 'ar' ? 'rtl' : 'ltr';
  }

  private onConfirmDialogOpen() {
    this.eventBrokerService.events$
      .pipe(filter(event => event.type === EAppEvent.OpenConfirmDialog))
      .subscribe(event => {
        this.dialog.open(ConfirmDialogComponent, {
          width: '100%',
          maxWidth: 500,
          data: event.payload,
          direction: this.direction,
        });
      });
  }

  private onRequestProductsDialogOpen() {
    this.eventBrokerService.events$
      .pipe(filter(event => event.type === EAppEvent.OpenRequestProductsDialog))
      .subscribe(event => {
        this.dialog.open(RequestProductsDialogComponent, {
          width: '100%',
          maxWidth: 720,
          data: event.payload,
          direction: this.direction,
        });
      });
  }

  private onReturnOrderDialogOpen() {
    this.eventBrokerService.events$
      .pipe(filter(event => event.type === EAppEvent.OpenReturnOrderDialog))
      .subscribe(event => {
        this.dialog.open(ReturnOrderDialogComponent, {
          width: '100%',
          maxWidth: 500,
          data: event.payload,
          direction: this.direction,
        });
      });
  }

  private onBankBalanceDialogOpen() {
    this.eventBrokerService.events$
      .pipe(filter(event => event.type === EAppEvent.OpenBankBalanceDialog))
      .subscribe(event => {
        this.dialog.open(BankBalanceDialogComponent, {
          width: '100%',
          maxWidth: 500,
          data: event.payload,
          direction: this.direction,
        });
      });
  }

  private onRequestAccountStatementDialogOpen() {
    this.eventBrokerService.events$
      .pipe(
        filter(
          event => event.type === EAppEvent.OpenRequestAccountStatementDialog
        )
      )
      .subscribe(event => {
        this.dialog.open(RequestAccountStatementDialogComponent, {
          width: '100%',
          maxWidth: 500,
          data: event.payload,
          direction: this.direction,
        });
      });
  }

  private onAccountStatementDialogOpen() {
    this.eventBrokerService.events$
      .pipe(
        filter(event => event.type === EAppEvent.OpenAccountStatementDialog)
      )
      .subscribe(event => {
        this.dialog.open(AccountStatementDialogComponent, {
          width: '100%',
          maxWidth: 800,
          data: event.payload,
          direction: this.direction,
        });
      });
  }

  private onViewDocumentsDialogOpen() {
    this.eventBrokerService.events$
      .pipe(filter(event => event.type === EAppEvent.OpenViewDocumentsDialog))
      .subscribe(event => {
        this.dialog.open(DocumentsDialogComponent, {
          width: '100%',
          maxWidth: 500,
          data: event.payload,
          direction: this.direction,
        });
      });
  }

  private onProductFormDialogOpen() {
    this.eventBrokerService.events$
      .pipe(filter(event => event.type === EAppEvent.OpenProductFormDialog))
      .subscribe(event => {
        this.dialog.open(ProductFormDialogComponent, {
          width: '100%',
          maxWidth: 800,
          data: event.payload,
          direction: this.direction,
        });
      });
  }

  private onPreviewDialogOpen() {
    this.eventBrokerService.events$
      .pipe(filter(event => event.type === EAppEvent.OpenPreviewDialog))
      .subscribe(event => {
        this.dialog.open(PreviewDialogComponent, {
          width: '100%',
          height: '100%',
          data: event.payload,
          direction: this.direction,
        });
      });
  }

  private onLoginDialogOpen() {
    this.eventBrokerService.events$
      .pipe(filter(event => event.type === EAppEvent.OpenLoginDialog))
      .subscribe(event => {
        this.dialog.open(LoginDialogComponent, {
          width: '100%',
          maxWidth: 1120,
          data: event.payload,
          direction: this.direction,
        });
      });
  }

  private onForgotPasswordDialogOpen() {
    this.eventBrokerService.events$
      .pipe(filter(event => event.type === EAppEvent.OpenForgotPasswordDialog))
      .subscribe(event => {
        this.dialog.open(ForgotPasswordDialogComponent, {
          width: '100%',
          maxWidth: 1120,
          data: event.payload,
          direction: this.direction,
        });
      });
  }

  private onLoginRequiredDialogOpen() {
    this.eventBrokerService.events$
      .pipe(filter(event => event.type === EAppEvent.OpenLoginRequiredDialog))
      .subscribe(event => {
        this.dialog.open(LoginRequireDialogComponent, {
          width: '100%',
          maxWidth: 400,
          data: event.payload,
          direction: this.direction,
        });
      });
  }

  private onRejectCompanyDialogOpen() {
    this.eventBrokerService.events$
      .pipe(filter(event => event.type === EAppEvent.OpenRejectCompanyDialog))
      .subscribe(event => {
        this.dialog.open(RejectCompanyDialogComponent, {
          width: '100%',
          maxWidth: 562,
          data: event.payload,
          direction: this.direction,
        });
      });
  }

  private onCompanyProfileDialogOpen() {
    this.eventBrokerService.events$
      .pipe(filter(event => event.type === EAppEvent.OpenCompanyProfileDialog))
      .subscribe(event => {
        this.dialog.open(CompanyProfileDialogComponent, {
          width: '100%',
          maxWidth: 562,
          data: event.payload,
          direction: this.direction,
        });
      });
  }

  private onBlockUserDialogOpen() {
    this.eventBrokerService.events$
      .pipe(filter(event => event.type === EAppEvent.OpenBlockUserDialog))
      .subscribe(event => {
        this.dialog.open(BlockUserDialogComponent, {
          width: '100%',
          maxWidth: 562,
          data: event.payload,
          direction: this.direction,
        });
      });
  }

  private onBankAccountDialogOpen() {
    this.eventBrokerService.events$
      .pipe(filter(event => event.type === EAppEvent.OpenBankAccountDialog))
      .subscribe(event => {
        this.dialog.open(BankAccountDialogComponent, {
          width: '100%',
          maxWidth: 500,
          data: event.payload,
          direction: this.direction,
        });
      });
  }

  private onBankDialogOpen() {
    this.eventBrokerService.events$
      .pipe(filter(event => event.type === EAppEvent.OpenBankDialog))
      .subscribe(event => {
        this.dialog.open(BankDialogComponent, {
          width: '100%',
          maxWidth: 500,
          data: event.payload,
          direction: this.direction,
        });
      });
  }

  private onCategoryDialogOpen() {
    this.eventBrokerService.events$
      .pipe(filter(event => event.type === EAppEvent.OpenCategoryDialog))
      .subscribe(event => {
        this.dialog.open(CategoryDialogComponent, {
          width: '100%',
          maxWidth: 500,
          data: event.payload,
          direction: this.direction,
        });
      });
  }

  private onNavigationDialogOpen() {
    this.eventBrokerService.events$
      .pipe(filter(event => event.type === EAppEvent.OpenNavigationDialog))
      .subscribe(event => {
        this.dialog.open(NavigationDialogComponent, {
          width: '100%',
          height: '100%',
          data: event.payload,
          direction: this.direction,
          closeOnNavigation: true,
        });
      });
  }

  private onDeactivateProductDialogOpen() {
    this.eventBrokerService.events$
      .pipe(
        filter(event => event.type === EAppEvent.OpenDeactivateProductDialog)
      )
      .subscribe(event => {
        this.dialog.open(DeactivateProductDialogComponent, {
          width: '100%',
          maxWidth: 500,
          data: event.payload,
          direction: this.direction,
        });
      });
  }

  private onFilterDialogOpen() {
    this.eventBrokerService.events$
      .pipe(filter(event => event.type === EAppEvent.OpenFilterDialog))
      .subscribe(event => {
        this.dialog.open(FilterDialogComponent, {
          width: '100%',
          height: 'calc(100% - 6rem)',
          data: event.payload,
          direction: this.direction,
          backdropClass: 'white',
          positionStrategy: this.overlay
            .position()
            .global()
            .centerHorizontally()
            .bottom(),
        });
      });
  }

  private onGalleryDialogOpen() {
    this.eventBrokerService.events$
      .pipe(filter(event => event.type === EAppEvent.OpenGalleryDialog))
      .subscribe(event => {
        this.dialog.open(GalleryDialogComponent, {
          data: event.payload,
          width: '100%',
          height: '100%',
        });
      });
  }
}
