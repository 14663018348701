<ng-container *ngIf="list$ | async as list">
  <shabic-dialog
    #dialog
    name="dialog.request-for-statement"
    [nameOnDesktopHidden]="true"
  >
    <div class="hidden desktop:block">
      <shabic-headline variant="h3" align="center">
        {{ 'dialog.request-for-statement' | translate }}
      </shabic-headline>
    </div>

    <div class="mt-1 desktop:mt-4">
      <ng-container *ngIf="loading$ | async; else listContent">
        <ng-container *ngTemplateOutlet="loader"></ng-container>
      </ng-container>

      <ng-template #listContent>
        <div class="overflow-x-auto">
          <table class="w-full">
            <thead>
              <tr class="border-y border-grey-300">
                <th
                  class="ltr:text-left rtl:text-right font-semibold uppercase text-[10px] text-grey px-3 py-[18px]"
                >
                  {{ 'common.no' | translate }}
                </th>
                <th
                  class="ltr:text-left rtl:text-right font-semibold uppercase text-[10px] text-grey px-3 py-[18px]"
                >
                  {{ 'common.date' | translate }}
                </th>
                <th
                  class="ltr:text-left rtl:text-right font-semibold uppercase text-[10px] text-grey px-3 py-[18px]"
                >
                  {{ 'common.description' | translate }}
                </th>
                <th
                  class="ltr:text-left rtl:text-right font-semibold uppercase text-[10px] text-grey px-3 py-[18px]"
                >
                  {{ 'common.credit' | translate }}
                </th>
                <th
                  class="ltr:text-left rtl:text-right font-semibold uppercase text-[10px] text-grey px-3 py-[18px]"
                >
                  {{ 'common.debit' | translate }}
                </th>
                <th
                  class="ltr:text-left rtl:text-right font-semibold uppercase text-[10px] text-grey px-3 py-[18px]"
                >
                  {{ 'common.balance' | translate }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                class="even:bg-grey-350"
                *ngFor="let statement of list.content"
              >
                <td
                  class="text-primary text-sm font-semibold ltr:rounded-l-2xl rtl:rounded-r-2xl"
                >
                  {{ statement.no }}
                </td>
                <td class="text-primary text-sm truncate">
                  {{ statement.createdAt | time: 'D MMM YYYY' }}
                </td>
                <td class="text-primary text-sm">
                  {{ statement.description }}
                </td>
                <td class="text-primary text-sm">
                  {{ statement.credit | currency }}
                </td>
                <td class="text-primary text-sm">
                  {{ statement.debit | currency }}
                </td>
                <td
                  class="text-primary text-sm ltr:rounded-r-2xl rtl:rounded-l-2xl"
                >
                  {{ statement.balance | currency }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-template>

      <p class="my-4 flex justify-center">
        <shabic-pagination
          [pages]="list.pages"
          [page]="list.page"
          (nextPage)="changeParam('page', $event)"
        ></shabic-pagination>
      </p>

      <hr class="text-grey-350" />

      <div class="desktop:grid gap-3 mt-4">
        <div class="text-center">
          <span class="text-sm font-medium text-grey text-center">
            {{ 'common.download-as' | translate }}
          </span>
        </div>

        <div
          class="flex flex-col desktop:flex-row desktop:justify-center gap-x-3 gap-y-1 pb-4 grow px-[4px] mt-4 desktop:mt-0"
        >
          <a
            href="https://shabic-dev.s3.eu-central-1.amazonaws.com/asserts/Bank+Request+Statement.pdf"
            shabicButton="default"
            class="gap-[10px]"
            download
          >
            <ng-template shabicIcon="pdf"></ng-template>
            <span>PDF</span>
          </a>

          <a
            href="https://shabic-dev.s3.eu-central-1.amazonaws.com/asserts/Bank+Request+Statement.xlsx"
            shabicButton="default"
            class="gap-[10px]"
            download
          >
            <ng-template shabicIcon="excel"></ng-template>
            <span>Excel</span>
          </a>

          <a
            href="https://shabic-dev.s3.eu-central-1.amazonaws.com/asserts/Bank+Request+Statement.csv"
            shabicButton="default"
            class="gap-[10px]"
            download
          >
            <ng-template shabicIcon="csv"></ng-template>
            <span>CSV</span>
          </a>
        </div>
      </div>
    </div>
  </shabic-dialog>
</ng-container>

<ng-template #loader>
  <div class="text-grey flex justify-center py-5">
    <shabic-spinner size="xl"></shabic-spinner>
  </div>
</ng-template>
