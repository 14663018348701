import { Injectable, NgZone } from '@angular/core';
import { TOKEN_KEY } from '@shabic/constants';
import { ApiToken } from '@shabic/models';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';

type Payload = Record<string, unknown>;
type Options = Record<string, unknown>;

declare class SSE {
  constructor(url: string, options: Options);
  stream(): unknown;
  onmessage(event: unknown): unknown;
  onerror(error: unknown): unknown;
}

@Injectable({
  providedIn: 'root',
})
export class EventSourceService {
  constructor(private zone: NgZone, private storageService: StorageService) {}

  getEventSource(url: string, payload: Payload): Observable<any> {
    const options = this.getOptions('POST', payload);

    return new Observable(observer => {
      const eventSource = new SSE(url, options);
      eventSource.stream();

      eventSource.onmessage = event => {
        this.zone.run(() => {
          observer.next(event);
        });
      };

      eventSource.onerror = error => {
        this.zone.run(() => {
          observer.error(error);
        });
      };
    });
  }

  private getOptions(meth: string, payload: unknown) {
    const options = {
      payload: JSON.stringify(payload),
      method: meth,
      headers: {
        'Content-Type': 'application/json',
      } as Record<string, string>,
    };

    const token = this.storageService.retrieve<string>(TOKEN_KEY, true);
    const tokenPayload = token ? new ApiToken(token) : null;

    if (tokenPayload && !tokenPayload.isExpired) {
      options['headers']['Authorization'] = `Bearer ${token}`;
    }

    return options;
  }
}
