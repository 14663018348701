import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from './search.component';
import {
  SearchFilterTagsComponent,
  SearchFilterCategoriesComponent,
} from './components';
import { TranslateModule } from '@ngx-translate/core';
import {
  LayoutModule,
  PaginationModule,
  SearchFormModule,
} from '@shabic/shared';
import { UiModule } from '@shabic/ui';
import { SearchFilterComponent } from './components/search-filter/search-filter.component';
import { IconModule } from '@shabic/icon';
import { FormControlModule } from '@shabic/form-control';
import { SearchFilterDividerDirective } from './directives';
import { ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from '@shabic/utils';
import { OverlayModule } from '@angular/cdk/overlay';
import { SearchPopoverComponent } from './components/search-popover/search-popover.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    LayoutModule,
    SearchFormModule,
    UiModule,
    IconModule,
    FormControlModule,
    PaginationModule,
    ReactiveFormsModule,
    UtilsModule,
    OverlayModule,
  ],
  declarations: [
    SearchComponent,
    SearchFilterTagsComponent,
    SearchFilterCategoriesComponent,
    SearchFilterComponent,
    SearchFilterDividerDirective,
    SearchPopoverComponent,
  ],
  exports: [
    SearchComponent,
    SearchFilterCategoriesComponent,
    SearchFilterTagsComponent,
    SearchFilterComponent,
    SearchFilterDividerDirective,
  ],
})
export class SearchModule {}
