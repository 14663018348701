import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  AccountStatementDialogData,
  BankAccount,
  bankAccountStatuses,
  BankBalanceDialogData,
  CreateBankAccountDialogData,
  EditBankAccountDialogData,
  IBankAccountResponse,
  IBankAccountStatementParams,
  IListParams,
  isErrorResponse,
  List,
  ListComponent,
  RequestAccountStatementDialogData,
  SortOption,
} from '@shabic/models';
import { AppEvent, EAppEvent, EventBrokerService } from '@shabic/event-broker';
import { switchMap } from 'rxjs/operators';
import { BankAccountService } from '@shabic/core';

@Component({
  selector: 'shabic-bank-account',
  templateUrl: './bank-account.component.html',
  styleUrls: ['./bank-account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BankAccountComponent
  extends ListComponent<BankAccount, IBankAccountResponse[]>
  implements OnInit
{
  readonly statuses = bankAccountStatuses;

  constructor(
    private eventBrokerService: EventBrokerService,
    private bankAccountService: BankAccountService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initList(
      switchMap(params =>
        this.bankAccountService.accounts(params as IListParams)
      )
    );

    this.setParams({
      ...List.initParams,
      statuses: 'ACTIVE',
      size: 100,
    });
  }

  onChangeParam(params: IListParams): void {
    this.setParams(params);
  }

  onShowBankBalance(account: BankAccount) {
    this.eventBrokerService.emit(
      new AppEvent<BankBalanceDialogData>(EAppEvent.OpenBankBalanceDialog, {
        account,
      })
    );
  }

  onRequestAccountStatement(account: BankAccount) {
    this.eventBrokerService.emit(
      new AppEvent<RequestAccountStatementDialogData>(
        EAppEvent.OpenRequestAccountStatementDialog,
        {
          account,
          onRequest: params => this.showAccountStatement(params),
        }
      )
    );
  }

  onAddBankAccount() {
    this.eventBrokerService.emit(
      new AppEvent<CreateBankAccountDialogData>(
        EAppEvent.OpenBankAccountDialog,
        new CreateBankAccountDialogData(
          payload => this.bankAccountService.connect(payload),
          () => this.reload()
        )
      )
    );
  }

  onEdit(account: BankAccount) {
    this.eventBrokerService.emit(
      new AppEvent<EditBankAccountDialogData>(
        EAppEvent.OpenBankAccountDialog,
        new EditBankAccountDialogData(
          account,
          payload => this.bankAccountService.update(account, payload),
          () => this.reload()
        )
      )
    );
  }

  onToggleStatus(account: BankAccount) {
    const request = account.isActive
      ? () => this.bankAccountService.disconnect(account)
      : () => this.bankAccountService.activate(account);

    request().subscribe(response => {
      if (!isErrorResponse(response)) {
        this.reload();
      }
    });
  }

  onSort(option: SortOption<string>) {
    this.changeParam('statuses', option.key);
  }

  private showAccountStatement(payload: IBankAccountStatementParams) {
    this.eventBrokerService.emit(
      new AppEvent<AccountStatementDialogData>(
        EAppEvent.OpenAccountStatementDialog,
        {
          params: payload,
        }
      )
    );
  }
}
