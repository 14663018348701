<ul class="flex flex-wrap gap-1">
  <li *ngFor="let item of statuses">
    <a
      href=""
      [shabicBadge]="item.active ? 'primary' : ''"
      (click)="onChange($event, item)"
      [class.hover:bg-grey-350]="!item.active"
      >{{ item.name | translate }}</a
    >
  </li>
</ul>
