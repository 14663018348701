import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LOGO_SOURCE_PRIMARY } from '@shabic/constants';
import { AccountService } from '@shabic/core';
import { AppEvent, EAppEvent, EventBrokerService } from '@shabic/event-broker';
import { ForgotPassword, LoginDialogData } from '@shabic/models';

@Component({
  selector: 'shabic-forgot-password-dialog',
  templateUrl: './forgot-password-dialog.component.html',
  styleUrls: ['./forgot-password-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordDialogComponent {
  readonly logoSource = LOGO_SOURCE_PRIMARY;
  forgotPassword: ForgotPassword<AccountService>;
  token?: string;

  constructor(
    public accountService: AccountService,
    private route: ActivatedRoute,
    private eventBrokerService: EventBrokerService
  ) {
    this.forgotPassword = new ForgotPassword(accountService);
    const { token, data } = this.route.snapshot.queryParams;

    if (token && data) {
      this.token = token;

      try {
        const payload = JSON.parse(atob(data));

        this.forgotPassword.formGroup.patchValue(payload);
      } catch (e) {
        // console.log(e);
      }

      this.forgotPassword.nextStep();
    }
  }

  onLogin() {
    this.eventBrokerService.emit(
      new AppEvent<LoginDialogData>(EAppEvent.OpenLoginDialog, {
        onSuccess: () =>
          this.eventBrokerService.emit(
            new AppEvent<null>(EAppEvent.Authorized, null)
          ),
      })
    );
  }
}
