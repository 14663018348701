import { IMediaResponse, Media } from './media.model';
import {
  DEFAULT_BANNER_DURATION,
  getCompanyProductsQuery,
} from '@shabic/constants';
import { FormControl } from '@angular/forms';

export type PromotionType =
  | 'PRODUCT_DETAILS'
  | 'PRODUCTS_CATALOG'
  | 'SUPPLIERS';

export const promotionTypes: PromotionType[] = [
  'PRODUCTS_CATALOG',
  'PRODUCT_DETAILS',
  'SUPPLIERS',
];

export interface IPromotionResponse {
  id: number;
  description: string;
  companyId: number;
  media: IMediaResponse;
  type: PromotionType;
  attributes?: unknown;
  active: boolean;
  duration?: number;
}

export interface IPromotionPayload {
  id?: Nullable<number>;
  description: string;
  companyId: number;
  mediaId?: File[] | number;
  type: PromotionType;
  attributes?: unknown;
  active?: boolean;
  duartion?: number;
}

export class Promotion {
  public id: number;
  public active: boolean;
  public attributes?: unknown;
  public companyId: number;
  public description?: string;
  public media: Media;
  public type: PromotionType;
  public productsQuery: string;
  public duration: number;

  control: FormControl;
  durationControl: FormControl;

  constructor(payload: IPromotionResponse) {
    this.id = payload.id;
    this.active = payload.active;
    this.attributes = payload.attributes;
    this.companyId = payload.companyId;
    this.description = payload.description;
    this.media = new Media(payload.media);
    this.type = payload.type;
    this.productsQuery = getCompanyProductsQuery(payload.companyId);
    this.control = new FormControl(payload.active);
    this.durationControl = new FormControl(payload.duration);
    this.duration = payload.duration || DEFAULT_BANNER_DURATION;
  }
}
