<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    opacity="0.4"
    d="M2 6.447C2 3.996 4.03024 2 6.52453 2H11.4856C13.9748 2 16 3.99 16 6.437V17.553C16 20.005 13.9698 22 11.4744 22H6.51537C4.02515 22 2 20.01 2 17.563V16.623V6.447Z"
    fill="currentColor"
    fill-opacity="0.75"
  />
  <path
    d="M21.7792 11.4541L18.9334 8.54506C18.6393 8.24506 18.166 8.24506 17.8728 8.54706C17.5807 8.84906 17.5816 9.33606 17.8748 9.63606L19.434 11.2291H17.9391H9.54875C9.13483 11.2291 8.79883 11.5741 8.79883 11.9991C8.79883 12.4251 9.13483 12.7691 9.54875 12.7691H19.434L17.8748 14.3621C17.5816 14.6621 17.5807 15.1491 17.8728 15.4511C18.0199 15.6021 18.2118 15.6781 18.4046 15.6781C18.5955 15.6781 18.7873 15.6021 18.9334 15.4531L21.7792 12.5451C21.9204 12.4001 22.0003 12.2041 22.0003 11.9991C22.0003 11.7951 21.9204 11.5991 21.7792 11.4541Z"
    fill="currentColor"
    fill-opacity="0.75"
  />
</svg>
