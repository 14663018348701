import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ListProduct } from '@shabic/models';

@Component({
  selector: 'shabic-product-overview',
  templateUrl: './product-overview.component.html',
  styleUrls: ['./product-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductOverviewComponent {
  @Input() product!: ListProduct;

  @Output() requested: EventEmitter<number> = new EventEmitter();

  onProductRequested(amount: number): void {
    this.requested.emit(amount);
  }
}
