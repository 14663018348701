import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppEvent, EAppEvent, EventBrokerService } from '@shabic/event-broker';
import { Language, Languages } from '@shabic/models';
import { DropdownComponent } from '@shabic/ui';
import { BehaviorSubject, map, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'shabic-language-dropdown',
  templateUrl: './language-dropdown.component.html',
  styleUrls: ['./language-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageDropdownComponent implements OnInit {
  private _destroy = new Subject<void>();
  private _currentLanguage = new BehaviorSubject<Language>(
    this.translateService.currentLang as Language
  );

  currentLanguage$ = this._currentLanguage.asObservable();
  readonly languages = Languages;

  @ViewChild(DropdownComponent) dropdown?: DropdownComponent;

  constructor(
    private translateService: TranslateService,
    private eventBrokerService: EventBrokerService
  ) {}

  ngOnInit(): void {
    this.translateService.onLangChange
      .pipe(
        takeUntil(this._destroy),
        map(value => value.lang as unknown as Language)
      )
      .subscribe(lang => this._currentLanguage.next(lang));
  }

  onChangeLanguage(lang: Language) {
    this.eventBrokerService.emit(
      new AppEvent<Language>(EAppEvent.ChangeLanguage, lang)
    );

    this.dropdown?.close();
  }
}
