import { FormControl, FormGroup, Validators } from '@angular/forms';
import { equalValidator, patternValidator } from '@shabic/constants';
import { ApiErrorResponse, ApiResponse } from './api.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Step } from './step.model';

type StepId = 'email' | 'passcode' | 'password';

export type ForgotPasswordStep = Step<StepId>;

interface IService {
  sendEmail: (
    email: string
  ) => Observable<ApiResponse<ApiErrorResponse | unknown>>;
  resetPassword: (
    password: string,
    token: string
  ) => Observable<ApiResponse<ApiErrorResponse | unknown>>;
}

export class ForgotPassword<S extends IService> {
  private _steps: ForgotPasswordStep[];
  private _currentStep: BehaviorSubject<ForgotPasswordStep>;
  private _completed: Subject<void> = new Subject<void>();
  private _service: S;

  formGroup: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl(''),
    confirmPassword: new FormControl(''),
  });

  currentStep$: Observable<ForgotPasswordStep>;
  completed$: Observable<void> = this._completed.asObservable();

  constructor(service: S) {
    this._steps = [new Step('email'), new Step('password')];

    this._currentStep = new BehaviorSubject(this._steps[0]);
    this.currentStep$ = this._currentStep.asObservable();
    this._service = service;
  }

  nextStep(): void {
    const currentStep = this._currentStep.value;
    const nextStepIndex =
      this._steps.findIndex(step => step.id === currentStep.id) + 1;

    if (nextStepIndex >= this._steps.length) {
      return;
    }

    currentStep.complete();

    this._currentStep.next(this._steps[nextStepIndex]);
    this.formGroup.get('password')?.setValidators([
      Validators.required,
      Validators.minLength(8),
      patternValidator(/\d/, { hasNumber: true }),
      patternValidator(/[A-Z]/, { hasCapitalCase: true }),
      patternValidator(/[a-z]/, { hasSmallCase: true }),
      patternValidator(/[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/, {
        hasSpecialCharacters: true,
      }),
    ]);
    this.formGroup.get('confirmPassword')?.setValidators([Validators.required]);
    this.formGroup.setValidators([
      equalValidator(
        ['password', 'confirmPassword'],
        'error.not-equal-password'
      ),
    ]);
  }

  sendEmail() {
    return this._service.sendEmail(this.formGroup.value.email);
  }

  savePassword(token: string) {
    return this._service.resetPassword(this.formGroup.value.password, token);
  }
}
