<shabic-dialog>
  <div class="flex flex-col gap-3 items-center mb-6">
    <div class="text-red">
      <ng-template shabicIcon="alert" size="lg"></ng-template>
    </div>

    <div *ngIf="isLoggedIn" class="text-primary text-center">
      {{ 'message.your-company-not-approved' | translate }}
    </div>

    <ng-container *ngIf="!isLoggedIn">
      <div class="text-primary text-center">
        {{ 'message.login-required' | translate }}
      </div>

      <a
        href=""
        class="text-secondary text-lg hover:underline"
        (click)="onLogin($event)"
      >
        {{ 'button.sign-in' | translate }}
      </a>
    </ng-container>
  </div>
</shabic-dialog>
