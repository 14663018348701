import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  BIRTHDAY_STORAGE_KEY,
  GOV_ID_STORAGE_KEY,
  SUCCESS,
} from '@shabic/constants';
import { OtpService, StorageService } from '@shabic/core';
import { FormComponent } from '@shabic/form-control';
import { AuthResponse } from '@shabic/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { SignUp, StepState } from '../../models';

@Component({
  selector: 'shabic-sign-up-govt-id-step',
  templateUrl: './sign-up-govt-id-step.component.html',
  styleUrls: ['./sign-up-govt-id-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignUpGovtIdStepComponent extends FormComponent<
  unknown | AuthResponse
> {
  private _state = new BehaviorSubject<StepState>('form');

  @Input() signUp!: SignUp;
  @Output() completed = new EventEmitter();

  state$: Observable<StepState> = this._state.asObservable();

  formGroup: FormGroup = new FormGroup({
    govId: new FormControl('', [Validators.required]),
    birthday: new FormControl('', [Validators.required]),
    passcode: new FormControl(''),
  });

  get today(): Date {
    return new Date();
  }

  constructor(
    private otpService: OtpService,
    private storageService: StorageService,
    protected override cd: ChangeDetectorRef
  ) {
    super();
  }

  onSubmit(): void {
    const state: StepState = this._state.value;
    const formValue = this.formGroup.value;

    if (state === 'form') {
      this.verifyGovId(formValue.govId);
    } else {
      this.verifyPasscode(formValue.passcode);
    }
  }

  private verifyGovId(govId: string): void {
    this.submitForm(
      this.formGroup,
      this.otpService.send(govId, 'GOV_ID')
    ).subscribe(status => {
      if (status === SUCCESS) {
        this._state.next('passcode');
        this.formGroup
          .get('passcode')
          ?.setValidators([Validators.required, Validators.minLength(6)]);
      }
    });
  }

  private verifyPasscode(passcode: string) {
    this.submitForm(
      this.formGroup,
      this.otpService.verify(passcode, 'ID')
    ).subscribe(status => {
      if (status === SUCCESS) {
        this.storageService.store(
          GOV_ID_STORAGE_KEY,
          this.formGroup.get('govId')?.value,
          true
        );
        this.storageService.store(
          BIRTHDAY_STORAGE_KEY,
          this.formGroup.get('birthday')?.value,
          true
        );

        this.completed.emit();
      }
    });
  }
}
