import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Product } from '@shabic/models';

@Component({
  selector: 'shabic-product-extra-details',
  templateUrl: './product-extra-details.component.html',
  styleUrls: ['./product-extra-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductExtraDetailsComponent {
  @Input() product!: Product;
}
