import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[shabicLayoutContent]',
})
export class LayoutContentDirective {
  @HostBinding('class')
  get elementClass(): string {
    return 'container px-[20px] desktop:max-w-[100vw] xl:max-w-[1752px] desktop:px-3 relative mx-auto min-h-full';
  }
}
