import {
  ChartArea,
  ChartConfiguration,
  ChartData,
  ChartOptions,
} from 'chart.js';

enum Color {
  Yellow = '#F0FF00',
  Primary = '#002E53',
  Secondary = '#00A4B9',
  Blue = '#0066AD',
  Grey = '#C8D2DA',
}

const colors = new Map<string, [string, string]>();
colors.set(Color.Primary, ['rgba(0, 46, 83, 0.6)', 'rgba(0, 46, 83, 0.1)']);
colors.set(Color.Secondary, [
  'rgba(0, 164, 185, 0.6)',
  'rgba(0, 164, 185, 0.1)',
]);
colors.set(Color.Blue, ['rgba(0, 102, 173, 0.6)', 'rgba(0, 102, 173, 0.1)']);
colors.set(Color.Yellow, ['rgba(240, 255, 0, 0.6)', 'rgba(240, 255, 0, 0.1)']);
colors.set(Color.Grey, [
  'rgba(200, 210, 218, 0.6)',
  'rgba(200, 210, 218, 0.1)',
]);

const backgroundColor: string[] = [
  Color.Primary,
  Color.Secondary,
  Color.Blue,
  Color.Yellow,
  Color.Grey,
];

interface IPieDataset {
  label: string;
  data: number[];
  backgroundColor: string[];
  hoverOffset: number;
}

interface IBarDataset {
  label: string;
  data: number[];
  backgroundColor: any[];
  borderColor?: any[];
  borderWidth?: number;
  tension: number;
}

interface ILineDataset {
  label: string;
  data: number[];
  fill?: boolean;
  backgroundColor?: any;
  borderColor?: any;
  tension: number;
}

export interface ILineChartResponse {
  timeline: Record<string, Record<'count', number>>;
}

export class PieChartConfig implements ChartConfiguration {
  readonly type = 'pie';
  data: ChartData;
  readonly options: ChartOptions = {
    responsive: true,
    plugins: {
      tooltip: {
        displayColors: false,
        backgroundColor: '#C8D2DA',
        bodyColor: '#002E53',
        cornerRadius: 4,
        bodyFont: {
          weight: '600',
          family: 'Montserrat',
        },
      },
    },
  };
  isEmpty: boolean;

  constructor(data: number[], labels?: string[]) {
    const datasets: IPieDataset[] = [
      {
        label: 'Dataset',
        data,
        backgroundColor,
        hoverOffset: 4,
      },
    ];
    this.data = {
      labels,
      datasets,
    };
    this.isEmpty = data.reduce((acc, curr) => acc + curr, 0) === 0;
  }

  get legend() {
    const { labels = [] } = this.data;
    const { backgroundColor, data } = this.data.datasets[0];

    return data.map((_, index) => {
      return {
        label: labels[index] as string,
        color: (backgroundColor as string[])[index],
      };
    });
  }
}

export class BarChartConfig implements ChartConfiguration {
  readonly type = 'bar';
  data: ChartData;
  readonly options: ChartOptions = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      tooltip: {
        displayColors: false,
        backgroundColor: '#C8D2DA',
        bodyColor: '#002E53',
        cornerRadius: 4,
        bodyFont: {
          weight: '600',
          family: 'Montserrat',
        },
      },
    },
  };

  constructor(data: number[], labels?: string[]) {
    const datasets: IBarDataset[] = [
      {
        label: 'Dataset',
        data: data,
        borderColor: backgroundColor.map(color => color),
        backgroundColor: backgroundColor.map(color => colors.get(color)?.[0]),
        tension: 0.4,
      },
    ];
    this.data = {
      labels,
      datasets,
    };
  }

  get legend() {
    const { labels = [] } = this.data;
    const { backgroundColor, data } = this.data.datasets[0];

    return data.map((_, index) => {
      return {
        label: labels[index] as string,
        color: (backgroundColor as string[])[index],
      };
    });
  }
}

export class LineChartConfig implements ChartConfiguration {
  readonly type = 'line';
  data: ChartData;
  readonly options: ChartOptions = {
    responsive: true,
    plugins: {
      tooltip: {
        displayColors: false,
        backgroundColor: '#C8D2DA',
        bodyColor: '#002E53',
        cornerRadius: 4,
        bodyFont: {
          weight: '600',
          family: 'Montserrat',
        },
      },
    },
  };

  constructor(data: number[], labels?: string[]) {
    const datasets: ILineDataset[] = [
      {
        label: '',
        data: data,
        fill: true,
        borderColor: Color.Primary,
        backgroundColor: gradient(Color.Primary),
        tension: 0.4,
      },
    ];

    this.data = {
      labels,
      datasets,
    };
  }

  get legend() {
    const { labels = [] } = this.data;

    return this.data.datasets.map((line, index) => {
      return {
        label: labels[index] as string,
        color: line.borderColor as string,
      };
    });
  }
}

const gradient = (color: Color) => (context: any) => {
  const chart = context.chart;
  const { ctx, chartArea } = chart;

  if (!chartArea) {
    // This case happens on initial chart load
    return;
  }

  let width: any, height: any, gradient: any;
  function getGradient(ctx: CanvasRenderingContext2D, area: ChartArea) {
    const chartWidth = area.right - area.left;
    const chartHeight = area.bottom - area.top;
    if (!gradient || width !== chartWidth || height !== chartHeight) {
      // Create the gradient because this is either the first render
      // or the size of the chart has changed
      width = chartWidth;
      height = chartHeight;
      gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);
      gradient.addColorStop(0, colors.get(color)?.[1]);
      gradient.addColorStop(1, colors.get(color)?.[0]);
    }

    return gradient;
  }

  return getGradient(ctx, chartArea);
};
