<shabic-headline variant="h5">
  <span class="text-secondary">{{ 'common.category' | translate }}</span>
</shabic-headline>

<ul class="flex flex-col gap-4 empty:hidden mt-4">
  <ng-container *ngFor="let category of categories">
    <li
      *ngIf="category.count"
      class="flex items-center justify-between text-sm text-grey font-semibold transition-colors hover:text-primary"
      [class.font-semibold]="category.active"
      [class.text-primary]="category.active"
    >
      <a href="#" (click)="onClick($event, category)">
        {{ category.name | currentLang | async }}
      </a>
      <span>{{ category.count }}</span>
    </li>
  </ng-container>
</ul>
