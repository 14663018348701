import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@shabic/core';
import { ProfileComponent } from './profile.component';
import {
  ChangePasswordComponent,
  CompanyInfoComponent,
  ProfileInfoComponent,
  BankAccountComponent,
  ApiUserComponent,
  MarketingComponent,
} from './components';

const routes: Routes = [
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ProfileInfoComponent,
      },
      {
        path: 'bank-account',
        component: BankAccountComponent,
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent,
      },
      {
        path: 'company-info',
        component: CompanyInfoComponent,
      },
      {
        path: 'api-user',
        component: ApiUserComponent,
      },
      {
        path: 'marketing',
        component: MarketingComponent,
      },
      {
        path: '**',
        redirectTo: '',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProfileRoutingModule {}
