import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconDirective } from './directives/icon.directive';
import {
  IconArrowComponent,
  IconCartComponent,
  IconCheckComponent,
  IconCloseComponent,
  IconDeleteComponent,
  IconEditComponent,
  IconVerifyCheckmarkInvertComponent,
  VerifyCheckmarkComponent,
  IconPlusComponent,
  IconSubItemComponent,
  IconManualComponent,
  IconPdfComponent,
  IconExcelComponent,
  IconCsvComponent,
  IconExcelLargeComponent,
  IconPictureComponent,
  IconAlertComponent,
  IconLocationComponent,
  IconImageComponent,
  IconCalendarComponent,
  IconViewDocumentsComponent,
  IconViewCompanyComponent,
  IconBlockUserComponent,
  IconAddToCartComponent,
  IconBookmarkComponent,
  IconMailComponent,
  IconPhoneComponent,
  IconHomeComponent,
  IconShabicComponent,
  IconArrowLeftComponent,
  IconProfileComponent,
  IconSearchComponent,
  IconUsersComponent,
  IconBasketComponent,
  IconDocumentComponent,
  IconLogoutComponent,
  IconNavComponent,
  IconBoxComponent,
  IconSignInComponent,
  IconOrderPlaceComponent,
  IconAddComponent,
  IconExcelLargeMonoComponent,
  IconStarComponent,
  IconFileComponent,
  IconBagComponent,
  IconCloseCircleComponent,
  IconCheckCircleComponent,
  IconBulkComponent,
  IconPersonComponent,
  IconPasswordComponent,
  IconDownloadComponent,
  IconPreviewComponent,
  IconLogoComponent,
  IconRefreshComponent,
  IconMagnifierComponent,
  IconArrowDownComponent,
  IconArrowUpComponent,
  IconEmptyBasketComponent,
  IconEmptyOrdersComponent,
} from './components';
import { IconCopyComponent } from './components/icon-copy/icon-copy.component';

@NgModule({
  imports: [CommonModule],
  declarations: [
    VerifyCheckmarkComponent,
    IconArrowComponent,
    IconVerifyCheckmarkInvertComponent,
    IconCartComponent,
    IconCheckComponent,
    IconCloseComponent,
    IconDeleteComponent,
    IconEditComponent,
    IconDirective,
    IconPlusComponent,
    IconSubItemComponent,
    IconPdfComponent,
    IconExcelComponent,
    IconCsvComponent,
    IconManualComponent,
    IconExcelLargeComponent,
    IconPictureComponent,
    IconAlertComponent,
    IconLocationComponent,
    IconImageComponent,
    IconCalendarComponent,
    IconViewDocumentsComponent,
    IconViewCompanyComponent,
    IconBlockUserComponent,
    IconAddToCartComponent,
    IconBookmarkComponent,
    IconMailComponent,
    IconPhoneComponent,
    IconShabicComponent,
    IconHomeComponent,
    IconArrowLeftComponent,
    IconProfileComponent,
    IconSearchComponent,
    IconUsersComponent,
    IconBasketComponent,
    IconDocumentComponent,
    IconLogoutComponent,
    IconNavComponent,
    IconBoxComponent,
    IconSignInComponent,
    IconOrderPlaceComponent,
    IconAddComponent,
    IconExcelLargeMonoComponent,
    IconFileComponent,
    IconStarComponent,
    IconBagComponent,
    IconCloseCircleComponent,
    IconCheckCircleComponent,
    IconBulkComponent,
    IconPersonComponent,
    IconPasswordComponent,
    IconDownloadComponent,
    IconPreviewComponent,
    IconLogoComponent,
    IconRefreshComponent,
    IconMagnifierComponent,
    IconArrowDownComponent,
    IconArrowUpComponent,
    IconEmptyBasketComponent,
    IconEmptyOrdersComponent,
    IconCopyComponent,
  ],
  exports: [
    VerifyCheckmarkComponent,
    IconVerifyCheckmarkInvertComponent,
    IconDirective,
  ],
})
export class IconModule {}
