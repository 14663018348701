<div class="grid gap-3 place-content-center h-full px-3">
  <div class="relative">
    <img
      shabicImage
      class="rounded-2xl image border border-grey-350 image"
      [src]="image$ | async"
    />
  </div>

  <ul class="flex gap-1 snap-x overflow-x-auto empty:hidden shrink-0 p-[2px]">
    <li
      class="snap-center shrink-0"
      *ngFor="let media of images; let index = index"
      (click)="setImage(index)"
    >
      <img
        shabicImage
        class="w-10 h-10 rounded-2xl outline outline-2"
        [class.outline-primary]="(image$ | async) === media"
        [class.outline-transparent]="(image$ | async) !== media"
        [src]="media"
      />
    </li>
  </ul>
</div>

<button
  type="button"
  class="text-primary absolute top-3 right-3 z-10 bg-white"
  shabicButton="navigation"
  (click)="close()"
>
  <ng-template shabicIcon="close"></ng-template>
</button>
