<shabic-headline variant="h5">
  <span class="text-secondary">{{ name | translate }}</span>
</shabic-headline>

<ul class="flex flex-col gap-4 mt-4" *ngIf="options$ | async as list">
  <li *ngFor="let option of list" #item>
    <shabic-form-field
      (changed)="onChange(option, $event, item)"
      *ngIf="option.isGroup === false"
    >
      <shabic-checkbox-control>
        <input
          class="peer"
          type="checkbox"
          [formControl]="option.control"
          shabicInput
          [id]="option.value"
        />

        <shabic-form-field-label
          [forAttribute]="option.value"
          [colorInherit]="true"
        >
          <span
            [class.text-primary]="option.control.value"
            class="text-sm normal-case font-medium text-ellipsis overflow-hidden block"
          >
            {{ option.label | translate }}
          </span>
        </shabic-form-field-label>
      </shabic-checkbox-control>
    </shabic-form-field>

    <ng-container *ngIf="option.isGroup">
      <span class="flex items-center justify-between">
        <span class="text-sm font-medium text-primary">
          {{ option.label | translate }}
        </span>

        <button
          shabicButton
          type="button"
          class="w-4 h-4"
          (click)="option.toggleGroup()"
        >
          <span [class.rotate-180]="option.checked" class="text-grey">
            <ng-template shabicIcon="arrow"></ng-template>
          </span>
        </button>
      </span>
    </ng-container>

    <ul *ngIf="option.isGroup && option.checked" class="mt-2">
      <li class="mb-3 last:mb-0" *ngFor="let el of option.options" #item>
        <shabic-form-field (changed)="onChange(option, $event, item)">
          <shabic-checkbox-control>
            <input
              class="peer"
              type="checkbox"
              shabicInput
              [formControl]="option.control"
              [id]="el.value"
            />

            <shabic-form-field-label [forAttribute]="el.value">
              <span class="text-sm normal-case">
                {{ el.label | translate }}
              </span>
            </shabic-form-field-label>
          </shabic-checkbox-control>
        </shabic-form-field>
      </li>
    </ul>
  </li>

  <li>
    <a
      *ngIf="options.length > 5"
      class="inline-flex items-center gap-1 py-1 px-2 text-xs font-semibold text-primary hover:cursor-pointer bg-grey-350 rounded-3xl hover:bg-grey-400 transition-colors"
      (click)="onToggle()"
    >
      <span *ngIf="list.length < options.length">
        {{ 'button.show-more' | translate }}
      </span>
      <span *ngIf="list.length === options.length">
        {{ 'button.show-less' | translate }}
      </span>

      <span [class.rotate-180]="options.length === list.length">
        <ng-template shabicIcon="arrow"></ng-template>
      </span>
    </a>
  </li>
</ul>
