import { LIST_SIZE } from '@shabic/constants';
import { BehaviorSubject, Observable, OperatorFunction, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';

export interface IListResponse<P> {
  content: P;
  totalPages: number;
  number: number;
  totalElements: number;
  size: number;
  last: boolean;
}

export interface IListParams {
  page: number | number[];
  size?: number;
  [key: string]: any;
}

export class List<T, P> {
  private _content: BehaviorSubject<T[]>;
  page: number;
  pages: number;
  total: number;
  size: number;
  last: boolean;

  content$: Observable<T[]>;

  static initValue<T, P>(): List<T, P> {
    return new List(
      {
        content: <T[]>[],
        totalPages: 0,
        number: 0,
        totalElements: 0,
        size: LIST_SIZE,
        last: true,
      },
      content => content
    );
  }

  static get initParams(): IListParams {
    return {
      page: 0,
      size: LIST_SIZE,
    };
  }

  get content(): T[] {
    return this._content.value;
  }

  constructor(payload: IListResponse<P>, parse: (content: P) => T[]) {
    this._content = new BehaviorSubject(parse(payload.content));
    this.content$ = this._content.asObservable();
    this.page = payload.number;
    this.pages = payload.totalPages;
    this.total = payload.totalElements;
    this.size = payload.size || LIST_SIZE;
    this.last = payload.last;
  }
}

export abstract class ListComponent<I, R> {
  private _init = new BehaviorSubject<boolean>(false);
  protected _destroy = new Subject<void>();
  private _list = new BehaviorSubject<List<I, R>>(List.initValue());
  private _loading = new BehaviorSubject<boolean>(false);
  private _loadMoreLoading = new BehaviorSubject<boolean>(false);
  private _params = new BehaviorSubject<IListParams | null>(null);
  private _hasSelectedProducts = new BehaviorSubject(false);
  private _hasPagination = new BehaviorSubject<boolean>(false);
  private _hasLoadMore = new BehaviorSubject<boolean>(false);

  list$ = this._list.asObservable();
  loading$ = this._loading.asObservable();
  loadMoreloading$ = this._loadMoreLoading.asObservable();
  params$ = this._params.asObservable();
  hasSelectedProducts$ = this._hasSelectedProducts.asObservable();
  hasPagination$ = this._hasPagination.asObservable();
  destroy$ = this._destroy.asObservable();
  hasLoadMore$ = this._hasLoadMore.asObservable();

  readonly listForm = new FormGroup<Record<string, FormControl>>({});

  abstract onChangeParam(params: IListParams): void;

  initList(fns: OperatorFunction<IListParams | null, any>, prop = 'id') {
    this.params$
      .pipe(
        filter(params => params !== null),
        tap(params => {
          Array.isArray(params?.page)
            ? this._loadMoreLoading.next(true)
            : this._loading.next(true);
        }),
        map(params => {
          if (params !== null) {
            return {
              ...params,
              page: Array.isArray(params.page)
                ? Math.max(...params.page)
                : params.page,
            };
          } else {
            return null;
          }
        }),
        fns
      )
      .subscribe(response => {
        Array.isArray(this._params.value?.page)
          ? this._loadMoreLoading.next(false)
          : this._loading.next(false);

        this._loading.next(false);

        if (response instanceof List) {
          if (Array.isArray(this._params.value?.page)) {
            response.content.unshift(...this._list.value.content);
          }

          response.content.forEach(item => {
            this.listForm.addControl(item[prop], new FormControl(false), {
              emitEvent: false,
            });
          });

          this._hasPagination.next(response.pages > 1);
          this._hasLoadMore.next(!response.last);
          this._list.next(response);
        }

        this._init.next(true);
      });

    this.listForm.valueChanges
      .pipe(takeUntil(this._destroy))
      .subscribe(value => {
        const hasSelectedProducts = Object.keys(value).some(key => value[key]);

        this._hasSelectedProducts.next(hasSelectedProducts);
      });
  }

  changeParam(key: keyof IListParams, value: any) {
    const params = { [key]: value };

    if (key !== 'page') {
      params['page'] = 0;
    }

    this.updateParams(params);
  }

  changeParams(value: Partial<IListParams>) {
    if ('page' in value) {
      this.updateParams(value);
    } else {
      value['page'] = 0;
      this.updateParams(value);
    }
  }

  setParams(params: IListParams) {
    this._params.next(params);
  }

  protected getParams() {
    return this._params.value;
  }

  protected onDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  protected getContent() {
    return this._list.value?.content;
  }

  protected reload() {
    this.changeParam('page', 0);
  }

  protected encodeQuery(params: IListParams) {
    const string = JSON.stringify(params);

    return btoa(encodeURIComponent(string));
  }

  protected decodeQuery(encodedQuery: string) {
    const string = decodeURIComponent(atob(encodedQuery));

    return JSON.parse(string);
  }

  protected loadMore() {
    const currentPage = this._params.value?.['page'] || 0;

    if (Array.isArray(currentPage)) {
      const lastPage = Math.max(...currentPage);

      this.changeParam('page', [...currentPage, lastPage + 1]);
    } else {
      this.changeParam('page', [currentPage, currentPage + 1]);
    }
  }

  protected get isInit() {
    return this._init.value;
  }

  protected getParamValue(param: string) {
    return this._params.value?.[param];
  }

  private updateParams(params: Partial<IListParams>) {
    const currentParams = this._params.value;
    const queryParams = currentParams
      ? Object.assign({}, currentParams)
      : ({} as IListParams);

    for (const param in params) {
      if (params[param] !== null) {
        queryParams[param] = params[param];
      } else {
        delete queryParams[param];
      }
    }

    this.onChangeParam(queryParams);
  }
}
