export enum EAppEvent {
  ChangeLanguage,
  OpenDialog,
  OpenConfirmDialog,
  OpenRequestProductsDialog,
  OpenReturnOrderDialog,
  OpenBankBalanceDialog,
  OpenRequestAccountStatementDialog,
  OpenAccountStatementDialog,
  OpenViewDocumentsDialog,
  OpenProductFormDialog,
  OpenPreviewDialog,
  OpenLoginDialog,
  OpenLoginRequiredDialog,
  OpenRejectCompanyDialog,
  OpenCompanyProfileDialog,
  OpenBlockUserDialog,
  OpenBankAccountDialog,
  OpenBankDialog,
  OpenCategoryDialog,
  OpenNavigationDialog,
  OpenDeactivateProductDialog,
  OpenFilterDialog,
  OpenGalleryDialog,
  OpenForgotPasswordDialog,
  Authorized,
  UpdateCartCount,
  ChangeCurrency,
  LogOut,
  ShowSnackbar,
}

export class AppEvent<P> {
  constructor(public readonly type: EAppEvent, public payload: P) {}
}
