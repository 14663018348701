import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from '../services';
import { AuthResponse } from '@shabic/models';
import { AppEvent, EAppEvent, EventBrokerService } from '@shabic/event-broker';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private AUTH_HEADER = 'Authorization';

  constructor(
    private authService: AuthService,
    private eventBrokerService: EventBrokerService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(this.addAuthenticationToken(request)).pipe(
      catchError(error => {
        if (
          error.status === 401 &&
          !request.url.includes('/authenticate/refresh')
        ) {
          if (this.authService.hasRefreshTokenAndNotExpired) {
            return this.authService.refreshToken().pipe(
              switchMap(response => {
                if (response.payload instanceof AuthResponse) {
                  return next.handle(this.addAuthenticationToken(request));
                }

                this.eventBrokerService.emit(
                  new AppEvent(EAppEvent.LogOut, 'message.session-expired')
                );

                return throwError(() => error);
              })
            );
          } else if (this.authService.token) {
            this.eventBrokerService.emit(
              new AppEvent(EAppEvent.LogOut, 'message.session-expired')
            );
          }
        }

        return throwError(() => error);
      })
    );
  }

  private addAuthenticationToken(
    request: HttpRequest<unknown>
  ): HttpRequest<unknown> {
    if (this.authService.hasAccessTokenAndNotExpired) {
      return request.clone({
        headers: request.headers.set(
          this.AUTH_HEADER,
          'Bearer ' + this.authService.token
        ),
      });
    }

    return request;
  }
}
