<form shabicSignUpForm [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <ng-container [ngSwitch]="state$ | async">
    <ng-container *ngSwitchCase="'form'">
      <shabic-headline variant="h2" align="center">
        {{ 'message.email-verified' | translate }}
      </shabic-headline>
      <shabic-sub-headline class="block mt-0.5">
        {{ 'verify-phone.subtitle' | translate }}
      </shabic-sub-headline>

      <shabic-form-field class="text-primary mt-5.5" name="common.phone-number">
        <shabic-form-field-label>
          {{ 'common.phone-number' | translate }}
        </shabic-form-field-label>

        <input
          type="text"
          shabicInput
          formControlName="phone"
          prefix="+9665"
          mask="0-000-0000"
        />
      </shabic-form-field>
    </ng-container>

    <ng-container *ngSwitchCase="'passcode'">
      <div class="flex justify-center">
        <ng-template shabicIcon="phone"></ng-template>
      </div>

      <shabic-headline class="mt-5 block text-center" variant="h2">
        {{ 'passcode.phone-passcode' | translate }}
      </shabic-headline>

      <shabic-sub-headline>
        {{ 'passcode.enter-passcode' | translate }}
      </shabic-sub-headline>

      <shabic-passcode
        formControlName="passcode"
        class="mt-5.5 text-primary pb-3"
      ></shabic-passcode>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="state$ | async as state">
    <button
      [disabled]="formGroup.invalid || (loading$ | async)"
      class="mt-4 w-full gap-1"
      shabicButton="secondary"
      type="submit"
    >
      {{ (state === 'form' ? 'button.verify' : 'button.confirm') | translate }}

      <shabic-spinner *ngIf="loading$ | async"></shabic-spinner>
    </button>
  </ng-container>
</form>
