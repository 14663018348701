<shabic-animate>
  <shabic-headline variant="h3">
    <span class="desktop:text-2xl">
      {{ 'navigation.profile' | translate }}
    </span>
  </shabic-headline>

  <form
    [formGroup]="formGroup"
    (ngSubmit)="onSubmit()"
    autocomplete="off"
    class="desktop:max-w-[400px]"
  >
    <div class="mt-4 desktop:mt-5.5">
      <shabic-group-title
        name="profile.basic-information"
        icon="edit"
      ></shabic-group-title>
    </div>

    <div class="mt-5">
      <shabic-form-field>
        <shabic-image-control
          [value]="formGroup.get('avatar')?.value"
          (changed)="patchValue(formGroup, 'mediaId', $event)"
        >
          <input
            type="file"
            name=""
            hidden
            shabicInput
            accept="image/png,image/jpeg,image/jpg"
          />
        </shabic-image-control>
      </shabic-form-field>

      <shabic-form-field class="text-primary mt-5.5">
        <shabic-form-field-label forAttribute="first-name">
          {{ 'common.first-name' | translate }}
        </shabic-form-field-label>

        <input
          type="text"
          class="border border-grey-300"
          shabicInput
          formControlName="firstName"
          id="first-name"
        />
      </shabic-form-field>

      <shabic-form-field class="text-primary mt-4">
        <shabic-form-field-label forAttribute="last-name">
          {{ 'common.last-name' | translate }}
        </shabic-form-field-label>

        <input
          type="text"
          class="border border-grey-300"
          shabicInput
          formControlName="lastName"
          id="last-name"
        />
      </shabic-form-field>

      <shabic-form-field class="text-primary mt-4">
        <shabic-form-field-label forAttribute="email">
          {{ 'common.email' | translate }}
        </shabic-form-field-label>

        <input
          type="email"
          class="border border-grey-300"
          shabicInput
          formControlName="email"
          id="email"
        />
      </shabic-form-field>

      <shabic-form-field class="text-primary mt-4" name="common.phone-number">
        <shabic-form-field-label forAttribute="phone">
          {{ 'common.phone-number' | translate }}
        </shabic-form-field-label>

        <input
          type="text"
          class="border border-grey-300"
          shabicInput
          prefix="+9665"
          mask="0-000-0000"
          formControlName="phone"
          id="phone"
        />
      </shabic-form-field>
    </div>

    <div class="mt-6 desktop:mt-7">
      <shabic-group-title
        name="common.other-information"
        icon="icon-star"
      ></shabic-group-title>
    </div>

    <div class="mt-5">
      <shabic-form-field class="text-primary">
        <shabic-form-field-label>
          <span class="normal-case text-sm">
            {{ 'common.preferred-language' | translate }}
          </span>
        </shabic-form-field-label>

        <div class="flex gap-5.5 mt-3 mb-4">
          <shabic-radio-control>
            <input
              shabicInput
              class="peer"
              name="langKey"
              formControlName="langKey"
              value="en"
              type="radio"
              id="english"
            />

            <shabic-form-field-label forAttribute="english">
              <span class="normal-case text-sm">
                {{ 'common.english' | translate }}
              </span>
            </shabic-form-field-label>
          </shabic-radio-control>

          <shabic-radio-control>
            <input
              shabicInput
              class="peer"
              name="langKey"
              formControlName="langKey"
              value="ar"
              type="radio"
              id="arabic"
            />

            <shabic-form-field-label forAttribute="arabic">
              <span class="normal-case text-sm">
                {{ 'common.arabic' | translate }}
              </span>
            </shabic-form-field-label>
          </shabic-radio-control>
        </div>
      </shabic-form-field>

      <ng-container *ngIf="isClientApp">
        <hr class="text-grey-400" />

        <shabic-form-field class="block mt-4">
          <shabic-checkbox-control>
            <input
              class="peer"
              type="checkbox"
              formControlName="supplier"
              shabicInput
              id="supplier"
            />

            <shabic-form-field-label forAttribute="supplier">
              <span class="text-sm normal-case">
                {{ 'common.want-be-supplier' | translate }}
              </span>
            </shabic-form-field-label>
          </shabic-checkbox-control>
        </shabic-form-field>
      </ng-container>

      <button
        shabicButton="primary"
        [disabled]="formGroup.invalid || (loading$ | async)"
        class="w-full mt-4 gap-1"
        type="submit"
      >
        {{ 'button.save' | translate }}

        <shabic-spinner *ngIf="loading$ | async"></shabic-spinner>
      </button>
    </div>

    <div
      class="flex align-items justify-between desktop:justify-start gap-3 py-5.5 desktop:py-7"
    >
      <button type="button" shabicButton="default" (click)="onLogout()">
        {{ 'navigation.logout' | translate }}
      </button>

      <button
        *ngIf="isClientApp"
        type="button"
        shabicButton="link"
        class="hover:text-red transition-colors"
        (click)="onDelete()"
      >
        {{ 'button.delete-account' | translate }}
      </button>
    </div>
  </form>
</shabic-animate>
