<svg
  width="24"
  height="25"
  viewBox="0 0 24 25"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_522_138)">
    <path
      d="M16.2188 6.12512H20.7286L15.5156 0.912109V5.422C15.5156 5.80994 15.8315 6.12512 16.2188 6.12512Z"
      fill="currentColor"
    />
    <path
      d="M16.2188 7.53125C15.0556 7.53125 14.1094 6.58503 14.1094 5.42188V0.5H4.96875C3.80559 0.5 2.85938 1.44622 2.85938 2.60938V10.4375H21.1406V7.53125H16.2188Z"
      fill="currentColor"
    />
    <path
      d="M21.8906 11.8438H2.10938C0.946219 11.8438 0 12.79 0 13.9531V22.3906C0 23.5538 0.946219 24.5 2.10938 24.5H21.8906C23.0538 24.5 24 23.5538 24 22.3906V13.9531C24 12.79 23.0538 11.8438 21.8906 11.8438ZM6.375 20.2812C6.76228 20.2812 7.07812 19.9661 7.07812 19.5781C7.07812 19.1895 7.39261 18.875 7.78125 18.875C8.16989 18.875 8.48438 19.1895 8.48438 19.5781C8.48438 20.7413 7.53816 21.6875 6.375 21.6875C5.21184 21.6875 4.26562 20.7413 4.26562 19.5781V16.7656C4.26562 15.6025 5.21184 14.6562 6.375 14.6562C7.53816 14.6562 8.48438 15.6025 8.48438 16.7656C8.48438 17.1543 8.16989 17.4688 7.78125 17.4688C7.39261 17.4688 7.07812 17.1543 7.07812 16.7656C7.07812 16.3777 6.76228 16.0625 6.375 16.0625C5.98772 16.0625 5.67188 16.3777 5.67188 16.7656V19.5781C5.67188 19.9661 5.98772 20.2812 6.375 20.2812ZM12 21.6875C11.4356 21.6875 10.9055 21.4678 10.5072 21.0688C10.2339 20.7942 10.2339 20.3486 10.5086 20.0746C10.7832 19.8013 11.2296 19.7999 11.5042 20.0759C11.908 20.4811 12.7031 20.1549 12.7031 19.5781C12.7031 19.1902 12.3873 18.875 12 18.875C10.8368 18.875 9.89062 17.9288 9.89062 16.7656C9.89062 15.6025 10.8368 14.6562 12 14.6562C12.5644 14.6562 13.0945 14.876 13.4928 15.2749C13.7661 15.5495 13.7661 15.9952 13.4914 16.2692C13.2168 16.5425 12.7704 16.5438 12.4958 16.2678C12.0934 15.8627 11.2969 16.1888 11.2969 16.7656C11.2969 17.1536 11.6127 17.4688 12 17.4688C13.1632 17.4688 14.1094 18.415 14.1094 19.5781C14.1094 20.7413 13.1632 21.6875 12 21.6875ZM19.7138 15.5297L18.3075 21.1547C18.2293 21.4677 17.9477 21.6875 17.625 21.6875C17.3023 21.6875 17.0207 21.4677 16.9425 21.1547L15.5362 15.5297C15.4428 15.1534 15.6708 14.7716 16.0485 14.6775C16.4179 14.578 16.8065 14.8114 16.9012 15.1891L17.625 18.0853L18.3487 15.1891C18.4421 14.8121 18.8197 14.5793 19.2015 14.6775C19.5792 14.7716 19.8072 15.1534 19.7138 15.5297Z"
      fill="currentColor"
    />
  </g>
  <defs>
    <clipPath id="clip0_522_138">
      <rect
        width="24"
        height="24"
        fill="currentColor"
        transform="translate(0 0.5)"
      />
    </clipPath>
  </defs>
</svg>
